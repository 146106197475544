import { TabHeader } from 'components/TabHeader';
import React, { useState, useEffect } from 'react'
import TabsComp from 'components/RFQ/TabsComp/TabsComp';
import { Flex } from '@chakra-ui/core'
import SearchBar from 'components/RFQ/SearchBar/SearchBar';
import ButtonViewGroup from 'components/ButtonViewGroup/ButtonViewGroup';
import { useNavigate } from "@reach/router"
import tw, { styled, css } from "twin.macro";
import NotFoundDatas from 'components/RFQ/NotFoundDatas/NotFoundDatas';
import { getRFQServices } from 'services/supplier-flow/rfqServices';
import { useDispatch } from 'react-redux';
import { createRFQTOTAL } from 'actions/supplierflow/supplierRfqActions';
import RfqCard from './RfqCard';
import RfqList from './RfqList';
import RfqCardAnimation from './RfqCardAnimation';
const SupplierContainer = styled.div(() => [
    tw`relative overflow-hidden px-1.5`,
    css` 
        .buttons-container {
        gap: 8px;
        }
        .container-content {
        padding-right: 24px;
        padding-left: 24px;
        }
    `
]);
function RfqOverview() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [filterData, setFilterData] = useState([])

    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingPagination, setLoadingPagination] = useState(false)
    const [currentPage, setCurrPage] = useState(1)
    const limit = 15
    const [lastPage, setLastPage] = useState("")
    const [view, setView] = useState('grid');
    const [currentTab, setCurrentTab] = useState('All');
    const [search, setSearch] = useState('');
    const [tabMenu, setTabMenu] = useState([
        {
            id: 'all',
            name: 'All',
            showBadge: true,
            total: 0
        },
        {
            id: 'accepted',
            name: 'Accepted',
            showBadge: true,
            total: 0
        },
        {
            id: 'submited',
            name: 'Submitted',
            showBadge: true,
            total: 0
        },
        {
            id: 'unsuccesful',
            name: 'Unsuccessful',
            showBadge: true,
            total: 0
        }
    ])

    const changeActiveTab = (tab) => {
        setCurrentTab(tab)
        setTimeout(() => {
            switchData(tab, data)
        }, 200)
    }


    const navigatePreview = (id) => {
        if (window.location.href[window.location.href.length - 1] === "/") {
            navigate(`${id}`)
        } else {
            navigate(window.location.href + "/" + id)
        }
    }


    const switchData = (currentTab, data) => {
        switch (currentTab) {
            case "All":
                setFilterData(data.all.data)
                break;
            case "Accepted":
                setFilterData(data.accepted.data)
                break;
            case "Submitted":
                setFilterData(data.submitted.data)
                break;
            case "Unsuccessful":
                setFilterData(data.unsuccessful.data)
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        const fetchData = async () => {
            try {
                if (currentPage === 1) {
                    setIsLoading(true)
                } else {
                    if (currentPage > lastPage) {
                        return ""
                    }
                    setLoadingPagination(true)
                }
                const response = await getRFQServices(currentPage, search, limit, { signal: abortController.signal })
                let responseData = response.data.data
                if (data.accepted || data.all || data.submitted || data.unsuccessful) {
                    if (search.trim() !== "") {
                        setData(responseData);
                        setCurrPage(1)
                        switchData(currentTab, responseData)
                    } else {
                        let tempData = { ...data }
                        tempData.all.data = [...tempData.all.data, ...responseData.all.data]
                        tempData.accepted.data = [...tempData.accepted.data, ...responseData.accepted.data]
                        tempData.submitted.data = [...tempData.submitted.data, ...responseData.submitted.data]
                        tempData.unsuccessful.data = [...tempData.unsuccessful.data, ...responseData.unsuccessful.data]
                        setData(tempData);
                        switchData(currentTab, tempData)
                    }
                } else {
                    setData(responseData);
                    switchData(currentTab, responseData)
                }
                let totalRFQs = responseData.all.meta.total
                setLastPage(responseData.all.meta.last_page)
                await dispatch(createRFQTOTAL(totalRFQs))
                setIsLoading(false)
                setLoadingPagination(false)
            } catch (error) {
                console.log("error", error)
            }
        };
        fetchData();
        // Clean-up function
        return () => {
            abortController.abort();
        };
        //eslint-disable-next-line
    }, [currentPage, search]);

    useEffect(() => {
        let tempTabMenu = [
            {
                id: 'all',
                name: 'All',
                showBadge: true,
                total: 0
            },
            {
                id: 'accepted',
                name: 'Accepted',
                showBadge: true,
                total: 0
            },
            {
                id: 'submited',
                name: 'Submitted',
                showBadge: true,
                total: 0
            },
            {
                id: 'unsuccesful',
                name: 'Unsuccessful',
                showBadge: true,
                total: 0
            }
        ]

        tempTabMenu[0].total = data?.all?.meta?.total
        tempTabMenu[1].total = data?.accepted?.meta?.total
        tempTabMenu[2].total = data?.submitted?.meta?.total
        tempTabMenu[3].total = data?.unsuccessful?.meta?.total
        setTabMenu(tempTabMenu)
    }, [data])



    const onScroll = async () => {
        const { clientHeight, scrollHeight, scrollTop } = document.getElementById("element-scroll");
        if (scrollTop + clientHeight >= scrollHeight - 180) {
            setCurrPage(currentPage + 1)
        }
    };



    useEffect(() => {
        const handleScroll = () => {
            onScroll();
        };
        const scrollableElement = document.getElementById("element-scroll");
        if (scrollableElement) {
            scrollableElement.addEventListener("scroll", handleScroll);
            return () => {
                scrollableElement.removeEventListener("scroll", handleScroll);
            };
        }
        // eslint-disable-next-line
    }, [filterData]);

    return (
        <>
            <SupplierContainer>
                <TabHeader heading='YOUR RFx' description="" />
                <div id="layout-dashboard">
                    <Flex style={{ justifyContent: 'space-between' }}>
                        <Flex style={{ gap: '8px' }}>
                            <TabsComp
                                menus={tabMenu}
                                activeTab={currentTab}
                                changeActiveTab={changeActiveTab}
                                isLoading={isLoading}
                            ></TabsComp>
                        </Flex>
                        <Flex style={{ gap: '16px' }}>
                            <SearchBar setState={setSearch} />
                            <ButtonViewGroup setView={(mode) => setView(mode)} view={view} />
                        </Flex>
                    </Flex>
                    <div className='mt-6 mb-6'>
                        {view === "grid" && <>
                            {isLoading === true && <>
                                <RfqCardAnimation />
                            </>}
                            {isLoading === false && <>
                                {filterData?.length < 1 &&
                                    <div class="flex justify-center items-center">
                                        <NotFoundDatas text={`No RFQs found`} noDesc />
                                    </div>}
                                <div 
                                    className='min-w-[680px] grid gap-6 grid-cols-2 xlg:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-6 w-full ' 
                                    style={{ marginRight: '20px' }} 
                                    id='scrollable-drawer'
                                >
                                    {filterData && filterData?.map((singelData, index) => {
                                        return <RfqCard key={index} data={singelData} navigatePreview={navigatePreview} />
                                    })}
                                </div>
                            </>}
                            {isLoadingPagination === true && <>
                                <RfqCardAnimation />
                            </>}
                        </>}
                        {view === "list" && <>
                            <RfqList data={filterData} navigatePreview={navigatePreview} isLoading={isLoading} isLoadingPagination={isLoadingPagination}></RfqList>
                        </>}
                    </div>
                </div>
            </SupplierContainer>
        </>
    )
}



export default RfqOverview;

