import React, { useEffect } from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
} from "@chakra-ui/react";
import { CloseButton } from "components/RFQ/CloseButton";
import { TabHeader } from "components/TabHeader";
import { PreviewRFQButtonTab } from "components/Pages/CreateRFQ/PreviewRFQButtonTab";
import * as constant from "../../../../utils/constants";

import { Form, Formik } from "formik";

import { Button } from "components/RFQ/Button";
import { Questionnaire } from "pages/RFQ/RFQ/CreateRFQ/PreviewRFQ/Questionnaire";
import { TenderCost } from "pages/RFQ/RFQ/CreateRFQ/PreviewRFQ/TenderCost";
import { Documentation } from "pages/RFQ/RFQ/CreateRFQ/PreviewRFQ/Documentation";
import StatusBadge from "components/RFQ/StatusBadge/StatusBadge";
import { getSubmission } from "actions/RFQ/submissionAction";
import moment from "moment";
import { getUnitMeasures } from "actions/RFQ/rfqsAction";
import { Avatar } from "components/RFQ/Avatar";
import { SkeletonBase } from "components/Skeleton";

const RFQSubmission = ({
  isOpen,
  onClose,
  logoCompany,
  onOpen,
  setTypeModal,
  companySubmission,
  rfqDetail,
  navigateCompareSelected,
  ...props
}) => {
  const listExcludeAwards = ["unsuccessful", "decline", "awarded", "invited"];
  const [currentTab, setCurrentTab] = React.useState("questionnaire");
  const documentationTotal = rfqDetail?.documentationDocument?.length;
  const [submissionData, setSubmissionData] = React.useState(null);
  const [loadSubmissionData, setLoadSubmissionData] = React.useState(false);
  const [unitMeasures, setUnitMeasures] = React.useState([]);
  const isGuest = submissionData?.email ? true : false;
  useEffect(() => {
    setLoadSubmissionData(true);
    (companySubmission.isGuest
      ? getSubmission(rfqDetail?.id, companySubmission.value, false)
      : getSubmission(rfqDetail?.id, companySubmission.value, true)
    ).then((res) => {
      if (res) {
        setSubmissionData(res);
        getUnitMeasures().then((res) => {
          setUnitMeasures(
            res.map((unit) => ({ label: unit.name, value: unit.id }))
          );
          setLoadSubmissionData(false);
        });
      } else {
        setLoadSubmissionData(false);
      }
    });
    //eslint-disable-next-line
  }, [companySubmission]);

  const initialDataChecker = (submissionData) => {
    const displayAnswer = (answer) => {
      switch (answer?.type) {
        case "project_experience":
        case "key_personnel":
          return isGuest ? answer?.value?.[0] || " - " : answer?.value || " - ";
        case "checkbox":
          return (
            answer?.value?.map((val) => answer?.option[val]).join(", ") || " - "
          );
        case "select":
        case "radio":
          return Array.isArray(answer?.value)
            ? answer?.option[answer.value[0]]
            : !isNaN(answer?.value)
              ? answer?.option[answer.value]
              : " - ";
        default:
          return answer?.value || " - ";
      }
    };

    const sections = submissionData?.returnableSchedule?.map((section, i) => ({
      id: section.id || null,
      name: section.name || "",
      description: section.description || "",
      sort_order: i + 1,
      isRemove: 0,
      questionnaire: section.question?.map((question, j) => ({
        id: question.id || null,
        name: question.name || "",
        fields: question?.fields?.option || [],
        sort_order: j + 1,
        required: question.required ? 1 : 0,
        rfq_question_type_id: question.type || null,
        isRemove: 0,
        values: displayAnswer(question?.rfqAnswer?.answer) || " - ",
      })),
    }));
    const quotes = submissionData?.quote?.map((quote) => ({
      id: quote.id || null,
      name: quote.name || "",
      description: quote.description || "",
      sort_order: quote.sortOrder || null,
      required: quote.required ? 1 : 0,
      isRemove: 0,
      items: quote?.items.map((item, k) => ({
        id: item.id || null,
        name: item.name || "",
        unit_id: item.unit?.id,
        sort_order: k + 1,
        isRemove: 0,
        rate: item?.rqfQuoteAnswer?.answer?.value?.rate || 0,
        total: item?.rqfQuoteAnswer?.answer?.value?.quantity || 0,
      })),
    }));

    return {
      sections: sections || [],
      quote: quotes || [],
      documents: submissionData?.document || [],
    };
  };

  const initialDataQuestionnaire = initialDataChecker(
    submissionData?.submission
  )?.sections;
  const initialDataTenderCost = initialDataChecker(
    submissionData?.submission
  )?.quote;

  return (
    <div>
      <Drawer
        placement={"right"}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        {...props}
        className="bg-white p-11"
      >
        <DrawerOverlay onClick={onClose} />
        <DrawerContent maxWidth={"1280px"}>
          <DrawerHeader>
            <Flex
              justifyContent="space-between"
              align={"center"}
            >
              <div>
                <p className="text-lg text-text-primary-900 font-semibold">
                  Supplier RFQ Submission
                </p>
              </div>
              <Flex
                gap="22px"
                align={"center"}
              >
                <Flex className="items-center">
                  <Button
                    className={
                      loadSubmissionData ||
                      listExcludeAwards.includes(
                        submissionData?.status?.toLowerCase()
                      )
                        ? "cursor-not-allowed pointer-events-none"
                        : "cursor-pointer"
                    }
                    btntype={"plain-color"}
                    onClick={() => {
                      setTypeModal({
                        type: "award",
                        companyId: companySubmission.value,
                      });
                      onOpen();
                    }}
                    disabled={
                      loadSubmissionData ||
                      listExcludeAwards.includes(
                        submissionData?.status?.toLowerCase()
                      )
                    }
                  >
                    Award Supplier
                  </Button>
                  <Button
                    className={
                      loadSubmissionData ||
                      listExcludeAwards.includes(
                        submissionData?.status?.toLowerCase()
                      )
                        ? "cursor-not-allowed pointer-events-none"
                        : "cursor-pointer"
                    }
                    btntype={"plain-error"}
                    onClick={() => {
                      setTypeModal({
                        type: "decline",
                        companyId: companySubmission.value,
                      });
                      onOpen();
                    }}
                    disabled={
                      loadSubmissionData ||
                      listExcludeAwards.includes(
                        submissionData?.status?.toLowerCase()
                      )
                    }
                  >
                    Decline
                  </Button>
                </Flex>
                <CloseButton onClose={onClose} />
              </Flex>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Box className="min-h-80 w-full border rounded-xl shadow-xl grid content-between p-8 bg-gray-50 border-gray-200 space-y-6">
              <div>
                <Avatar
                  size="lg"
                  avatar={rfqDetail?.project?.logoPath}
                  defaultImage="company"
                  className={`${loadSubmissionData ? "animate-pulse bg-gray-300" : "bg-white"}`}
                />
              </div>
              <TabHeader
                noDivider={true}
                heading={`${rfqDetail?.project?.name || " - "} | ${rfqDetail?.supplierList?.name || " - "}`}
                secondHeading={
                  isGuest
                    ? submissionData?.name
                    : submissionData?.company?.name
                      ? submissionData?.company?.name
                      : " - "
                }
                description={rfqDetail?.description || " - "}
                isLoading={loadSubmissionData}
              />
              {!loadSubmissionData ? (
                <div id="content-title">
                  <Flex
                    gap={4}
                    className="my-4"
                  >
                    <p className="font-roboto font-normal min-w-48 text-text-secondary-500 text-base">
                      Status
                    </p>
                    <StatusBadge status={submissionData?.status} />
                  </Flex>
                  <Flex
                    gap={4}
                    className="my-4"
                  >
                    <p
                      className="font-roboto font-normal min-w-48 text-text-secondary-500"
                      style={{ lineHeight: "24px", fontSize: "16px" }}
                    >
                      Last Update
                    </p>
                    <p
                      className="font-roboto font-semibold text-text-primary-900"
                      style={{ fontSize: "16px", lineHeight: "24px" }}
                    >
                      {moment(submissionData?.lastUpdated).format(
                        "DD MMM YYYY"
                      ) || " - "}
                    </p>
                  </Flex>
                  <Flex
                    gap={4}
                    className="my-4"
                  >
                    <p
                      className="font-roboto font-normal min-w-48 text-text-secondary-500"
                      style={{ lineHeight: "24px", fontSize: "16px" }}
                    >
                      Location
                    </p>
                    <p
                      className="font-roboto font-semibold text-text-primary-900"
                      style={{ fontSize: "16px", lineHeight: "24px" }}
                    >
                      {rfqDetail?.location || " - "}
                    </p>
                  </Flex>
                  <Flex
                    gap={4}
                    className="my-4"
                  >
                    <p
                      className="font-roboto font-normal min-w-48 text-text-secondary-500"
                      style={{ lineHeight: "24px", fontSize: "16px" }}
                    >
                      Company Size
                    </p>
                    <p
                      className="font-roboto font-semibold text-text-primary-900"
                      style={{ fontSize: "16px", lineHeight: "24px" }}
                    >
                      {submissionData?.company?.size_name || " - "}
                    </p>
                  </Flex>
                </div>
              ) : (
                <div className="space-y-4">
                  <SkeletonBase
                    width="512px"
                    className="mb-6"
                  />
                  {Array(4)
                    .fill(0)
                    .map(() => (
                      <Flex>
                        <SkeletonBase
                          width="120px"
                          className="mr-12"
                        />
                        <SkeletonBase
                          width="240px"
                          className="mr-12"
                        />
                      </Flex>
                    ))}
                </div>
              )}
            </Box>
            <div className="my-6">
              <PreviewRFQButtonTab
                setCurrentTab={setCurrentTab}
                currentTab={currentTab}
                isLoading={loadSubmissionData}
                documentationTotal={documentationTotal}
              />
            </div>
            {submissionData && !loadSubmissionData && (
              <Formik initialValues={{ quote: initialDataTenderCost || [] }}>
                <Form>
                  <div>
                    {currentTab === constant.QUESTIONNAIRE && (
                      <Questionnaire
                        questionnaire={initialDataQuestionnaire}
                        preview
                        isGuest={submissionData?.email ? true : false}
                      />
                    )}
                    {currentTab === constant.TENDER_COST && (
                      <div>
                        <TenderCost
                          tables={initialDataTenderCost}
                          unitMeasures={unitMeasures}
                          disable={true}
                        />
                      </div>
                    )}
                    {currentTab === constant.DOCUMENTATION && (
                      <Documentation
                        documentationData={rfqDetail?.documentationDocument}
                      />
                    )}
                  </div>
                </Form>
              </Formik>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default RFQSubmission;
