import { Flex } from '@chakra-ui/react'
import { Badge } from 'components/RFQ/Badge'
import { Button } from 'components/RFQ/Button'
import React from 'react'
import { DOCUMENTATION, PROJECT_DETAILS, QUESTIONNAIRE, TENDER_COST } from 'utils/constants'

const PreviewRFQButtonTab = ({ currentTab, setCurrentTab, formValues, isLoading }) => {

  const documentationTotal = formValues?.documents.filter((doc) => !doc?.isRemove).length || 0;
  const hasTenderCost = formValues?.quote.length > 0;
  const hasQuestionnaire = formValues?.sections.filter((section) => section?.id !== null).length > 0;

  return (
    <div>
      <Flex style={{ gap: '8px' }}>
        <Button btntype='tab' active={currentTab === PROJECT_DETAILS} onClick={() => setCurrentTab(PROJECT_DETAILS)} disabled={isLoading}>
          Project Details
        </Button> 
        {documentationTotal !== 0 ? (
          <Button className='gap-2 inline-flex' btntype='tab' active={currentTab === DOCUMENTATION} onClick={() => setCurrentTab(DOCUMENTATION)} disabled={isLoading}>
            <span>Documentation</span>
          {!isLoading ? <Badge size='md' className='text-sm'>
            {documentationTotal}
            </Badge> : null}
          </Button>
        ) : null}
        {hasQuestionnaire ? (
          <Button btntype='tab' active={currentTab === QUESTIONNAIRE} onClick={() => setCurrentTab(QUESTIONNAIRE)} disabled={isLoading}>
            Returnable Schedules
          </Button> 
        ) : null}
        {hasTenderCost ? (
          <Button btntype='tab' active={currentTab === TENDER_COST} onClick={() => setCurrentTab(TENDER_COST)} disabled={isLoading}>
            Quote
          </Button>
        ) : null}
      </Flex>
    </div>
  )
}

export default PreviewRFQButtonTab

