import React, { Suspense } from "react";
import { Flex, Skeleton, SkeletonCircle } from "@chakra-ui/react";
import NotFoundDatas from "components/RFQ/NotFoundDatas/NotFoundDatas";
import { RenderOnViewportEntry } from "components/RFQ/RenderOnViewportEntry/RenderOnViewportEnter";
import { useSelector } from "react-redux";
import tw, { styled, css } from "twin.macro";
import ProjectTile from "components/Pages/Projects/ProjectTile/ProjectTile";
import { Button } from "components/RFQ/Button";
import { Icon } from "assets/icons/Icon";


const RFQCard = React.lazy(() =>
  import("./RFQCard/RFQCard")
);

const GridViewStyled = styled.div(() => [tw`py-8 flex gap-6 flex-wrap min-w-[680px]`]);
const SuspenseStyled = styled(Suspense)(() => [tw`py-8 flex gap-6 flex-wrap`]);


const RFQGridView = ({ currentTab, currPage,refreshData, onOpen, setModalType }) => {
  const rfqsData = useSelector((state) => state.rfq.rfqs?.rfqs?.[currentTab]?.data);
  const isLoading = useSelector((state) => state.rfq.rfqs?.loading);

  return (
    <GridViewStyled>
      <RenderOnViewportEntry
        loadingComponent={<ProjectTile />}
        className="w-full py-8 grid grid-cols-2 xlg:grid-cols-3 xxlg:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6 gap-6"
      >
        {(isLoading && currPage === 1 && !rfqsData) ? (
          <>
            <>
              {[...Array(6)].map(() => (
                <ProjectCardSkeleton />
              ))}
            </>
          </>
        ) : (
          <>
            {rfqsData?.length > 0 ? (
              <SuspenseStyled>
                {rfqsData.map((rfq, index) => {
                  return (
                    <RFQCard
                      key={index}
                      rfq={rfq}
                      currentTab={currentTab}
                      refreshData={refreshData}
                    />
                  );
                })}
                {(isLoading && currPage >= 1 && rfqsData) && [...Array(6)].map(() => (
                  <ProjectCardSkeleton />
                ))}
              </SuspenseStyled>
            ) : (
              <div className="absolute -translate-x-2/4 left-2/4">
                <NotFoundDatas
                  text={`No RFQs found`}
                  description={`To get started add a New RFQ.`}
                >
                    <Button style={{maxHeight:"48px"}} onClick={() => {onOpen(); setModalType('createRFQ')}}>
                        <Flex  className='items-center gap-[6px]'>
                            <div className='w-5 h-5 items-center justify-center flex'>
                                <Icon icon='plus' style={{width:'12px', height:'12px', color: '#fff', fontWeight: 'bold'}} />                      
                            </div>
                            <div className='font-roboto capitalize font-semibold text-base'>
                               Create RFQ
                            </div>
                        </Flex>
                    </Button>
                </NotFoundDatas>
              </div>
            )}
          </>
        )}
      </RenderOnViewportEntry>
    </GridViewStyled>
  );
};

export default RFQGridView;


const ProjectCardSkeletonStyled = styled.div(() => [
  tw`w-full h-[483px] rounded-md border-t-8 relative`,
  css`
    #card-content {
      padding: 24px 24px 6px 24px;
      background-color: white;
      height: 100%;
      max-height: 372px;
      position: relative;
      display: grid;
      align-content: space-between;
      bottom: 0;
      border-radius: 8px;
    }
    #header-card {
      background-color: white;
      height: 84px;
    }
  `,
]);

const ProjectCardSkeleton = () => {
  return (
    <ProjectCardSkeletonStyled className='card-shadow'>
      <div id="header-card" className='relative z-10'>
                    <SkeletonCircle size='48px' startColor="#c8ccd4" className='absolute top-6 left-6 z-1 border border-white' />
                </div>
                <div id="card-content">
                    <Skeleton width='75%' height="32px" startColor="#c8ccd4" />
                    <Skeleton width='50%' height="18px" mb="16px" startColor="#c8ccd4" />
                    <Skeleton height="24px" mb="2px" startColor="#c8ccd4" />
                    <div className='w-full border border-gray-100' />
                    <Skeleton height="24px" mb="2px" startColor="#c8ccd4" />
                    <div className='w-full border border-gray-100' />
                    <Skeleton height="24px" mb="2px" startColor="#c8ccd4" />
                    <div className='w-full border border-gray-100' />
                    <Skeleton height="24px" mb="2px" startColor="#c8ccd4" />
                    <div className='w-full border border-gray-100' />
                    <Skeleton height="24px" mb="24px" startColor="#c8ccd4" />
                    <Skeleton height="48px" startColor="#c8ccd4" />
                </div>
    </ProjectCardSkeletonStyled>
  )
}