import styled from "styled-components";


export const TabHeaderStyled = styled.div`
    padding-top: 24px;
    h1{
        /* padding-left: 40px; */
        /* font-size: 2.9rem; */
        color: rgb(0, 69, 245);
        font-weight: 400;
        font-size: 48px;
        line-height: 60px;
    }
    p {
        /* padding-left: 40px; */
        margin-top: 18px;
        font-weight: 400;
        font-family: Roboto;
        font-size: 16px;
        color: #475467 !important;
        line-height: 24px;
    }

    .tooltip {
        background-color: #003CE9;

        p {
            font-size: 14px;
            color: white !important; //overiding the color of the tooltip
            font-weight: 400;
            line-height: 24px;
            margin-top: 0 !important;
        }
    }
`