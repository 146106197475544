import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import apiV2 from "api-v2";
import { navigate } from "@reach/router";
import { setProjectArchived } from "actions/RFQ/projectsAction";
import { setAwardedSupplier } from "actions/RFQ/rfqsAction";
import { Icon } from "assets/icons/Icon";
import { Image } from "assets/img";
import { DateInput } from "components/DateInput";
import { Button } from "components/RFQ/Button";
import { FileUpload } from "components/RFQ/FileUpload";
import { FieldForm } from "components/RFQ/Input";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import tw, { styled } from "twin.macro";
import { cn } from "utils/helpers";
import * as yup from 'yup';
import { toast } from "react-toastify";
import moment from "moment";

const validationSchemaAward = yup.object().shape({
  company: yup.string().required('Required'),
  rfq: yup.string().required('Required'),
  message: yup.string().required('Required'),
  file: yup
    .mixed()
    .test('is-string-or-file', 'Must be a file', value => {
      return typeof value === 'string' || value?.[0] instanceof File;
    })
    .required('Required'),
});

const validationSchemaAwardGuest = yup.object().shape({
  rfq: yup.string().required('Required'),
  message: yup.string().required('Required'),
  file: yup
    .mixed()
    .test('is-string-or-file', 'Must be a file', value => {
      return typeof value === 'string' || value?.[0] instanceof File;
    })
    .required('Required'),
});
const validationSchemaDeclineGuest = yup.object().shape({
  rfq: yup.string().required('Required'),
  message: yup.string().required('Required'),
});
const validationSchemaDecline = yup.object().shape({
  company: yup.string().required('Required'),
  rfq: yup.string().required('Required'),
  message: yup.string().required('Required'),
});

const validationSchemaProject = yup.object().shape({
  rfq: yup.string().required('Required'),
  reason: yup.string().required('Required'),
  message: yup.string().required('Required'),
});

const validationSchemaPause = yup.object().shape({
  date: yup.date().required('Required'),
  rfq: yup.string().required('Required'),
  message: yup.string().required('Required'),
});



const ContainerLogo = styled.div(() => [
  tw`relative w-[480px] h-[48px] text-center items-center flex`,
]);

const AwardSupplierModal = ({ isOpen, onClose, rfq, typeModal, setLoadRFQData, refreshData, guestName, guestEmail }) => {
  const companyId = typeModal?.companyId;
  const projectId = typeModal?.projectId;
  const supplierInvitedList = rfq?.supplierInvited?.all || [];
  const listExcludeAwards = ['unsuccessful', 'decline', 'awarded', 'invited']
  const dispatch = useDispatch();

  let filterInvitedList = supplierInvitedList.filter((f) => {
    return !listExcludeAwards.includes(f?.status?.toLowerCase())
  })

  const projectsOptions = filterInvitedList?.map((company, index) => ({
    value: company?.company?.id ? company?.company?.id : filterInvitedList[index]?.email,
    label: company?.company?.name ? company?.company?.name : filterInvitedList[index]?.name,
  }));
  const reasonOptions = [
    { value: "finish", label: "Finished Project" },
    { value: "service", label: "Services Longer Needed" },
  ]


  const IntialValueFeedback = {
    rfq: `${rfq?.project?.name} | ${rfq?.supplierList?.name}`,
    company: guestEmail || companyId || "",
    message: "",
    status: 1,
    file: null,
  };
  const InitialValueArchive = {
    rfq: `${rfq?.project?.name} | ${rfq?.supplierList?.name}`,
    reason: "",
    message: "",
  };
  const InitialValueDelete = {
    rfq: `${rfq?.project?.name} | ${rfq?.supplierList?.name}`,
    status: 0,
    company: companyId || "",
    reason: "",
    message: "",
  };
  const InitialValuePause = {
    rfq: `${rfq?.project?.name} | ${rfq?.supplierList?.name}`,
    company: companyId || "",
    date: "",
    message: "",
  };

  let InitialValueModal = null;
  let validationSchema = null;
  switch (typeModal.type) {
    case "award":
      InitialValueModal = IntialValueFeedback;
      validationSchema = guestName !== undefined ? validationSchemaAwardGuest : validationSchemaAward;
      break;
    case "archive":
      InitialValueModal = InitialValueArchive;
      validationSchema = validationSchemaProject;
      break;
    case "decline":
      InitialValueModal = InitialValueDelete;
      validationSchema = guestName !== undefined ? validationSchemaDeclineGuest : validationSchemaDecline;
      break;
    case "pause":
      InitialValueModal = InitialValuePause;
      validationSchema = validationSchemaPause;
      break;
    case "cancel":
      InitialValueModal = InitialValueDelete;
      validationSchema = validationSchemaProject;
      break;
    default:
      InitialValueModal = IntialValueFeedback;
      validationSchema = validationSchemaAward;
      break;
  }

  let content = {};
  switch (typeModal.type) {
    case "award":
      content = {
        title: "Award this supplier",
        icon: "check-circle",
        iconColor: "text-utility-success-700 bg-utility-success-200",
        description:
          "Lorem ipsum dolor sit amet consectetur. Blandit eget mattis ipsum pharetra nisl sed. Sagittis tellus phasellus.",
        selectionLabel: "Company name",
        name: "company",
        typeField: "select",
        messageLabel: "Message",
        options: projectsOptions,
        hint: "This is a hint text to help user.",
        isFileUpload: true,
        isDisabled: true,
      };
      break;
    case "decline":
      content = {
        title: "Decline this supplier",
        icon: "x-square",
        iconColor: "bg-utility-warning-secondary text-fg-warning",
        description:
          "Lorem ipsum dolor sit amet consectetur. Blandit eget mattis ipsum pharetra nisl sed. Sagittis tellus phasellus.",
        selectionLabel: "Company name",
        name: "company",
        typeField: "select",
        messageLabel: "Message",
        options: projectsOptions,
        hint: "This is a hint text to help user.",
        isFileUpload: false,
        isDisabled: true,
      };
      break;
    case "archive":
      content = {
        title: "Archive Project",
        icon: "archive",
        iconColor: "bg-utility-gray-100 text-text-primary-500",
        description:
          "Lorem ipsum dolor sit amet consectetur. Blandit eget mattis ipsum pharetra nisl sed. Sagittis tellus phasellus.",
        selectionLabel: "Reason",
        options: reasonOptions,
        name: "reason",
        typeField: "select",
        messageLabel: "Notes",
        hint: "This is a hint text to help user.",
        isFileUpload: false,
        isDisabled: false,
      };
      break;
    case "pause":
      content = {
        title: "Pause Project",
        icon: "pause-square",
        iconColor: "bg-utility-gray-100 text-text-primary-500",
        description:
          "Lorem ipsum dolor sit amet consectetur. Blandit eget mattis ipsum pharetra nisl sed. Sagittis tellus phasellus.",
        selectionLabel: "Estimated Restart Date",
        name: "date",
        typeField: "date",
        messageLabel: "Message ",
        hint: "This is a hint text to help user.",
        isFileUpload: false,
        isDisabled: false,
      };
      break;
    case "cancel":
      content = {
        title: "Cancel Project",
        icon: "trash-03",
        iconColor: "bg-utility-gray-100 text-text-primary-500",
        description:
          "Lorem ipsum dolor sit amet consectetur. Blandit eget mattis ipsum pharetra nisl sed. Sagittis tellus phasellus.",
        selectionLabel: "Reason",
        name: "reason",
        options: reasonOptions,
        typeField: "select",
        messageLabel: "Notes",
        hint: "This is a hint text to help user.",
        isFileUpload: false,
        isDisabled: false,
      };
      break;
    default:
      content = {};
      break;
  }

  const pauseHandler = async (values) => {
    try {
      let formData = new FormData()
      formData.append('restart_date', moment(values?.date).format("YYYY-MM-DD"))
      formData.append('message', values?.message)
      let res = await apiV2.post(`project/${projectId}/pause`, formData)
      if (res.status === 200) {
        toast.success(res?.data?.message)
      }
    } catch (err) {
      toast.error(err?.response?.data?.message)
    }
    onClose()
  }

  const handleRFQActionSubmit = async (values) => {
    try {
      setLoadRFQData(true);
      if (typeModal.type === "archive") {
        archivedHandler(projectId);
      } else if (typeModal.type === "cancel") {
        // cancelHandler(projectId);
      }
      else if (typeModal.type === "pause") {
        await pauseHandler(values)
      } else {
        values.email = guestEmail
        let res = await setAwardedSupplier(rfq?.id, values, typeModal.type);
        refreshData()
        if (res) {
          onClose()
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    setLoadRFQData(false);

  };

  const archivedHandler = async (id) => {
    await dispatch(setProjectArchived(id))
      .then((data) => {
        if (data?.status) {
          navigate('../../projects/')
        }
      }
      )
  };


  return (
    <div className="translate-y-1/2 overflow-hidden">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        height="100%"
        size={"xl"}
        closeOnEsc
      >
        <ModalOverlay />
        <ModalContent>
          <Formik
            initialValues={InitialValueModal}
            onSubmit={handleRFQActionSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, isValid, dirty }) => (
              <Form>
                <ModalHeader>
                  <div className="overflow-hidden">
                    <ContainerLogo>
                      <Image
                        img="background-pattern-02"
                        className="absolute z-0 -top-10 -left-5"
                        style={{ width: "180px" }}
                      />
                      <div
                        className={cn(
                          "rounded-full h-10 w-10 items-center content-center ",
                          content.iconColor
                        )}
                        style={{ textAlign: "-webkit-center" }}
                      >
                        <Icon icon={content.icon} />
                      </div>
                    </ContainerLogo>
                    <h2 className="text-lg text-text-primary-900 font-semibold z-20  relative">
                      {content.title}
                    </h2>
                    <p className="text-sm font-normal text-text-tertiary-600">
                      {content.description}
                    </p>
                  </div>
                </ModalHeader>
                <ModalCloseButton onClose={onClose} />
                <ModalBody>
                  <FieldForm
                    label="RFx"
                    name="rfq"
                    type="text"
                    disabled={true}
                    icon="file-attachment-04"
                    className={"text-text-primary-500"}
                  />
                  {content.typeField === "date" ? (
                    <DateInput
                      icon={true}
                      label={content.selectionLabel}
                      name="date"
                      className='mb-1'
                    />
                  ) : (
                    <>
                      {content.selectionLabel === "Company name" ? <>
                        {typeModal?.companyId !== undefined ? <FieldForm
                          label={content.selectionLabel}
                          name={content.name}
                          type="select"
                          options={content.options}
                          isDisabled={companyId ? true : false}
                        /> :
                          <>
                            <FieldForm
                              label={content.selectionLabel}
                              name={content.name}
                              type="select"
                              value={guestName}
                              options={[{
                                value: guestName,
                                label: guestName,
                              }]}
                              isDisabled={true}
                            />
                          </>
                        }
                      </> : <>
                        <FieldForm
                          label={content.selectionLabel}
                          name={content.name}
                          type="select"
                          options={content.options}
                          isDisabled={companyId ? true : false}
                        />
                      </>}
                    </>
                  )}
                  <FieldForm
                    type="textArea"
                    label={content.messageLabel}
                    name="message"
                    placeholder="Enter a description..."
                  />
                  {content.isFileUpload && (
                    <FileUpload
                      type="file"
                      name="file"
                      className="w-full my-4"
                      types={["pdf", "docx", "xlsx", "jpeg", "jpg"]}
                      defaultFileName="Document"
                      isDisabled={true}
                      maxFileSize={50}
                    />
                  )}
                </ModalBody>
                <ModalFooter>
                  <Flex gap={3} className="!w-full">
                    <Button
                      btntype={"base"}
                      style={{ width: "100%" }}
                      onClick={onClose}
                      type="button"
                    >
                      Cancel
                    </Button>
                    <Button style={{ width: "100%" }} disabled={!isValid || !dirty}>
                      {isSubmitting ? <Spinner></Spinner> : "Confirm"}
                    </Button>
                  </Flex>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AwardSupplierModal;
