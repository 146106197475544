import { Button } from "../Button";
import { ReactComponent as BackIcon } from "../../../assets/icons/close.svg"

export const CloseButton = ({onClose}) => {
    return (
      <Button btntype={"icon"} onClick={onClose}>
        <BackIcon
          style={{ width: "25px", height: "25px", marginRight: "0px" }}
        />
      </Button>
    );
  };