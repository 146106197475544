import React, { useEffect } from "react";

import { Select } from "../Select";
import { getTradeValues } from "actions/companyActions";
import { connect } from "react-redux";

const TradeValueComponent = ({name, setFieldValue, values, defaultValue, getTradeValues, options, ...props}) => {
    const optionsTrade = options.map((opt)=>({label:opt.name,value:opt.id}))
    
    // [
    //     {value:1, label:'0-100K'},
    //     {value:2, label:'100K-250K'},
    //     {value:3, label:'250K-500K'},
    //     {value:4, label:'500K-1M'},
    //     {value:5, label:'1M-2.5M'},
    //     {value:6, label:'2.5M-5M'},
    //     {value:7, label:'5M +'}
    // ];

    useEffect(() => {
        getTradeValues();
    },[getTradeValues]);

    let value = optionsTrade.filter((item) => {
        return item.value === parseInt(defaultValue);
    });
    return (
        <>
        <Select
            label='Trade Package value (TOTAL)'
            required
            name={name}
            setFieldValue={setFieldValue}
            defaultValue={value}
            options={optionsTrade}
        />
        </>

    );
};

TradeValueComponent.defaultProps = {
    options: [],
};

const mapStateProps = (state) => {
    return {
        options: state.companyProfile.tradeValues,
    };
};

export const TradeValue = connect(mapStateProps, { getTradeValues})(TradeValueComponent);