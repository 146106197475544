import React, { useState, useEffect } from "react";
import debounce from "lodash.debounce";
import { Formik, Form, useFormikContext } from "formik";
import isEmpty from "lodash.isempty";
import * as Yup from "yup";
import Skeleton from "react-loading-skeleton";
import { TextInput } from "../FormInputs/TextInput";
import { ImageUpload } from "../FormInputs/ImageUpload";
import { ResourceProjects } from "../FormInputs/ResourceProjects";
import { FormButtonRow } from "../FormButtonRow";
import { Button } from "../Button";
import { ResourceProjectForm } from "../FormInputs/ResourceProjects/ResourceProjectForm";
import { Sectors } from "../FormInputs/Sectors";
import { Modal } from "../Modal";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { Resume } from "../Resume/Resume";
import { ResumeForm } from "../Resume/ResumeForm";
import { TabHeader } from "../TabHeader";
import { FlashError } from "../FormInputs/FlashError";
import { Spinner } from "@chakra-ui/react";
import tw from "twin.macro";
import styled from "styled-components";

const AutoSave = ({ debounceMs, setAddResume }) => {
  const formik = useFormikContext();
  formik.values.status = "published";
  // eslint-disable-next-line
  const debouncedSubmit = React.useCallback(
    debounce(() => formik.submitForm(), debounceMs),
    [formik.submitForm]
  );

  useEffect(() => {
    debouncedSubmit();
    setAddResume(false);
  }, [debouncedSubmit, setAddResume, formik.values]);

  return true;
};

const RowInput = styled.div`
  ${tw`grid grid-cols-2 gap-6`}
  ${(props) => props.oneRow && tw`grid-cols-1`}
`;

export const ResourceForm = ({
  initialValues,
  projectsDataStructure,
  editForm,
  autoUpdateForm,
  handleDelete,
  setAutoUpdateForm,
  isRequesting,
  ...props
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [resumeModalIsOpen, setResumeModalIsOpen] = useState(false);
  const [editId, setEditId] = useState(false);
  const [addResume, setAddResume] = useState(false);

  return initialValues !== undefined ? (
    <Formik
      enableReinitialize={editForm}
      initialValues={initialValues}
      validationSchema={Yup.object({
        first_name: Yup.string().required(),
        last_name: Yup.string().required(),
        email: Yup.string().email().required(),
        position: Yup.string().required(),
        experience: Yup.number().required(),
        roles: Yup.string().required(),
        sector_ids: Yup.array().min(1, "You must select at least one sector"),
      })}
      onSubmit={props.handleSubmit}
    >
      {(props) => {
        const {
          setFieldValue,
          values,
          isSubmitting,
          submitForm,
          status,
          setStatus,
        } = props;
        const handleSubmitProject = (projectValues) => {
          //using an internal state value here to track which index of the projects_completed array we're editing
          //if editId is set, update the value in the form values array matching the editId
          if (editId !== false) {
            values.projects_completed.splice(editId, 1, projectValues);
          } else {
            //else if nothing is set, we'll assume that we're creating a new project and update the whole array.
            //need to add to the current projects_completed array and replace the whole form value with the new array
            values.projects_completed.push(projectValues);
          }
          setEditId(false);
          setModalIsOpen(false);
        };

        const handleSubmitResume = (resumeValues) => {
          values.resume = resumeValues; // this is passed to FileUpload, the object is referenced by values[name]
          values.resume_file = resumeValues.resume_file;
          setAddResume(true);
          setResumeModalIsOpen(false);
        };

        const handleEditProject = (index) => {
          //use this internal state to track of the array index of project currently being edited
          setEditId(index);
          setModalIsOpen(true);
        };

        const handleDeleteProject = (index) => {
          //remove element from values array
          values.projects_completed.splice(index, 1);
          //use the formik provided function here so the form state updates properly
          setFieldValue("projects_completed", values.projects_completed);
          setEditId(false);
        };

        const handleDeletetResume = () => {
          values.resume = "";
          setFieldValue("resume", "");
          setAddResume(true);
        };

        /* const accountStatusOptions = [
                    { label: 'Active', value: true },
                    { label: 'Inactive', value: false }
                ]; */
        const onSubmitValidationError = () => { };
        return (
          <>
            <TabHeader heading="Create Key Personnel" />
            <Form onChange={() => setStatus(null)}>
              {editForm && status === null &&
                !autoUpdateForm &&
                isEmpty(status) &&
                !(values.status === "published" && isSubmitting) &&
                ((JSON.stringify(initialValues) !== JSON.stringify(values) &&
                  JSON.stringify(initialValues.resume) ===
                  JSON.stringify(values.resume)) ||
                  addResume) && (
                  <AutoSave debounceMs={1000} setAddResume={setAddResume} />
                )}
              <div className="row">
                <div className="col sm-12">
                  <ImageUpload
                    label="Profile Photo"
                    name="avatar_image"
                    image={initialValues.avatar_image}
                    setFieldValue={setFieldValue}
                    module="key-personel"
                  />

                
                </div>
                <RowInput>
                  <TextInput
                    label="First Name"
                    required="true"
                    name="first_name"
                    type="text"
                    className="col md-6"
                  />

                  <TextInput
                    label="Last Name"
                    required="true"
                    name="last_name"
                    type="text"
                    className="col md-6"
                  />
                </RowInput>
                <RowInput >
                  <TextInput
                    label="Email"
                    required="true"
                    name="email"
                    type="email"
                    className="col md-6"
                  />

                  <TextInput
                    label="Current Position"
                    required="true"
                    name="position"
                    type="text"
                    className="col md-6"
                  />
                </RowInput>
                <RowInput>
                  <TextInput
                    label="Industry Experience in Years"
                    required="true"
                    name="experience"
                    type="number"
                    max="50"
                    min="1"
                    className="col md-6"
                    guideLine="Will be updated every year"
                  />

                  <Sectors
                    label="Top 3 Sectors"
                    required="true"
                    name="sector_ids"
                    setFieldValue={setFieldValue}
                    values={values}
                    className="col md-6"
                    maxOptions={3}
                  />
                </RowInput>
                {/* leaving this field commented out incase client wishes to add back in */}
                {/* <Select
                                    label='Account Status'
                                    name='active'
                                    className='col md-6'
                                    setFieldValue={setFieldValue}
                                    options={accountStatusOptions}
                                    value={accountStatusOptions.filter((option) => values.active === option.value)}
                                /> */}

                <ResourceProjects
                  projects={values.projects_completed}
                  setFieldValue={setFieldValue}
                  className="col md-12"
                  handleAddClick={() => {
                    setEditId(false);
                    setModalIsOpen(true);
                  }}
                  handleEditClick={handleEditProject}
                  handleDeleteClick={handleDeleteProject}
                />

                <Resume
                  resume={values.resume}
                  setFieldValue={setFieldValue}
                  className="col md-12"
                  handleAddClick={() => setResumeModalIsOpen(true)}
                  handleEditClick={() => setResumeModalIsOpen(true)}
                  handleDeleteClick={handleDeletetResume}
                />
              </div>

              <TextInput
                name="company_id"
                value={values.company_id}
                type="hidden"
              />
              <div className="col xl-12">
                {status && (
                  <FlashError
                    heading={errorMessages[status]?.heading || status}
                    text={errorMessages[status]?.description || status}
                    margin="0px 0px 20px 0px"
                  />
                )}
              </div>
              <FormButtonRow>
                <div>
                  <Button
                    type="button"
                    isSubmitting={
                      values.status === "published" && isSubmitting
                    }
                    text="Save to Profile"
                    disablePointer={isSubmitting}
                    disabledStyle={isSubmitting}
                    onClick={() => {
                      setFieldValue("status", "published", false);
                      editForm && setAutoUpdateForm(true);
                      submitForm();
                    }}
                  >
                    {values.status === "published" && isSubmitting ? (
                      <Spinner className="spinner"/>
                    ) : (
                      "Save to Profile"
                    )}
                  </Button>
                </div>

                {handleDelete && (
                  <Button
                    type="button"
                    danger
                    onClick={handleDelete}
                    disablePointer={isRequesting}
                    disabledStyle={isRequesting}
                  >
                    {!isRequesting ? "Delete Personnel" : "Deleting..."}
                  </Button>
                )}
              </FormButtonRow>

              <OnSubmitValidationError callback={onSubmitValidationError} />
            </Form>

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setModalIsOpen(false)}
            >
              <ResourceProjectForm
                index={editId}
                title="Project Details"
                values={values}
                onSubmit={handleSubmitProject}
                initialValues={
                  editId !== false
                    ? values.projects_completed[editId]
                    : {
                      name: "",
                      sector_id: "",
                      role: "",
                      location: "",
                      value: "",
                    }
                }
              />
            </Modal>
            <Modal
              isOpen={resumeModalIsOpen}
              onRequestClose={() => setResumeModalIsOpen(false)}
            >
              <ResumeForm
                values={values}
                onSubmit={handleSubmitResume}
                initialValues={
                  values.resume ? values.resume : { resume_file: "" }
                }
              />
            </Modal>
          </>
        );
      }}
    </Formik>
  ) : (
    <Skeleton count={5} duration={0.5} />
  );
};
const errorMessages = {
  required: {
    heading: "* Required Field",
    description: "There are incomplete required fields, Please complete them.",
  },
  connection: {
    heading: "Connection not established",
    description: "Unable to process your request",
  },
  "Email Already Exist": {
    heading: "User Already Exist !",
    description: "Please, try with different email address.",
  },
};
