import styled from "styled-components";
import { Modal } from "components/Modal";
import { theme } from "theme";

export const ConfirmCancelStyled = styled(Modal)`
  padding: 1.5rem 2rem;
  background-color: white;
  min-width: 570px;
  .confirm-btn {
    float: right;
    padding: 8px 12px;
    color: white;
    background: ${theme.colors.royalBlue};
    border: 1px solid ${theme.colors.royalBlue};
    cursor: pointer;
    display: flex;
    padding: 8px 35px;
    align-items: center;
    justify-content: center;
  }
  .cancel-btn {
    float: right;
    padding: 8px 12px;
    color: #0045f5;
    background: transparent;
    border: 1px solid #263859;
    cursor: pointer;
    display: flex;
    padding: 8px 35px;
    justify-content: center;
    align-items: center;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: end;
  gap: 1.5rem;
  margin: 1.5rem rem 0.5rem 0.5rem 0.5rem;
`;

export const DialogConfirm = styled.h2`
  font-size: 1.15rem;
  width: 95%;
  margin: 1.5rem 0;
  font-weight: 500;
`;
