import React from "react";
import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay
} from "@chakra-ui/react";
import tw, { styled, css } from "twin.macro";
import { TabHeader } from "components/TabHeader";
import { CloseButton } from "components/RFQ/CloseButton";
import SupplierForm from "pages/RFQ/SupplierList/SupplierForm";
const DrawerBodyStyled = styled(DrawerBody)(() => [tw`p-6 max-h-[calc(100vh - 100px)]`]);

const DrawerHeaderStyled = styled(DrawerHeader)(() => [
    tw`text-black py-6 px-0`,
    css`
    .close-text {
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
    }

    .header-container {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      align-items: center;
    }

    .clear-container {
      display: flex;
      gap: 24px;
      justify-items: center;
      place-items: center;
    }
  `,
]);

const CreateSupplierDrawer = ({ onClose, isOpen, listIDCompanies, ...props }) => {
    return (
        <div>
            <Drawer
                placement={"right"}
                isOpen={isOpen}
                onClose={onClose}
                size="xl"
                {...props}
            >
                <DrawerOverlay onClick={onClose} />
                <DrawerContent>
                    <DrawerHeaderStyled borderBottomWidth="1px">
                        <div className="mx-6">
                            <TabHeader
                                noDivider={true}
                                heading={"CREATE NEW SUPPLIER LIST"}
                                description="Add shortlisted companies to a new supplier list."
                            >
                                <CloseButton onClose={onClose} />
                            </TabHeader>
                        </div>
                    </DrawerHeaderStyled>
                    <DrawerBodyStyled>
                        <SupplierForm
                            isNew={true}
                            isEdit={false}
                            listId=""
                            isAddSupplier={true}
                            listIDCompanies={listIDCompanies}
                            closeDialog={onClose}
                        />
                    </DrawerBodyStyled>
                </DrawerContent>
            </Drawer>
        </div>
    );
};

export default CreateSupplierDrawer;
