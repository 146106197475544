import React from "react";
import { Heading } from "components/Heading";
import RFQStatusComp from "components/RFQ/RFQStatusComp/RFQStatusComp";
import RfQResponseComp from "components/RFQ/RfQResponseComp/RfQResponseComp";
import moment from "moment";
import { Flex, SkeletonCircle } from "@chakra-ui/react";
import { Avatar } from "components/RFQ/Avatar";
import { TextBoxHTML } from "components/TextBoxHTML";
import { SkeletonBase } from "components/Skeleton";
import { timezoneChecker } from "utils/helpers";
import PreviewOnlyComp from "components/RFQ/PreviewOnlyComp/PreviewOnlyComp";
import { Divider } from "components/Divider";
import { Icon } from "assets/icons/Icon";

const ListHorizontalItem = ({title,value}) => {
  return <Flex>
    <p style={{ width: "200px" }} className="text-gray-text-400">
      {title}
    </p>
    <p
      className="font-roboto font-medium text-text-primary-900"
      style={{ fontSize: "16px" }}
    >
      {value}
    </p>
</Flex>
}

const JumbodivonComp = ({ data, isLoading, submissionStatus }) => {
  console.log(data);
  
  return (
    <div className="rounded-lg shadow-lg p-10 bg-white border border-[#EAECF0] border-solid min-h-[430px]">
      <div
        className="flex items-center mb-2 justify-center"
        style={{ height: "50px" }}
      >
        <div>
          {isLoading ? (
            <SkeletonCircle width={12} height={12} startColor="lightgray" />
          ) : (
            <Avatar
              avatar={data?.project?.logoPath}
              defaultImage="company"
              size="lg"
            />
          )}
        </div>
        <div className="">
          {!isLoading ? (
            <Heading fontSize="2rem" className="!leading-[50px] p-4">
              <div
                className="text-royal-blue text-[48px]"
              >
                {data?.project?.name} | {data?.name}
              </div>
            </Heading>
          ) : (
            <SkeletonBase width="300px" height="36px" />
          )}
        </div>
        <div className="ml-auto" style={{ float: "right" }}>
          {!isLoading ? (
            <div style={{ float: "right" }}>
              <RfQResponseComp
                response={
                  submissionStatus
                    ? submissionStatus?.toUpperCase()
                    : "ACCEPTED"
                }
                size="p-4"
              ></RfQResponseComp>
            </div>
          ) : (
            <SkeletonBase
              width="100px"
              height="32px"
              className="!rounded-full"
            />
          )}
        </div>
      </div>
      <div className="flex mt-4 mb-10 text-text-secondary-700 pt-8">
        {!isLoading ? (
          <TextBoxHTML html={data?.description} className="text-lg" />
        ) : (
          <SkeletonBase width="100%" height="48px" />
        )}
      </div>
      <div className="flex space-x-6">
        <div className="flex-1 border-t border-[#EAECF0] border-solid pt-3">
          <div className="grid grid-rows-2 gap-y-3">
            {!isLoading ? (
              <>
                <Flex>
                  <p style={{ width: "200px" }} className="text-gray-text-400">
                    Project Status
                  </p>
                  <p>
                    <RFQStatusComp
                      status={data?.project?.status}
                      rounded="rounded-full"
                    />
                  </p>
                </Flex>

                <ListHorizontalItem title="Client" value={data?.client}/>
                <ListHorizontalItem title="Contact Name" value={data?.companyOwner?.contact_name || ' - '}/>
                <ListHorizontalItem title="Project Location" value={data?.location}/>
                <ListHorizontalItem title="Submission Deadline" value={moment(data?.submissionDeadline)
                      .tz(timezoneChecker(data?.submissionTimezone || 'AWST'))
                      .format("dddd, D MMMM YYYY")}/>
                <ListHorizontalItem title="RFx ID" value={data?.rfqIdNumber}/>
                <ListHorizontalItem title="Request Type" value={data?.type || "-"}/>

                <Divider/>
                <div>
                  <div className="flex gap-3 pt-4">
                    <Icon icon="user3" /> <p>Contact Person</p>
                  </div>
                  <div className="py-4 grid grid-rows-2 gap-y-3">
                    <ListHorizontalItem title="Name" value={data?.leadContactName || '-'}/>
                    <ListHorizontalItem title="Email" value={data?.email || '-'}/>
                    <ListHorizontalItem title="Phone Number" value={data?.phoneNumber || '-'}/>
                  </div>
                </div>
                {/* id RFx-container is defined in RFQPreview.js file */}
                <a href="#RFx-container" className="">
                  <PreviewOnlyComp submissionStatus='submitted' label="Preview RFx below" icon="arrow-down" />
                </a>
              </>
            ) : (
              Array(5)
                .fill(0)
                .map((_, i) => (
                  <div className="flex gap-3">
                    <SkeletonBase key={i} width="120px" className="mr-10" />
                    <SkeletonBase key={i} width="200px" />
                  </div>
                ))
            )}
          </div>
        </div>
        <div className="flex-1 border-t border-[#EAECF0] border-solid pt-3">
          <div className="">
            {!isLoading ? (
              <>
                <p className="font-roboto font-[16px] pb-2 text-gray-text-400 min-w-[200px]">
                 Scope of Service
                </p>
                <TextBoxHTML
                  html={data?.escopeOfService}
                  className="text-base text-text-primary-900 font-medium"
                />
              </>
            ) : (
              <>
                <SkeletonBase width="160px" className='mr-8' />
                <SkeletonBase width="100%" height="100px" />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JumbodivonComp;
