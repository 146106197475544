import React from 'react'
import { useField } from "formik";
import { styled, css } from "twin.macro";
import { Button } from "components/RFQ/Button";
import { Flex } from '@chakra-ui/core';
import { Icon } from 'assets/icons/Icon';
import { FieldForm } from 'components/RFQ/Input';
import { cn } from 'utils/helpers';
export const RFQSupplierInviteFormStyled = styled.div(() => [
    css`
    .img-card {
      object-fit: cover;
      height: 104px;
      width: 100%;
    }
    .rfq-supplier-form-wrapper{
        margin-bottom:20px;
    }
    .rfq-form-sub-title{
        font-family: Roboto;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        height: 30px;
    }
    .rfq-form-caption{
       font-family : Roboto;
       font-size: 14px;
       line-height: 20px;
       height: 20px;
       margin-bottom: 30px;
    }
    .input-wrapper {
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;
    }

  `,
]);

const InviteForm = () => {    
    const fieldname = (index,name) => index !== undefined ? `guest[${index}]${name}` : `guest`
    const [,{value : guestLists},{setValue: setGuestLists}] = useField(fieldname()) 

    const addInviteGuestFunc = () => {
        setGuestLists([...guestLists,{name:'',email:''}])
    }
    const deleteInviteGuestFunc = (index) => {
        setGuestLists(guestLists.filter((_,i) => i !== index))
    }

    return <RFQSupplierInviteFormStyled>
       <div className='rfq-supplier-form-wrapper'>
       <h3 className='rfq-form-sub-title'>Invite guests</h3>
        <p className='rfq-form-caption'>Get your quotes and running faster by inviting guests to collaborate</p>
                {guestLists.map((invite, index) => {
                    return <div className='input-wrapper group flex items-start relative pr-8 '>
                        <div style={{ flex: 1 }} className='mr-3'>
                            <FieldForm 
                                type='text'
                                placeholder="Name"
                                icon="user3"
                                name={fieldname(index,'name')}
                            />
                        </div>
                        <div style={{ flex: 1 }} className='ml-3'>
                            <FieldForm 
                                type='text'
                                placeholder="you@example.com"
                                icon="envelope"
                                name={fieldname(index,'email')}
                            />
                        </div>
                        <div 
                            className={cn('hidden group-hover:flex bg-brand-secondary p-2 rounded-full w-max h-max absolute top-4 -right-6 cursor-pointer')}
                            onClick={() => deleteInviteGuestFunc(index)}
                        >
                            <Icon icon='close' style={{ width: '14px', height: '14px',color:'#003CE9' }} />
                        </div>
                    </div>
                })}
                <Flex gap={4} align={"center"}>
                    <Button btntype='secondary' type={'button'} className='!w-max' onClick={addInviteGuestFunc}>
                        <Flex gap={2} className='items-center'>
                            <Icon icon='plus-blue' />
                            Add another
                        </Flex>
                    </Button>
                </Flex>
       </div>
    </RFQSupplierInviteFormStyled>
}

export default InviteForm