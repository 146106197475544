import { Button } from "components/RFQ/Button";
import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Spinner,
} from "@chakra-ui/react";
import tw, { styled, css } from "twin.macro";
import { TabHeader } from "components/TabHeader";
import { CloseButton } from "components/RFQ/CloseButton";
import { Form, Formik } from "formik";
import * as yup from 'yup';
import { toast } from 'react-toastify';
import apiV2 from "api-v2";
import { FieldForm } from "components/RFQ/Input";
import { useSelector } from "react-redux";
import { Link } from "@reach/router";
const DrawerBodyStyled = styled(DrawerBody)(() => [tw`p-6 max-h-[calc(100vh - 100px)]`]);

const DrawerHeaderStyled = styled(DrawerHeader)(() => [
  tw`text-black py-6 px-0`,
  css`
    .close-text {
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
    }

    .header-container {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      align-items: center;
    }

    .clear-container {
      display: flex;
      gap: 24px;
      justify-items: center;
      place-items: center;
    }
  `,
]);

const InviteSupplierDrawer = ({ rfqName,onClose, isOpen, rfqId, rfqDetail,unCheckedList ,inviteSupplierHandler, ...props }) => {

  const InitialValueModal = {
    supplier: ""
  }
  const companyId = useSelector((state) => state.auth.user?.company_id) || null;
  const validationSchema = yup.object().shape({
    supplier: yup.number().required('Required'),
  });

  const handleRFQActionSubmit = async (values) => {
    try {
      let payload = {
        supplier_ids: [values.supplier]
      }
      let res = await apiV2.post(`rfq/${rfqId}/invite-supplier`, payload)
      if (res.status === 200) {
        toast.success(res?.data?.message)
        inviteSupplierHandler(res.data.data?.supplierInvited)
        onClose()
      }
    } catch (err) {
      toast.error(err?.response?.data?.message)
    }
  }

  return (
    <div>
      <Drawer
        placement={"right"}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        {...props}
      >
        <DrawerOverlay onClick={onClose} />
        <DrawerContent>
          <DrawerHeaderStyled borderBottomWidth="1px">
            <div className="mx-6">
              <TabHeader
                noDivider={true}
                heading={"Add Supplier"}
                description={`Add suppliers to RFQ “${rfqName}” from your Supplier List.`}
              >
                <CloseButton onClose={onClose} />
              </TabHeader>
            </div>
          </DrawerHeaderStyled>
          <DrawerBodyStyled>
            <Formik
              initialValues={InitialValueModal}
              onSubmit={handleRFQActionSubmit}
              validationSchema={validationSchema}
            >
              {({ isSubmitting, isValid, dirty }) => (
                <Form className="space-y-8">
                  <FieldForm
                    label="Supplier"
                    name="supplier"
                    type="select"
                    options={unCheckedList}
                    isDisabled={unCheckedList?.length < 1}
                    placeholder={unCheckedList?.length > 0 ? "Select Supplier" : "No Supplier left"}
                  />
                  <div className="px-6 py-5 bg-utility-blue-light-100 rounded-xl">
                    <p className="text-text-secondary-700">
                      {unCheckedList?.length > 0 ? 'You are adding suppliers' : 'All suppliers'} from “{rfqDetail?.supplierList?.name}” list
                      {unCheckedList?.length < 1 && ' are invited'}. Manage your 
                        {' '}
                        <Link to={`/account/${companyId}/supplier-list/${rfqDetail?.supplierList?.id}/${rfqDetail?.supplierList?.name}`}
                        className="underline text-text-secondary-700"> 
                          supplier list
                        </Link>
                        {' '}
                      if you want to add a new supplier.
                    </p>
                  </div>
                  <Button className="mt-3" style={{ width: "100%" }} type="submit" disabled={!isValid || !dirty || isSubmitting}>
                    {isSubmitting ? <Spinner></Spinner> : "Add Supplier"}
                  </Button>
                </Form>
              )}
            </Formik>
          </DrawerBodyStyled>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default InviteSupplierDrawer;
