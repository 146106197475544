import { Heading } from "components/Heading";
import React from "react";
import { Field, useField } from "formik";
import { FieldForm } from "components/RFQ/Input";

const YourQuoteComp = ({
  listQuoutes,
  unitMeasures
}) => {
  const [, { value: values }] = useField("quote_answer");

  const tableHeader = [
    {
      name: "#",
      width: "5%",
    },
    {
      name: "Description of Scope",
      width: "19%",
    },
    {
      name: "Unit of Measurement",
      width: "19%",
    },
    {
      name: "Quantity",
      width: "19%",
    },
    {
      name: "Rate",
      width: "19%",
    },
    {
      name: "Cost",
      width: "19%",
    },
  ];

  const listResult = [
    {
      id: 'subtotal',
      name: "Subtotal",
      color: "#475467",
    },
    {
      id: 'gst',
      name: "GST(10%)",
      color: "#475467",
    },
    {
      id: 'totalcost',
      name: "Total Cost",
      color: "#101828",
    },
  ];

  

  const formatCurrency = (total) =>
    total.toLocaleString("en-US", { style: "currency", currency: "USD" });

  const totalCost = (type, keys, allValue) => {
    const keyIds = keys === 'all' ? Object.keys(allValue) : keys
    const subTotal = keyIds.reduce((acc, key) => acc + (allValue[key].rate * allValue[key].quantity) , 0)
    switch (type) {
        case "subtotal":
            return subTotal
        case "gst":
            return subTotal * 0.1
        case "totalcost":
            return subTotal + (subTotal * 0.1)
        default:
            return ""
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === '-') {
      event.preventDefault();
    }
  };

  return (
    <>
      {listQuoutes.map((quote, index) => {
        return (
          <div className="rounded-lg shadow-sm p-10 bg-white border border-[#EAECF0] border-solid mb-10">
            <Heading className="text-4xl !text-royal-blue">
                {quote?.name}
            </Heading>
            <p className='text-lg text-text-tertiary-600 mt-3'>{quote?.description}</p>
            <div className="w-full border-b border-[#D0D5DD] h-10 shadow-sm"></div>
            <div className="overflow-x-auto border border-[#EAECF0] rounded-xl shadow-sm bg-white mt-9 ">
              <table className="w-full table-auto bg-white rounded-xl border-b border-[#EAECF0] border-solid">
                <thead style={{ height: "44px", background: "#F9FAFB" }}>
                  <tr className="border-b border-[#EAECF0]">
                    {tableHeader.map((th, index) => {
                      return (
                        <th
                          className="font-roboto font-medium text-text-tertiary-600 text-[12px]"
                          style={{
                            width: th.width,
                            textAlign: "left",
                            background: "#F9FAFB",
                          }}
                        >
                          <div style={{ marginLeft: "20px", marginTop: "3px" }}>
                            {th.name}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {quote?.items.map((singleData, index2) => {
                    return (
                      <tr key={index2} className="border-b border-[#EAECF0]">
                        <td
                          className="px-4 py-2 border border-[#EAECF0] text-center text-text-primary-500 font-roboto font-normal"
                          style={{
                            background: "#F9FAFB !important",
                            height: "48px",
                          }}
                        >
                          {index2 + 1}
                        </td>
                        <td
                          className="px-4 py-2 border border-[#EAECF0] font-roboto relative"
                          style={{ color: "#333" }}
                        >
                          <Field
                            id={singleData.description}
                            name={`quote_answer[${singleData.id}][name]`}
                            placeholder=""
                            type="text"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              padding: "5px 20px",
                            }}
                            value={singleData?.name || " - "}
                            disabled={true}
                          />
                        </td>
                        <td
                          className="border border-[#EAECF0] font-roboto relative"
                          style={{ color: "#333" }}
                        >
                          <FieldForm
                            label=""
                            placeholder=""
                            name={`quote_answer[${singleData.id}][unit]`}
                            type="select"
                            options={unitMeasures}
                            noborder={true}
                            disabled
                            disabledTable
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              padding: "5px 20px",
                              border: "solid 0px red !important",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              borderStyle: "none !important",
                              borderWidth: "0px !important",
                              boxShadow: "none !important",
                              boxSizing: "unset !important",
                            }}
                          />
                        </td>
                        <td
                          className="px-4 py-2 border border-[#EAECF0] font-roboto relative"
                          style={{ color: "#333" }}
                        >
                          <Field
                            name={`quote_answer[${singleData.id}][quantity]`}
                          >
                            {({ field, meta }) => (
                              <input
                                type="number"
                                id="quantity"
                                {...field}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  padding: "5px 20px",
                                  border: meta.touched && meta.error && '2px solid #dd0c0cb3',
                                }}
                                placeholder="0"
                                className={`outline-none`}
                                min={0}
                                onKeyDown={handleKeyDown}
                              />
                            )}
                          </Field>
                        </td>
                        <td
                          className="px-4 py-2 border border-[#EAECF0] font-roboto relative"
                          style={{ color: "#333" }}
                        >
                          <div className="flex">
                            <div className="absolute left-2 z-10 top-1/2 -translate-y-1/2">
                              $
                            </div>
                            <div>
                              <Field
                                name={`quote_answer[${singleData.id}][rate]`}
                              >
                                {({ field, meta }) => (
                                  <div>
                                    <input
                                      type="number"
                                      id="rate"
                                      {...field}
                                      style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        width: "100%",
                                        height: "100%",
                                        padding: "5px 20px",
                                        paddingLeft: "20px",
                                        border: meta.touched && meta.error && '2px solid #dd0c0cb3',
                                      }}
                                      placeholder="0"
                                      className={`outline-none`}
                                      min={0}
                                      onKeyDown={handleKeyDown}
                                    />
                                  </div>
                                )}
                              </Field>
                            </div>
                          </div>
                        </td>
                        <td
                          className="px-4 py-2 border border-[#EAECF0] font-roboto relative"
                          style={{ color: "#333" }}
                        >
                          <Field
                            id={singleData.description}
                            name={`quote_answer[${singleData.id}][cost]`}
                            placeholder=""
                            type="text"
                            disabled={true}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              padding: "5px 20px",
                            }}
                            value={`${(values?.[singleData.id]?.quantity * values?.[singleData.id]?.rate).toLocaleString("en-US", { style: "currency", currency: "USD" })}`}
                          />
                        </td>
                      </tr>
                    );
                  })}
                  {listResult.map((list) => {
                    return (
                      <tr>
                        <td
                          colSpan="4"
                          style={{
                            background: "#F9FAFB !important",
                            height: "48px",
                          }}
                          className="border-none"
                        ></td>
                        <td
                          className="px-4 py-2 border border-[#EAECF0] bg-gray-utility-100"
                          style={{ color: "#D0D5DD" }}
                        >
                          <div
                            className="font-roboto font-medium"
                            style={{ fontSize: "12px", color: list.color }}
                          >
                            {list.name}
                          </div>
                        </td>
                        <td
                          className="px-4 py-2 border border-[#EAECF0] bg-gray-utility-100"
                          style={{ color: "#333" }}
                        >
                          {formatCurrency(totalCost(list.id,quote?.items.map((item) => item.id),values))}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
       <div
        className="rounded-xl shadow-lg bg-white border border-[#EAECF0] border-solid  flex justify-start items-center overflow-hidden mb-10"
        style={{ height: "64px" }}
      >
        <div
          className="border-l-4 border-[#003CE9] pl-10 content-center font-roboto text-[18px] text-text-primary-900 font-semibold"
          style={{ height: "100%" }}
        >
          Total
        </div>
        <div
          className="border-l border-[#EAECF0] border-solid  content-center pl-6 pr-20 ml-auto"
          style={{ height: "100%" }}
        >
          <div
            className="font-roboto font-medium text-text-primary-900"
            style={{ fontSize: "12px" }}
          >
            Subtotal
          </div>
          <div className="font-roboto font-medium" style={{ color: "#D0D5DD" }}>
            {formatCurrency(totalCost('subtotal','all',values))}
          </div>
        </div>
        <div
          className="border-l border-[#EAECF0] border-solid  content-center pl-6 pr-20"
          style={{ height: "100%" }}
        >
          <div
            className="font-roboto font-medium text-text-primary-900"
            style={{ fontSize: "12px" }}
          >
            GST (10%)
          </div>
          <div className="font-roboto font-medium" style={{ color: "#D0D5DD" }}>
            {formatCurrency(totalCost('gst','all',values))}
          </div>
        </div>
        <div
          className=" border-[#EAECF0] border-solid  content-center pl-6 pr-20 bg-royal-blue2"
          style={{ height: "100%" }}
        >
          <div
            className="font-roboto font-medium text-text-primary-900"
            style={{ fontSize: "12px", color: "#D0D5DD" }}
          >
            Total Cost
          </div>
          <div className="font-roboto font-medium text-white">
            {formatCurrency(totalCost('totalcost','all',values))}
          </div>
        </div>
      </div>
    </>
  );
};

export default YourQuoteComp;
