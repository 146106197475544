import { Flex } from "@chakra-ui/react";
import { BoxQuestioner } from "components/RFQ/BoxQuestioner";
import React from "react";

const QuoteTotal = () => {
  return (
    <div className="font-roboto w-full">
      <BoxQuestioner className="pl-6 overflow-hidden card-shadow">
        <Flex align={"center"} justifyContent={"space-between"}>
          <div>
            <h1 className="text-lg font-semibold">Total</h1>
          </div>
          <Flex className="w-1/4" alignContent={'space-between'}>
            <div className="border-l p-3 w-full">
              <h2 className="text-xs font-semibold">Subtotal</h2>
              <td className="text-gray-300"> ${"0.00"}</td>
            </div>
            <div className="border-l p-3 w-full">
              <h2 className="text-xs font-semibold">GST (10%)</h2>
              <td className="text-gray-300"> ${"0.00"}</td>
            </div>
            <div className="border-l p-3 bg-royal-blue text-white w-full">
              <h2 className="text-xs font-semibold">Total Cost</h2>
              <td> ${"0.00"}</td>
            </div>
          </Flex>
        </Flex>
      </BoxQuestioner>
    </div>
  );
};

export default QuoteTotal;
