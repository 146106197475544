import React from "react";
import NotFoundDatas from "components/RFQ/NotFoundDatas/NotFoundDatas";
import { RenderOnViewportEntry } from "components/RFQ/RenderOnViewportEntry/RenderOnViewportEnter";

import tw, { styled } from "twin.macro";
import ProjectTile from "components/Pages/Projects/ProjectTile/ProjectTile";

const SupplierCard = React.lazy(() =>
  import("components/RFQ/SupplierCard/SupplierCard")
);

const GridViewStyled = styled.div((isNoDatas) => [
  tw`py-8 `,
  isNoDatas && tw`justify-center`
]);

const GridViewSupplier = ({ supplierLists, invitedSuppliers, handleInviteSupplier,handleRemoveSupplier }) => {
  return (
    <GridViewStyled isNoDatas={supplierLists?.length === 0}>
      <RenderOnViewportEntry
        loadingComponent={<ProjectTile />}
        className={`${supplierLists?.length>0?'xlg:min-w-[680px] grid grid-cols-1 xlg:grid-cols-3 4xl:grid-cols-4 gap-8 ':'mx-auto w-1/2'}`}
      > 
        <>
            {supplierLists?.length > 0 ? 
            <>
                {supplierLists.map((supplier, index) => {
                  return (
                    <SupplierCard
                      supplier={supplier}
                      handleInviteSupplier={handleInviteSupplier}
                      handleRemoveSupplier={handleRemoveSupplier}
                      isInvited={invitedSuppliers.includes(supplier.id)}
                    />
                  );
                })}
            </>
             : (
              <div className="mx-auto w-1/2">
                <NotFoundDatas
                    text={`No supplier found`}
                    description={`The supplier list is empty and needs suppliers to create a RFQ`}
                />
              </div>
            )}
          </>
      </RenderOnViewportEntry>
    </GridViewStyled>
  );
};

export default GridViewSupplier;
