import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { AccountManagementStyled, CancelLabel, RestartLabel } from "./styled";
import tw, { styled } from "twin.macro";
import {
  allTransaction,
  restart_subs,
} from "../../actions/AccountManagementActions";
import { setAccountStatus } from "../../actions/AccountManagementActions";
import { getAccountTypes } from "../../actions/accountTypeActions";
import { getCompanyUsers } from "../../actions/AccountManagementActions";
import { updateSubscription } from "actions/companyActions";
import { cn } from "utils/helpers";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import { RestartSubscriptionModal } from "components/RestartModal";
import { dateParser } from "utils/dateFormat";
import ChangeSubscriptionModal from "./ChangeSubscriptionModal";
import { Button } from "components/RFQ/Button";
import { Flex } from "@chakra-ui/react";
import NoBorderButton from "components/RFQ/NoBorderButton/NoBorderButton";
import { Icon } from "assets/icons/Icon";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/RFQ/Table";
import moment from "moment";
import { navigate } from "@reach/router";
// import { InvoiceTable } from "components/InvoiceTable";
// import { TabHeader } from "../TabHeader";
// import { TabContent } from "../Tabs/styled";
// import { profileTypeOptions } from "../../utils/helpers";
// import {
//   AccountManagementStyled,
//   PlanSection,
//   InvoicesSection,
//   CancelLabel,
//   RestartLabel,
// } from "./styled";
// import { FlashError } from "../FormInputs/FlashError";
export const AccountManagementComponent = ({
  company,
  viewOnly,
  allTransaction,
  getAccountTypes,
  getCompanyUsers,
  current_user,
  setAccountStatus,
  restart_subs,
  updateSubscription,
  account_types,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpenSubs, setIsOpenSubs] = useState(false);
  const [listTransaction, setListTransaction] = useState([]);

  useEffect(() => {
    allTransaction(company.id);
    getCompanyUsers(company.id);
    getAccountTypes();
  }, [allTransaction, getAccountTypes, getCompanyUsers, current_user, company]);

  useEffect(() => {
    if (props?.invoices) {
      setIsLoading(false);
      setListTransaction(props.invoices);
    }
  }, [props?.invoices]);

  // const bussiness_profile_type = profileTypeOptions.find(
  //   (item) => item.value === company.profile_type.id
  // );

  const company_id = localStorage.getItem("company_id");

  const expiredCheck = (date) => {
    const present = new Date();
    const billingDate = dateParser(date);
    if (billingDate < present) return true;
    else return false;
  };

  const dummy_payment = {
    card_type: "Card Number",
    last4: "4242",
  };

  // const getPlanLabel = (packageName) => {
  //   const index = account_types?.findIndex(
  //     (type) => type.package_name === packageName
  //   );
  //   return account_types[index]?.label;
  // };

  const TableHeadComponent = ({ title, className }) => {
    return (
      <div
        className={cn("font-roboto text-text-tertiary-600 th", className)}
        style={{ gap: "8px", alignItems: "center" }}
      >
        {title}
      </div>
    );
  };

  const SkeletonCustom = styled.div(() => [
    tw`animate-pulse bg-gray-300 w-full h-6`,
  ]);

  const SkeleteonRow = () => (
    <TableRow>
      <TableCell>
        <SkeletonCustom />
      </TableCell>
      <TableCell>
        <SkeletonCustom />
      </TableCell>
      <TableCell>
        <SkeletonCustom />
      </TableCell>
      <TableCell>
        <SkeletonCustom />
      </TableCell>
    </TableRow>
  );

  // const billingDate = () => {
  //   {
  //     company.next_billing_date ? (
  //       <>
  //         {expiredCheck(company.next_billing_date) ||
  //           company.account_status === "cancelled"
  //           ? "--/--/--"
  //           : company.next_billing_date}
  //         {!expiredCheck(company.next_billing_date) &&
  //           (company.account_status === "cancelled" ? (
  //             <RestartLabel onClick={() => setIsOpen2(true)}>
  //               Restart Subscription
  //             </RestartLabel>
  //           ) : (
  //             <CancelLabel onClick={() => setIsOpen(true)}>
  //               Cancel Subscription
  //             </CancelLabel>
  //           ))}
  //       </>
  //     ) : (
  //       <>{"--/--/-- (next billing date is empty)"}</>
  //     )
  //   }
  // }

  return company ? (
    <AccountManagementStyled>
      {/* {!company.subscription_status && (
        <div className="mt-5">
          <FlashError
            heading={"Payment Transaction Declined"}
            text={`We've noticed there is a problem with your payment details.
                    Please check and update all card information including
                    expiry date and CVV (security code) as soon as possible so
                    you can continue using Procuracon.`}
            margin="-16px 0px 40px 0px"
          />
        </div>
      )} */}
      <div className="shadow-card w-full p-5 rounded-md border border-gray-200 mt-5 mb-5">
        <div className="flex items-center justify-start pb-2 pt-2">
          <div>
            <img src="/img/buildpossible.svg" alt="buildpossible" />
          </div>
          <div className="ml-auto">
            <div className="flex">
              {/* {company.last4
                ? `${company.payment_method
                  ? "Card Number"
                  : dummy_payment.card_type
                } (Last 4 digits: ${company.last4})`
                : "Not Specified"} */}
              {dummy_payment.card_type === "Card Number" && (
                <NoBorderButton
                  onClick={() =>
                    navigate(
                      `/account/${company_id}/account-settings/update-card-details`,
                    )
                  }
                  text="Update Payment"
                  className="bg-white text-text-tertiary-600 text-[14px] font-roboto font-medium"
                />
              )}
              <Button
                btntype="base"
                className="border border-utility-blue-light-300"
                onClick={() => setIsOpenSubs(true)}
              >
                <Flex align={"center"}>
                  <p className="font-roboto font-semibold text-utility-blue-dark-100">
                    Explore Plans
                  </p>
                </Flex>
              </Button>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-200 mb-3 mt-3"></div>
        <div className="flex flex-col w-full">
          <div className="mb-3">
            <div className="flex w-full items-center">
              <div className="w-4 mr-4">
                <Icon icon="bill-cc" style={{ color: "#667085" }} />
              </div>
              <div className="font-opensans font-normal text-sm mr-4 text-text-secondary-700">
                {/* {company?.plan
                  ? getPlanLabel(company?.plan)
                  : "No Plan Selected"} */}
              </div>
              <div className="font-inter font-medium mr-4 text-light-blue bg-could-blue text-[10px] rounded-md p-1 pl-2 pr-2">
                Billed monthly
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="flex w-full items-center">
              <div className="w-4 mr-4">
                <Icon icon="bill-calendar" style={{ color: "#667085" }} />
              </div>
              <div className="font-opensans font-normal text-sm mr-4 text-text-secondary-700">
                Next renew{" "}
                {company.next_billing_date ? (
                  <>
                    {expiredCheck(company.next_billing_date) ||
                    company.account_status === "cancelled"
                      ? "--/--/--"
                      : company.next_billing_date}
                    {!expiredCheck(company.next_billing_date) &&
                      (company.account_status === "cancelled" ? (
                        <RestartLabel onClick={() => setIsOpen2(true)}>
                          Restart Subscription
                        </RestartLabel>
                      ) : (
                        <CancelLabel onClick={() => setIsOpen(true)}>
                          Cancel Subscription
                        </CancelLabel>
                      ))}
                  </>
                ) : (
                  <>{"--/--/-- (next billing date is empty)"}</>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-200 mb-3 mt-3"></div>
        <div>
          <p className="font-roboto text-[12px] font-normal text-gray-text-400">
            If you would like to change your Subscription Plan or find out more
            please get in touch with us at support@procuracon.com.au
          </p>
        </div>
      </div>
      <div className="shadow-card w-full p-5 rounded-md border border-gray-200 mt-5 mb-5">
        <div className="mb-5">
          <div className="font-roboto font-semibold text-text-primary-900 text-[18px]">
            Transactions
          </div>
          <p className="font-roboto text-sm text-text-tertiary-600 mt-2">
            Manage your users that have access to the platform here.
          </p>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>
                <TableHeadComponent title="Invoice Number"></TableHeadComponent>
              </TableHead>
              <TableHead>
                <TableHeadComponent title="Date"></TableHeadComponent>
              </TableHead>
              <TableHead>
                <TableHeadComponent title="Subscription"></TableHeadComponent>
              </TableHead>
              <TableHead>
                <TableHeadComponent title="Amount"></TableHeadComponent>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {!isLoading &&
              listTransaction.map((transaction, index) => {
                return (
                  <TableRow key={transaction?.id}>
                    <TableCell>
                      <div className="font-roboto font-medium text-text-primary-900 text-sm">
                        {transaction?.transaction_id}
                      </div>
                    </TableCell>
                    <TableCell>
                      <p className="font-roboto text-sm text-text-tertiary-600 font-normal">
                        {moment(
                          transaction?.transaction_date,
                          "DD/MM/YYYY",
                        ).format("D MMM YYYY")}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-roboto text-sm text-text-tertiary-600 font-normal">
                        {transaction?.plan_name}
                      </p>
                    </TableCell>
                    <TableCell>
                      <div className="font-roboto text-sm text-text-tertiary-600 font-normal">
                        {transaction?.charged_amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            {isLoading && (
              <>
                <SkeleteonRow></SkeleteonRow>
                <SkeleteonRow></SkeleteonRow>
                <SkeleteonRow></SkeleteonRow>
              </>
            )}
          </TableBody>
        </Table>
      </div>
      {/* <TabContent>

        <PlanSection>
          <div className="plan-container ">
            <div className="plan-row">
              <h6>Subscription</h6>
              <p>
                <span>
                  {company?.plan
                    ? getPlanLabel(company?.plan)
                    : "No Plan Selected"}
                </span>{" "}
                {company.next_billing_date ? (
                  <>

                    --- on hold ---
                    {company.account_status !== "cancelled" && (
                      <Link className="plan-link" to={`edit-subscription`}>
                        Edit Subscription
                      </Link>
                    )}

                    <RestartLabel onClick={() => setIsOpenSubs(true)}>
                      Change Subscription Plan
                    </RestartLabel>
                  </>
                ) : (
                  <div className="plan-link">{"Edit Subscription"}</div>
                )}
                <RestartLabel onClick={() => setIsOpenSubs(true)}>
                  Change Subscription Plan
                </RestartLabel>
              </p>
            </div>
            {company.profile_type.id !== 1 && (
              <div className="plan-row">
                <h6>Business Profile Type</h6>
                <p>{bussiness_profile_type?.label || <>&mdash;</>}</p>
              </div>
            )}

            <div className="plan-row">
              <h6>Account Expires</h6>
              <p>
                {company.next_billing_date ? (
                  <>
                    {expiredCheck(company.next_billing_date) ||
                      company.account_status === "cancelled"
                      ? "--/--/--"
                      : company.next_billing_date}
                    {!expiredCheck(company.next_billing_date) &&
                      (company.account_status === "cancelled" ? (
                        <RestartLabel onClick={() => setIsOpen2(true)}>
                          Restart Subscription
                        </RestartLabel>
                      ) : (
                        <CancelLabel onClick={() => setIsOpen(true)}>
                          Cancel Subscription
                        </CancelLabel>
                      ))}
                  </>
                ) : (
                  <>{"--/--/-- (next billing date is empty)"}</>
                )}
              </p>
            </div>
            <div className="plan-row">
              <h6>Payment Method</h6>
              <p>
                {company.last4
                  ? `${company.payment_method
                    ? "Card Number"
                    : dummy_payment.card_type
                  } (Last 4 digits: ${company.last4})`
                  : "Not Specified"}

                {dummy_payment.card_type === "Card Number" && (
                  <Link
                    className="plan-link"
                    to={`/account/${company_id}/company-profile/account-management/update-card-details`}
                  >
                    Update Your Payment Details
                  </Link>
                )}
              </p>
            </div>
          </div>
          <div></div>
        </PlanSection>

        <InvoicesSection>
          <h2 className="transaction-head">Transactions</h2>
          <InvoiceTable datas={props.invoices} accountTypes={account_types} />
        </InvoicesSection>
      </TabContent> */}
      <CancelSubscriptionModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setAccountStatus={setAccountStatus}
      />
      <RestartSubscriptionModal
        isOpen={isOpen2}
        setIsOpen={setIsOpen2}
        restart_subs={restart_subs}
        currentInvoice={props.invoices[0]}
      />

      <ChangeSubscriptionModal
        isOpen={isOpenSubs}
        setIsOpenSubs={setIsOpenSubs}
      />
    </AccountManagementStyled>
  ) : (
    <Skeleton count={5} duration={0.5} />
  );
};

const mapStateToProps = (state) => {
  const company = state.search.activeCompany;
  const current_user = state.auth.user;
  const invoices = state.accountManagement.transactionData;
  const account_types = state.accountTypes.accountTypeData;
  const company_users = state.accountManagement.companyUsers;
  return {
    isRequesting: state.companyProfile.isRequesting,
    company: company,
    current_user: current_user,
    invoices: invoices,
    account_types: account_types,
    company_users: company_users,
  };
};

export const AccountManagement = connect(mapStateToProps, {
  allTransaction,
  getAccountTypes,
  getCompanyUsers,
  setAccountStatus,
  restart_subs,
  updateSubscription,
})(AccountManagementComponent);
