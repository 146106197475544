import { Link } from "@reach/router";
import {
  getSupplierLists,
  setSupplierArchived,
  setSupplierUnArchived,
} from "actions/RFQ/supplierListsActions";
import { Icon } from "assets/icons/Icon";
import GlobalAvatarGroup from "components/RFQ/AvatarGroup/AvatarGroup";
import { Badge } from "components/RFQ/Badge";
import { Button } from "components/RFQ/Button";
import { Checkbox } from "components/RFQ/Checkbox";
import NotFoundDatas from "components/RFQ/NotFoundDatas/NotFoundDatas";
import DropDownItem from "components/RFQ/SupplierListCard/DropDownItem";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/RFQ/Table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import tw, { styled, css } from "twin.macro";
import { cn, htmlToText } from "utils/helpers";

const ListViewStyled = styled.div(() => [
  tw`my-8 rounded-2xl`,
  css`
    box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.25);
    .title {
      font-weight: 500;
      font-family: Roboto;
      font-size: 14px;
    }

    .text {
      font-weight: 400;
      font-family: Roboto;
      font-size: 14px;
    }
  `,
]);

const SkeletonCustom = styled.div(() => [
  tw`animate-pulse bg-gray-300 w-full h-6`,
]);

const SkeleteonRow = () => (
  <TableRow>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
  </TableRow>
);

const ListViewSupplierLists = ({
  data,
  isLoading,
  currPage,
  archived,
  currentTab,
}) => {
  const { company_admin } = useSelector((state) => state?.auth?.user);
  let showToggleMenu = true;
  if (!company_admin && currentTab === "companyApprovedList") {
    showToggleMenu = false;
  }
  const avatars = (suppliers) =>
    suppliers.map((supplier) => {
      return {
        avatar: supplier?.logo_path,
      };
    });
  const dispatch = useDispatch();
  const [checkedRow, setCheckedRow] = useState([]);
  const rowSelected = (rowId) => checkedRow.some((row) => row.uuid === rowId);
  const checkHandler = (e, row) => {
    if (e.target.checked) {
      setCheckedRow((prevRow) => [...prevRow, row]);
    } else {
      const newArray = checkedRow.filter(
        (checked) => checked.uuid !== row.uuid
      );
      setCheckedRow(newArray);
    }
  };

  const archiveBatchHandler = async () => {
    if (checkedRow.length > 0) {
      const ids = checkedRow.map((row) => row.id);
      dispatch(setSupplierArchived(ids)).then(() => {
        dispatch(getSupplierLists({ reset: true, archived: archived }));
      });
      setCheckedRow([]);
    }
  };

  const unarchiveBatchHandler = async () => {
    if (checkedRow.length > 0) {
      const ids = checkedRow.map((row) => row.id);
      setCheckedRow([]);
      dispatch(setSupplierUnArchived(ids)).then(() => {
        dispatch(getSupplierLists({ reset: true }));
      });
    }
  };

  useEffect(() => {
    setCheckedRow([]);
  }, [data]);

  const ThComp = ({ title }) => {
    return (
      <div
        className=" font-roboto text-text-tertiary-600 text-xs th"
        style={{ gap: "8px", alignItems: "center" }}
      >
        {title}
      </div>
    );
  };

  return (
    <ListViewStyled>
      <div className="w-full rounded-t-2xl border-gray-200 p-6">
        {checkedRow.length === 0 ? (
          <h2 className="font-semibold text-gray-800 text-lg h-9 leading-9">
            Supplier Lists
          </h2>
        ) : (
          <div className="flex gap-4 items-center justify-between h-9">
            <div className="flex gap-4 items-center">
              <Icon
                className="cursor-pointer"
                icon="close"
                onClick={() => setCheckedRow([])}
              />
              <p>{checkedRow.length} Selected</p>
            </div>
            <div className="flex gap-4 items-center">
              <Button
                size="xs"
                secondary="white"
                onClick={archived ? unarchiveBatchHandler : archiveBatchHandler}
              >
                {archived ? "Unarchive" : "Archive"}
              </Button>
            </div>
          </div>
        )}
      </div>
      <Table
        containerClass={tw`w-full rounded-2xl rounded-t-none border-t border-gray-200`}
      >
        <TableHeader>
          <TableRow>
            <TableHead firstCol={true} className="!pl-6">
              <div
                className="flex"
                style={{ gap: "8px", alignItems: "center" }}
              >
                <ThComp title="Supplier List"></ThComp>
              </div>
            </TableHead>
            <TableHead className={tw`max-w-[208px]`}>
              <ThComp title="Supplier List Code"></ThComp>
            </TableHead>
            <TableHead className={tw`max-w-[108px] min-w-[108px]`}>
              <ThComp title="Start Date"></ThComp>
            </TableHead>
            <TableHead className={tw`max-w-[108px]`}>
              <ThComp title="End Date"></ThComp>
            </TableHead>
            <TableHead className={tw`max-w-[400px]`}>
              <ThComp title="No. of Suppliers"></ThComp>
            </TableHead>
            <TableHead className={tw`max-w-[108px] min-w-[108px]`}>
              <ThComp title="No. of RFQ"></ThComp>
            </TableHead>
            {showToggleMenu && (
              <TableHead className={tw`max-w-[48px]`}>
                <div className="hidden">Icon</div>
              </TableHead>
            )}
          </TableRow>
        </TableHeader>
        <TableBody>
          {isLoading && currPage === 1 && !data ? (
            [...Array(4)].map(() => <SkeleteonRow />)
          ) : data?.length > 0 ? (
            <>
              {data?.map((supplier, index) => {
                const slug = supplier?.name?.toLowerCase().replace(/ /g, "-");
                return (
                  <TableRow
                    key={index}
                    className={`${cn(
                      rowSelected(supplier.uuid) &&
                        "[&>td]:bg-utility-blue-light-50/30"
                    )} group`}
                  >
                    <TableCell
                      firstCol={true}
                      className="w-[35%] overflow-hidden !pl-6 group-hover:bg-utility-blue-light-50/30"
                    >
                      <div className="flex gap-4">
                        <Checkbox
                          onChange={(e) => checkHandler(e, supplier)}
                          checked={rowSelected(supplier?.uuid)}
                          className="cursor-pointer"
                        />
                        <Link to={`${supplier?.id}/${slug}`}>
                          <div className="relative ml-3 text-text-tertiary-600">
                            <p className="max-w-[350px] overflow-hidden text-ellipsis title capitalize text-text-primary-900">
                              {supplier.name ? supplier.name : " - "}
                            </p>
                            <p className="max-w-[350px] overflow-hidden text-ellipsis">
                              {supplier.description
                                ? htmlToText(supplier.description)
                                : "-"}
                            </p>
                          </div>
                        </Link>
                      </div>
                    </TableCell>
                    <TableCell className={tw`max-w-[208px] group-hover:bg-utility-blue-light-50/30`}>
                      <p className="max-w-[200px] text capitalize overflow-hidden text-ellipsis text-nowrap">
                        {supplier?.code || " - "}
                      </p>
                    </TableCell>
                    <TableCell className={tw`max-w-[218px] group-hover:bg-utility-blue-light-50/30`}>
                      <p className="text">
                        {moment(supplier?.startDate).format("DD MMMM YYYY")}
                      </p>
                    </TableCell>
                    <TableCell className={tw`max-w-[218px] group-hover:bg-utility-blue-light-50/30`}>
                      <p className="text">
                        {moment(supplier?.endDate).format("DD MMMM YYYY")}
                      </p>
                    </TableCell>
                    <TableCell className={tw`max-w-[400px] group-hover:bg-utility-blue-light-50/30`}>
                      <p className="text">
                        <GlobalAvatarGroup
                          avatarSize="sm"
                          maxAvatars={5}
                          avatars={avatars(supplier?.suppliers)}
                        />
                      </p>
                    </TableCell>
                    <TableCell className={tw`max-w-[108px] group-hover:bg-utility-blue-light-50/30`}>
                      <Badge
                        notFull={true}
                        rounded={"sm"}
                        color={"light"}
                        size="md"
                      >
                        <p className="text-xs font-medium">
                          {supplier.numberRfq ? supplier.numberRfq : "0"}
                        </p>
                      </Badge>
                    </TableCell>
                    {showToggleMenu && (
                      <TableCell className={tw`group-hover:bg-utility-blue-light-50/30`}>
                        <DropDownItem
                          id={supplier?.id}
                          archived={archived}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {isLoading &&
                currPage >= 1 &&
                data &&
                [...Array(4)].map(() => <SkeleteonRow />)}
            </>
          ) : (
            <TableRow className="col-span-5 h-[500px]">
              <div className="absolute -translate-x-2/4 left-2/4">
                <NotFoundDatas
                  text={`No Supplier lists found`}
                  description={
                    "To get started, import relevant Suppliers from your Supplier List (Panel) to this project"
                  }
                />
              </div>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </ListViewStyled>
  );
};

export default ListViewSupplierLists;
