import React from "react";
import { Box } from "@chakra-ui/core";

import { ReactComponent as Tick } from "./tick.svg";
import { ReactComponent as Briefcase } from "./briefcase.svg";
import { ReactComponent as BillCalendar } from "./bill-calendar.svg";
import { ReactComponent as BillCC } from "./bill-cc.svg";
import { ReactComponent as User3 } from "./user-03.svg";
import { ReactComponent as RedCircleTrash } from "./red-circle-trash.svg";
import { ReactComponent as BlueCircleCheck } from "./blue-check-circle.svg";
import { ReactComponent as AcceptRequest } from "./accept-request.svg";
import { ReactComponent as DeclineRequest } from "./decline-request.svg";
import { ReactComponent as EyeOn } from "./eye-on.svg";
import { ReactComponent as EyeOf } from "./eye-off.svg";
import { ReactComponent as EyeSlash } from "./eye-slash.svg";
import { ReactComponent as Eye } from "./eye.svg";
import { ReactComponent as CheckLight } from "./check-light.svg";
import { ReactComponent as Awaiting } from "./awaiting.svg";
import { ReactComponent as Declined } from "./declined.svg";
import { ReactComponent as PaperPlane } from "./paper-plane.svg";
import { ReactComponent as Inprogress } from "./inprogress.svg";
import { ReactComponent as DotsThree } from "./dots_three.svg";
import { ReactComponent as Dashboard } from "./house.svg";
import { ReactComponent as Envelope } from "./envelope.svg";
import { ReactComponent as Verified } from "./verified.svg";
import { ReactComponent as Blueplus } from "./blueplus.svg";
import { ReactComponent as WhiteCheck } from "./whitecheck.svg";
import { ReactComponent as CompanyProfile } from "./company-profile.svg";
import { ReactComponent as DashboardSquare } from "./dashboard-icon.svg";
import { ReactComponent as Profile } from "./profile.svg";
import { ReactComponent as ProjectsIcon } from "./projects-icon.svg";
import { ReactComponent as RequestQuoteIcon } from "./request-icon.svg";
import { ReactComponent as SearchIcon } from "./search.svg";
import { ReactComponent as SupplierIcon } from "./supplier-icon.svg";
import { ReactComponent as ChevronRightIcon } from "./chevron-right.svg";
import { ReactComponent as SettingsIcon } from "./settings.svg";
import { ReactComponent as FilterIcon } from "./filters.svg";
import { ReactComponent as ListIcon } from "./list-view.svg";
import { ReactComponent as FavoriteIcon } from "./favorite.svg";
import { ReactComponent as DotsVerticIcon } from "./dots-vertical.svg";
import { ReactComponent as ArrowDownIcon } from "./arrow-down.svg";
import { ReactComponent as ArrowRightIcon } from "./arow-right.svg";
import { ReactComponent as EditIcon } from "./edit.svg";
import { ReactComponent as ArchiveIcon } from "./archive-icon.svg";
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as UploadIcon } from "./upload-icon.svg";
import { ReactComponent as CheckIcon } from "./check.svg";
import { ReactComponent as PNGIcon } from "./png.svg";
import { ReactComponent as JPEGIcon } from "./jpeg.svg";
import { ReactComponent as FileIcon } from "./file.svg";
import { ReactComponent as PDFIcon } from "./pdf.svg";
import { ReactComponent as XLSXIcon } from "./xlsx.svg";
import { ReactComponent as CSVIcon } from "./csv.svg";
import { ReactComponent as DOCIcon } from "./doc.svg";
import { ReactComponent as DOCXIcon } from "./docx.svg";
import { ReactComponent as TrashIcon } from "./trash.svg";
import { ReactComponent as FolderPlus } from "./folder-plus.svg";
import { ReactComponent as PlusIcon } from "./plus.svg";
import { ReactComponent as DeleteIcon } from "./delete.svg";
import { ReactComponent as SwitchHorizontalIcon } from "./switch-horizontal.svg";
import { ReactComponent as DownloadIcon } from "./download.svg";
import { ReactComponent as TemplateIcon } from "./template.svg";
import { ReactComponent as TextInputIcon } from "./text-input.svg";
import { ReactComponent as CheckOnlyIcon } from "./check-only.svg";
import { ReactComponent as ViewIcon } from "./view.svg";
import { ReactComponent as CheckBoxIcon} from "./check-box.svg";
import { ReactComponent as CalendarIcon} from "./calendar.svg";
import { ReactComponent as ClockIcon} from "./clock.svg";
import { ReactComponent as SixDotIcon} from "./six-dots-vertical.svg";
import { ReactComponent as PlusCircleIcon} from "./circle-plus.svg";
import { ReactComponent as SectionIcon} from "./section.svg";
import { ReactComponent as CopyIcon} from "./copy-icon.svg";
import { ReactComponent as DragIcon} from "./drag-icon.svg";
import { ReactComponent as TrashStrokeIcon} from "./trash-stroke.svg";
import { ReactComponent as ShortAnswerIcon} from "./short-answer.svg";
import { ReactComponent as ParagraphIcon} from "./paragraph.svg";
import { ReactComponent as SingleOptionIcon} from "./single-option.svg";
import { ReactComponent as MultipleChoiceIcon} from "./multiple-choice.svg";
import { ReactComponent as DropdownIcon} from "./dropdown.svg";
import { ReactComponent as DateSelectionIcon} from "./date-selection.svg";
import { ReactComponent as AddressFinderIcon} from "./address-finder.svg";
import { ReactComponent as StarIcon} from "./stars-icon.svg";
import { ReactComponent as FileUploadIcon} from "./upload-file.svg";
import { ReactComponent as QuoteTypeIcon} from "./quote-type.svg";
import { ReactComponent as FileAttachmen04Icon} from "./file-attachment-04.svg";
import { ReactComponent as Trash03Icon} from "./trash-03.svg";
import { ReactComponent as PauseSquareIcon} from "./pause-square.svg";
import { ReactComponent as PluSquareIcon} from "./plus-square.svg";
import { ReactComponent as EyeIcon} from "./eye.svg";
import { ReactComponent as EyeOffIcon} from "./eye-off.svg";
import { ReactComponent as FlipBackwardIcon} from "./flip-backward.svg";
import { ReactComponent as CornerUpLeftIcon} from "./corner-up-left.svg";
import { ReactComponent as CheckCircleIcon} from "./check-circle.svg";
import { ReactComponent as XSquarecon} from "./x-square.svg";
import { ReactComponent as SearchLGcon} from "./search-lg.svg";
import { ReactComponent as SearchSMIcon} from "./search-small.svg";
import { ReactComponent as LockIcon} from "./lock.svg";
import { ReactComponent as AlertTriangle} from "./alert-triangle.svg";


export const allIcons = {
  "tick": (props) => <Tick {...props} />,
  "briefcase": (props) => <Briefcase {...props} />,
  "bill-cc": (props) => <BillCC {...props} />,
  "bill-calendar": (props) => <BillCalendar {...props} />,
  "user3": (props) => <User3 {...props} />,
  "red-circle-trash": (props) => <RedCircleTrash {...props} />,
  "blue-circle-check": (props) => <BlueCircleCheck {...props} />,
  "decline-request": (props) => <DeclineRequest {...props} />,
  "accept-request": (props) => <AcceptRequest {...props} />,
  "eye-of": (props) => <EyeOf {...props} />,
  "eye-on": (props) => <EyeOn {...props} />,
  "eye-slash": (props) => <EyeSlash {...props} />,
  "check-light": (props) => <CheckLight {...props} />,
  "arrow-right": (props) => <ArrowRightIcon {...props} />,
  "eye2": (props) => <Eye {...props} />,
  "awaiting": (props) => <Awaiting {...props} />,
  "declined": (props) => <Declined {...props} />,
  "paper-plane": (props) => <PaperPlane {...props} />,
  "inprogress": (props) => <Inprogress {...props} />,
  "dashboard": (props) => <Dashboard {...props} />,
  "company-profile": (props) => <CompanyProfile {...props} />,
  "dashboard-square": (props) => <DashboardSquare {...props} />,
  "plus-blue": (props) => <Blueplus {...props} />,
  "dots-three": (props) => <DotsThree {...props} />,
  "envelope": (props) => <Envelope {...props} />,
  "my-profile": (props) => <Profile {...props} />,
  "white-check": (props) => <WhiteCheck {...props} />,
  "verified": (props) => <Verified {...props} />,
  "projects": (props) => <ProjectsIcon {...props} />,
  "request-for-quote": (props) => <RequestQuoteIcon {...props} />,
  "search": (props) => <SearchIcon {...props} />,
  "supplier-list": (props) => <SupplierIcon {...props} />,
  "chevron-right": (props) => <ChevronRightIcon {...props} />,
  "chevron-down": (props) => <ChevronRightIcon {...props} style={{transform:'rotate(90deg)'}} />,
  "chevron-left": (props) => <ChevronRightIcon {...props} style={{transform:'rotate(180deg)'}} />,
  "chevron-up": (props) => <ChevronRightIcon {...props} style={{transform:'rotate(270deg)'}} />,
  "account-settings": (props) => <SettingsIcon {...props} />,
  "filter": (props) => <FilterIcon {...props} />,
  "list-view": (props) => <ListIcon {...props} />,
  "favorite": (props) => <FavoriteIcon {...props} />,
  "dots-vertical": (props) => <DotsVerticIcon {...props} />,
  "arrow-down": (props) => <ArrowDownIcon {...props} />,
  "edit-icon": (props) => <EditIcon {...props} />,
  "archive": (props) => <ArchiveIcon {...props} />,
  "close": (props) => <CloseIcon {...props} />,
  "upload": (props) => <UploadIcon {...props} />,
  "png": (props) => <PNGIcon {...props} />,
  "jpg": (props) => <JPEGIcon {...props} />,
  "file": (props) => <FileIcon {...props} />,
  "pdf": (props) => <PDFIcon {...props} />,
  "xlsx": (props) => <XLSXIcon {...props} />,
  "csv": (props) => <CSVIcon {...props} />,
  "doc": (props) => <DOCIcon {...props} />,
  "docx": (props) => <DOCXIcon {...props} />,
  "check": (props) => <CheckIcon {...props} />,
  "trash": (props) => <TrashIcon {...props} />,
  "folder-plus": (props) => <FolderPlus {...props} />,
  "plus": (props) => <PlusIcon {...props} />,
  "delete": (props) => <DeleteIcon {...props} />,
  "switch-horizontal": (props) => <SwitchHorizontalIcon {...props} />,
  "download": (props) => <DownloadIcon {...props} />,
  "text-input": (props) => <TextInputIcon {...props} />,
  "template": (props) => <TemplateIcon {...props} />,
  "check-only": (props) => <CheckOnlyIcon {...props} />,
  "view": (props) => <ViewIcon {...props} />,
  "check-box": (props) => <CheckBoxIcon {...props} />,
  "calendar": (props) => <CalendarIcon {...props} />,
  "clock": (props) => <ClockIcon {...props} />,
  "sixDots": (props) => <SixDotIcon {...props} />,
  "section": (props) => <SectionIcon {...props} />,
  "plus-circle": (props) => <PlusCircleIcon {...props} />,
  "copy-icon": (props) => <CopyIcon {...props} />,
  "drag-icon": (props) => <DragIcon {...props} />,
  "trash-stroke": (props) => <TrashStrokeIcon {...props} />,
  "short-answer-icon": (props) => <ShortAnswerIcon {...props} />,
  "paragraph-icon": (props) => <ParagraphIcon {...props} />,
  "single-option-icon": (props) => <SingleOptionIcon {...props} />,
  "multiple-choice-icon": (props) => <MultipleChoiceIcon {...props} />,
  "dropdown-icon": (props) => <DropdownIcon {...props} />,
  "date-selection-icon": (props) => <DateSelectionIcon {...props} />,
  "address-finder-icon": (props) => <AddressFinderIcon {...props} />,
  "stars-icon": (props) => <StarIcon {...props} />,
  "file-upload-icon": (props) => <FileUploadIcon {...props} />,
  "quote-type": (props) => <QuoteTypeIcon {...props} />,
  "file-attachment-04": (props) => <FileAttachmen04Icon {...props} />,
  "trash-03": (props) => <Trash03Icon {...props} />,
  "pause-square": (props) => <PauseSquareIcon {...props} />,
  "plus-square": (props) => <PluSquareIcon {...props} />,
  "eye": (props) => <EyeIcon {...props} />,
  "eye-off": (props) => <EyeOffIcon {...props} />,
  "flip-backward": (props) => <FlipBackwardIcon {...props} />,
  "corner-up-left": (props) => <CornerUpLeftIcon {...props} />,
  "check-circle": (props) => <CheckCircleIcon {...props} />,
  "x-square": (props) => <XSquarecon {...props} />,
  "search-lg": (props) => <SearchLGcon {...props} />,
  "search-sm" : (props) => <SearchSMIcon {...props} />,
  "lock" : (props) => <LockIcon {...props} />,
  "alert-triangle" : (props) => <AlertTriangle {...props} />,
};

export const Icon = ({
	icon,
	sx,
	size,
	width,
	height,
	strokeWidth,
	className,
	color,
	...props
}) => {
	const pixelMe = (val) =>
		val === "auto" || val.includes("%") || val.includes("px")
			? val
			: `${val}px`;
	const svgWidth =
		width !== undefined ? pixelMe(`${width}`) : pixelMe(`${size}`);
	const svgHeight =
		height !== undefined ? pixelMe(`${height}`) : pixelMe(`${size}`);

	if (color) {
		sx = {
			color: color,
			...sx,
		};
	}

	switch (icon) {
		case "arrow-down":
			sx = {
				transform: "rotate(180deg)",
				...sx,
			};
			break;
		default:
			break;
	}

	const renderIcon = (icon) => {
		if (allIcons.hasOwnProperty(icon)) {
			return allIcons[icon](props);
		}
		return "";
	};

	return (
		<Box
			sx={{
				display: "inline-flex",
				alignItems: "center",
				justifyContent: "center",
				"> svg": {
					width: `${svgWidth}`,
					height: `${svgHeight}`,
					maxWidth: "100%",
					maxHeight: "100%",
					"& path": {
						strokeWidth,
					},
				},
				...sx,
			}}
			className={className}
			{...props}
		>
			{renderIcon(icon)}
		</Box>
	);
};

Icon.defaultProps = {
	size: "auto",
};
