import React, { useState } from "react";
import { Link } from "@reach/router";
import { SignUpForm } from "./SignUpForm";
import { SignUpPaymentDetails } from "./SignUpPaymentDetails";
import { SignUpBusinessDetails } from "./SignUpBusinessDetails";
import { SignUpAccountType } from "./SignUpAccountType";
import { SignUpWelcome } from "./SignUpWelcome";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  LeftSection,
  RightSection,
  SignupWrapper,
  Step,
  StepIndicators,
} from "./styled";
import rightImg from "./../../icons/login-right-img.svg";
import { useDispatch } from "react-redux";
import { setSignupData } from "../../actions/authActions";
import { preserveSignUpData } from "../../utils/helpers";
import { useLocation } from "@reach/router";
import { LogoWrapper } from "../LogoWrapper/LogoWrapper";

export const MultiStep = () => {
  const dispatch = useDispatch();
  const savedData = JSON.parse(localStorage.getItem("signup_data"));
  let savedStep = JSON.parse(localStorage.getItem("signup_step"));
  const location = useLocation();
  const email = location.state?.email;

  // must select package
  if (savedStep === 3) savedStep = 2;
  dispatch(setSignupData(savedData || {}));

  const [data, setData] = useState({
    first_name: savedData?.first_name || "",
    last_name: savedData?.last_name || "",
    email: savedData?.email || email || "",
    password: savedData?.password || "",
    confirm_password: savedData?.confirm_password || "",
    agreed: savedData?.agreed || "",
    business_name: savedData?.business_name || "",
    phone_no: savedData?.phone_no || "",
    abn: savedData?.abn || "",
    profile_type_id: savedData?.profile_type_id || null,
    account_type_id: savedData?.account_type_id || "",
    automatic_subscription: true, //savedData?.automatic_subscription || false,
    card_name: savedData?.card_name || "",
    discount_code: savedData?.discount_code || "",
  });

  const totalSteps = 4;
  const [currentStep, setCurrentStep] = useState(savedStep || 0);
  const [accountType, setAccountType] = useState(null);

  const handleNextStep = (newData) => {
    localStorage.setItem("signup_step", currentStep + 1);
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev + 1);
    preserveSignUpData(data, newData);
  };

  const handlePrevStep = (newData) => {
    localStorage.setItem("signup_step", currentStep - 1);
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
    preserveSignUpData(data, newData);
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

  const steps = [
    <SignUpForm next={handleNextStep} data={data} email={email} />,
    <SignUpBusinessDetails
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
    />,
    <SignUpAccountType
      setAccountType={setAccountType}
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
    />,
    <Elements stripe={stripePromise}>
      <SignUpPaymentDetails
        accountType={accountType}
        next={handleNextStep}
        prev={handlePrevStep}
        data={data}
      />
    </Elements>,
    <SignUpWelcome next={handleNextStep} prev={handlePrevStep} data={data} />,
  ];

  return (
    <SignupWrapper>
      <LeftSection>
        {currentStep < totalSteps && (
          <StepIndicators>
            <Step completed />
            <Step completed={currentStep >= 1} />
            <Step completed={currentStep >= 2} />
            <Step completed={currentStep >= 3} />
          </StepIndicators>
        )}
        <LogoWrapper />
        {steps[currentStep]}
        <div className="float-bottom">
          <p>
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </p>
          <p>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
          <p>
            <Link to="#">
              © {new Date().getFullYear()} Procuracon. All right reserved.
            </Link>
          </p>
        </div>
      </LeftSection>
      <RightSection
        style={{ backgroundImage: `url(${rightImg})` }}
      ></RightSection>
    </SignupWrapper>
  );
};
