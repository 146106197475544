import React, { useEffect } from "react";
import { Router, navigate } from "@reach/router";

import { PageWrap, ContentContainer } from "../components/Layout";

//Routes
import { Shortlist } from "./search/Shortlist";
import { NotFound } from "../routes/NotFound";
import { ContextMenu } from "../components/ContextMenu";
import styled from "styled-components";
import { useSelector } from 'react-redux';

export const CompaniesWrapper = styled.div`
  h1 {
    font-weight: 400;
    margin: 0px 0px 2.5rem;
    line-height: 1;
    font-size: 5.25rem;
  }
  .content-container {
    padding-top: 20px;
    padding-left: 48px;
    padding-right: 48px;
  }
  .content-container > div > div {
    padding: 0;
  }
  .content-container > div > div > div {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const CompanyListing = ({ company, ...props }) => {
    let { allow_companies_listing_access } = useSelector((state) => state?.auth?.current_company)
    allow_companies_listing_access = parseInt(allow_companies_listing_access)
  useEffect(() => {
    if (localStorage.getItem("is_admin") !== "true") {
            if (allow_companies_listing_access !== 1) {
          navigate("/404");
            }
    }
        //eslint-disable-next-line
  }, []);

  return (
    <PageWrap>
      <ContextMenu />
      <CompaniesWrapper>
        <ContentContainer className={"content-container"}>
          <Router primary={false}>
            <Shortlist
              path="/"
              admin={props.admin}
              linkNewTab={false}
              className={"shortlist-container"}
            />
            <NotFound default />
          </Router>
        </ContentContainer>
      </CompaniesWrapper>
    </PageWrap>
  );
};
