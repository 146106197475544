import React from 'react';
import tw, {styled} from 'twin.macro';

const TableContainerStyled = styled.div(({className}) => [
    tw`overflow-x-auto overflow-y-auto`,
    className
])

const TableStyled = styled.table(({className}) => [
    tw`relative table-auto overflow-scroll w-full bg-white rounded`,
    className
])

export const Table = ({ children, className, containerClass, ...props }) => {
    return (
        <TableContainerStyled className={containerClass} >
            <TableStyled className={className} {...props}>
                {children}
            </TableStyled>
        </TableContainerStyled>
    )
}

const TableHeaderStyled = styled.thead(({sticky, className}) =>[
    tw`[&_tr]:border-b bg-white z-10`,
    sticky && tw`sticky top-0 drop-shadow-sm`,
    className
])

export const TableHeader = ({ children, className, sticky = true, ...props }) => {
    return (
        <TableHeaderStyled sticky={sticky} {...props}>
            {children}
        </TableHeaderStyled>
    )
}

const TableRowStyled = styled.tr(({className}) => [
    tw`border-b transition-colors `,
    className
]);


export const TableRow = ({ children, className, ...props }) => {
    return (
        <TableRowStyled className={
            className
        }
            {...props}  >
            {children}
        </TableRowStyled>
    )
}

const TableHeadStyled = styled.th(({className,firstCol})=>[
    tw`font-roboto h-11 px-6 text-left align-middle font-medium text-text-tertiary-600  [&:has([role=checkbox])]:pr-0 `,
    firstCol && tw`whitespace-nowrap sticky left-0 bg-white drop-shadow-sm top-0 z-40`,
    className
])

export const TableHead = ({ children, className, firstCol = false, ...props }) => {
    return (
        <TableHeadStyled firstCol={firstCol} className={className} {...props} >
           <div style={{fontSize:'12px'}}>{children}</div>
        </TableHeadStyled>
    )
}

const TableBodyStyled = styled.tbody(({className}) => [
    tw`[&_tr:last-child]:border-0 overflow-auto z-[1] relative`,
    className
])


export const TableBody = ({ children, className, ...props }) => {
    return (
        <TableBodyStyled {...props} className={className}>
            {children}
        </TableBodyStyled>
    )
}

const TableCellStyled = styled.td(({className, firstCol}) => [
    tw`py-4 px-6 align-middle`,
    firstCol && tw`whitespace-nowrap bg-white left-0 `,
    className
])

export const TableCell = ({ children, className, firstCol = false, ...props }) => {
    return (
        <TableCellStyled
            firstCol={firstCol}
            className={className}
            {...props} >
            {children}
        </TableCellStyled>
    )
}