import apiV2 from "api-v2";
import * as constants from "./actionTypes";

export const getTemplate = () => {
    return async (dispatch) => {
        dispatch({ type: constants.GET_TEMPLATE_REQUEST });
        try {
            const { data:{data} } = await apiV2.get(`template`);
            if(data){
                const payload = data;
                dispatch({
                    type: constants.GET_TEMPLATE_SUCCESS,
                    payload,
                });
            }
        } catch (error) {
            dispatch({
                type: constants.GET_TEMPLATE_FAILED,
                payload: error,
            });
        }
    };
}

export const addTemplate = (data) => {
    return async (dispatch) => {
        dispatch({ type: constants.ADD_TEMPLATE, payload:data });
    };
}

