import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";
import { UserFormStyled } from "./styled";
import { Select } from "../FormInputs/Select";
import { findUserRole, userRoles } from "../../utils/helpers";
import { createCompanyUser } from "../../actions/authActions";
import { TabHeader } from "components/TabHeader";
import { navigate } from "@reach/router";

export const CreateUserFormComponent = ({ company, tab, ...props }) => {
  const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    position: "",
    company_id: company?.id,
  };

  return company ? (
    <>
      <TabHeader heading="Create User" description="Create a new user for your company." />
      <UserFormStyled>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object({
            first_name: Yup.string().required("* This Field is required"),
            last_name: Yup.string().required("* This Field is required"),
            email: Yup.string().email().required("* This Field is required"),
            password: Yup.string().required("* This Field is required"),
            position: Yup.string().required("* This Field is required"),
            phone: Yup.string()
              .required("* THIS FIELD IS REQUIRED")
              .matches(
                /^(?=.*)((?:\+?61) ?(?:\((?=.*\)))?([2-47-8])\)?|(?:\((?=.*\)))?([0-1][2-47-8])\)?) ?-?(?=.*)((\d{1} ?-?\d{3}$)|(00 ?-?\d{4} ?-?\d{4}$)|( ?-?\d{4} ?-?\d{4}$)|(\d{2} ?-?\d{3} ?-?\d{3}$))/,
                "Invalid Phone No.",
              ),
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              const result = await props.createCompanyUser(values, setSubmitting);
              if (result.success) {
                navigate('./');
              }
            } catch (e) {
              const error = await e;
              toast.error(error.message);
              error.errors !== undefined && setErrors(error.errors);
            }
          }}
        >
          {(props) => {
            const { isSubmitting, values } = props;
            return (
              <div>
                  <Form>
                    <div className="grid grid-cols-12 gap-x-8">
                      <TextInput
                        label="First Name"
                        required
                        name="first_name"
                        className="col-span-6"
                        placeholder={"First Name"}
                      />
                      <TextInput
                        label="Last Name"
                        required
                        name="last_name"
                        className="col-span-6"
                        placeholder={"Last Name"}
                      />
                      <TextInput
                        label="Phone Number"
                        required
                        name="phone"
                        className="col-span-6"
                        placeholder={"Phone No"}
                      />
                      <TextInput
                        label="Email Address"
                        required
                        name="email"
                        className="col-span-6"
                        placeholder={"Email"}
                      />
                      <Select
                        required="true"
                        options={userRoles}
                        name={"position"}
                        label={"User Type"}
                        className={"col-span-6"}
                        value={findUserRole(values.position)}
                      />
                      <TextInput
                        label="Password"
                        required
                        name="password"
                        className="col-span-6"
                        icon
                        type="password"
                      />
                      <div className="col-span-6">
                        <Submit
                          type="submit"
                          isSubmitting={isSubmitting}
                          text="Save"
                          submittingText="Saving..."
                        />
                      </div>
                    </div>
                  </Form>
              </div>
            );
          }}
        </Formik>
      </UserFormStyled>
    </>
  ) : (
    <Skeleton count={5} duration={0.5} />
  );
};

const mapStateToProps = (state) => {
  const company = state.search.activeCompany;
  const current_user = state.auth.user;

  return {
    company: company,
    current_user: current_user,
  };
};

export const CreateUserForm = connect(mapStateToProps, { createCompanyUser })(
  CreateUserFormComponent,
);
