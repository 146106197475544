import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useField, useFormikContext } from "formik";

import { Error } from "../Error";
import tw from "twin.macro";
import LabelForm from "components/RFQ/LabelForm/LabelForm";

const animatedComponents = makeAnimated();

export const SpecialityMulti = ({
  options,
  name,
  setFieldValue,
  value,
  noLabel,
  maxOptions,
  customOnChange,
  ...props
}) => {
  //eslint-disable-next-line
  const [field, meta] = useField(name);
  const { setFieldTouched } = useFormikContext();

  const isError = meta.error && meta.touched;
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#336AF7",
    }),
    control: (provided) => ({
      ...provided,
      padding: "0.438rem",
      minHeight: "58px",
      borderRadius: "6px",
      borderColor: isError ? "#E11900" : "#e7eaee",
      marginBottom: isError ? "2.1rem" : "1.75rem",
      ...(isError ? tw`focus-within:ring-4 focus-within:!border-utility-error-300 !ring-ring-red-primary` 
        :   tw`focus-within:ring-4 focus-within:!border-utility-brand-300 !ring-ring-blue-primary`)    
    }),
  };

  const handleOnChange = (options) => {
    //create array of values for formik
    const valuesArray = options ? options.map((options) => options.value) : [];
    setFieldValue(name, valuesArray);
  };

  return (
    <div className={props.className} style={{ position: "relative" }}>
      {props.label && !noLabel && (
        <LabelForm label={props.label} required={props.required}></LabelForm>
      )}

      <Select
        isMulti
        styles={customStyles}
        closeMenuOnSelect={false}
        components={{ animatedComponents }}
        options={value.length === maxOptions ? [] : options}
        noOptionsMessage={() => {
          return value.length === maxOptions
            ? `Maximum of ${maxOptions} sectors allowed`
            : "No options available";
        }}
        onChange={customOnChange ? customOnChange : handleOnChange}
        onBlur={() => setFieldTouched(name, true)}
        value={value}
        placeholder={props.placeholder || "Select.."}
      />

      <Error meta={meta} errorBottom={'-24px'} />
    </div>
  );
};
