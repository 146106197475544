import { GUESTUSER } from 'constants/guest';

const INITIAL_SECTORS_STATE = {};

export const guestReducer = (state = INITIAL_SECTORS_STATE, action) => {
    switch (action.type) {
        case GUESTUSER:
            return action.payload
        default:
            return state;
    }
};
