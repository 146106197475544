import { Flex } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import EditableTable from "components/RFQ/EditableTable/EditableTable";
import { FieldForm, Input } from "components/RFQ/Input";
import {
  CONTINGENCY,
  OTHER,
  PRELIMINARIES,
  PROFESSIONALFEES,
  PROVISIONALSUMS,
  TRADEPACKAGE,
} from "constants/rfq";
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { defaultOptions } from "./tableDefaultOptions";

const quoteTypeOptions = [
  {
    label: PRELIMINARIES,
    field: { name: PRELIMINARIES, description: "" },
    placeholder: "Project Prelims",
    value: PRELIMINARIES,
  },
  {
    label: TRADEPACKAGE,
    field: { name: TRADEPACKAGE, description: "" },
    placeholder:
      "Concrete - Supply and Install including repairs and supervision",
    value: TRADEPACKAGE,
  },
  {
    label: CONTINGENCY,
    field: { name: CONTINGENCY, description: "" },
    placeholder: CONTINGENCY,
    value: CONTINGENCY,
  },
  {
    label: PROFESSIONALFEES,
    field: { name: PROFESSIONALFEES, description: "" },
    placeholder: "Lead Design Architect Fees for Stage 1",
    value: PROFESSIONALFEES,
  },
  {
    label: PROVISIONALSUMS,
    field: { name: PROVISIONALSUMS, description: "" },
    placeholder: PROVISIONALSUMS,
    value: PROVISIONALSUMS,
  },
  {
    label: OTHER,
    field: { name: OTHER, description: "" },
    placeholder: "Hourly Rates for additional Services",
    value: OTHER,
  },
];

export const QuoteContent = ({
  initialDataItem,
  tableVariable,
  index,
  unitMeasures,
  disable,
  isPreview = false,
  type,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingDesc, setIsEditingDesc] = useState(false);

  const fieldname = (name) => `quote[${index}][${name}]`;
  const [, { error: itemsError, value: items }] = useField(fieldname("items"));
  const [
    ,
    { error: nameError },
    { setTouched: touchedName, setValue: setName },
  ] = useField(fieldname("name"));
  const [
    ,
    { error: descriptionError },
    { setTouched: touchedDescription, setValue: setDescription },
  ] = useField(fieldname("description"));
  const [isUnitOfMeasurementError, setIsUniteOfMeasurementError] =
    useState(false);
  const data = initialDataItem;

  const [, { value: rowData }, { setValue: setRowData }] = useField(
    fieldname("items")
  );

  const addRow = (name) => {
    setRowData([
      ...rowData,
      { id: null, name: name, unit_id: "", sort_order: rowData?.length + 1 },
    ]);
  };

  const copyRow = (row, index) => {
    setRowData([
      ...rowData,
      {
        id: null,
        placeholder: row.placeholder,
        name: null,
        unit_id: row.unit_id,
        sort_order: rowData?.length + 1,
        isRemove: row.isRemove,
      },
    ]);
  };

  const removeRow = (row, index) => {
    if (row.id) {
      setRowData(
        rowData.map((item) => {
          if (item.id === row.id) {
            return { ...item, isRemove: 1 };
          }
          return item;
        })
      );
    } else {
      setRowData(rowData.filter((_, i) => index !== i));
    }
  };

  const handleChangeType = (e) => {
    setIsEditing(false);
    setIsEditingDesc(false);
    setName(e.field.name);
    setDescription(e.field.description);
    setRowData(defaultOptions(e.field.name));
  };

  useEffect(() => {
    if (itemsError) {
      let tempUnitOfMeasurementError = false;
      for (let a = 0; a < itemsError?.length; a++) {
        if (itemsError[a]?.unit_id) {
          setIsUniteOfMeasurementError(true);
          break;
        } else {
          setIsUniteOfMeasurementError(tempUnitOfMeasurementError);
        }
      }
    }
  }, [itemsError]);

  const isSubmission = type === "submission";
  const isCustomName = (name) =>
    !quoteTypeOptions.some((option) => option.value === name) ||
    name === "Other";

  const subTotal = items?.reduce((a, b) => {
    return a + (b?.rate * b?.total || 0);
  }, 0);
  const GST = subTotal * 0.1;
  const includeGST = subTotal + GST;
  const currencyFormat = (number) =>
    (number || 0).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });

  const ErrorMessageComp = ({ text }) => {
    return <div className="text-red-500 text-md mt-2">{text}</div>;
  };

  const getPlaceHolder = (name) => {
    let placeholder = quoteTypeOptions.find(
      (option) => option.label === name
    )?.placeholder;
    if (placeholder) {
      return placeholder;
    } else {
      return "";
    }
  };

  return (
    <div>
      <Flex justifyContent={"space-between"}>
        <div
          id="card-header"
          className="w-2/3"
        >
          <div className="flex items-center gap-3">
            {isEditing && !isSubmission ? (
              <FieldForm
                name={fieldname("name")}
                classNameBoxInput="bg-black"
                size="sm"
                className={`w-2/4`}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    setIsEditing(false);
                    setName(e.target.value);
                    touchedName(true, true);
                  }
                }}
              />
            ) : (
              <h2 className="font-roboto text-lg font-semibold text-text-primary-900">
                {data?.name ? data?.name : "Section name"}
              </h2>
            )}
            {!isSubmission && isCustomName(data.name) && (
              <Icon
                icon={isEditing ? "check" : "edit-icon"}
                onClick={() => setIsEditing(!isEditing)}
                className="cursor-pointer"
                style={!isEditing ? { width: "14px", height: "14px" } : null}
              />
            )}
            {nameError ? (
              <div className="text-red-500 text-xs ml-2">
                Section name is required
              </div>
            ) : null}
          </div>

          <div className="flex items-center gap-3">
            {isEditingDesc && !isSubmission ? (
              <FieldForm
                name={fieldname("description")}
                classNameBoxInput="bg-black"
                size="sm"
                placeholder={getPlaceHolder(data?.name)}
                className={`w-2/4`}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    setIsEditingDesc(false);
                    setDescription(e.target.value);
                    touchedDescription(true, true);
                  }
                }}
              />
            ) : (
              <p>
                {data?.description ? data?.description : "Section description"}
              </p>
            )}
            {!isSubmission && (
              <Icon
                icon={isEditingDesc ? "check" : "edit-icon"}
                onClick={() => setIsEditingDesc(!isEditingDesc)}
                className="cursor-pointer"
                style={
                  !isEditingDesc ? { width: "14px", height: "14px" } : null
                }
              />
            )}
            {descriptionError ? (
              <div className="text-red-500 text-xs ml-2">
                Section description is required
              </div>
            ) : null}
          </div>
          {typeof itemsError === "string" ? (
            <ErrorMessageComp text="Please ensure you've added at least one row" />
          ) : itemsError?.length ? (
            isUnitOfMeasurementError ? (
              <ErrorMessageComp text="Please ensure you've select unit of measurement for each row" />
            ) : (
              <ErrorMessageComp text="Please ensure you've added a description of scope for each row" />
            )
          ) : null}
        </div>
        <div style={{ width: "368px" }}>
          {!isPreview && (
            <Input
              type="select"
              name="section"
              placeholder="Select quote type"
              options={quoteTypeOptions}
              checkIcon={true}
              icon="quote-type"
              onChange={handleChangeType}
              value={isCustomName(data.name) ? "Other" : data.name}
            />
          )}
        </div>
      </Flex>
      <div className="w-[calc(100% + 88px)] -mx-[44px] font-roboto overflow-hidden">
        <EditableTable
          unitMeasures={unitMeasures}
          data={initialDataItem.items}
          tableVariable={tableVariable}
          index={index}
          isPreview={isPreview}
          type={type}
          disable={disable}
          removeRow={removeRow}
          addRow={addRow}
          copyRow={copyRow}
        >
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="text-xs py-3 px-4 font-medium bg-bg-gray-200 text-text-tertiary-600 border border-gray-300">
              Subtotal
            </td>
            <td className="border py-3 px-4 border-gray-300">
              {isSubmission ? currencyFormat(subTotal) : "$0.00"}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="text-xs py-3 px-4 font-medium bg-bg-gray-200 text-text-tertiary-600 border border-gray-300">
              GST (10%)
            </td>
            <td className="border py-3 px-4 border-gray-300">
              {isSubmission ? currencyFormat(GST) : "$0.00"}
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="text-xs py-3 px-4 font-medium bg-bg-gray-200 text-text-tertiary-600 border border-gray-300">
              Total Cost
            </td>
            <td className="border py-3 px-4 border-gray-300">
              {isSubmission ? currencyFormat(includeGST) : "$0.00"}
            </td>
          </tr>
        </EditableTable>
      </div>
    </div>
  );
};