
import { Flex } from '@chakra-ui/react';
import { assignSupplierListToProject } from 'actions/RFQ/projectsAction';
import EditDropdown from 'components/Pages/ProjectDetail/EditDropdown/EditDropdown';
import GlobalAvatarGroup from 'components/RFQ/AvatarGroup/AvatarGroup';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/RFQ/Table';
import React from 'react'
import { toast } from 'react-toastify';
import tw, { styled, css } from 'twin.macro';
import { RFQButtonActions } from './RFQButtonActions';
import { STATUS_EVALUATION, STATUS_ACTIVE } from 'utils/constants';

const ListViewStyled = styled.div(() => [
    tw`pt-0`,
    css`
    .title {
        font-weight: 500;
        font-family: Roboto;
        font-size: 14px;
    }

    .text {
        font-weight: 400;
        font-family: Roboto;
        font-size: 14px;
    }
    
    `
])

const ThComp = ({ title }) => {
    return <div className=' font-roboto text-text-tertiary-600 text-xs th' style={{ gap: '8px', alignItems: 'center' }}>
        {title}
    </div>
}

const SupplierList = ({ data, project, setSupplierLists }) => {
    const isEvaluation = (rfqStatus) => rfqStatus && rfqStatus?.toUpperCase() === STATUS_EVALUATION
    const isActive = (rfqStatus) => rfqStatus && rfqStatus?.toUpperCase() === STATUS_ACTIVE
    const avatars = (data) =>
        data.map((supplier) => {
            return {
                avatar: supplier?.logo_path,
            };
        });

    const removeHandler = (supplier) => {
        const checkedRow = data.filter(item => item.id !== supplier.id)
        assignSupplierListToProject(project?.id, { supplier_list_id: checkedRow?.length > 0 ? checkedRow.map(item => item.id) : null })
            .then(res => {
                setSupplierLists(checkedRow)
                toast.success(res)
            })
            .catch(err => {
                toast.error('Error while removing supplier list')
            })
    }

    return (
        <ListViewStyled>
            <Table containerClass={tw`w-full`}>
                <TableHeader>
                    <TableRow>
                        <TableHead firstCol={true} className='!max-w-[180px]' >
                            <div className='flex' style={{ gap: '8px', alignItems: 'center' }}>
                                <ThComp title="Supplier List"></ThComp>
                            </div>
                        </TableHead>
                        <TableHead className="!max-w-[180px]">
                            <ThComp title="List"></ThComp>
                        </TableHead>
                        <TableHead className="!max-w-[200px]">
                            <ThComp title="Description"></ThComp>
                        </TableHead>
                        <TableHead className="!max-w-[500px]" >
                            <ThComp title="Suppliers"></ThComp>
                        </TableHead>
                        <TableHead className="!min-w-[208px]">
                            <div className='hidden'>
                                CTA
                            </div>
                        </TableHead>
                        <TableHead className="max-w-[48px]">
                            <div className='hidden'>
                                Icon Dropdown
                            </div>
                        </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody className='text-sm'>
                    {data?.map((supplier, index) => {
                        const slug = supplier.name?.toLowerCase().replace(/ /g, "-");
                        return (
                            <TableRow key={index} className="group">
                                <TableCell 
                                   firstCol={true} 
                                   className="!max-w-[268px] after-box-shadow group-hover:bg-utility-blue-light-50/30"
                                   >
                                    <div
                                        style={{
                                            alignItems: 'center',
                                            gap: '8px'
                                        }}>
                                        <a 
                                            href={`../../supplier-list/${supplier.id}/${slug}?project-id=${project?.id}`}
                                            target='_blank'
                                            rel='noreferrer'
                                            className='title line-clamp-1 text-ellipsis text-text-tertiary-600'
                                        >
                                            {supplier?.name}
                                        </a>
                                    </div>
                                </TableCell>
                                <TableCell 
                                   className="!max-w-[200px] min-w-[180px] group-hover:bg-utility-blue-light-50/30"
                                   >
                                    <div
                                        style={{
                                            alignItems: 'center',
                                            gap: '8px',
                                            display: 'flex',
                                            background: '#F9FAFB',
                                            width: 'fit-content',
                                            borderRadius: '30px',
                                            padding: '4px 8px',
                                            
                                        }}>
                                            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="4" cy="4" r="3" fill="#667085"/>
                                            </svg>
                                            <p style={{ fontWeight: '500' }}>{supplier?.is_company_approved_list ? 'Panel' : 'My Suppliers'}</p>
                                    </div>
                                </TableCell>
                                <TableCell className="max-w-[500px] group-hover:bg-utility-blue-light-50/30">
                                    <p className='font-roboto text-text-tertiary-600 font-normal' style={{ fontSize: '14px' }}>
                                        {supplier?.description}
                                    </p>
                                </TableCell>
                                <TableCell className={"group-hover:bg-utility-blue-light-50/30"}>
                                    <Flex className='items-center gap-3'>
                                        {supplier?.suppliers.length > 1 ?
                                            <>
                                                <p>{supplier?.suppliers.length} suppliers</p>
                                                <GlobalAvatarGroup avatarSize='sm' maxAvatars={5} avatars={avatars(supplier?.suppliers)} />
                                            </>
                                            :
                                            <>
                                                {supplier?.suppliers.length === 1 &&
                                                    <>
                                                        <GlobalAvatarGroup avatarSize='md' maxAvatars={1} avatars={avatars(supplier?.suppliers)} />
                                                        <p className='text-sm font-medium line-clamp-1 text-ellipsis'>{supplier?.suppliers[0]?.name}</p>
                                                    </>
                                                }
                                            </>
                                        }
                                    </Flex>
                                </TableCell>
                                <TableCell className="group-hover:bg-utility-blue-light-50/30">
                                    <RFQButtonActions project={project} supplierList={supplier} />
                                </TableCell>
                                <TableCell className="group-hover:bg-utility-blue-light-50/30">
                                    {
                                        isEvaluation(supplier?.relateRfq?.rfqStatus) ||
                                        isActive(supplier?.relateRfq?.rfqStatus) ? 
                                            <></> : 
                                            <EditDropdown className='ml-auto w-max' removeHandler={() => removeHandler(supplier)} />
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    }
                    )}
                </TableBody>
            </Table>
        </ListViewStyled>
    )
}

export default SupplierList

