import React from 'react';
import { ContextMenuLink } from "./ContextMenuLink";
import { IconBox } from "../IconBox";
import { ReactComponent as RequestIcon } from "../../icons/request-icon.svg";
import { ReactComponent as SearchIcon } from "./../../icons/search.svg"
import { ReactComponent as ShortListIcon } from "./../../icons/shortlist.svg"
import { ReactComponent as SignOutIcon } from "./../../icons/logout.svg"
import { logoutGuestUser, upgradeAccount } from "actions/authActions";
import { connect } from "react-redux";
import logo from "./logo.png";
import logoText from "./logo-procuracon.svg";
// import { useSelector } from 'react-redux';
import { limitNotif } from 'helpers/notificationHelper';
import { Button } from 'components/Button';
import { SearchUpgradeModal } from 'components/GuestModal/SearchUpgradeModal';
function ContextMenuGuestComponent({
    isCollapseNav,
    logoutGuestUser,
    guest_rfq,
    user,
    upgradeAccount,
    props
}) {
    const [isOpen, setIsOpen] = React.useState(false);
    const displaySideMenu = true
    const topMenu = [
        {
            name: 'RFx',
            link: 'request-for-quote',
            icon: <RequestIcon />,
            total: guest_rfq.total,
            right: "-250px",
            color_primary: '#0045F5',
            color_secondary: '#BDD6FF',
            icon_width: '24px',
            icon_height: '24px',
        }
    ]

    const menus = [

        {
            name: "Search & Compare",
            isLink: true,
            icon: <SearchIcon />,
            link: "company-profile/company-information",
            showBorder: false,
            icon_width: '24px',
            icon_height: '24px',
            color_primary: '#0045F5',
            color_secondary: '#BDD6FF'
        },
        {
            name: "Shortlist Report",
            isLink: true,
            icon: <ShortListIcon />,
            link: "company-profile/edit-user-profile",
            showBorder: false,
            icon_width: '24px',
            icon_height: '24px',
            color_primary: '#0045F5',
            color_secondary: '#BDD6FF'
        }
    ]

    return (
        <>
            {!isCollapseNav && <img src={logoText} alt='Procuracon Logo text' className={`ml-5 fadeintext ${!isCollapseNav && 'active'}`} style={{ marginTop: '50px', width: '207px', height: '32px' }} />}
            {isCollapseNav && <img src={logo} className={`fadeintext ${isCollapseNav && 'activelogo'}`} style={{ minWidth: '40px !important', height: "40px !important", width: '40px', marginTop: '40px' }} alt="Procuracon Logo" />}
            <div style={{ display: 'grid', overflow: 'hidden', marginTop: '50px' }}>
                <div className='scroll-container' style={{ height: isCollapseNav ? "calc(100vh - 60%)" : "calc(100vh - 50%)" }} >
                    {topMenu.map((menu, index) => {
                        return <div key={index} className={`${isCollapseNav ? 'menuleft hover:bg-white-20 rounded-md mr-3 mb-2' : 'menuleft group hover:bg-utility-blue-light-50 focus:bg-white  overflow-hidden'}`}>
                            <ContextMenuLink to={menu.link} height={isCollapseNav ? 'auto' : '56px'}>
                                <div style={{ paddingLeft: isCollapseNav ? '13px' : '32px', width: '24px', marginRight: '36px' }}>
                                    <IconBox icon={menu.icon} color={isCollapseNav ? menu.color_secondary : menu.color_primary} width={menu.icon_width} height={menu.icon_width} />
                                </div>
                                <div style={{ display: (isCollapseNav ? 'none' : 'flex'), position: "relative", fontSize: '16px', lineHeight: '24px' }} className='font-roboto font-medium sidemuchild group-hover:text-royal-blue group-focus:text-royal-blue'>
                                    {menu.name}
                                    <div style={{ fontSize: '12px', textAlign: 'center', width: '30px', height: '22px', position: 'absolute', right: menu.right, display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='bg-gray-utility-100 rounded-full font-medium border border-quarternary-400'>
                                        <div className='font-roboto text-text-secondary-700 font-semibold'>
                                            {limitNotif(menu?.total)}
                                        </div>
                                    </div>
                                </div>
                            </ContextMenuLink>
                        </div>
                    })}
                </div>
                <div style={{ position: 'absolute', bottom: '32px', width: '100%' }}>
                    {displaySideMenu && menus.map((menu, index) => {
                        return <div key={index} className={`${isCollapseNav ? 'menuleft hover:bg-white-20 rounded-md mr-3 mb-2 h-auto' : 'menuleft group hover:bg-utility-blue-light-50 focus:bg-white  overflow-hidden h-56px'}`} onClick={() => setIsOpen(true)}>
                            {menu.isLink && <div className='flex items-center cursor-pointer' style={{ paddingTop: isCollapseNav?"":'10px' }}>
                                <div className={isCollapseNav?"":"-mt-1"} style={{ paddingLeft: isCollapseNav ? '12px' : '32px', width: '24px', marginRight: '36px' }}>
                                    <IconBox icon={menu.icon} color={isCollapseNav ? menu.color_secondary : menu.color_primary} width={menu.icon_width} height={menu.icon_width} />
                                </div>
                                <div style={{ display: (isCollapseNav ? 'none' : 'flex'), position: "relative", fontSize: '16px', lineHeight: '24px' }} className='font-roboto font-medium sidemuchild  group-hover:text-royal-blue group-focus:text-royal-blue'>
                                    {menu.name}
                                </div>
                            </div>}
                        </div>
                    })}
                    {displaySideMenu && <div className={`${isCollapseNav ? "hover:bg-white-20 rounded-md mr-3 mb-2 h-auto" : 'group hover:bg-utility-blue-light-50 focus:bg-white'}`}>
                        <div style={{ borderBottom: isCollapseNav ? 'solid 0px #EAECF0' : 'solid 1px #EAECF0', marginBottom: '15px', marginLeft: "7px", }} className='sidemuchild'></div>
                        <div onClick={() => logoutGuestUser()} className='flex gap-2 ml-4 items-center cursor-pointer'>
                            <div style={{ marginLeft: isCollapseNav ? '-7px' : '16px', width: '24px', marginRight: '8px' }}>
                                <IconBox icon={<SignOutIcon width="24px" height="24px" />} color={isCollapseNav ? "#BDD6FF" : "#0045F5"} width="24px" height="24px" />
                            </div>
                            <div style={{ display: (isCollapseNav ? 'none' : 'block'), lineHeight: '24px' }} className='font-roboto font-medium sidemuchild  group-hover:text-royal-blue group-focus:text-royal-blue'>
                                <p className='text-lg'>
                                    Logout
                                </p>
                            </div>
                        </div>
                        <div style={{ borderBottom: isCollapseNav ? 'solid 0px #EAECF0' : 'solid 1px #EAECF0', marginTop: '15px', marginLeft: "7px", }} className='sidemuchild'></div>
                    </div>}
                    {!isCollapseNav && <>
                        <div className='ml-5 mr-5 mt-5 mb-5 '>
                            <div className={`font-roboto text-text-primary-500 font-medium fadeintext  ${!isCollapseNav && 'active'}`} style={{ fontSize: '16px' }}>
                                Guest Account
                            </div>
                            <div className={`overflow-hidden overflow-ellipsis  line-clamp-1 font-roboto font-medium text-text-primary-900 fadeintext ${!isCollapseNav && 'active'}`} style={{ fontSize: '18px' }}>
                                {user.email}
                            </div>
                        </div>
                        <div className={`ml-5 mr-5 mt-5 mb-5 fadeintext ${!isCollapseNav && 'active'}`}>
                            <Button width="100%" onClick={() => { upgradeAccount(user?.email) }}>
                                Upgrade Account
                            </Button>
                        </div>
                    </>}
                </div>
            </div>
            <SearchUpgradeModal isOpen={isOpen} setIsOpen={setIsOpen} guestEmail={user.email} upgradeAction />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.guestUser,
        guest_rfq: state.guest_rfq
    };
};

const ContextMenuGuest = connect(mapStateToProps, {
    logoutGuestUser,
    upgradeAccount,
})(ContextMenuGuestComponent);

export default ContextMenuGuest