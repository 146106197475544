import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Router, Redirect } from "@reach/router";
import { navigate } from "@reach/router";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import BlurCompany from "./blur-image.png";
import { theme } from "../theme";
import { PageWrap } from "../components/Layout";
import { Sidebar } from "../components/Sidebar";
import { SideDraw } from "../components/SideDraw";
import { Tabs } from "../components/Tabs";
// Routes
import { CompanyProjectsContainer } from "../routes/company/CompanyProjectsContainer";
import { CompanyResources } from "../routes/company/CompanyResources";
import { CorporateDocuments } from "../routes/company/CorporateDocuments";
import {
  getCompanyView,
  getCompany,
  getResources,
  getDocuments,
  getPastProjects,
} from "../actions/companyActions";
import { CompanyLocations } from "../components/CompanyLocations";
import { CompanySectors } from "components/CompanySectors";
import { AdditionalProfilesModal } from "../components/GuestModal/AdditionalProfilesModal";
import { isEmptyObj } from "../utils/helpers";
import { ContextMenu } from "../components/ContextMenu";
import { isSuperAdmin } from "../utils/helpers";
import { useSelector } from "react-redux";

const CompanyContainer = styled.div`
  margin-left: 350px;
  margin-right: auto;
  padding: 50px;
  width: ${(props) => !props?.isCollapse ? 'calc(100vw - 850px)'  : 'calc(100vw - 550px)'};
  .blur-wrapper {
    position: relative;
    top: 50%;
    height: 50vh;
    h1 {
      text-align: center;
      margin-bottom: 40px;
      color: black;
    }
    button {
      margin: auto;
    }
  }

  ${theme.mediaQueries.xxl} {
    margin-left: 450px;
  }
`;

const PaidProfileWrapper = styled.div`
  background: url(${BlurCompany});
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
`;

const NotFound = () => {
  return <h1>ERROR: PATH NOT FOUND</h1>;
};

export const CompanyComponent = ({
  getCompany,
  getCompanyView,
  getResources,
  getDocuments,
  getPastProjects,
  companyId,
  company,
  resources,
  documents,
  guestUser,
  pastProjects,
  loading,
  isCollapse,
  ...props
}) => {

  const [statusCode, setStatusCode] = useState();
  let pathname = window.location.pathname;
  pathname = pathname.replace('/company/', '');
  let idCompanyFromURL = pathname.replace('/projects', '');

  const { account_type_id } = useSelector((state) => state?.auth?.current_company)
  const [isAdditionalProfileModalOpen, setIsAdditionalProfileModalOpen] =
    useState(true);
  
  useEffect(() => {
    if (companyId) {
      async function fetchData() {
        const res = await getCompanyView(companyId);
        setStatusCode(res?.status);
      }
      fetchData();
      getResources(companyId);
      getDocuments(companyId);
      getPastProjects(companyId, true);
    }
  }, [getCompanyView, getResources, getDocuments, getPastProjects, companyId]);

  useEffect(() => {
    setIsAdditionalProfileModalOpen(statusCode === 403);
  }, [statusCode]);


  useEffect(() => {
    if (parseInt(idCompanyFromURL) === company?.id) {
      let loggedInAccountType = parseInt(account_type_id)
      let dataAccountType = parseInt(company?.account_type_id)
      if (!isSuperAdmin()) {
        if (loggedInAccountType === 1 && loggedInAccountType === dataAccountType) {
          navigate('/404')
        } else if (loggedInAccountType === 3 && dataAccountType === 1) {
          navigate('/404')
        } else {
          if (!loggedInAccountType === 3 || !loggedInAccountType === 1) {
            navigate('/404')
          }
        }
      }
    }
    //eslint-disable-next-line
  }, [account_type_id, company])

  const firstSharedProfile =
    !isEmptyObj(guestUser) && guestUser?.shared_profiles?.length
      ? guestUser.shared_profiles[0].company_id
      : 0;

  const labelType =
    company?.profile_type?.id === 2
      ? "Specialty"
      : company?.profile_type?.id === 3
        ? "Trade"
        : "Disciplines";

  return (
    <PageWrap>
      <ContextMenu isDefaultCollapsed />
      <div className="relative">
        <Sidebar guestUser={guestUser} />
        <CompanyContainer isCollapse={isCollapse} >
          {(!loading && company) || !statusCode === 403 ? (
            <>
              <Tabs
                bg="white"
                data={[
                  {
                    link: "company-locations",
                    title: "Office Locations",
                  },
                  {
                    link: "sectors-speciality",
                    title: `Sectors and ${labelType}`,
                  },
                  {
                    link: "projects",
                    title: "Projects & Experience",
                  },
                  {
                    link: "resources",
                    title: "Key Personnel & Expertise",
                  },
                  {
                    link: "corporate-documents",
                    title: "Corporate Documents",
                  },
                ]}
              />

              <Router primary={false}>
                <CompanyLocations
                  path="company-locations"
                  locations={company.locations}
                />
                <CompanySectors path="sectors-speciality" company={company} />
                <CompanyProjectsContainer
                  path="projects"
                  companyId={company.id}
                />
                <CompanyResources
                  path="resources"
                  companyId={company.id}
                  resources={resources}
                  guestUser={guestUser}
                />
                <CorporateDocuments
                  path="corporate-documents"
                  documents={documents}
                  isRequesting={props.isRequesting}
                />
                {companyId && (
                  <Redirect
                    from="/"
                    to={`/company/${companyId}/projects`}
                    noThrow
                  />
                )}
                <NotFound default />
              </Router>
            </>
          ) : statusCode === 403 ? (
            <>
              <PaidProfileWrapper />
              <div className="blur-wrapper" />
              <AdditionalProfilesModal
                guestEmail={guestUser.email}
                isOpen={isAdditionalProfileModalOpen}
                setIsOpen={setIsAdditionalProfileModalOpen}
                redirectUrl={`/company/${firstSharedProfile}/projects`}
              />
            </>
          ) : (
            <Skeleton count={30} height={12} duration={1} />
          )}
        </CompanyContainer>
      </div>
      <SideDraw />
    </PageWrap>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.search.activeCompany,
    guestUser: state.auth.guestUser,
    resources: state.companyProfile.resources,
    documents: state.companyProfile.documents,
    loading: state.search.isLoading,
    isRequesting: state.search.activeCompany.isRequesting,
    isCollapse: state.sidebar.sidebarcollapse,
  };
};

export const Company = connect(mapStateToProps, {
  getCompany,
  getCompanyView,
  getResources,
  getDocuments,
  getPastProjects,
})(CompanyComponent);
