import React, { useState, useRef, useEffect } from 'react';
import { Calendar } from 'react-calendar';
import tw, { css, styled } from 'twin.macro';
import moment from 'moment';
import { Icon } from 'assets/icons/Icon';
import { Button } from 'components/RFQ/Button';


const DatePickerStyled = styled.div(({ ranged }) => [
    tw`relative px-6 py-5 pb-24 bg-white rounded-xl`,
    !ranged && tw`shadow-md`,
    ranged && tw`pb-4`,
    css`
        .react-calendar {
            width: 300px;
            max-width: 300px;
            min-width: 300px;
        }
        .event-date {
            position: relative;
            &:before {
                content: ' ';
                position: absolute;
                bottom: 6px;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 50%;
                width: 4px;
                height: 4px;
                z-index: 11;
                ${tw`bg-royal-blue`}
            }
        }
        .react-calendar__month-view__days {
            ${tw`gap-y-1`}
        }
        .react-calendar__tile {
            transition: none;
            ${tw`h-max w-max !overflow-visible outline-none rounded-full`}
        }
        .react-calendar__tile abbr {
            ${tw`relative block p-1 h-10 w-10 rounded-full hover:bg-blue-50 text-sm leading-8 z-10`}
        }
        .react-calendar__tile--active {
            &:before {
                background-color: white;
            }
        }
        .react-calendar__tile--active abbr {
            ${tw`!bg-royal-blue !text-white`}
        }
        .react-calendar__month-view__weekdays {
            ${tw`text-center mb-3`}
            abbr {
                font-size: 14px;
                text-decoration: none;
                font-weight: 500;
            }
        }
        .react-calendar__month-view__days__day--neighboringMonth {
            ${tw`text-gray-400 `}
        }
        .react-calendar__month-view__days__day{
            ${tw`!bg-transparent border border-transparent`}
        }
        .react-calendar__tile--range {
            border-radius: 0px !important;
            ${tw`!bg-gray-100 !text-black`}
            abbr {
                ${tw`!bg-gray-100 !text-black`}
            }
            &:before {
                ${tw`bg-royal-blue`}
            }
        }
        .react-calendar__tile--rangeStart {
            ${tw`relative !bg-royal-blue !text-white !rounded-full`};
            &:after {
                content: ' ';
                ${tw`absolute bg-gray-100 w-full h-full top-0`}
            }
            &:before {
                background-color: white;
            }
        }
        .react-calendar__tile--rangeEnd {
            ${tw`relative !bg-royal-blue !text-white !rounded-full`};
            &:after {
                content: ' ';
                ${tw`absolute bg-gray-100 w-full h-full top-0 right-5`}
            }
            &:before {
                background-color: white;
            }
        }
        .react-calendar__tile--rangeStart abbr {
            ${tw`relative !bg-royal-blue !text-white rounded-full`};
        }
        .react-calendar__tile--rangeEnd abbr {
            ${tw`relative !bg-royal-blue !text-white rounded-full`};
        }
        .react-calendar__tile--rangeBothEnds {
            ${tw`relative !bg-royal-blue !text-white rounded-full`};
            &:after {
                content: ' ';
                ${tw`absolute bg-gray-100 w-full h-full top-0 right-5 hidden`}
            }
            &:before {
                background-color: white;
            }
        }
    `,
]);

const Navigation = styled.div(() => [tw`flex justify-between w-full font-semibold mt-2 mb-4 [&_svg]:cursor-pointer`]);

export const DatePicker = ({ ranged, dates, minDate, onChange, className, onClickDayHandler, onApply, onCancel, ref, markedDate }) => {
    const calendarRef = useRef(null);
    const [date, setDate] = useState(dates ? moment(dates) : null);
    const [activeDate, setActiveDate] = useState(dates ? new Date(dates) : moment().toDate());

    const todayHandler = () => {
        setDate(moment().toDate());
        setActiveDate(moment().toDate());
    };

    const onChangeHandler = (value) => {
        onChange ? onChange(value) : setDate(value);
        onApply && onApply(value);
    };

    const onApplyHandler = (close) => {
        return onApply(date, close);
    };


    const handleKeyDown = (e) => {
        let cdate = new Date(date);
        if (!(cdate instanceof Date)) {
            console.error('Date is not a Date object');
            return;
        }
        let newDate = new Date(cdate);
        switch (e.key) {
            case 'ArrowDown':
                newDate.setDate(cdate.getDate() + 7);
                setDate(newDate);
                break;
            case 'ArrowUp':
                newDate.setDate(cdate.getDate() - 7);
                setDate(newDate);
                break;
            case 'ArrowLeft':
                newDate.setDate(cdate.getDate() - 1);
                setDate(newDate);
                break;
            case 'ArrowRight':
                newDate.setDate(cdate.getDate() + 1);
                setDate(newDate);
                break;
            case 'Enter':
                e.preventDefault()
                onApplyHandler(true)
                break;
            case 'Backspace':
                e.preventDefault()
                onCancel()
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        //eslint-disable-next-line
    }, [date]);


    return (
        <DatePickerStyled ranged={ranged} className={className}>
            <Navigation>
                <Icon icon="chevron-left" onClick={() => setActiveDate(moment(activeDate).add(-1, 'M').toDate())} />
                {moment(activeDate).format('MMMM YYYY')}
                <Icon icon="chevron-right" onClick={() => setActiveDate(moment(activeDate).add(1, 'M').toDate())} />
            </Navigation>
            {!ranged && (
                <div className="flex gap-2 my-5">
                    <div className={`flex pointer-events-none ${date ? '' : '!text-gray-500'} w-full p-3 border-2 border-gray-200 items-center justify-center rounded-md`}>
                        {date ? moment(date).format('MMM D, YYYY') : 'Select Date'}
                    </div>
                    <Button id="today" className='!w-full !bg-white !border-gray-300 !text-gray-800 !py-3' onClick={todayHandler}>
                        Today
                    </Button>
                </div>
            )}
            <div ref={calendarRef} tabIndex="0">
                <Calendar
                    dropdownMode="select"
                    showWeekNumbers={false}
                    showNavigation={false}
                    activeStartDate={activeDate}
                    onChange={onChangeHandler}
                    formatShortWeekday={(locale, date) => [`Su`, `Mo`, `Tu`, `We`, `Th`, `Fr`, `Sat`][date.getDay()]}
                    value={ranged ? dates : date}
                    selectRange={ranged}
                    minDate={minDate}
                    allowPartialRange={true}
                    onClickDay={onClickDayHandler}
                    tileClassName={({ date }) => (markedDate?.includes(moment(date).format('YYYY/MM/DD')) ? 'event-date' : '')}
                ></Calendar>
            </div>
            {!ranged && (
                <div className="flex justify-center absolute bottom-0 left-0 w-full gap-4 p-4 border-t border-gray-200">
                    <Button className='!w-full !py-3 !bg-white !border-gray-300 !text-gray-800' onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button className='!w-full !py-3' onClick={() => onApplyHandler(true)}>
                        Apply
                    </Button>
                </div>
            )}
        </DatePickerStyled>
    );
};
