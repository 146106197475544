import * as constants from '../actions/RFQ/actionTypes';

const INITIAL_SUPPLIER_STATE = {
    supplier:{},
    supplierCount:{},
    loading:false,
    error:'',
   
}

export const supplierListsReducer = (state = INITIAL_SUPPLIER_STATE, action) => {
    switch (action.type) {
        case constants.GET_SUPPLIERS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case constants.GET_SUPPLIERS_SUCCESS:
            return {
                ...state,
                loading: false,
                supplier: action.payload,
                error:'',
            };
        case constants.GET_SUPPLIERS_FAILED:
            return {
                ...state,
                loading: false,
                supplier: {},
                error: action.payload,
            };
        
        case constants.RESET_SUPPLIER_LISTS:
            return {
                ...state,
                supplier: {},
            }

        case constants.CREATE_SUPPLIER_LIST:
        return {
            ...state,
            loading: true,
        };

        case constants.CREATE_SUPPLIER_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                supplier: action.payload,
                error:'',
            };
        case constants.CREATE_SUPPLIER_LIST_FAILED:
            return {
                ...state,
                loading: false,
                supplier: {},
                error: action.payload,
            };

        case constants.SET_SUPPLIER_FAVORITE: 
            return {
                ...state,
                loading: true
            }
        case constants.SET_SUPPLIER_FAVORITE_SUCCESS: 
            return {
                ...state,
                loading: false
            }
        case constants.SET_SUPPLIER_FAVORITE_FAILED: 
            return {
                ...state,
                error: action.payload,
            }
        case constants.SET_SUPPLIER_ARCHIVED: 
            return {
                ...state,
                loading: true,
            }
        case constants.SET_SUPPLIER_ARCHIVED_SUCCESS: 
            return {
                ...state,
                loading: true,
            }
        case constants.SET_SUPPLIER_ARCHIVED_FAILED: 
            return {
                ...state,
                loading:false,

                error: action.payload,
            }
        case constants.SET_SUPPLIER_BADGES_COUNT:
            return {
                ...state,
                supplierCount: action.payload,
            }
        
        default:
            return state;
    }
};