import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
  } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { Heading } from "components/Heading";
import { cn } from "utils/helpers";

const ContractModal = ({title,children,isOpen, onCloseHandler}) =>{
    

    return <Modal isOpen={isOpen} size="xl">
        <ModalOverlay />
        <ModalContent className="px-4 py-8">
            <ModalHeader>
                <div className="flex justify-between items-center">
                    <div>
                        <Heading fontSize={"3rem"}>
                            <div className="text-royal-blue">{title}</div>
                        </Heading>
                        
                        <div className="text-base text-text-primary-900">Add Contract</div>
                    </div>
                    <button
                        className={cn(
                            "rounded-full h-10 w-10 items-center content-center ",
                        'bg-soft-blue'
                        )}
                        style={{ textAlign: "-webkit-center" }}
                        onClick={onCloseHandler}
                        >
                        <Icon icon={"close"}  className="text-royal-blue"/>
                    </button>
                </div>
            </ModalHeader>
            <ModalBody>
            {children}
               
            </ModalBody>
        </ModalContent>

    </Modal>
}

export default ContractModal;