import React from 'react'
import { cn } from 'utils/helpers'

const FormHeader = ({header,subHeader,className}) => {
  return (
    <div className={cn('font-roboto text-text-secondary-700',className)}>
      <h5 className='text-base font-semibold'>{header}</h5>
      <p className='text-base font-normal text-text-tertiary-600'>{subHeader}</p>
    </div>
  )
}

export default FormHeader
