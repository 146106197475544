import React from 'react'
import RFQStatusComp from 'components/RFQ/RFQStatusComp/RFQStatusComp'
import RfQResponseComp from 'components/RFQ/RfQResponseComp/RfQResponseComp'
import { Button } from 'components/RFQ/Button';
import moment from "moment";
import { Flex } from '@chakra-ui/core'
import { STATUS_DECLINE, STATUS_DRAFT, STATUS_EVALUATION } from "utils/constants";
import { timezoneChecker } from 'utils/helpers';
const RfqCard = ({ data, navigatePreview }) => {
    
    const renderButtonRFQView = (status) => {
        const statusName = typeof status === 'string' ? status.toUpperCase() : status;

        if (statusName === STATUS_DRAFT) {
            return "View Draft"
        } else if (statusName === STATUS_EVALUATION) {
            return "View Responses"
        } else {
            return "View RFx"
        }
    }

    return <div className='bg-white rounded-md shadow-lg h-[405px] mt-3 mb-3 overflow-hidden w-full' style={{ minWidth: '320px' }}>
        <div className='bg-royal-blue2 w-full h-[6px]'></div>
        <div className='pl-5 pr-5 pt-3 pb-3'>
            <div className='flex justify-between items-center' style={{ height: '60px' }}>
                <div className='rounded-full' style={{ width: '48px', height: '48px', backgroundImage: `url(${data?.rfq?.project?.logoPath})` }}></div>
            </div>
            <div>
                <h3 className='font-roboto font-semibold text-text-primary-900 capitalize line-clamp-1 overflow-hidden text-[20px] mt-3'>{data?.rfq?.project?.name}</h3>
                <div className='font-roboto text-text-primary-500 text-[14px] font-normal uppercase mb-4'>{data?.rfq?.supplierList?.name}</div>
            </div>
            <div className='flex justify-between items-center space-x-0 border-b border-[#EAECF0] border-solid' style={{ height: '40px' }}>
                <div className='font-roboto text-text-primary-500 text-[14px] font-normal'>Response</div>
                <RfQResponseComp response={data?.status}></RfQResponseComp>
            </div>
            <div className='flex justify-between items-center space-x-0 border-b border-[#EAECF0] border-solid' style={{ height: '40px' }}>
                <div className='font-roboto text-text-primary-500 text-[14px] font-normal'>Client</div>
                <div className='font-roboto text-text-primary-900 text-[14px] font-semibold'>{data?.rfq?.client}</div>
            </div>
            <div className='flex justify-between items-center space-x-0 border-b border-[#EAECF0] border-solid' style={{ height: '40px' }}>
                <div className='font-roboto text-text-primary-500 text-[14px] font-normal'>Submission Deadline</div>
                <div className='font-roboto text-text-primary-900 text-[14px] font-semibold text-end'>
                    {
                     moment(data?.rfq?.submissionDeadline)
                      .tz(timezoneChecker(data?.rfq?.submissionTimezone || 'AWST'))
                      .format('dddd, D MMMM YYYY')
                    }
                </div>
            </div>
            <div className='flex justify-between items-center space-x-0 border-b border-[#EAECF0] border-solid' style={{ height: '40px' }}>
                <div className='font-roboto text-text-primary-500 text-[14px] font-normal'>RFx status</div>
                <RFQStatusComp status={data?.rfq?.status}></RFQStatusComp>
            </div>
            {data?.status !== STATUS_DECLINE && <Button btntype='secondary' width="100%" className="mt-5" onClick={() => navigatePreview(data?.rfq?.id)}>
                <Flex align={'center'} >
                    <p className='font-roboto font-semibold text-utility-blue-dark-100'>
                        {renderButtonRFQView(data?.rfq?.status)}
                    </p>
                </Flex>
            </Button>}
        </div>
    </div>
}
export default RfqCard