import React from "react";
import { Avatar, AvatarGroup as AvatarGroupComponent } from "@chakra-ui/react";
import { Button } from "../Button";
import Tippy from "@tippyjs/react";
import styled from "styled-components";

import { Flex, Box, Text } from "@chakra-ui/react";
import { CompareAvatarContainer } from "./styled";
import { UserTooltip } from "../UserTooltip/UserTooltip";

const StyledTippy = styled(Tippy)`
  background: ${(props) => props.theme.colors.royalBlue};
  padding: 0;
  margin: 0;

  /* Styling the arrow for different placements */
  &[data-placement^="top"] > .tippy-arrow::before {
    border-top-color: ${(props) => props.theme.colors.royalBlue};
  }
`;

export const AvatarGroup = ({
  items,
  list,
  compareSlideoutClick,
  showButton,
  companyId,
  sectorId,
  ...props
}) => {
  if (!items.length) {
    return <>&mdash;</>;
  }

  const getAvatar = (location) => {
    return location !== null
      ? location
      : process.env.PUBLIC_URL + "/img/avatar-placeholder.png";
  };

  return (
    <>
      {!list ? (
        <CompareAvatarContainer>
          <AvatarGroupComponent size="sm" max={3}>
            {items.map((item) => (
              <Avatar
                size="sm"
                name={item.name}
                src={getAvatar(item.avatar_location)}
                key={item.id}
                style={{
                  width: "42px",
                  height: "42px",
                }}
              />
            ))}
          </AvatarGroupComponent>
        </CompareAvatarContainer>
      ) : (
        items.length > 0 &&
        items.slice(0, 4).map((item, index) => {
          return (
            <StyledTippy
              key={index}
              content={
                <UserTooltip
                  industryExperience={item.experience}
                  projectsCompleted={
                    item.projects_completed.filter(
                      (pc) => pc.sector_id === sectorId,
                    ).length
                  }
                />
              }
            >
              <Flex align="center" mb="1.25rem">
                <Avatar
                  src={getAvatar(item.avatar_location)}
                  showBorder={!item.avatar_location}
                />
                <Box ml="3">
                  <Text color="grayDark" fontWeight="600">
                    {item.full_name}
                  </Text>
                  <Text>{item.position}</Text>
                </Box>
              </Flex>
            </StyledTippy>
          );
        })
      )}

      {items.length > 0 && showButton && (
        <Button
          btntype="blueBorder"
          items={items}
          size="sm"
          width="100%"
          mt="auto"
          onClick={() => compareSlideoutClick(items, "personnel", companyId)}
        >
          Show all Personnel
        </Button>
      )}
    </>
  );
};

AvatarGroup.defaultProps = {
  items: [],
  showButton: false,
};
