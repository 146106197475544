import tw, { styled, css } from "twin.macro";
import { cn } from "utils/helpers";
import { Icon } from "assets/icons/Icon";
import { Image } from "@chakra-ui/core";

const sizes = {
  sm: tw`p-[5px] [&_svg]:mt-0`,
  md: tw`px-1.5 py-0.5 [&_svg]:mt-0`,
  lg: tw`px-2.5 py-0.5 [&_svg]:mt-0`,
};
const colors = {
  default: tw`bg-gray-50 border-gray-200 text-gray-700 before:bg-gray-700 [&_svg]:text-gray-400 hover:bg-gray-100`,
  active: tw`bg-utility-success-50 border-utility-success-200 !text-utility-success-700 before:bg-utility-success-700  [&_svg]:text-utility-success-200 hover:bg-utility-success-50`,
  error: tw`bg-utility-error-50 border-utility-error-200 !text-utility-error-700 before:bg-utility-error-700 [&_svg]:text-utility-error-200 hover:bg-utility-error-200`,
  success: tw`bg-utility-blue-light-50 border-utility-blue-light-200 text-utility-blue-light-700 before:bg-utility-blue-light-700 [&_svg]:text-utility-blue-light-700 hover:bg-utility-blue-light-200`,
  light: tw`bg-white border-utility-gray-200 text-text-primary-700 before:bg-white  [&_svg]:text-gray-400 hover:bg-white `,
};
const outlineColors = {
  default: tw`border-gray-700 text-gray-700 before:bg-gray-700 [&_svg]:text-gray-400 hover:bg-gray-50`,
  active: tw`border-utility-success-700 !text-utility-success-700 before:border-utility-success-700  [&_svg]:text-utility-success-200 hover:border-utility-success-50`,
  error: tw`border-utility-error-700 text-utility-error-700 before:border-utility-error-700 [&_svg]:text-utility-error-200 hover:bg-utility-error-200`,
};

const types = {
  dot: tw`relative pl-5 before:p-2 before:scale-[0.4] before:rounded-full before:block before:absolute before:top-1/2 before:-translate-y-1/2 before:left-1 before:bg-gray-500`,
};

const rounded = {
  sm: tw`rounded-md`,
  md: tw`rounded-lg`,
  lg: tw`rounded-xl`,
};

const BadgeStyled = styled.div(
  ({ size = "md", color = "default", type = "", outline, notFull, group }) => [
    tw`flex gap-1.5 items-center h-max w-max border rounded-full before:hidden px-2.5 py-0.5`,
    css`
      svg {
        width: 12px;
        height: 12px;
        margin-top: 2px;
      }
    `,
    sizes[size],
    outline ? [outlineColors[color]] : colors[color],
    outline && tw`bg-white`,
    !group &&
      css`
        pointer-events: none;
      `,
    types[type],
    notFull && tw`rounded-md`,
    rounded[rounded],
  ]
);

export const Badge = ({
  size = "lg",
  color,
  type = "",
  img,
  icon,
  iconLeft,
  children,
  className,
  outline,
  rounded,
  notFull,
  group,
}) => {
  return (
    <BadgeStyled
      group={group}
      notFull={notFull}
      size={size}
      color={color}
      outline={outline}
      rounded={rounded}
      type={type}
      className={cn(img && "!pl-1", className)}
    >
      {iconLeft && <Icon icon={iconLeft} />}
      {img && <Image width={16} height={16} src={img} />}
      {children}
      {icon && <Icon icon={icon} />}
    </BadgeStyled>
  );
};
