import { Spinner } from '@chakra-ui/react'
import { Icon } from 'assets/icons/Icon'
import React from 'react'
import tw, { styled } from 'twin.macro'

const UtilityButtonStyled = styled.button(() => [
    tw`h-[44px] font-roboto px-6 py-4 flex gap-2 items-center rounded-[4px] self-center `
])

const IconView = ({ icon,iconClassName,style }) => {
    if(style){
        return <Icon icon={icon} className={iconClassName} style={{style}}/>
    } else {
        return <Icon icon={icon} className={iconClassName} style={{width:'10px',height:'10px'}}/>
    }
}
const NoBorderButton = ({ text, icon, iconPosition, loading, className,iconStyle, iconClassName,...props }) => {
 
    return (
        <UtilityButtonStyled {...props} className={className ? className : 'disabled:opacity-50'} >
            {!loading ?
                <>
                    {(iconPosition !== undefined && iconPosition === "left") && <IconView icon={icon} iconClassName={iconClassName} style={{iconStyle}}/>}
                    <text className='font-roboto capitalize font-medium'>{text}</text>
                    {(iconPosition !== undefined && iconPosition === "right") && <IconView icon={icon} iconClassName={iconClassName} style={{iconStyle}}/>}
                </>
                :
                <Spinner />
            }
        </UtilityButtonStyled>
    )
}

export default NoBorderButton