import apiV2 from "api-v2";
import { toFormData } from "axios";
import { toast } from "react-toastify";

  export const getAddendum = async(id) => {
    try{
      const {data:addendum} = await apiV2.get(`addendum/${id}`);
      if(addendum.status){
        return addendum.data
      }else{
        return null
      }
    }catch(err){ 
      throw new Error(err?.response?.data?.message)
     }
  }

  export const postAddendumQuestion = async({rfq_id,addendum}) => {
    const formData = toFormData({rfq_id,addendum})
    try{
      const {data:addendum} = await apiV2.post(`addendum/store-question`,formData);
      if(addendum.status){
        toast.success(addendum.message)
        return addendum.data
      }else{
        toast.error(addendum.message || "Error posting addendum")
        return null
      }
    }catch(err){ 
        toast.error(err?.response?.data?.message || "Error posting addendum")
      throw new Error(err?.response?.data?.message)
     }
  } 

  export const postAddendumAnswer = async(data) => {
    const formData = toFormData(data)
    try{
      const {data:addendum} = await apiV2.post(`addendum/store-answer`,formData);
      if(addendum.status){
        toast.success("Addendum posted successfully")
        return addendum.data
      }else{
        toast.error(addendum.message || "Error posting addendum")
        return null
      }
    }catch(err){ 
        toast.error(err?.response?.data?.message || "Error posting addendum")
      throw new Error(err?.response?.data?.message)
     }
  } 

  export const updateAddendumAnswer = async(id,data) => {
    const formData = toFormData(data)
    try{
      const {data:addendum} = await apiV2.patch(`addendum/${id}/update-answer`,formData);
      if(addendum.status){
        toast.success(data.published ? "Addendum published successfully" : "Addendum unpublished successfully")
        return addendum.data
      }else{
        toast.error(addendum.message || "Error publish addendum")
        return null
      }
    }catch(err){ 
        toast.error(err?.response?.data?.message || "Error publish addendum")
        throw new Error(err?.response?.data?.message)
     }
  } 

  export const deleteAddendumClient = async(id,isAnswer) => {
    try{
      const {data:addendum} = await apiV2.delete(`addendum/${id}/${isAnswer ? 'delete-answer' : 'delete-question'}`);
      if(addendum.status){
        toast.success(addendum.message || "Addendum deleted successfully")
        return addendum.data
      }else{
        toast.error(addendum.message || "Error delete addendum")
        return null
      }
    }catch(e){
      toast.error("Error delete addendum")
      console.log(e)
    }
  }