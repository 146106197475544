import React from 'react'
import RFQStatusComp from 'components/RFQ/RFQStatusComp/RFQStatusComp'
import RfQResponseComp from 'components/RFQ/RfQResponseComp/RfQResponseComp'
import { TableCell, TableRow } from 'components/RFQ/Table';
import tw, { styled } from "twin.macro";
import moment from "moment";
import { Avatar } from 'components/RFQ/Avatar';
import { timezoneChecker } from 'utils/helpers';
const RfqList = ({ data, navigatePreview, isLoading, isLoadingPagination }) => {
    const SkeletonCustom = styled.div((() => [
        tw`animate-pulse bg-gray-300 w-full h-6`
    ]))

    const SkeleteonRow = () => (
        <TableRow>
            <TableCell >
                <SkeletonCustom />
            </TableCell>
            <TableCell >
                <SkeletonCustom />
            </TableCell>
            <TableCell >
                <SkeletonCustom />
            </TableCell>
            <TableCell >
                <SkeletonCustom />
            </TableCell>
            <TableCell >
                <SkeletonCustom />
            </TableCell>
            <TableCell >
                <SkeletonCustom />
            </TableCell>
        </TableRow>
    )
    const tableHeader = [
        {
            name: 'Project Name',
            width: "20%"
        },
        {
            name: 'Response',
            width: "20%"
        },
        {
            name: 'Client',
            width: "20%"
        },
        {
            name: 'Submission Deadline',
            width: "20%"
        },
        {
            name: 'RFx Status',
            width: "10%"
        },
        {
            action: '',
            width: '10%'
        }
    ]

    return <div className="overflow-x-auto border border-[#EAECF0] rounded-md shadow-lg bg-white ">
        <table className="w-full table-auto bg-white rounded-md mt-3 mb-3 border-b border-[#EAECF0] border-solid">
            <thead style={{ height: '30px' }}>
                <tr className="border-b border-[#EAECF0]">
                    {tableHeader.map((th, index) => {
                        return <th className="font-roboto font-medium text-text-tertiary-600 text-[12px]" style={{ width: th.width, textAlign: 'left' }}>
                            <div style={{ marginTop: '-10px', marginLeft: index === 0 ? '20px' : '0px' }}>
                                {th?.name}
                            </div>
                        </th>
                    })}
                </tr>
            </thead>
            <tbody>
                {!isLoading && <>
                    {data.map((singelData, index) => {
                        return <tr key={index} className="border-b border-[#EAECF0] hover:bg-utility-blue-light-50/30" onClick={() => navigatePreview(singelData?.rfq?.id)} style={{ cursor: 'pointer' }}>
                            <td className="px-4 py-2">
                                <div className='flex items-center'>
                                    <Avatar avatar={singelData?.rfq?.project?.logoPath} size="lg" />
                                    <div className='ml-2'>
                                        <h3 className='font-roboto font-semibold text-text-primary-900 capitalize line-clamp-1 overflow-hidden text-[14px] mt-3'>{singelData?.rfq?.project?.name}</h3>
                                        <div className='font-roboto text-text-primary-500 text-[14px] font-normal uppercase mb-4'>{singelData?.rfq?.supplierList?.name}</div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <RfQResponseComp response={singelData?.status}></RfQResponseComp>
                            </td>
                            <td>
                                <div className='font-roboto text-text-primary-500 text-[14px] font-medium'>{singelData?.rfq.client}</div>
                            </td>
                            <td>
                                <div className='font-roboto text-text-primary-500 text-[14px] font-medium'>
                                    {
                                        moment(singelData?.rfq?.submissionDeadline)
                                        .tz(timezoneChecker(singelData?.rfq?.submissionTimezone || 'AWST'))
                                        .format('dddd, D MMMM YYYY')
                                    }
                                </div>
                                <div className='font-roboto text-gray-400 text-[14px] font-normal'>
                                    {
                                        moment(singelData?.rfq?.submissionDeadline)
                                        .tz(timezoneChecker(singelData?.rfq?.submissionTimezone || 'AWST'))
                                        .format('HH:mm A')
                                    }
                                </div>
                            </td>
                            <td>
                                <RFQStatusComp status={singelData?.rfq?.status}></RFQStatusComp>
                            </td>
                            <td></td>
                        </tr>
                    })}
                </>}
                {isLoadingPagination && <>
                    <SkeleteonRow />
                    <SkeleteonRow />
                    <SkeleteonRow />
                </>}
            </tbody>
        </table>
    </div>
}

export default RfqList