import { Icon } from "assets/icons/Icon"
import ContractModal from "./ContractModal"
import { FileUpload } from "components/RFQ/FileUpload";
import { Formik, Form } from "formik";
import  { Button } from "components/RFQ/Button";
import { DateInput } from "components/DateInput";
import { useState } from "react";
import { addSupplierContract, deleteSupplierContract } from "actions/RFQ/supplierListsActions";
import moment from "moment";
import { DropdownMenu,DropdownMenuDrawer,DropdownMenuTrigger,DropdownItem } from "components/RFQ/MenuDropdown";
import { Spinner } from "@chakra-ui/react";
import { toast } from "react-toastify";
import Tooltip from 'react-tooltip'
import { OnSubmitValidationError } from "utils/OnSubmitValidationError";
import * as Yup from 'yup';
import { CustomModal } from "components/RFQ/Modal/CustomModal";

const MenuDropdown = ({ onDeleteContract }) => {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger icon></DropdownMenuTrigger>
        <DropdownMenuDrawer>
          <DropdownItem onClick={onDeleteContract}>
            <Icon icon="trash-03" />
            <p
              className="font-roboto font-medium text-text-secondary-700 text-[14px]"
              style={{ lineHeight: "20px" }}
            >
              Delete Contract
            </p>
          </DropdownItem>
        </DropdownMenuDrawer>
      </DropdownMenu>
    );
  };


const ContractUploadButton = ({onUploadButtonClick}) => {
    return <button onClick={onUploadButtonClick} className="flex items-center text-royal-blue cursor-pointer gap-2">
        <Icon icon="upload" style={{height:18,width:18}}/>
        <span className="text !font-medium text-royal-blue">Upload Contract</span>
    </button>
}

const ContractViewButton = ({onDeleteContract,contract, isLoading})=>{

    const contractRemainingValidDays = moment(contract.contract_expiry).diff(new Date(),'days');
    
    return <div className="flex items-center justify-between relative">
       {isLoading?<Spinner/>: <>
        <a href={contract.document_path} target="_blank" rel="noreferrer" className="flex items-center gap-2 cursor-pointer">
            <Icon icon={contract.file_type} style={{height:32,width:32}}/>
            
            {/* <p className="text !font-medium !text-text-primary-900 ">{contract.file_name.replace(/\.[^.$]+$/,'')}</p> */}
        </a>
        {contractRemainingValidDays<=60 && <>
                <div data-for="alert-tooltip" data-tip={`Contract is expiring in ${contractRemainingValidDays} days`} className="relative cursor-pointer">
                    <Icon icon="alert-triangle" style={{height:20,width:20}}/>
                </div>
                <Tooltip id="alert-tooltip" place="bottom"/>
            </>}
        
            <MenuDropdown onDeleteContract={onDeleteContract}/>
        </>}
        
    </div>
}

const Contract = ({company,supplierListId,onContractAddOrRemove}) => {

    const [isUploadModalOpen,setIsUploadModalOpen] = useState(false)
    const [isDeleteWarningDialogOpen,setIsDeleteWarningDialogOpen] = useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const dialogProperty = {
        icon: "blue-circle-check",
        bgColor: "bg-brand-secondary",
        iconColor: "text-utility-brand-700",
        title: `Are you sure you want to delete this contract?`,
        description: `You're about to delete the contract`,
      };

      const FooterComponent = () =>{
        return (
            <div className="flex justify-between gap-2 w-full">
              <Button
              btntype="plain-border"
                onClick={()=>setIsDeleteWarningDialogOpen(false)}
                className="!w-full"
              >
                Cancel
              </Button>
              <Button
                danger={true}
                className="flex !w-full gap-3"
                onClick={onDeleteContract}
              >
                Yes
              </Button>
            </div>
          );
      }

    const onContractSubmit = async (formObject) => {
       
        let formData = new FormData();
        formData.append("contract_document",formObject.contract_document[0])
        // dates need to be format into Y-m-d before sending to api
        formData.append("contract_expiry",moment(formObject.contract_expiry,'MMM DD YYYY HH:mm:ss [GMT]ZZ').format('YYYY-MM-DD'))
        formData.append("company_id",company.id)

        setIsLoading(true)
        await addSupplierContract(supplierListId,formData).then((res)=>{
            setIsLoading(false)
            onContractAddOrRemove(res.data?.suppliers);
            setIsUploadModalOpen(false)
            toast.success('Contract added successfully')
        }).catch((error)=>{
            setIsLoading(true)
            toast.error('Error on adding contract')
        })

    }

    const onDeleteContract =  async() =>{

        setIsDeleteWarningDialogOpen(false)
        setIsLoading(true)
        await deleteSupplierContract(supplierListId,{'company_id':company.id})
        .then((res)=>{
            setIsLoading(false)
            onContractAddOrRemove(res.data?.suppliers);
            toast.success('Contract deleted successfully')
        }).catch((e)=>{
            setIsLoading(false)
            toast.error('Error on deleting contract')
        })
    }

    const onUploadButtonClick = () =>{
        setIsUploadModalOpen(!isUploadModalOpen)
    }

    

    return <>
    {company.contract?<ContractViewButton contract={company.contract} onDeleteContract={()=>setIsDeleteWarningDialogOpen(true)}/>:<ContractUploadButton onUploadButtonClick={onUploadButtonClick}/>}
    
    <ContractModal 
        isOpen={isUploadModalOpen} 
        title={company.name}  
        onCloseHandler={()=>setIsUploadModalOpen(false)}>
        <Formik 
            initialValues={{contract_document:null,contract_expiry:''}} 
            validationSchema={Yup.object({contract_document:Yup.mixed().required(),contract_expiry:Yup.string().required()})}
            onSubmit={onContractSubmit}>
            {(props) => {
                const { setFieldValue,values,setStatus,} = props;
                const onSubmitValidationError = () => {
                    setStatus("required");
                  };
                    return (
                    <Form>
                        <FileUpload  type="file"
                            label="Contract"
                            name="contract_document"
                            className="w-full my-4"
                            types={["pdf", "docx","doc", "xlsx", "xls"]}
                            defaultFileName="Document"
                            required
                            multiple={false}
                            isDisabled={true}
                            maxFileSize={10}/>
                            

                        <div className="bg-could-blue px-8 pt-8 pb-3 rounded-[16px]"> 
                        <DateInput
                            icon="calendar"
                            name="contract_expiry"
                            label="Contract Expiry"
                            setFieldValue={setFieldValue}
                            values={values}
                            required
                            className="max-w-[40%]"
                            inputClassName="bg-white py-3 "
                            minDate={new Date()}
                        />
                        </div>
                        <div className="py-8">
                        <Button
                            btntype="submit"
                            className="!w-full !py-3"
                            onClick={() => {}}>
                            Save
                        </Button>
                       
                        {isLoading &&  <div className="flex items-center gap-4 justify-center pt-4 text-royal-blue">
                            <Spinner/>
                            <p className="text-center font-bold">Uploading ....</p> 
                        </div>}
                        </div>
                        <OnSubmitValidationError callback={onSubmitValidationError}/>
                    </Form>
                    );
            }}
            </Formik>
    </ContractModal>
    <CustomModal 
        isOpen={isDeleteWarningDialogOpen} 
        header={dialogProperty} 
        footerComponent={<FooterComponent/>} 
        onClose={()=>setIsDeleteWarningDialogOpen(false)}/>
     </>

}

export default Contract