import { Flex } from "@chakra-ui/core";
import React, { useEffect, useState } from "react";

import ButtonViewGroup from "components/ButtonViewGroup/ButtonViewGroup";

import { getProjects } from "actions/RFQ/projectsAction";
import { connect } from "react-redux";
import GridViewSupplier from "./GridViewSupplier";
import ListViewSupplier from "./ListViewSupplier";

import tw, { styled, css } from "twin.macro";
import InviteForm from "./InviteForm";
import { useField } from "formik";
import { Heading } from "components/Heading";

export const RFQSupplierStyled = styled.div(() => [
  tw`flex flex-col w-full`,
  css`
    .img-card {
      object-fit: cover;
      height: 104px;
      width: 100%;
    }
    .rfq-form-sub-title {
      font-family: Roboto;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      height: 30px;
    }
    .rfq-form-caption {
      font-family: Roboto;
      font-size: 14px;
      line-height: 20px;
      height: 20px;
      margin-bottom: 30px;
    }
    .layout-jumbotron {
      display: flex;
    }
    .layout-jumbotron-1 {
      flex: 1;
    }
    .layout-jumbotron-2 {
      flex: 1;
      align-self: flex-end;
    }
    .layout-jumbotron-title {
      font-family: Roboto;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #101828;
    }
    .layout-jumbotron-subtitle {
      font-family: Roboto;
      font-weight: 400;
      font-size: 14px;
      line-height: 30px;
      color: #475467;
    }
  `,
]);

function Suppliers({ rfqData, showErrorMessage }) {
  const [view, setView] = useState("grid");
  const supplierLists = rfqData?.supplierList?.suppliers
  const [, { value: invitedSuppliers }, { setValue: setInvitedSuppliers, }] = useField('suppliers')

  useEffect(()=>{
    setInvitedSuppliers(supplierLists.map((supplier)=>supplier.id))
    // this comment is added to remove warning of dependency missing for setInvitedSuppliers
    // eslint-disable-next-line
  },[supplierLists])

  const handleInviteSupplier = (supplier) => {
    setInvitedSuppliers([...invitedSuppliers, supplier])
  }
  const handleRemoveSupplier = (supplier) => {
    setInvitedSuppliers(invitedSuppliers.filter(sup => sup !== supplier))
  }

  const ErrorMessageComp = ({ text }) => {
    return <div className="text-red-500 text-md mt-2">{text}</div>
  }
  return (
    <div style={{ width: "100%" }}>
      <RFQSupplierStyled>
        <div id="layout-dashboard">
          <div className="layout-jumbotron">
            <div className="layout-jumbotron-1 py-8 mt-4">
              <Heading fontSize={'36px'} color="#003CE9">RFX PARTICIPANTS</Heading>
              <div
                id="subheader-page-subtitle"
                className="font-roboto text-sm text-text-tertiary-600 mt-1"
              >
                Confirm your Rfx participants and invite guests (non-members) to this Rfx below.
              </div>
              {showErrorMessage && <ErrorMessageComp text="Please ensure you've added at least one supplier"></ErrorMessageComp>}
            </div>
            <Flex gap={4} align={"center"} className="float-end">
              <ButtonViewGroup setView={setView} view={view} />
            </Flex>
          </div>
          <div className="relative mb-4">
            {view === "grid" ? (
              <GridViewSupplier
                supplierLists={supplierLists}
                invitedSuppliers={invitedSuppliers}
                handleInviteSupplier={handleInviteSupplier}
                handleRemoveSupplier={handleRemoveSupplier}
              />
            ) : (
              <ListViewSupplier
                supplierLists={supplierLists}
                invitedSuppliers={invitedSuppliers}
                handleInviteSupplier={handleInviteSupplier}
                handleRemoveSupplier={handleRemoveSupplier}
              />
            )}
          </div>
          <div className="w-1/2 relative">
            <InviteForm></InviteForm>
          </div>
        </div>
      </RFQSupplierStyled>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    projects: state.rfq.projects.projects,
    isLoading: false,
  };
};

export default connect(mapStateToProps, { getProjects })(Suppliers);
