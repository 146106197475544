import { Heading } from 'components/Heading';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as BGError } from "../../src/assets/img/not-found-bg.svg";
import { Link } from '@reach/router';
import { useSelector } from 'react-redux';

const NotFoundStyled = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const NotFound = () => {
    const company = useSelector((state) => state.auth.current_company);
    const isClient = company.profile_type?.id === 1
    const companyId = company?.id;
    const redirectPath = companyId ? (`/account/${companyId}/${ isClient ? 'projects' : 'request-for-quote'}`) : `/account/request-for-quote`;
    if (!companyId) return null;
    return (
        <NotFoundStyled>
            <div className='relative mx-auto w-full h-[90vh] max-w-[1280px] max-h-[680px] mt-[10vh]'>
                <BGError className='absolute w-full h-full left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-10' />
                <div className='relative z-20 text-center space-y-6 top-[45%] -translate-y-1/2'>
                    <Heading className='text-[160px] !text-white !leading-[1]'>404</Heading>
                    <Heading className='text-[48px] !text-utility-brand-300'>
                        Page not found
                    </Heading>
                    <p className='font-roboto text-xl text-white'>
                        The page you are looking for might have been removed <br/> or is temporarily unavailable.
                    </p>
                    <Link className='block !bg-white !text-royal-blue mx-auto !mt-12 h-[60px] w-[180px] px-6 py-4 rounded-sm font-semibold font-roboto leading-[30px]' 
                        to={redirectPath}
                    >
                        Go to homepage
                    </Link>
                </div>
            </div>
        </NotFoundStyled>
    );
};
