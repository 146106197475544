import React, { useEffect, useState } from 'react';
import { ContextMenuLink } from "./ContextMenuLink";
import { IconBox } from "../IconBox";
import { ReactComponent as SearchIcon } from "../../icons/search-icon.svg";
import { ReactComponent as RequestIcon } from "../../icons/request-icon.svg";
import { ReactComponent as MyProfileClientIcon } from '../../icons/user-icon.svg';
import { ReactComponent as UserIcon } from "../../icons/users-02.svg";
import { ReactComponent as SettingIconPopup } from "./../../icons/settings-b.svg";
import { ReactComponent as EditIcon } from "./../../icons/edit-b.svg";
import { ReactComponent as UserAdminIcon } from "./../../icons/users-b.svg";
import { ReactComponent as LogoutIcon } from "./../../icons/logout-b.svg"
import { ReactComponent as CompanyProfileIcon } from "./../../icons/suitcase.svg"
import { ReactComponent as SignOutIcon } from "./../../icons/logout.svg"
import { ReactComponent as GearIcon } from "./../../icons/gear.svg"
import { logoutUser, logoutGuestUser } from "actions/authActions";
import { connect } from "react-redux";
import {
  isAdmin,
  isSuperAdmin,
} from "../../utils/helpers";
import logo from "./logo.png";
import logoText from "./logo-procuracon.svg";
import { ShareProfileModal } from 'components/ShareProfile/ShareProfileModal';
import { CompanyLogoImg, PopUp } from 'components/GlobalNav/styled';
import { Link } from '@reach/router';
import { limitNotif } from 'helpers/notificationHelper';
import { createRFQTOTAL } from 'actions/supplierflow/supplierRfqActions';
import { getRFQServices } from 'services/supplier-flow/rfqServices';
import { useDispatch } from 'react-redux';
import { Avatar } from 'components/RFQ/Avatar';
function ContextMenuSupplierComponent({
  isCollapseNav,
  user,
  company,
  guestUser,
  logoutUser,
  logoutGuestUser,
  supplier_rfq_total,
  props
}) {
  const dispatch = useDispatch()
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false)
  const company_id = localStorage.getItem("company_id");
  const [displaySideMenu, setDisplaySideMenu] = useState(false)
  const [showSupplierMenu, setShowSupplierMenu] = useState("init")

  useEffect(() => {
    setShowPopUp(false)
  }, []);

  useEffect(() => {
    if (Object.keys(company).length !== 0) {
      if (isSuperAdmin() || company.grouping === 'procurer' || company.grouping === 'bidder_procurer') {
        setShowSupplierMenu("yes")
      } else {
        setShowSupplierMenu("no")
      }
    }
  }, [company])

  useEffect(() => {
    if (showSupplierMenu !== "init") {
      setDisplaySideMenu(true)
    }
  }, [showSupplierMenu])


  const topMenu = [
    {
      name: 'RFx',
      link: `/account/${company_id}/request-for-quote`,
      icon: <RequestIcon />,
      total: supplier_rfq_total || 0,
      right: "-250px",
      color_primary: '#0045F5',
      color_secondary: '#BDD6FF',
      icon_width: '24px',
      icon_height: '24px',
    }
  ]

  const menus = [
    {
      name: "Account Settings",
      isLink: true,
      icon: <GearIcon />,
      link: `/account/${company_id}/account-settings`,
      showBorder: false,
      icon_width: '24px',
      icon_height: '24px',
      color_primary: '#0045F5',
      color_secondary: '#BDD6FF'
    },
    {
      name: "Company Profile",
      isLink: true,
      icon: <CompanyProfileIcon />,
      link: `/account/${company_id}/company-profile`,
      showBorder: false,
      icon_width: '24px',
      icon_height: '24px',
      color_primary: '#0045F5',
      color_secondary: '#BDD6FF',
      exactLink : true
    },
    {
      name: "My Profile",
      isLink: true,
      icon: <MyProfileClientIcon />,
      link: `/account/${company_id}/company-profile/edit-user-profile`,
      showBorder: false,
      icon_width: '24px',
      icon_height: '24px',
      color_primary: '#0045F5',
      color_secondary: '#BDD6FF',
      exactLink : true
    }
  ]

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      try {
        let currentPage = 1
        let limit = 10
        let search = ""
        const response = await getRFQServices(currentPage, search, limit, { signal: abortController.signal })
        let responseData = response.data.data
        let totalRFQs = responseData.all.meta.total
        await dispatch(createRFQTOTAL(totalRFQs))
      } catch (error) {
        console.log("error", error)
      }
    };
    fetchData();
    // Clean-up function
    return () => {
      abortController.abort();
    };
    //eslint-disable-next-line
  }, [])

  const logout = async () => {
    await logoutUser();
  };
  const logoutGuest = async () => {
    await logoutGuestUser();
  };

  const menuFunction = (menu) => {
    switch (menu) {
      case "logout":
        guestUser.login ? logoutGuest() : logout()
        break;
      default:
        break;
    }

  }

  return (
    <div>
      <div style={{ display: 'flex' }}  >
        <div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: isCollapseNav ? '40px 0px 20px 0px' : '40px 8px 20px 12px', transition: 'margin 0.2s linear 0.1s' }}>
            <div>
              <Avatar avatar={props.logo} alt={props.name} className='!p-0' noHover />
            </div>
            <div>
              <div id="profile" className='overflow-hidden text-ellipsis'>
                <h2 className='font-roboto font-bold ml-3 capitalize overflow-hidden text-ellipsis' style={{ fontSize: '30px', color: '#101828', maxWidth: '300px', overflow: 'hidden', maxHeight: '40px' }}>{props.name}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'grid', overflow: 'hidden' }}>
        <div className='scroll-container' style={{ height: isCollapseNav ? "calc(100vh - 60%)" : "calc(100vh - 50%)" }} >
          {displaySideMenu && showSupplierMenu === "yes" && <div className={`${isCollapseNav ? 'menuleft hover:bg-white-20 rounded-md mr-3 mb-2' : 'menuleft group hover:bg-utility-blue-light-50 focus:bg-white  overflow-hidden'}`}>
            <ContextMenuLink to="/search" height={isCollapseNav ? 'auto' : '56px'}>
              <div style={{ paddingLeft: isCollapseNav ? '13px' : '32px', width: '24px', marginRight: '36px' }}>
                <IconBox icon={<SearchIcon />} color={isCollapseNav ? "#BDD6FF" : "#0045F5"} width="24px" height="24px" />
              </div>
              <div style={{ display: (isCollapseNav ? 'none' : 'flex'), position: "relative", fontSize: '16px', lineHeight: '24px' }} className='font-roboto font-medium sidemuchild group-hover:text-royal-blue group-focus:text-royal-blue'>
                Search Suppliers
              </div>
            </ContextMenuLink>
          </div>}
          {displaySideMenu && topMenu.map((menu, index) => {
            return <div key={index} className={`${isCollapseNav ? 'menuleft hover:bg-white-20 rounded-md mr-3 mb-2' : 'menuleft group hover:bg-utility-blue-light-50 focus:bg-white  overflow-hidden'}`}>
              <ContextMenuLink to={menu.link} height={isCollapseNav ? 'auto' : '56px'}>
                <div style={{ paddingLeft: isCollapseNav ? '13px' : '32px', width: '24px', marginRight: '36px' }}>
                  <IconBox icon={menu.icon} color={isCollapseNav ? menu.color_secondary : menu.color_primary} width={menu.icon_width} height={menu.icon_width} />
                </div>
                <div style={{ display: (isCollapseNav ? 'none' : 'flex'), position: "relative", fontSize: '16px', lineHeight: '24px' }} className='font-roboto font-medium sidemuchild group-hover:text-royal-blue group-focus:text-royal-blue'>
                  {menu.name}
                  <div style={{ fontSize: '12px', textAlign: 'center', width: '30px', height: '22px', position: 'absolute', right: menu.right, display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='bg-gray-utility-100 rounded-full font-medium border border-quarternary-400'>
                    <div className='font-roboto text-text-secondary-700 font-semibold'>
                      {limitNotif(menu?.total)}
                    </div>
                  </div>
                </div>
              </ContextMenuLink>
            </div>
          })}
        </div>
        <div style={{ position: 'absolute', bottom: '32px', width: '100%' }}>
          {displaySideMenu && menus.map((menu, index) => {
            return <div key={index} className={`${isCollapseNav ? 'menuleft hover:bg-white-20 rounded-md mr-3 mb-2' : 'menuleft group hover:bg-utility-blue-light-50 focus:bg-white  overflow-hidden'}`}>
              {menu.isLink && <ContextMenuLink to={menu.link} height={isCollapseNav ? 'auto' : '56px'}>
                <div style={{ paddingLeft: isCollapseNav ? '12px' : '32px', width: '24px', marginRight: '36px' }}>
                  <IconBox icon={menu.icon} color={isCollapseNav ? menu.color_secondary : menu.color_primary} width={menu.icon_width} height={menu.icon_width} />
                </div>
                <div style={{ display: (isCollapseNav ? 'none' : 'flex'), position: "relative", fontSize: '16px', lineHeight: '24px' }} className='font-roboto font-medium sidemuchild  group-hover:text-royal-blue group-focus:text-royal-blue'>
                  {menu.name}
                </div>
              </ContextMenuLink>}
            </div>
          })}
          {displaySideMenu && <div className={`${isCollapseNav ? "hover:bg-white-20 rounded-md mr-3" : 'group hover:bg-utility-blue-light-50 focus:bg-white'}`}>
            <div style={{ borderBottom: isCollapseNav ? 'solid 0px #EAECF0' : 'solid 1px #EAECF0', marginBottom: '15px', marginLeft: "7px", }} className='sidemuchild'></div>
            <div onClick={() => menuFunction("logout")} className='flex gap-2 ml-4 items-center cursor-pointer'>
              <div style={{ marginLeft: isCollapseNav ? '-7px' : '16px', width: '24px', marginRight: '8px' }}>
                <IconBox icon={<SignOutIcon width="24px" height="24px" />} color={isCollapseNav ? "#BDD6FF" : "#0045F5"} width="24px" height="24px" />
              </div>
              <div style={{ display: (isCollapseNav ? 'none' : 'block'), lineHeight: '24px' }} className='font-roboto font-medium sidemuchild  group-hover:text-royal-blue group-focus:text-royal-blue'>
                <p className='text-lg'>
                  Logout
                </p>
              </div>
            </div>
            <div style={{ borderBottom: isCollapseNav ? 'solid 0px #EAECF0' : 'solid 1px #EAECF0', marginTop: '15px', marginLeft: "7px", }} className='sidemuchild'></div>
          </div>}
          <div style={{ padding: '20px', marginTop: '30px' }}>
            <img src={logoText} alt='Procuracon Logo text' className='sidemuchild logolong' style={{ width: '154px', height: '24px' }} />
          </div>
          <div style={{ display: (isCollapseNav ? 'block' : 'none') }}>
            <div className=''>
              <IconBox icon={<img src={logo} alt="Procuracon Logo" width="40" height="40" style={{ marginLeft: '-9px' }} />} />
            </div>
          </div>
        </div>
      </div>
      <ShareProfileModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} />
      {showPopUp && (
        <PopUp style={{ marginLeft: !isCollapseNav ? "20px" : "", bottom: isCollapseNav ? "160px" : "90px" }}>
          <div className="header" onClick={() => setShowPopUp(false)}>
            <CompanyLogoImg
              src={
                company?.logo_path ||
                `https://ui-avatars.com/api/?name=${company?.name || guestUser.email
                }&color=fff&background=000`
              }
              onClick={() => setShowPopUp(false)}
            />
            <p className="title">{company?.name}</p>
            <span className="tag">
              {company?.plan?.package_name || guestUser.login ? "guest" : ""}
            </span>
          </div>
          <div className="body">
            {!guestUser.login && (
              <li onClick={() => setShowPopUp(false)} >
                {" "}
                <Link
                  to={`/account/${company_id}/company-profile/edit-user-profile`}
                >
                  <UserIcon /> My Profile
                </Link>
              </li>
            )}
            {(isAdmin(user) || isSuperAdmin()) && (
              <>
                <li onClick={() => setShowPopUp(false)}>
                  {" "}
                  <Link
                    to={`/account/${company_id}/company-profile/account-management`}
                  >
                    <SettingIconPopup /> Account Management
                  </Link>
                </li>
                <li onClick={() => setShowPopUp(false)}>
                  {" "}
                  <Link
                    to={`/account/${company_id}/company-profile/company-information`}
                  >
                    <EditIcon /> Edit Company Details
                  </Link>
                </li>
              </>
            )}
            {localStorage.getItem("is_admin") === "true" && (
              <li onClick={() => setShowPopUp(false)}>
                {" "}
                <Link to="/companies">
                  <UserAdminIcon /> Companies Admin
                </Link>
              </li>
            )}
            <li>
              {" "}
              <button onClick={guestUser.login ? logoutGuest : logout}>
                <LogoutIcon /> Sign Out
              </button>
            </li>
          </div>
        </PopUp>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    access_token: state.auth.access_token,
    guestUser: state.auth.guestUser,
    user: state.auth.user,
    company: state.auth.current_company,
    supplier_rfq_total: state?.supplier_rfq?.total
  };
};

const ContextMenuSupplier = connect(mapStateToProps, {
  logoutUser,
  logoutGuestUser,
})(ContextMenuSupplierComponent);

export default ContextMenuSupplier