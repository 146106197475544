import React from 'react';

import { IconBoxStyled, IconStyled } from './styled';

export const IconBox = ({ to, children, icon, color, logo, width, height, ...rest }) => {
    return (
        <IconBoxStyled {...rest} color={color} logo={logo}  width={width} height={height}>
            <IconStyled color={color}>{icon} </IconStyled>
        </IconBoxStyled>
    );
};
