import NotFoundDatas from "components/RFQ/NotFoundDatas/NotFoundDatas";
import SupplierListCard from "components/RFQ/SupplierListCard/SupplierListCard";
import React from "react";
import tw, { styled } from "twin.macro";

const GridViewStyled = styled.div(() => [
  tw`py-8 grid gap-x-6 gap-y-8 grid-cols-2 min-w-[592px] xlg:grid-cols-3 xxl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6`,
]);

const SkeletonCustom = styled.div(() => [
  tw`animate-pulse bg-gray-300 w-full h-6`,
]);

const CardSupplierSkeleton = () => {
  return (
    <SkeletonCustom className="!h-[320px] rounded-xl !bg-gray-200 relative p-6 flex flex-col gap-2">
      <SkeletonCustom className="!w-48" />
      <SkeletonCustom className="!w-32" />
      <SkeletonCustom className="!h-12" />
      <SkeletonCustom className="mt-20" />
      <SkeletonCustom />
      <SkeletonCustom />
    </SkeletonCustom>
  );
};

const GridViewSupplierLists = ({
  data,
  isLoading,
  archived,
  currPage,
  currentTab,
}) => {
  return (
    <GridViewStyled>
      {isLoading && currPage === 1 && !data ? (
        [...Array(6)].map(() => <CardSupplierSkeleton />)
      ) : data?.length > 0 ? (
        <>
          {data.map((supplier, index) => (
            <SupplierListCard
              key={index}
              title={supplier.name}
              description={supplier.description}
              id={supplier.id}
              code={supplier.code}
              endDate={supplier.endDate}
              startDate={supplier.startDate}
              suppliers={supplier.suppliers}
              archived={archived}
              currentTab={currentTab}
            />
          ))}
          {isLoading &&
            currPage >= 1 &&
            data &&
            [...Array(6)].map(() => <CardSupplierSkeleton />)}
        </>
      ) : (
        <div className="absolute -translate-x-2/4 left-2/4">
          <NotFoundDatas
            text={`No supplier lists found`}
            description={
              "To get started, import relevant Suppliers from your Supplier List (Panel) to this project"
            }
          />
        </div>
      )}
    </GridViewStyled>
  );
};

export default GridViewSupplierLists;
