import { Box, Flex } from "@chakra-ui/react";
import { ProgressCircular } from "components/RFQ/ProgressIndicator";
// import { AvatarGroup } from "components/AvatarGroup";
import StatusBadge from "components/RFQ/StatusBadge/StatusBadge";
import { SkeletonBase } from "components/Skeleton";
import { TabHeader } from "components/TabHeader";
import React from "react";
import moment from "moment-timezone"
import GlobalAvatarGroup from "components/RFQ/AvatarGroup/AvatarGroup";
import { timezoneChecker } from "utils/helpers";

const HeaderCard = ({ rfqDetail, isLoading }) => {

  const companySupplierLists = rfqDetail?.supplierInvited?.all || [];
  const { accepted, all, submitted } = rfqDetail?.supplierInvited || { accepted: [], all: [], declined: [], submitted: [] };
  const allTotal = all?.length || 0;
  const submittedTotal = submitted?.length || 0;
  const acceptedTotal = accepted?.length + submittedTotal || 0;


  return (
    <div>
      <Box className="min-h-80 w-full border rounded-md shadow-xl content-between py-10">
        <Flex id="header-wrapper" className="justify-between" gap={10}>
          <div id="header-content" className="w-full ">
            <div className="px-6">
              <TabHeader
                noDivider={true}
                heading={`${rfqDetail?.project?.name || ' - '} | ${rfqDetail?.supplierList?.name || ' - '}`}
                description={rfqDetail?.description || ' - '}
                isLoading={isLoading}
                color="#003CE9"
              />
            </div>
            <div id="project-info" className="mt-12 pt-6 px-6">
              {!isLoading ? (
                <Flex className="text-base flex-col gap-4 font-semibold">
                  <Flex gap={4}>
                    <p className="font-roboto font-normal min-w-48 text-text-secondary-500 text-base">
                      RFx Status
                    </p>
                    <StatusBadge status={rfqDetail?.status} />
                  </Flex>
                  <Flex gap={4}>
                    <p
                      className="font-roboto font-normal min-w-48 text-text-secondary-500"
                      style={{ lineHeight: "24px", fontSize: "16px" }}
                    >
                      Client
                    </p>
                    <p
                      className="font-roboto font-semibold"
                      style={{ fontSize: "16px", lineHeight: "24px" }}
                    >
                      {rfqDetail?.client || " - "}
                    </p>
                  </Flex>
                  <Flex gap={4}>
                    <p
                      className="font-roboto font-normal min-w-48 text-text-secondary-500"
                      style={{ lineHeight: "24px", fontSize: "16px" }}
                    >
                      Project Location
                    </p>
                    <p
                      className="font-roboto font-semibold"
                      style={{ fontSize: "16px", lineHeight: "24px" }}
                    >
                      {rfqDetail?.location || " - "}
                    </p>
                  </Flex>
                  <Flex gap={4}>
                    <p
                      className="font-roboto font-normal min-w-48 text-text-secondary-500"
                      style={{ lineHeight: "24px", fontSize: "16px" }}
                    >
                      Submission Deadline
                    </p>
                    <p
                      className="font-roboto font-semibold"
                      style={{ fontSize: "16px", lineHeight: "24px" }}
                    >
                      <span className="font-semibold">
                        {rfqDetail?.submissionDeadline ? moment(rfqDetail?.submissionDeadline)
                          .tz(timezoneChecker(rfqDetail?.submissionTimezone || 'AWST'))
                          .format("dddd DD MMMM YYYY") 
                          : " - "
                        }
                      </span>
                      {" "}
                      <span className="font-normal"> 
                        {moment(rfqDetail?.submissionDeadline)
                          .tz(timezoneChecker(rfqDetail?.submissionTimezone || 'AWST'))
                          .format('hh:mma (\\G\\M\\T Z)')}
                      </span>
                    </p>
                  </Flex>
                  <Flex gap={4}>
                    <div className="flex justify-start items-center">
                      <p
                        className="font-roboto font-normal min-w-48 text-text-secondary-500"
                        style={{ lineHeight: "24px", fontSize: "16px" }}
                      >
                        Invited
                      </p>
                      <div className="ml-3">
                        <GlobalAvatarGroup avatarSize='sm' avatars={companySupplierLists.map((supplier) => ({ avatar: supplier?.company?.logo_path }))} />
                      </div>
                    </div>
                  </Flex>
                </Flex>
              ) : (
                <Flex className="text-base flex-col gap-4 font-semibold">
                  <Flex gap={4}>
                    <SkeletonBase />
                    <SkeletonBase width="280px" />
                  </Flex>
                  <Flex gap={4}>
                    <SkeletonBase />
                    <SkeletonBase width="280px" />
                  </Flex>
                  <Flex gap={4}>
                    <SkeletonBase />
                    <SkeletonBase width="280px" />
                  </Flex>
                  <Flex gap={4}>
                    <SkeletonBase />
                    <SkeletonBase width="280px" />
                  </Flex>
                  <Flex gap={4}>
                    <SkeletonBase />
                    <SkeletonBase width="280px" />
                  </Flex>
                </Flex>
              )}
            </div>
          </div>
          {!isLoading && <Flex gap={4} id="header-chart" className="w-[40%] px-6" justifyContent={'space-evenly'}>
            <ProgressCircular
              detail={
                <div className="text-base">
                  <p className="font-medium text-xs">Accepted</p>
                  <h1 className="text-2xl font-semibold text-text-primary-900">
                    {allTotal > 1
                      ? `${acceptedTotal} / ${allTotal}`
                      : "-"}
                  </h1>
                </div>
              }
              progress={
                ((acceptedTotal) / allTotal) * 100 || 0
              }
              size="sm"
            />
            <ProgressCircular
              detail={
                <div className="text-base">
                  <p className="font-medium text-xs">Submitted</p>
                  <h1 className="text-2xl font-semibold text-text-primary-900">
                    {acceptedTotal > 1
                      ? `${submittedTotal} / ${acceptedTotal}`
                      : "-"}
                  </h1>
                </div>
              }
              progress={
                (submittedTotal /
                  acceptedTotal) *
                100 || 0
              }
              size="sm"
            />
          </Flex>}
        </Flex>
      </Box>
    </div>
  );
};

export default HeaderCard;
