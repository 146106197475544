import React from "react";
import styled from "styled-components";
import Tippy from "@tippyjs/react";

export const MatchesLabel = ({ label, matched }) => {
  return (
    <span className={`matchesLabel p-1 m-1 ${matched ? "matched" : ""}`}>{label}</span>
  );
};

export const TooltipLabel = ({ allMatch, children, id, label }) => {
  const StyledTippy = styled(Tippy)`
    background: ${(props) => props.theme.colors.royalBlue};
    padding: 12px 36px;
    margin: 0;
    ul {
      margin-left: -0.5rem;
    }
    li {
      margin-top: 4px;
      list-style: disc;
    }

    /* Styling the arrow for different placements */
    &[data-placement^="right"] > .tippy-arrow::before {
      border-right-color: ${(props) => props.theme.colors.royalBlue};
    }
  `;

  const MatchList = ({ allMatch }) => {
    return (
      <ul>
        {allMatch?.length > 0 &&
          allMatch.map((matched) => <li>{matched.name}</li>)}
      </ul>
    );
  };

  const Label = ({ label }) => {
    return (
      <ul>
        {label.length > 0 &&
          label.map((option) => <li>{option.name}</li>)}
      </ul>
    );
  };

  return (
    <StyledTippy
      key={id}
      placement="right"
      content={allMatch?<MatchList allMatch={allMatch}/>:<Label label={label} />}
    >
      {children}
    </StyledTippy>
  );
};
