import React from "react";
import tw, { styled } from "twin.macro";

const BoxQuestionertyled = styled.div(() => [
    tw`w-full bg-white h-full min-h-8 rounded-md border-l-8 border-royal-blue relative`,
  ]);

const BoxQuestioner = ({children, className,...props}) => {
  return (
    <BoxQuestionertyled className={className} {...props}>
      {children}
    </BoxQuestionertyled>
  )
}

export default BoxQuestioner
