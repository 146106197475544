import { Icon } from 'assets/icons/Icon'
import React, { useEffect, useState } from 'react'
import { STATUS_PRIVATE, STATUS_PUBLIC } from 'utils/constants';
import { ToggleButton } from '../ToggleButton';
import { Flex } from '@chakra-ui/react';
import { AddendumMessage } from 'components/Pages/RFQDetail/AddendumMessage';
import moment from 'moment';
import tw, {styled,css} from 'twin.macro'
import { updateAddendumAnswer } from 'actions/RFQ/addendumAction';
import { Avatar } from '../Avatar';
import DropdownAddendumMenu from './DropdownAddendumMenu';

const ChatBox = styled.p(() => [
    tw`font-roboto text-base py-2.5 px-3.5 rounded-md`,
    css`
        ol{
            list-style: decimal;
            margin-left: 24px;
        }
        ul{
            list-style: disc;
            margin-left: 24px;
        }
        em{
            font-style: italic;
            color: inherit;
        }
        strong{
            color: inherit;
        }
        p{
            word-wrap: break-word;
        }
    `
]);


export const SplitComp = ({ label }) => {
    return <div className='flex my-4 mx-10 justify-center items-center font-roboto text-text-primary-tertiary-600 text-[14px] relative overflow-hidden px-4' style={{ height:'20px' }}>
        <div className='border border-[#EAECF0] border-solid ' style={{ width: '100%' }}></div>
        <div className='absolute bg-white p-4'>
            {label}
        </div>
    </div>
}

export const StatusComp = ({time, status="public", isUser, handleClickReply, isAnswer, nested, id, addendum, ...props}) => {

    const [isPublished,setIsPublised] = useState(status === STATUS_PUBLIC);
    const [isLoading,setIsLoading] = useState(false);

    useEffect(()=>{
        setIsPublised(status === STATUS_PUBLIC);
    },[status])

    const handleChangePublished = async () => {
        setIsLoading(true);
        updateAddendumAnswer(id, {published: isPublished ? 0 : 1 ,addendum:addendum}).then(res => {
            if(res){
                props?.publishQuestionHandler(props?.questionId,!isPublished);
                setIsPublised(!isPublished);
                setIsLoading(false);
            }else{
                setIsLoading(false);
            }
        })
    }

    return  <div className='flex justify-between items-center mt-1.5 cursor-pointer'>
    <Flex gap={3}>
    <div className='font-roboto text-text-tertiary-600 text-xs'>
        {moment(time).format('dddd h:mmA')}
    </div>
    {!isAnswer && props?.emptyAnswer && 
    <Flex gap={2} align={'center'} onClick={handleClickReply}>
        <Icon icon="corner-up-left" color="#475467" />
        <p className='text-royal-blue text-xs'>
            Reply
        </p>
    </Flex>
    }
    </Flex>
    <div>
        <div className='flex justify-end items-center'>
            {isAnswer && isUser && nested 
            ? <>
                {isPublished 
                    ? <p className='font-roboto text-xs text-text-tertiary-600'>Published</p> 
                    : <ToggleButton checked={isPublished} label={"Publish"} onChange={handleChangePublished} disabled={isLoading} />}
            </>
            : <>
                 <p className='font-roboto text-text-tertiary-600 text-xs'>{isPublished ? "Public" : "Private"}</p>
                {status === STATUS_PUBLIC &&  <div className='ml-2'> <Icon icon="eye-on" color="#475467" /></div>}
                {status === STATUS_PRIVATE &&  <div className='ml-2'> <Icon icon="eye-off" color="#475467" /></div>}
            </>}
        </div>
    </div>
</div>
};

export const AnswerComp = ({message, displayName,status,companyLogo,answerId,isUser,nested, ...props}) => {
    return <div className={`flex justify-start items-start gap-3 group ${nested?'ml-16':''}`}>
        <Avatar defaultImage={companyLogo ? 'company' : 'user'} avatar={companyLogo} className='bg-gray-50' />
        <div className='flex-auto' style={{maxWidth: 'calc(100% - 52px)'}}>
            <div className='flex mb-2 items-center w-full justify-between min-h-10'>
                <div className='flex items-center gap-2'>
                    <div className='font-roboto text-text-secondary-700 font-medium mb-1 mt-1' style={{ fontSize: '14px' }}>{displayName}</div>
                    {isUser && <div className='rounded-full px-2 text-xs font-medium font-roboto py-0.5 bg-gray-50 border border-gray-200 capitalize text-utility-gray-700 max-h-[22px]'>YOU</div>}
                </div>
                <DropdownAddendumMenu nested={nested} answerId={answerId} id={`item-${answerId}`} key={`item-${answerId}`} {...props} />
            </div>
            <ChatBox 
                className='!text-white bg-royal-blue2'
                dangerouslySetInnerHTML={{ __html: message.replace('<span','<p') }}  
            />
           <StatusComp addendum={message} isAnswer={true} status={status} isUser={isUser} id={answerId} nested={nested} {...props} />
        </div>
    </div>
}

export  const AskComp = ({message, displayName,status,isUser,companyLogo,time,rfqId,questionId,indexList,setAddendumData,emptyAnswer,publishQuestionHandler}) => {
    const [isReply, setIsReply] = React.useState(false);

    return (
    <>
    <div className='flex justify-start items-start mt-8 mb-4 gap-3'>
        <Avatar defaultImage={companyLogo ? 'company' : 'user'} avatar={companyLogo} className='bg-gray-50' />
        <div className='w-full'>           
            <div className='font-roboto text-text-secondary-700 font-medium mb-1 mt-1 flex gap-2 items-center' style={{ fontSize: '14px' }}>
            <span>{displayName}</span>
            {isUser && <div className='rounded-full px-2 text-xs font-medium font-roboto py-0.5 bg-utility-gray-200 capitalize text-utility-gray-700 max-h-[22px]'>YOU</div>}
            </div>
           <ChatBox 
                className='border border-[#EAECF0] text-text-primary-900 bg-gray-utility-100 !rounded-ss-none'
                dangerouslySetInnerHTML={{ __html: message }}   
            />
            <StatusComp addendum={message} status={status} handleClickReply={()=> setIsReply(!isReply)} time={time} rfqId={rfqId} id={questionId} emptyAnswer={emptyAnswer} publishQuestionHandler={publishQuestionHandler} />
        </div>
    </div>
        {isReply && <AddendumMessage nested={true} logo={companyLogo} questionId={questionId} indexList={indexList} rfqId={rfqId} setAddendumData={setAddendumData} handleClose={()=>setIsReply(false)}  />}
    </>)
}

