

export const PRELIMINARIES="Preliminaries"
export const TRADEPACKAGE = "Trade: Materials, Labor, Plant & Equip.";
export const CONTINGENCY = "Contingency";
export const PROFESSIONALFEES = "Professional Fees";
export const PROVISIONALSUMS = "Provisional SUM/S";
export const OTHER = "Other";




