//Loading


export const IS_LOADING = "IS_LOADING";


//Project

export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILED = "GET_PROJECTS_FAILED";
export const RESET_PROJECTS = "RESET_PROJECTS";
export const GET_PROJECTS_FILTER_OPTIONS = "GET_PROJECTS_FILTER_OPTIONS";

export const GET_PROJECT_FAVORITES_REQUEST = "GET_PROJECT_FAVORITES_REQUEST";
export const GET_PROJECT_FAVORITES_SUCCESS = "GET_PROJECT_FAVORITES_SUCCESS";
export const GET_PROJECT_FAVORITES_FAILED = "GET_PROJECT_FAVORITES_FAILED";
export const SET_PROJECT_FAVORITE = "SET_PROJECT_FAVORITE";
export const SET_PROJECT_FAVORITE_SUCCESS = "SET_PROJECT_FAVORITE_SUCCESS";
export const SET_PROJECT_UNFAVORITE_SUCCESS = "SET_PROJECT_UNFAVORITE_SUCCESS";
export const SET_PROJECT_FAVORITE_FAILED = "SET_PROJECT_FAVORITE_FAILED";

export const SET_PROJECT_ARCHIVED ="SET_PROJECT_ARCHIVED";
export const SET_PROJECT_ARCHIVED_SUCCESS ="SET_PROJECT_ARCHIVED_SUCCESS";
export const SET_PROJECT_ARCHIVED_FAILED ="SET_PROJECT_ARCHIVED_FAILED";


export const POST_NEW_PROJECT_REQUEST = "POST_NEW_PROJECT_REQUEST";
export const POST_NEW_PROJECT_SUCCESS = "POST_NEW_PROJECT_SUCCESS";
export const POST_NEW_PROJECT_FAILED = "POST_NEW_PROJECT_FAILED";

// Project badges counting

export const SET_PROJECT_BADGES_COUNT = "SET_PROJECT_BADGES_COUNT";


//Supplier 

export const GET_SUPPLIERS_REQUEST = "GET_SUPPLIER_REQUEST";
export const GET_SUPPLIERS_SUCCESS = "GET_SUPPLIER_SUCCESS";
export const GET_SUPPLIERS_FAILED = "GET_SUPPLIER_FAILED";

export const RESET_SUPPLIER_LISTS = "RESET_SUPPLIER_LISTS";

export const SET_SUPPLIER_FAVORITE = "SET_SUPPLIER_FAVORITE";
export const SET_SUPPLIER_FAVORITE_SUCCESS = "SET_SUPPLIER_FAVORITE_SUCCESS";
export const SET_SUPPLIER_FAVORITE_FAILED = "SET_SUPPLIER_FAVORITE_FAILED";

export const SET_SUPPLIER_ARCHIVED ="SET_SUPPLIER_ARCHIVED";
export const SET_SUPPLIER_ARCHIVED_SUCCESS ="SET_SUPPLIER_ARCHIVED_SUCCESS";
export const SET_SUPPLIER_ARCHIVED_FAILED ="SET_SUPPLIER_ARCHIVED_FAILED";

export const CREATE_SUPPLIER_LIST = "CREATE_SUPPLIER_LIST";
export const CREATE_SUPPLIER_LIST_SUCCESS = "CREATE_SUPPLIER_LIST_SUCCESS";
export const CREATE_SUPPLIER_LIST_FAILED = "CREATE_SUPPLIER_LIST_FAILED";

// Project badges counting

export const SET_SUPPLIER_BADGES_COUNT = "SET_SUPPLIER_BADGES_COUNT";

// rfqs

export const GET_RFQS_REQUEST = "GET_RFQS_REQUEST";
export const GET_RFQS_PER_PAGE = "GET_RFQS_REQUEST";
export const GET_RFQS_SUCCESS = "GET_RFQS_SUCCESS";
export const GET_RFQS_FAILED = "GET_RFQS_FAILED";
export const RESET_RFQ = "RESET_RFQ";
export const GET_RFQ_FILTER_OPTIONS = "GET_RFQ_FILTER_OPTIONS";

export const SET_RFQ_FAVORITE = "SET_RFQ_FAVORITE";
export const SET_RFQ_FAVORITE_SUCCESS = "SET_RFQ_FAVORITE_SUCCESS";
export const SET_RFQ_FAVORITE_FAILED = "SET_RFQ_FAVORITE_FAILED";

export const SET_RFQ_ARCHIVED ="SET_RFQ_ARCHIVED";
export const SET_RFQ_ARCHIVED_SUCCESS ="SET_RFQ_ARCHIVED_SUCCESS";
export const SET_RFQ_ARCHIVED_FAILED ="SET_RFQ_ARCHIVED_FAILED";


export const POST_NEW_RFQ_REQUEST = "POST_NEW_RFQ_REQUEST";
export const POST_NEW_RFQ_SUCCESS = "POST_NEW_RFQ_SUCCESS";
export const POST_NEW_RFQ_FAILED = "POST_NEW_RFQ_FAILED";

// RFQ Counting badges

export const SET_RFQ_BADGES_COUNT = "SET_RFQ_BADGES_COUNT";


//template

export const GET_TEMPLATE_REQUEST = "GET_TEMPLATE_REQUEST";
export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAILED = "GET_TEMPLATE_FAILED";
export const ADD_TEMPLATE = "ADD_TEMPLATE";

