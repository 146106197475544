import { Box } from "@chakra-ui/react";
import { QuoteCard } from "components/Pages/CreateRFQ/QuoteCard";
import { FileCard } from "components/RFQ/FileCard";
import React, { useEffect, useState } from "react";

const TenderCost = ({ tables, quoteType, quoteFile, unitMeasures, disable, ...props }) => {
  const [tenderCostTotal, setTenderCostTotal] = useState({
    subtotal: "",
    gst: "",
    total: "",
  });
  const tableVariable = [
    "#",
    "Description of Scope",
    "Unit of Measurement",
    "Quantity",
    "Rate",
    "Cost",
  ];

  useEffect(() => {
    let subtotal = parseFloat(0);
    let gst = parseFloat(0);
    let total = parseFloat(0);
    for (let a = 0; a < tables.length; a++) {
      for (let b = 0; b < tables[a].items.length; b++) {
        subtotal =
          parseFloat(tables[a].items[b].total) *
            parseFloat(tables[a].items[b].rate) +
          parseFloat(subtotal);
      }
    }
    gst = (subtotal * 10) / 100;
    total = parseFloat(subtotal) + parseFloat(gst);
    setTenderCostTotal({
      subtotal: subtotal,
      gst: gst,
      total: total,
    });
  }, [tables]);

  const formatCurrency = (total) =>
    total.toLocaleString("en-US", { style: "currency", currency: "USD" });
  return (
    <Box
      className="min-h-80 w-full border rounded-md shadow-xl p-10 "
      {...props}
    >
      {quoteType !== "file" ? (
        <>
          {tables?.map((tableData, index) => (
            <QuoteCard
              key={index}
              index={index}
              initialDataItem={tableData}
              tableVariable={tableVariable}
              isPreview={true}
              unitMeasures={unitMeasures}
              disable={disable}
              type="submission"
            />
          ))}
          {tenderCostTotal && (
            <div
              className="rounded-xl shadow-lg bg-white border border-[#EAECF0] border-solid  flex justify-start items-center overflow-hidden mb-10"
              style={{ height: "64px" }}
            >
              <div
                className="border-l-4 border-[#003CE9] pl-10 content-center font-roboto text-[18px] text-text-primary-900 font-semibold"
                style={{ height: "100%" }}
              >
                Total
              </div>
              <div
                className="border-l border-[#EAECF0] border-solid  content-center pl-6 pr-20 ml-auto"
                style={{ height: "100%" }}
              >
                <div
                  className="font-roboto font-medium text-text-primary-900"
                  style={{ fontSize: "12px" }}
                >
                  Subtotal
                </div>
                <div
                  className="font-roboto font-medium"
                  style={{ color: "#D0D5DD" }}
                >
                  {tenderCostTotal?.subtotal
                    ? formatCurrency(tenderCostTotal?.subtotal)
                    : "$0.00"}
                </div>
              </div>
              <div
                className="border-l border-[#EAECF0] border-solid  content-center pl-6 pr-20"
                style={{ height: "100%" }}
              >
                <div
                  className="font-roboto font-medium text-text-primary-900"
                  style={{ fontSize: "12px" }}
                >
                  GST (10%)
                </div>
                <div
                  className="font-roboto font-medium"
                  style={{ color: "#D0D5DD" }}
                >
                  {tenderCostTotal?.gst
                    ? formatCurrency(tenderCostTotal?.gst)
                    : "$0.00"}
                </div>
              </div>
              <div
                className=" border-[#EAECF0] border-solid  content-center pl-6 pr-20 bg-royal-blue2"
                style={{ height: "100%" }}
              >
                <div
                  className="font-roboto font-medium text-text-primary-900"
                  style={{ fontSize: "12px", color: "#D0D5DD" }}
                >
                  Total Cost
                </div>
                <div className="font-roboto font-medium text-white">
                  {tenderCostTotal?.total
                    ? formatCurrency(tenderCostTotal?.total)
                    : "$0.00"}
                </div>
              </div>
            </div>
          )}
        </> 
      ) : <>
            <h1 className='text-xl font-bold h-max'>Uploaded Template</h1>
            {quoteFile && <FileCard file={quoteFile} isPreview className='h-10' />}
          </>}
    </Box>
  );
};

export default TenderCost;
