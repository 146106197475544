import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import axios from "axios";
import { Heading } from "../../components/Heading";
import { CompanyCard } from "../../components/CompanyCard";
import { getCompanies } from "../../actions/companyActions";
import { Button } from "components/Button";
import "styles/loading.scss";
import { TabContent } from "../../components/Tabs/styled";
import { Icon } from "assets/icons/Icon";
import CreateSupplierDrawer from "./drawer/CreateSupplierDrawer";
import AddSupplierDrawer from "./drawer/AddSupplierDrawer";
import { isAdmin } from "utils/helpers";
import { Flex } from '@chakra-ui/react';
import { SearchBox } from "components/SearchBox";
import { Toolbar } from "components/Toolbar";
import { Input } from "components/RFQ/Input";

const ShortlistStyled = styled.div`
  padding: ${(props) =>
    props.padded ? `${props.theme.space.lg} ${props.theme.space.md}` : 0};

  ${(props) =>
    props.searchShortlist &&
    `
        margin: 0;
    `}

  .shortlisted-text {
    font-size: ${(props) => props.theme.fontSizes.xxl};
    line-height: 32px;
    margin-bottom: 0px;
  }
`;

const ShortlistContainer = ({
  getCompanies,
  items,
  noHeading,
  padded,
  searchShortlist,
  handleRemoveButtonClick,
  linkNewTab,
  user,
  ...props
}) => {
  const [showCreateDrawer, setShowCreateDrawer] = useState(false);
  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [idSupplierList, setIdSupplierList] = useState("");
  const sortlist = [
    {
      value: 'name',
      label: 'Sort Alphabetically (A-Z)'
    },
    {
      value: '-name',
      label: 'Sort Alphabetically (Z-A)'
    }
  ];

  const [sortBy, setSortBy] = useState("name")
  const [keyword, setKeyword] = useState("")
  let ableToSearch = useRef(false)


  useEffect(() => {
    if (!items.length && !searchShortlist) {
      getCompanies("", "");
    }
  }, [items, getCompanies, searchShortlist]);

  useEffect(() => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let supplier = params.get("supplier");
    if (supplier) {
      setIdSupplierList(supplier);
    }
  }, []);

  const companies = items.length ? items : props.searchResult;
  let listIDCompanies = [];
  let listCompanies = [];
  for (let a = 0; a < companies.length; a++) {
    listIDCompanies.push(companies[a].id);
    listCompanies.push({
      label: companies[a]?.name,
      value: companies[a]?.id,
    });
  }
  const groupId = props.groupId;
  const companyId = props.companyId;

  const download = () => {
    setIsLoading(true);
    axios({
      url:
        process.env.REACT_APP_API_URL +
        `/groups/print-pdf/${groupId}?companyId=${companyId}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "shortlist.pdf");
      document.body.appendChild(link);
      link.click();
      setIsLoading(false);
    });
  };

  const createSupplierList = () => {
    setShowCreateDrawer(true);
  };

  const addSupplierToList = () => {
    setShowAddDrawer(true);
  };

  const onCloseCreateDrawer = () => {
    setShowCreateDrawer(false);
  };

  const onCloseAddDrawer = () => {
    setShowAddDrawer(false);
  }

  useEffect(() => {
    if (ableToSearch.current) {
   
      setIsLoading(true)
      let res = getCompanies(keyword, sortBy);
      if (res) {
        // setIsLoading(false)
      }
    }
    // eslint-disable-next-line
  }, [keyword, sortBy])



  const onSearchSubmit = (values) => {
    ableToSearch.current = true
    setKeyword(values?.keyword)
  }

  const changeSortBy = (select) => {
    ableToSearch.current = true
    setSortBy(select?.value)
  }

  useEffect(() => {
    if (ableToSearch.current) {
   
      setIsLoading(true)
      let res = getCompanies(keyword, sortBy);
      if (res) {
        // setIsLoading(false)
      }
    }
    // eslint-disable-next-line
  }, [keyword, sortBy])

  return (
    <>
      <TabContent>
        {!noHeading && (
          <Heading size="xl">
            {props.admin ? "Companies Admin" : "Companies Listings"}
          </Heading>
        )}
        <Flex className="justify-end !w-full">
          <div>
            <Toolbar>
              <SearchBox
                placeholder="Search resources"
                handleFormSubmit={onSearchSubmit}
                keywordFilter
                whiteInput
              />
              <div style={{ marginLeft: '20px' }}>
                <Input
                  options={sortlist}
                  onChange={changeSortBy}
                  placeholder={sortBy}
                  value={sortlist[0].value}
                  type="select"
                  className="!w-[250px] !max-h-[50px]"
                  size="base"
                />
              </div>
            </Toolbar>
          </div>
        </Flex>
        <ShortlistStyled
          className="row"
          padded={padded}
          searchShortlist={searchShortlist}
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 justify-between">
            {companies.length > 0 ? (
              Object.values(companies).map((item) => {
                return (
                  <div>
                    <CompanyCard
                      key={item.id}
                      id={item.id}
                      name={item.name}
                      size={item.size}
                      logo={item.logo_path}
                      banner={item.banner_path}
                      className={`col md-6 ${searchShortlist ? "lg-3" : "lg-4"}`}
                      admin={props.admin}
                      location={item.location}
                      profileType={item.profile_type}
                      handleRemoveButtonClick={handleRemoveButtonClick}
                      linkNewTab={linkNewTab}
                      company_profile_status={item.company_profile_status}
                    />
                  </div>
                );
              })
            ) : (
              <div style={{ flexGrow: 1 }}>
                <Skeleton count={5} duration={1} />
              </div>
            )}
          </div>
          {companies.length > 0 &&
          window.location.pathname !== "/accounts" &&
          window.location.pathname !== "/companies" ? (
            <>
              <div className="flex">
                <Button
                  btntype="no-background-border"
                  height="46px"
                  className="mr-3"
                  onClick={download}
                >
                  <span className="iconInline">
                    <Icon icon="download" />
                  </span>
                  <div className="ml-2">Download Shortlist</div>
                </Button>
                <Button
                  btntype="blueBorder"
                  height="46px"
                  className="mr-3"
                  onClick={() => addSupplierToList()}
                >
                  Add to Existing Supplier list
                </Button>
                <Button
                  btntype="primary"
                  height="46px"
                  onClick={() => createSupplierList()}
                >
                  Add to New Supplier list
                </Button>
              </div>
            </>
          ) : (
            ""
          )}

          {isLoading ? (
            <div
              style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0.5)",
                top: 0,
                left: 0,
                zIndex: 9999,
              }}
            >
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </ShortlistStyled>
      </TabContent>
      <CreateSupplierDrawer
        isOpen={showCreateDrawer}
        onClose={onCloseCreateDrawer}
        listIDCompanies={listIDCompanies}
      ></CreateSupplierDrawer>
      <AddSupplierDrawer
        isOpen={showAddDrawer}
        onClose={onCloseAddDrawer}
        listCompanies={listCompanies}
        activeSupplierList={idSupplierList}
        isAdmin={isAdmin(user)}
      ></AddSupplierDrawer>
    </>
  );
};

ShortlistContainer.defaultProps = {
  items: [],
};

const mapStateToProps = (state) => {
  return {
    searchResult: state.search.searchResult,
    groupId: state.search.groupId,
    companyId: state.auth.current_company.id,
    user: state.auth.user,
  };
};

export const Shortlist = connect(mapStateToProps, { getCompanies })(
  ShortlistContainer
);
