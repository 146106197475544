import { Icon } from 'assets/icons/Icon'
import React from 'react'
import tw, { styled } from 'twin.macro'

const FilterButtonStyled = styled.button(({ isActive })=>[
    tw`h-[40px] leading-[1.5] font-roboto shadow-input bg-white text-text-secondary-700 text-sm font-semibold px-4 py-2 border border-[#D0D5DD] flex gap-2 items-center rounded-[4px] self-center
      hover:bg-gray-50 focus:ring-2 focus:ring-gray-200
    `,
    isActive && tw`ring-2 !ring-ring-blue-primary border-utility-brand-300 !text-royal-blue`,
])

const FilterButton = ({ filters, ...props }) => {

  const isActive = () => {
    let active = false
    if (!filters) active = false
    for (const key in filters) {
      if (filters[key] === '' || filters[key]?.length > 0) {
        active = true
        break
      }
    }
    return active
  }

  return (
    <FilterButtonStyled type='button' {...props} isActive={isActive()}>
      <Icon icon='filter'/>
      Filters
    </FilterButtonStyled>
  )
}

export default FilterButton
