import React from 'react'
import { Skeleton, SkeletonCircle } from "@chakra-ui/react";
import tw, { styled, css } from "twin.macro";
const RfqCardAnimation = () => {
    const ProjectCardSkeletonStyled = styled.div(() => [
        tw`w-full rounded-md border-t-8 bg-gray-50 relative drop-shadow-lg mb-8`,
        css`
          #card-content {
            padding: 24px;
            background-color: white;
            height: 100%;
            max-height: 320px;
            position: relative;
            display: grid;
            align-content: space-between;
            bottom: 0;
            border-radius: 8px;
          }
          #header-card {
            background-color: white;
            height: 72px;
          }
        `,
    ]);

    return <div className='min-w-[680px] grid gap-6 grid-cols-2 xlg:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-6 w-full'>
        {
            [...Array(6)].map(() => 
            <ProjectCardSkeletonStyled>
                <div id="header-card" className='relative z-10'>
                    <SkeletonCircle size='48px' startColor="#c8ccd4" className='absolute top-6 left-6 z-1 border border-white' />
                </div>
                <div id="card-content">
                    <Skeleton width='75%' height="32px" startColor="#c8ccd4" />
                    <Skeleton width='50%' height="18px" mb="16px" startColor="#c8ccd4" />
                    <Skeleton height="24px" mb="2px" startColor="#c8ccd4" />
                    <div className='w-full border border-gray-100' />
                    <Skeleton height="24px" mb="2px" startColor="#c8ccd4" />
                    <div className='w-full border border-gray-100' />
                    <Skeleton height="24px" mb="2px" startColor="#c8ccd4" />
                    <div className='w-full border border-gray-100' />
                    <Skeleton height="24px" mb="16px" startColor="#c8ccd4" />
                    <Skeleton height="48px" mb="2px" startColor="#c8ccd4" />
                </div>
            </ProjectCardSkeletonStyled> )
        }
    </div>
}

export default RfqCardAnimation