import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import tw, { styled } from "twin.macro";
import { getCompanyUsers } from "actions/AccountManagementActions";
import { cn } from "utils/helpers";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/RFQ/Table";
import { Avatar } from "components/RFQ/Avatar";
import { Icon } from "assets/icons/Icon";
import { Button } from "../Button";
import { Link } from "@reach/router";
export const AccountUsersComponent = ({
  company_users,
  company,
  getCompanyUsers,
  tab,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getCompanyUsers(company.id);
  }, [getCompanyUsers, company]);

  useEffect(() => {
    if (company_users && company_users.length >= 1) {
      setIsLoading(false);
    }
  }, [company_users]);

  const TableHeadComponent = ({ title, className }) => {
    return (
      <div
        className={cn("font-roboto text-text-tertiary-600 th", className)}
        style={{ gap: "8px", alignItems: "center" }}
      >
        {title}
      </div>
    );
  };

  const SkeletonCustom = styled.div(() => [
    tw`animate-pulse bg-gray-300 w-full h-6`,
  ]);

  const SkeleteonRow = () => (
    <TableRow>
      <TableCell>
        <SkeletonCustom />
      </TableCell>
      <TableCell>
        <SkeletonCustom />
      </TableCell>
      <TableCell>
        <SkeletonCustom />
      </TableCell>
      <TableCell>
        <SkeletonCustom />
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <div className="w-full rounded-lg border border-gray-200 mt-5 mb-5">
        <div className="flex justify-between mb-5 p-5">
          <div>
            <div className="font-roboto font-semibold text-text-primary-900 text-[18px]">
              Users
            </div>
            <p className="font-roboto text-sm text-text-tertiary-600 mt-2">
              Manage your users that have access to the platform here.
            </p>
          </div>

          <Button
            style={{ maxHeight: "48px" }}
          >
            <Link className="flex text-white items-center gap-[6px]" to="create-user">
              <div className="w-5 h-5 items-center justify-center flex">
                <Icon
                  icon="plus"
                  style={{
                    width: "12px",
                    height: "12px",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                />
              </div>
              <div className="font-roboto capitalize font-semibold text-base">
                Create User
              </div>
            </Link>
          </Button>
        </div>
        <Table className="border border-utility-gray-200">
          <TableHeader>
            <TableRow>
              <TableHead>
                <TableHeadComponent title="Company"></TableHeadComponent>
              </TableHead>
              <TableHead>
                <TableHeadComponent title="Email"></TableHeadComponent>
              </TableHead>
              <TableHead>
                <TableHeadComponent title="Phone"></TableHeadComponent>
              </TableHead>
              <TableHead>
                <TableHeadComponent title="User Type"></TableHeadComponent>
              </TableHead>
              <TableHead>
                <TableHeadComponent title=""></TableHeadComponent>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {!isLoading &&
              company_users.map((company, index) => {
                return (
                  <TableRow key={company?.id}>
                    <TableCell className="w-[20%] overflow-hidden">
                      <div className="flex justify-start items-center gap-2">
                        <Avatar avatar="" />
                        <div className="font-roboto font-medium text-text-primary-900 text-sm">
                          {company?.full_name}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <p className="font-roboto text-sm text-text-tertiary-600 font-normal">
                        {company?.email}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p className="font-roboto text-sm text-text-tertiary-600 font-normal">
                        {company?.phone}
                      </p>
                    </TableCell>
                    <TableCell>
                      <div className="font-roboto text-sm text-text-tertiary-600 font-normal">
                        {company?.position}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Icon
                        icon="dots-vertical"
                        style={{ color: "#D0D5DD" }}
                        className="cursor-pointer"
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            {isLoading && (
              <>
                <SkeleteonRow></SkeleteonRow>
                <SkeleteonRow></SkeleteonRow>
                <SkeleteonRow></SkeleteonRow>
              </>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const company = state.search.activeCompany;
  const company_users = state.accountManagement.companyUsers;
  return {
    company: company,
    company_users: company_users,
  };
};

export const AccountUsers = connect(mapStateToProps, { getCompanyUsers })(
  AccountUsersComponent,
);
