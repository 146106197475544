import apiV2 from 'api-v2';

const getRFQServices = async (email) => {
    return await apiV2.post(`/guest`,{
        email:email
    })
}
const getSubmissionServices = async (rfqId,email) => {
    return await apiV2.post(`/guest/show-submission/${rfqId}`,{
        email:email
    })
}
const saveSubmissionServices = async (rfqId,formData) => {
    return await apiV2.post(`/guest/save-submission/${rfqId}`,formData)
}
const acceptDeclineServices = async (rfqId,email,isAccept) => {
    return await apiV2.post(`/guest/accept-or-decline/${rfqId}`,{
        isAccept:isAccept,
        email:email
    })
}
const guestFirstLoggedServices = async (email,isAccept) => {
    return await apiV2.post(`/guest/first-logged`,{
        isAccept:isAccept,
        email:email
    })
}
export {getRFQServices,getSubmissionServices,saveSubmissionServices,acceptDeclineServices,guestFirstLoggedServices}