import React, { useState, useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import debounce from "lodash.debounce";
import { TextInput } from "../FormInputs/TextInput";
import { TextArea } from "../FormInputs/TextArea";
import { CheckboxStyled } from "../FormInputs/Checkbox";
import { Select } from "../FormInputs/Select";
import { Sectors } from "../FormInputs/Sectors";
import { Location } from "../FormInputs/Location";
import { ProcurementMethod } from "../FormInputs/ProcurementMethod";
import { YearCompleted } from "../FormInputs/YearCompleted";
import { FormButtonRow } from "../FormButtonRow";
import { Button } from "../Button";
import { Currency } from "../FormInputs/Currency";
import { Trades } from "../FormInputs/Trades";
import { TabHeader } from "../TabHeader";
import { TabContent } from "../Tabs/styled";
import { FlashError } from "../FormInputs/FlashError";
import { Spinner } from "@chakra-ui/react";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";
import { Modal } from "../Modal";
import { ModalBodyStyled, ModalHeader } from "../ShareProfile/styled";
import { Heading } from "../Heading";
import { TradeValue } from "components/FormInputs/TradeValue";
import _ from "lodash"
import { FormatedInput } from "components/FormInputs/FormatedInput";
import { FileUpload } from "components/RFQ/FileUpload";
import tw from "twin.macro";
import LabelForm from "components/RFQ/LabelForm/LabelForm";


const fundingTypeOptions = [
  { label: "N/A", value: "" },
  { label: "Public Project", value: "Public Project" },
  { label: "Private Project", value: "Private Project" },
  { label: "Public and Private Project", value: "Public and Private Project" },
  { label: "Confidential", value: "Confidential" },
];

const AreaCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 65px;

  .container-checkbox {
    margin-right: 15px;
  }
`;

const RowInput = styled.div`
  ${tw`grid grid-cols-2 gap-6`}
  ${(props) => props.oneRow && tw`grid-cols-1`}
`;

export const FormLabel = styled.label`
  font-size: ${(props) => props.theme.fontSizes.xs};
  color: ${(props) => props.theme.colors.textDark};
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  flex-direction: row;

  > span {
    color: #dc2538;
    margin-left: 3px;
  }
`;
const AutoSave = ({ debounceMs }) => {
  const formik = useFormikContext();
  formik.values.status = "published";
  // eslint-disable-next-line
  const debouncedSubmit = React.useCallback(
    debounce(() => formik.submitForm(), debounceMs),
    [formik.submitForm]
  );

  useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return true;
};
export const ProjectForm = ({
  profileTypeId,
  handleSubmit,
  initialValues,
  isRequesting,
  setAutoUpdateForm,
  autoUpdateForm,
  editForm,
  handleDelete,
  errorStatus,
  setErrorStatus,
  savedField,
  setInitialValue,
  setSavedField,
  ...props
}) => {
  const [siteAreaChecked, setSiteAreaChecked] = useState(false);
  const [grossAreaChecked, setGrossAreaChecked] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  

  const closeInfoModal = () => {
    setModalIsOpen(false);
  };
  useEffect(() => {
    setSiteAreaChecked(initialValues.site_area ? true : false);
    setGrossAreaChecked(initialValues.gross_floor_area ? true : false);
  }, [initialValues]);
  

  useEffect(() => {
    setModalIsOpen(true);
  }, []);

  return initialValues.name !== undefined ? (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object({
        name: Yup.string().required(),
        location: Yup.string().required(),
        summary: Yup.string().required(),
        value: profileTypeId === 3?Yup.string():Yup.string().required(),
        year_completed: Yup.string().required(),
        head_contractor: Yup.string(),
        client_name: Yup.string().required(),
        sector_id: Yup.string().required(),
        trade_value: profileTypeId === 3?Yup.string().required():Yup.string(),
        // images: Yup.array().min(1, 'You must add at least one image'),
        // testimonials: Yup.array().min(1, 'You must add at least one file')
      })}
      onSubmit={handleSubmit}
    >
      {(props) => {
        const {
          setFieldValue,
          isSubmitting,
          values,
          submitForm,
          setStatus,
          status,
          setErrors,
          errors,
        } = props;
        const onSubmitValidationError = () => {
          setStatus("required");
          setErrorStatus(true);
        };
        const onMaxCharacter = () => {
          setStatus("maxCharacter");
          setErrorStatus(true);
        }
        return (
          <Form
            onChange={() => {
              setErrorStatus(false);
              setStatus(null);
            }}
          >
            {editForm &&
              !autoUpdateForm &&
              !errorStatus &&
              !(values.status === "published" && isSubmitting) &&
              (!_.isEqual(savedField, values)) && (
                <AutoSave debounceMs={3000} />
              )}
            <TabHeader heading={editForm ? "Edit Project" : "Create Project"} />
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeInfoModal}
              width="800"
            >
              <ModalHeader>
                <Heading fontSize="32px">USER TIP</Heading>
              </ModalHeader>
              <ModalBodyStyled>
                <div>
                  <h2>
                    To save your project, make sure you complete all required
                    fields marked as <span className="required">*</span>
                  </h2>
                </div>
              </ModalBodyStyled>
            </Modal>
            <TabContent noPadding className="!pb-12">
              <div className="row">
                <RowInput >
                  <TextInput
                    label="Project Name"
                    required="true"
                    name="name"
                    type="text"
                    className="col xl-6"
                  />

                  <Location
                    label="Project Location"
                    required="true"
                    name="location"
                    setFieldValue={setFieldValue}
                    initialValue={initialValues.location}
                    className="col xl-6 !mb-6"
                    rfq
                    rfqIcon='search-lg'
                    projectForm={false}
                  />
                </RowInput>
                
                <FormatedInput  
                  label="Project Summary"
                  required="true"
                  name="summary"
                  type="text"
                  rfq
                  characterLimit={1500}
                  className="col xl-12"
                  setErrors={setErrors}
                  onMaxCharacter={onMaxCharacter}
                  guideLine={profileTypeId === 3?"Tell us about this project and your company's role":"Tell us about the project, your company's role, scope and/or services provided."}
                   />

                {profileTypeId === 3 && <Trades className="col xl-6" name="trade_ids"  guideLine="Identify the trade package/s your company delivered" />}
                <RowInput>
                  {profileTypeId !== 3 && 
                    <Currency
                    label={profileTypeId === 3?"Trade Package Value(Total)":"Project Construction Value (AUD) "}
                    required="true"
                    name="value"
                    type="number"
                    lang="en_EN"
                    setFieldValue={setFieldValue}
                    values={values}
                    guideLine={profileTypeId !==  3 && "If construction value is confidential, enter '0'"}
                    className="col xl-6"
                  />}

                  {profileTypeId === 3 && 
                  <div className="col md-6">
                    <TradeValue
                      name="trade_value"
                      required="true"
                      values={values}
                      setFieldValue={setFieldValue}
                      defaultValue={initialValues.trade_value}
                      />
                  </div>                
                  } 

                  <div className="col md-6">
                    <YearCompleted
                      name="year_completed"
                      required="true"
                      values={values}
                      setFieldValue={setFieldValue}
                      defaultValue={initialValues.year_completed}
                      className="col xl-6"
                    />
                  </div>
                </RowInput>

                <RowInput oneRow={profileTypeId === 3}>
                  {profileTypeId !== 3 && 
                  <ProcurementMethod
                    name="procurement_method_ids"
                    required="true"
                    setFieldValue={setFieldValue}
                    values={values}
                    className="col xl-6"
                    guideLine="https://s3.ap-southeast-2.amazonaws.com/assets.procuracon.juicebox.com.au/public/Procurement_Model___Guidance_Notes.pdf"
                  />}

                  <TextInput
                    label="Client Name"
                    required="true"
                    name="client_name"
                    type="text"
                    className="col xl-6"
                  />
                </RowInput>

                {/* Show to only Subcontractors */}
                {/* {[3].includes(profileTypeId) && (
                  <TextInput
                    label="Head Contractor"
                    required="true"
                    name="head_contractor"
                    type="text"
                    className="col xl-6"
                  />
                )} */}

                <RowInput oneRow={profileTypeId === 3} >
                  <Sectors
                    label="Primary Sector"
                    required="true"
                    name="sector_id"
                    defaultValue={initialValues.sector_id}
                    setFieldValue={setFieldValue}
                    values={values}
                    singleValue
                    className="col xl-6"
                  />

                  {profileTypeId !== 3 &&
                  <Select
                    label="Type of Funding"
                    name="funding_type"
                    className="col xl-6"
                    defaultValue={initialValues.funding_type}
                    setFieldValue={setFieldValue}
                    options={fundingTypeOptions}
                    value={fundingTypeOptions.filter((option) => {
                      return values.funding_type === option.value;
                    })}
                  />
                  }
                </RowInput>

                {profileTypeId !== 3 &&
                <div className="mb-3">
                <div className="col xl-12">
                  <LabelForm htmlFor="site_area">Project Area</LabelForm>
                </div>
                
                <RowInput>
                <div className="col xl-6">
                  <AreaCheckboxWrapper>
                    <CheckboxStyled
                      className="container-checkbox"
                      fontSize="15px"
                      checkmarkTop="2px"
                    >
                      Site Area (Ha)
                      <input
                        type="checkbox"
                        checked={siteAreaChecked}
                        name="sitearea"
                        onChange={() => {
                          if (!siteAreaChecked === false) {
                            values.site_area = "";
                          }
                          setSiteAreaChecked(!siteAreaChecked);
                        }}
                      />
                      <span className="checkmark"></span>
                    </CheckboxStyled>
                    {siteAreaChecked ? (
                      <TextInput
                        label=""
                        flexGrow="1"
                        placeholder="Enter Site Area"
                        name="site_area"
                        type="text"
                        marginBottom="0px"
                        noMargin
                      />
                    ) : (
                      <></>
                    )}
                  </AreaCheckboxWrapper>
                </div>

                <div className="col xl-6">
                  <AreaCheckboxWrapper>
                    <CheckboxStyled
                      className="container-checkbox"
                      fontSize="15px"
                      checkmarkTop="2px"
                    >
                      Gross Floor Area (m2)
                      <input
                        type="checkbox"
                        className="grossfloor"
                        name="grossfloor"
                        checked={grossAreaChecked}
                        onChange={() => {
                          if (!grossAreaChecked === false) {
                            values.gross_floor_area = "";
                          }

                          setGrossAreaChecked(!grossAreaChecked);
                        }}
                      />
                      <span className="checkmark"></span>
                    </CheckboxStyled>
                    {grossAreaChecked ? (
                      <TextInput
                        label=""
                        flexGrow="1"
                        placeholder="Enter Gross Floor Area"
                        name="gross_floor_area"
                        type="text"
                        marginBottom="0px"
                        noMargin
                      />
                      
                    ) : (
                      <></>
                    )}
                  </AreaCheckboxWrapper>
                </div>
                </RowInput>
                </div>
                }


                <FileUpload
                  label="Images"
                  multiple={true}
                  name="images"
                  className="col xl-12"
                  types={['jpg', 'jpeg', 'png', 'svg']}
                  maxFileSize={10}
                />

                {profileTypeId !== 3 && 
                <>
                <FormatedInput
                  label="Innovation Summary"
                  name="innovation_value_summary"
                  type="text"
                  className="col xl-12 mt-6 innovation_value_summary"
                  characterLimit={1500}
                  setErrors={setErrors}
                  onMaxCharacter={onMaxCharacter}
                  guideLine="Tell us about any innovation / point of difference / value added to the project or how you overcame any challenges during the project."
                />

                <TextArea
                  label="Awards"
                  name="awards"
                  className="col xl-12 awards"
                  characterLimit={350}
                  rfq
                />

                <FileUpload
                  label="Client Testimonial"
                  name="testimonials"
                  values={values}
                  setFieldValue={setFieldValue}
                  className="col xl-12"
                  types={['jpg', 'jpeg', 'png', 'svg']}
                  maxFileSize={10}
                />
                </>}

                <div className="col xl-12">
                  {status && !_.isEmpty(errors) && (
                    <FlashError
                      heading={errorMessages[status]?.heading || status}
                      text={errorMessages[status]?.description || status}
                      margin="0px 0px 20px 0px"
                    />
                  )}
                </div>
              </div>

              <FormButtonRow className='!mt-12'>
                <div>
                  <Button
                    type="button"
                    isSubmitting={values.status === "published" && isSubmitting}
                    text="Save to Profile"
                    disablePointer={isSubmitting}
                    disabledStyle={isSubmitting}
                    disabled={!_.isEmpty(errors)}
                    onClick={() => {
                      setFieldValue("status", "published", false);
                      editForm && setAutoUpdateForm(true);
                      submitForm();
                    }}
                  >
                    {values.status === "published" && isSubmitting ? (
                      <Spinner className="spinner"/>
                    ) : (
                      "Save to Profile"
                    )}
                  </Button>

                  <Button
                    type="button"
                    btntype="secondary"
                    onClick={() => {
                      setFieldValue("status", "draft", false);
                      submitForm();
                    }}
                    disablePointer={isSubmitting}
                    disabledStyle={isSubmitting}
                    isSubmitting={values.status === "draft" && isSubmitting}
                    style={{ marginLeft: "1rem" }}
                  >
                    {values.status === "draft" && isSubmitting
                      ? "Saving..."
                      : "Save Draft"}
                  </Button>
                </div>

                {handleDelete && (
                  <Button
                    type="button"
                    btntype="button"
                    danger
                    onClick={handleDelete}
                    disablePointer={isRequesting}
                    disabledStyle={isRequesting}
                  >
                    {!isRequesting ? "Delete Project" : "Deleting..."}
                  </Button>
                )}
              </FormButtonRow>
              <OnSubmitValidationError callback={onSubmitValidationError} />
            </TabContent>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <Skeleton count={5} duration={0.5} />
  );
};
const errorMessages = {
  required: {
    heading: "* Required Field",
    description: "There are incomplete required fields, Please complete them.",
  },
  connection: {
    heading: "Connection not established",
    description: "Unable to process your request",
  },
  maxCharacter: {
    heading: "Input has reached max character",
    description: "Input has reached max character",
  },
};
