import apiV2 from "api-v2";
import { toFormData } from "axios";
import { toast } from "react-toastify";

export const getSubmission = async (rfqId, companyId) => {
  try {
    let response = await apiV2.get(
      `rfq/${rfqId}/submission?companyId=${companyId}`
    );
    if (response.status === 200) {
      let quoteData = response?.data?.data?.submission?.quote;
      let returnableData = response?.data?.data?.submission?.returnableSchedule;

      return {
        submissionStatus: response?.data?.data?.status,
        quoteData: quoteData,
        returnableData: returnableData,
        rfqDetail: response?.data?.data?.rfq,
        rfqCompanyId: response?.data?.data?.rfq?.companyId,
      };
    } else {
      return null;
    }
  } catch {
    return null;
  }
};

export const acceptDecliceSubmission = async (rfqId, isAccept, reason) => {
  const data = toFormData({
    isAccept: isAccept,
    reason: reason,
  });
  try {
    let response = await apiV2.post(`/rfq/${rfqId}/accept-or-decline`, data);
    if (response.status === 200) {
      toast.success(response.data.message);
      return response.data;
    } else {
      toast.error(response.data.message);
      return false;
    }
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const getListCompanyData = async (type, params) => {
  try {
    let response = await apiV2.get(`${type}`, { params });
    if (response.status === 200) {
      return response.data.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export const saveSubmissionRFQ = async (rfqId, value) => {
  const formData = toFormData(value);
  try {
    let response = await apiV2.post(`rfq/${rfqId}/store-submission`, formData);
    if (response.status === 200) {
      toast.success(response.data.message);
      return response.data.data;
    } else {
      toast.error("Failed to save submission");
      return false;
    }
  } catch (e) {
    console.log(e);
    toast.error("Failed to save submission");
  }
};

export const submitSubmissionRFQ = async (rfqId, companyId, isRead) => {
  const formData = toFormData({ companyId, isRead });
  try {
    let response = await apiV2.post(`rfq/${rfqId}/submit-submission`, formData);
    if (response.status === 200) {
      toast.success(response.data.message);
      return response.data.data;
    } else {
      toast.error(response.data.message);
      return false;
    }
  } catch (e) {
    console.log(e);
    toast.error(e?.response?.data?.message || "Failed to submit submission");
  }
};

export const downloadAllDocumentation = async (rfqId) => {
  try {
    let response = await apiV2.get(`rfq/${rfqId}/download-documents`, {
      responseType: "blob",
    });
    if (response.status === 200) {
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = "download.zip";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success("Documents downloaded successfully");
      return true;
    } else {
      toast.error("Failed to download documents");
      return false;
    }
  } catch (e) {
    toast.error("Failed to download documents");
    return false;
  }
};
