import React from "react";
import PropTypes from "prop-types";

import { ButtonStyled, ButtonWithIconStyled } from "./styled";
import { cn } from "utils/helpers";

const secondaryClass = {
  default: `h-[40px]`,
  white: `h-[40px] !bg-white !text-black !font-semibold px-6 py-4 !border !border-[#D0D5DD] flex gap-2 items-center rounded-[4px] self-center`
}

export const Button = ({
  children,
  btntype,
  alt,
  margin,
  padding,
  className,
  disabled,
  active,
  type,
  secondary,
  ...rest
}) => {
  return (
    <ButtonStyled
      margin={margin}
      btntype={btntype}
      padding={padding}
      type={type}
      disabled={disabled}
      className={cn('disabled:pointer-events-none disabled:bg-gray-100 disabled:text-gray-400 disabled:border-none', className, secondary === true ? secondaryClass['default'] : secondaryClass[secondary])}
      active={active}
      alt={alt ? 1 : undefined}
      {...rest}
    >
      {children}
    </ButtonStyled>
  );
};

Button.defaultProps = {
  btntype: "primary",
  alt: false,
  size: "md",
};

Button.propTypes = {
  btntype: PropTypes.string,
  alt: PropTypes.bool,
  size: PropTypes.string,
  disabledStyle: PropTypes.bool,
};

export const ButtonWithIcon = ({
  text,
  icon,
  style,
  className,
  btntype,
  ...props
}) => {
  return (
    <ButtonWithIconStyled
      style={{ ...style }}
      className={className}
      btntype={btntype}
      {...props}
    >
      {icon}
      {text}
    </ButtonWithIconStyled>
  );
};