

import apiV2 from 'api-v2';

const createQuestionServices = async (payload) => {
    return await apiV2.post(`addendum/store-question`, payload)
}
const getAddendumServices = async (idrfq) => {
    return await apiV2.get(`addendum/${idrfq}`)
}
const deleteAddendumQuestionServices = async (idrfq) => {
    return await apiV2.delete(`addendum/${idrfq}/delete-question`)
}

export { createQuestionServices, getAddendumServices, deleteAddendumQuestionServices }