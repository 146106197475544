import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
  } from "@chakra-ui/react";
  import { Icon } from "assets/icons/Icon";
  import React, { useState } from "react";
  import tw, { styled, css } from "twin.macro";

  import { Tags } from "components/Tags";
  import { Form, Formik } from "formik";
  import { Button } from "../Button";
import AccordionFilter from "./AccordionFilter";
  
  const FormikStyled = styled(Formik)(() => [tw`overflow-y-auto`]);
  
  const DrawerHeaderStyled = styled(DrawerHeader)(() => [
    tw`text-black py-6 border-b`,
    css`
      .close-text {
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
      }
  
      .header-container {
        display: flex;
        justify-content: space-between;
        justify-items: center;
        align-items: center;
      }
  
      .clear-container {
        display: flex;
        justify-items: center;
        place-items: center;
      }
    `,
  ]);
  
  const AppliedFilters = styled.div(() => [
    tw`grid`,
    css`
      .title-filter {
        font-size: 14px;
        font-weight: 600;
        color: black;
        margin-bottom: 16px;
      }
      .chip-container {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
      }
    `,
  ]);
  
  const DrawerBodyStyled = styled(DrawerBody)(() => [tw`p-0 max-h-[calc(100vh - 300px)]`]);
  
  const FilterDrawer = ({ onClose, isOpen, filters, setFilters, filterOptions,...props }) => {
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [prevValues, setPrevValues] = useState(filters);
  
    const initialValuesChecker = () => {
        let obj = {};
        filterOptions.forEach((filter) => {
            obj[filter?.name] = prevValues?.[filter?.name] || [];
        });
        return obj;
    }

    const emptyValues = () => {
        let obj = {};
        filterOptions.forEach((filter) => {
            obj[filter?.name] = [];
        });
        return obj;
    }
  
    const handleClearAll = (setValues) => {
        setValues(emptyValues());
        setPrevValues(emptyValues());
    }

    const handleSubmit = async (filters, actions) => {
      if (isSubmiting) {
        setFilters(filters)
        setIsSubmiting(false);
        onClose(true)
      } else {
        actions.resetForm()
      }
    };
  
    return (
      <div>
        <Drawer
          placement={"right"}
          isOpen={isOpen}
          onClose={onClose}
          size="md"
          {...props}
        >
          <DrawerOverlay onClick={onClose} />
          {filterOptions  ? <FormikStyled
            initialValues={initialValuesChecker()}
            onSubmit={handleSubmit}
            
          >
            {({ values, setValues, resetForm,  }) => {
              setPrevValues(values);
              return (
                <Form >
                  <DrawerContent >
                    <DrawerHeaderStyled borderBottomWidth="1px">
                      <div className="header-container">
                        <p className="font-roboto text-base font-bold">Filter</p>
                        <div className="clear-container">
                          <Button btntype="plain" type={"button"}  className="close-text font-roboto font-medium" style={{fontSize:'14px'}} onClick={() => handleClearAll(setValues)}>Clear all</Button>
                          <Button btntype="plain-icon" type={"button"} onClick={onClose}>
                            <Icon icon={"close"}  />
                          </Button>
                        </div>
                      </div>
                    </DrawerHeaderStyled>
                    <DrawerHeaderStyled>
                      <AppliedFilters>
                        <p className="title-filter">
                          <div className="font-roboto font-semibold text-text-primary-900" style={{ fontSize: '14px', lineHeight: '20px' }}>
                            Applied filters
                          </div>
                        </p>
                        <div className="chip-container">
                          {/* {values?.location &&
                            locationFilter
                              .filter((data) =>
                                values?.location.includes(data.value)
                              )
                              .map((filteredData) => {
                                const handleDeleteLocation = (deletedValue) => {
                                  setValues(prevValues => ({
                                    ...prevValues,
                                    location: prevValues.location.filter(newVal => newVal !== deletedValue)
                                  }))
                                }
                                return (
                                  <Tags
                                    key={filteredData.value}
                                    deletable
                                    handleDelete={() => handleDeleteLocation(filteredData.value)
                                    }
                                  >
                                    <div style={{ fontSize: '14px' }}>
                                      {filteredData.label}
                                    </div>
                                  </Tags>
                                );
                              })}
                          {values?.status &&
                            projectStatus
                              .filter((data) =>
                                values?.status.includes(data.value)
                              )
                              .map((filteredData) => {
                                const handleDeleteStatuses = (deletedValue) => {
                                  setValues(prevValues => ({
                                    ...prevValues,
                                    status: prevValues.status.filter(newVal => newVal !== deletedValue)
                                  }))
                                }
                                return (
                                  <Tags
                                    key={filteredData.value}
                                    deletable
                                    handleDelete={() => handleDeleteStatuses(filteredData.value)
                                    }
                                  >
                                    {filteredData.label}
                                  </Tags>
                                );
                              })}
                          {values?.sector &&
                            sectorFilter
                              .filter((data) =>
                                values?.sector.includes(data.value?.toString())
                              )
                              .map((filteredData) => {
                                const handleDeleteSector = (deletedValue) => {
                                  setValues(prevValues => ({
                                    ...prevValues,
                                    sector: prevValues.sector.filter(newVal => newVal !== deletedValue)
                                  }))
                                }
                                return (
                                  <Tags
                                    key={filteredData.value}
                                    deletable
                                    handleDelete={() => handleDeleteSector(filteredData.value?.toString())
                                    }
                                  >
                                    {filteredData.label}
                                  </Tags>
                                );
                              })} */}
                            {
                                filterOptions?.map((filter) => {
                                    return (
                                        filter?.item?.filter((data) => values?.[filter?.name]?.includes(data.value.toString()))
                                        .map((filteredData) => {
                                            const handleDelete = (deletedValue) => {
                                                setValues(prevValues => ({
                                                    ...prevValues,
                                                    [filter?.name]: prevValues[filter?.name].filter(newVal => newVal !== deletedValue)
                                                }))
                                            }
                                            return (
                                                <Tags
                                                    key={filteredData.value}
                                                    deletable
                                                    handleDelete={() => handleDelete(filteredData.value)}
                                                >
                                                    <div style={{ fontSize: '14px' }}>
                                                        {filteredData.label}
                                                    </div>
                                                </Tags>
                                            );
                                        })
                                    )
                                })
                            }
                        </div>
                      </AppliedFilters>
                    </DrawerHeaderStyled>
                    <DrawerBodyStyled> 
                      <AccordionFilter setIsSubmiting={setIsSubmiting} filterOptions={filterOptions} />
                    </DrawerBodyStyled>
                  </DrawerContent>
                </Form>
              )
            }}
          </FormikStyled> : null}
        </Drawer>
      </div>
    );
  };
  
  export default FilterDrawer;
  