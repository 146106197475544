import { Flex } from "@chakra-ui/react";
import { Icon } from "assets/icons/Icon";
import { Button } from "components/RFQ/Button";
import {
  DropdownItem,
  DropdownMenu,
  DropdownMenuDrawer,
  DropdownMenuTrigger,
  MenuDivider,
} from "components/RFQ/MenuDropdown";
import React from "react";

const InviteSupplierButton = ({setTypeModal, onOpen}) => {
  const listMenuEdit = [
    {
      name: "Add Supplier",
      value:'add_supplier',
      icon: "plus",
      divider: true,
    },
    {
      name: "Invite Guest",
      value:'invite_guest',
      icon: "request-for-quote",
      divider: false,
    },
  ];

  const handleMenuClick = (value) => {
    setTypeModal(value);
    onOpen();
  };


  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Button btntype={"primary"} className=' h-12 !max-h-12'>
            <Flex gap={2} align={"center"}>
              <div className="text-base">Add Supplier</div>
              <Icon icon="chevron-down" />
            </Flex>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuDrawer className={'!-right-40'}>
          {listMenuEdit.map((menu, index) => {
            return (
              <div key={index}>
                <DropdownItem
                  className="w-full"
                  onClick={() => handleMenuClick(menu.value)}
                >
                  <Icon icon={menu.icon} color="#667085" width={20} />
                  <p
                    className="font-roboto font-medium text-text-secondary-700 text-[14px] hover:text-[14px]"
                    style={{ lineHeight: "20px" }}
                  >
                    {menu.name}
                  </p>
                </DropdownItem>
                {menu.divider && <MenuDivider />}
              </div>
            );
          })}
        </DropdownMenuDrawer>
      </DropdownMenu>
    </div>
  );
};

export default InviteSupplierButton;
