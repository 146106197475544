import { Flex } from "@chakra-ui/react";
import { DateInput } from "components/DateInput";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import { Location } from "components/FormInputs/Location";
import { Heading } from "components/Heading";
import { FieldForm } from "components/RFQ/Input/Input";
import TimePickerField from "components/TimePicker/TimePicker";
import { useField } from "formik";
import React from "react";
import tw, { styled } from "twin.macro";


const ProjectDetailContainer = styled.div(() => [tw`flex flex-col w-full`]);

const ProjectDetail = ({ titlePage, subTitlePage, rfqTypes }) => {
  const [, locationMeta] = useField('address.value')

  return (
    <ProjectDetailContainer>
      <div id="subheader-page" className="py-8">
        <div
          id="subheader-page-title"
          className="text-xl font-semibold text-text-primary-900"
        >
          <Heading fontSize={'36px'} color="#003CE9"> {titlePage}</Heading>
        </div>
        <div
          id="subheader-page-subtitle"
          className="font-roboto text-sm text-text-tertiary-600 mt-1"
        >
          {subTitlePage}
        </div>
      </div>
      <div id="project-detail-content" className="h-full relative min-h-[60vh]">
        <Flex gap={8} className="items-baseline">
          <FieldForm
            label="Request Title"
            placeholder="Eg. Request for Lead Design and Engineering Services"
            name="name"
            options={rfqTypes}
            required={true}
            className='mb-6'
            maxLength={50}
          />
          <FieldForm
            label="RFx ID number*"
            placeholder="RFQ01234"
            name="rfq_id_number"
            required={true}
            className='mb-6'
            maxLength={50}
          />
        </Flex>
        <Flex gap={8} className="items-baseline">
          <FieldForm
            label="Type of Request"
            placeholder="Select type"
            name="type"
            type="select"
            checkIcon={true}
            options={rfqTypes}
            required={true}
            className='mb-6'
          />
          <Location
            label="Project Location"
            name="address"
            errorBottom={"-3px"}
            rfq
            rfqIcon='search-sm'
            className="relative"
            isErrorValue={locationMeta.error}
            required={true}
          />
        </Flex>
        <Flex gap={8} className="items-baseline">
          <FieldForm
            label="Lead contact name"
            placeholder="Provide contact name"
            name="lead_contact_name"
            options={rfqTypes}
            required={true}
            className='mb-6'
            maxLength={50}
          />
          <FieldForm
            label="Phone number"
            placeholder="Provide phone number"
            name="phone_number"
            required={true}
            className='mb-6'
            maxLength={50}
          />
        </Flex>
        <Flex gap={8} className="items-baseline">
          <FieldForm
            label="Email"
            placeholder="Provide Email"
            name="email"
            options={rfqTypes}
            required={true}
            className='mb-6 w-[calc(50%-16px)]'
            maxLength={50}
          />
        </Flex>
        <Flex>
          <FormatedInput
            label="Project / Asset Description"
            name="description"
            characterLimit={1500}
            rfq
            className="w-full"
            placeholder="Enter a description..."
            subTitle={
              "Briefly describe the project or asset. Consider the scale, size, quantity or staging and any other important features."
            }
          />
        </Flex>
        <Flex gap={8}>
          <FieldForm
            label="Client"
            placeholder="Enter full name"
            name="client"
            required={true}
            className='mb-6'
          />
        </Flex>
        <Flex className="items-baseline gap-8">
          <DateInput
            icon={true}
            label="Submission Deadline"
            name="deadline_date"
            className="!font-regular !border-fg-disabled_subtle flex-3 w-[80%]"
            required={true}
          />
          <div className="w-[20%]">
            <TimePickerField className="mt-9 flex-1 w-full" name="deadline_time" nameTimezone="submission_deadline_timezone" label="" />
          </div>
        </Flex>
        <Flex>
          <FormatedInput
            label="Requested Services"
            name="escope_of_service"
            characterLimit={1500}
            rfq
            className="w-full"
            placeholder="Enter a description..."
            subTitle={
              "Provide a brief description of the scope of services needed for this project."
            }
            containerClassName="!mt-4"
          />
        </Flex>
      </div>
    </ProjectDetailContainer>
  );
};

export default ProjectDetail;
