
import { Icon } from 'assets/icons/Icon'
import { FormatedInput } from "components/FormInputs/FormatedInput";
import React, { useRef, useState } from 'react'
import { Formik, Form } from "formik";
import { Button } from "components/RFQ/Button";
import moment from "moment";
import { createQuestionServices, deleteAddendumQuestionServices, getAddendumServices } from 'services/supplier-flow/adendumServices';
import { getAddendumServices as getAddendumServicesGuest, createAddendumServices, deleteAddendumServices  } from 'services/guest-flow/addendumServices';
import { getDateRelation } from 'helpers/dateHelper';
import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spinner,
} from "@chakra-ui/react";
import tw, { css,styled } from "twin.macro";
import { cn } from "utils/helpers";
import { Image } from "assets/img";
import { Checkbox } from 'components/RFQ/Checkbox';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { SkeletonBase } from "components/Skeleton";
import { SkeletonCircle } from "@chakra-ui/react";
import { Avatar } from 'components/RFQ/Avatar';
import { DropdownItem, DropdownMenu, DropdownMenuDrawer, DropdownMenuTrigger } from 'components/RFQ/MenuDropdown';
const AddendumsComp = ({ rfqId, rfqCompanyId, addendumData, isGuest, guestEmail }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const currentCompany = useSelector((state) => state?.auth?.current_company);
    const [selectedAddendum, setSelectedAddendum] = useState()
    const formikRef = useRef(null);
    const ContainerLogo = styled.div(() => [
        tw`relative w-[480px] h-[48px] text-center items-center flex`,
    ]);
    const initialValues = {
        addendum: ""
    }
    const [openDialogDelete, setOpenDialogDelete] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [addendum, setAdendum] = useState("")
    const [isChecked, setIsChecked] = useState(false)
    const listToAsk = ["Ask a question or clarify information", "Request additional documentation", "Proin et risus maecenas hendrerit. "]
    const [listAddendum, setListAddendum] = useState(addendumData)
    
    const getAddendum = async () => {
        let res = isGuest ? await getAddendumServicesGuest(rfqId,guestEmail) : await getAddendumServices(rfqId)
        if (res.status === 200) {
            setListAddendum(res.data.data)
            setIsLoading(false)
        }
    }

    const onCLoseDialogDelete = () => {
        setOpenDialogDelete(false)
    }

    const ChatBox = styled.p(() => [
        tw`font-roboto text-base py-2.5 px-3.5 rounded-md`,
        css`
            ol{
                list-style: decimal;
                margin-left: 24px;
            }
            ul{
                list-style: disc;
                margin-left: 24px;
            }
            em{
                font-style: italic;
                color: inherit;
            }
            strong{
                color: inherit;
            }
            p{
                word-wrap: break-word;
            }
        `
    ]);

    const SplitComp = ({ label }) => {
        return <div className='flex my-4 justify-center items-center font-roboto text-text-primary-tertiary-600 text-[14px] relative overflow-hidden' style={{ height: '20px' }}>
            <div className='border border-[#EAECF0] border-solid ' style={{ width: '100%' }}></div>
            <div className='absolute bg-white p-4'>
                {getDateRelation(moment().format('YYYY-MM-DD'), label)}
            </div>
        </div>
    }

    const StatusComp = ({ isPublish, createdAt }) => {
        return <div className='flex justify-between items-center mt-1.5 mb-3'>
            <div className='font-roboto text-text-tertiary-600 text-xs'>{moment(createdAt).format('dddd h:mmA')}</div>
            <div>
                <div className='flex justify-end items-center'>
                    <div className='text-xs'>{isPublish === 1 ? 'Public' : 'Private'}</div>
                    <div className='ml-2'> <Icon icon={isPublish ===1 ? 'eye-on' : 'eye-off'} color="#475467" /></div>
                </div>
            </div>
        </div>
    }

    const UserName = ({ username, companyname, companyId }) => {
        return <>
            <div className='font-roboto text-text-secondary-700 font-medium mb-1 mt-1' style={{ fontSize: '14px' }}>
                {companyId === currentCompany?.id ? <>
                    <div className='flex items-center mb-2'>
                        <div>
                            {username} {`${companyname ? `| ${companyname }` : ''}`}
                        </div>
                        {companyId === currentCompany?.id && <div className='ml-3 w-12 border border-[#EAECF0] border-solid font-roboto rounded-full text-text-primary-900 bg-gray-utility-100 text-center max-h-[22px] leading-5' style={{ fontSize: '12px' }}>
                            YOU
                        </div>}
                    </div>
                </> : <div className='flex items-center mb-2'>
                    {username} {`${companyname ? `| ${companyname }` : ''}`}
                </div>}
            </div>
        </>
    }

    const AnswerComp = ({ data, nested }) => {
        return <div className={`flex justify-start items-start pb-1 gap-3 ${nested ? 'ml-16' : ''}`}>
            <Avatar size='md' logo={data?.company?.logoPath} />
            <div style={{ width: '100%' }} className='ml-5'>
                <UserName
                    username={data?.user?.name}
                    companyname={data?.company?.name}
                    companyId={data?.company?.company_id}
                />
                <ChatBox 
                    className={`rounded-xl ${data?.company?.company_id === rfqCompanyId ? 'text-white bg-royal-blue2' : 'text-text-primary-900 bg-gray-utility-100'}  p-5`}
                    dangerouslySetInnerHTML={{ __html: data?.addendum.replace('<span','<p') }}
                />
                <StatusComp isPublish={data?.published} createdAt={data?.createdAt}></StatusComp>
            </div>
        </div>
    }


    const AskSkeletonComp = () => {
        return <div className='mt-2 mb-2'>
            <div className='flex justify-start items-start pl-10 pr-10 pb-1 mt-2'>
                <SkeletonCircle width={20} height={20} startColor="lightgray" />
                <div style={{ width: '100%' }} className='ml-5'>
                    <SkeletonBase width="300px" height="26px" />
                    <div className={`mt-2 mb-2`}>
                        <SkeletonBase width="100%" height="46px" />
                    </div>
                    <div className='mt-5 mb-5'>
                        <SkeletonBase width="200px" height="26px" />
                    </div>
                </div>
            </div>
        </div>
    }

    const AskComp = ({ data }) => {
        return <div className='relative'>
            <div className='flex justify-start items-start gap-3 pb-1 mt-2'>
                <Avatar size='md' logo={data?.company?.logoPath} defaultImage={data?.company ? 'company' : 'user'} />
                <div style={{ width: 'calc(100% - 52px)' }}>
                    <UserName
                        username={data?.user?.name}
                        companyname={data?.company?.name}
                        companyId={data?.company?.company_id}
                    />
                    <ChatBox 
                        className={`border border-[#EAECF0] border-solid font-roboto text-[16px] rounded-md ${data?.company?.company_id === rfqCompanyId ? 'text-white bg-royal-blue2' : 'text-text-primary-900 bg-gray-utility-100'}  p-5`}
                        dangerouslySetInnerHTML={{ __html: data?.addendum.replace('<span','<p') }}
                    />
                    <StatusComp isPublish={data?.published} createdAt={data?.createdAt}></StatusComp>
                </div>
            </div>
            {
                data.answer.length > 0 && [data?.answer[0]].map((answ) => {
                    return <AnswerComp data={answ} nested={true}></AnswerComp>
                })
            }
            {
                data?.company?.company_id === currentCompany?.id && <div className='absolute -right-2 top-0'>
                    <DropdownMenu>
                        <DropdownMenuTrigger icon noBg={true}></DropdownMenuTrigger>
                        <DropdownMenuDrawer>
                        <DropdownItem className="w-full" onClick={() => {setOpenDialogDelete(true);setSelectedAddendum(data)}}>
                            <Icon icon='delete' width={12} height={12} />
                            <p className="font-roboto font-medium text-text-secondary-700" style={{fontSize:'14px'}}>{'Delete Question'}</p>
                        </DropdownItem>
                        </DropdownMenuDrawer>
                    </DropdownMenu>
                </div>
            }
        </div >
    }

    const onClose = () => {
        setOpenDialog(false)
    }

    const handleOutsideSubmit = async () => {
        try {
            if (isChecked) {
                let res = isGuest 
                ? await createAddendumServices({
                    addendum: addendum,
                    rfqId: rfqId,
                    email: guestEmail,
                })
                : await createQuestionServices({
                    addendum: addendum,
                    rfq_id: rfqId
                })
                if (res.status === 200) {
                    toast.success(res?.data?.message)
                    if (formikRef.current) {
                        formikRef.current.resetForm();
                    }
                    setIsChecked(false)
                    setOpenDialog(false)
                    getAddendum()
                }
            }
        } catch (err) {
            toast.error(err?.message)
        }
    }

    const handleSubmit = (values, { resetForm }) => {
        setAdendum(values.addendum)
        setOpenDialog(true)
    };

    const deleteQuestions = async () => {
        setIsDeleting(true)
        try {
            let res = isGuest 
            ? await deleteAddendumServices(rfqId,selectedAddendum.id,guestEmail)
            : await deleteAddendumQuestionServices(selectedAddendum.id)
            if (res.status === 200) {
                toast.success(res?.data?.message)
                getAddendum()
                setSelectedAddendum()
                setOpenDialogDelete(false)
                setIsDeleting(false)
            }else{
                setIsDeleting(false)
            }
        } catch (err) {
            toast.error(err.message)
            setIsDeleting(false)
        }
    }

    return <>
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            style={{ width: '100%' }}
        >
            {({ values, isValid }) => (
                <Form>
                    <div className='bg-white rounded-2xl  border border-[#EAECF0] border-solid shadow-md overflow-hidden pb-6'>
                        <div className='bg-gray-utility-100 p-10'>
                            <h5 className='font-roboto font-medium text-text-primary-900 mb-5' style={{ fontSize: '14px' }}>Add a question</h5>
                            <div className='flex justify-start items-start'>
                                <div style={{ width: '40px', height: '40px', backgroundImage: `url(${currentCompany?.logo_path})` }} className='rounded-full '></div>
                                <div style={{ width: '100%' }} className='ml-5 mb-4'>
                                    <div className='relative'>
                                        <FormatedInput
                                            name="addendum"
                                            type="text"
                                            value=""
                                            placeholder="Add a question"
                                            characterLimit={1500000}
                                            className="col xl-12"
                                            background="#fff"
                                            guideLine={"Tell us about this project and your company's role"}
                                            id="textnya"
                                        />
                                        <div className='absolute bottom-0 right-0 bg-white p-3 mb-1 mr-1 ml-1'>
                                            <div className='flex justify-end items-center'>
                                                <Icon icon="eye-slash" />
                                                <div className='ml-2 font-roboto text-[12px] text-text-tertiary-600 mr-10'>
                                                    This question becomes public only if the supplier decides to publish it.
                                                </div>
                                                <Button btntype='primary' type="submit" disabled={values?.addendum?.trim() === "" || !isValid}>
                                                    <p className='font-roboto font-semibold'>
                                                        Send
                                                    </p>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        {listToAsk.map((ask, index) => {
                                            return <div key={index} className='flex justify-start items-center' style={{ height: '30px' }}>
                                                <div><Icon icon="check-light" /></div>
                                                <div className='ml-5 font-roboto font-[14px] text-text-primary-700'>{ask}</div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isLoading ? <>
                            <div className='mt-5 mb-4'>
                                <AskSkeletonComp></AskSkeletonComp>
                                <AskSkeletonComp></AskSkeletonComp>
                            </div>
                        </> : <div className='px-8'>
                            {listAddendum.map((singleData) => {
                                return <>
                                    <SplitComp label={singleData?.date}></SplitComp>
                                    {singleData?.question.map((c, index) => {
                                        return <AskComp data={c} key={index}></AskComp>
                                    })}
                                </>
                            })}
                        </div>}
                    </div>
                    <div className="translate-y-1/2 overflow-hidden">
                        <Modal
                            isOpen={openDialog}
                            onClose={onClose}
                            size={"xl"}
                            closeOnEsc
                            isCentered
                        >
                            <ModalOverlay />
                            <ModalContent containerProps={{sx:{ height: '100vh' }}}>
                                <ModalHeader>
                                    <div className="overflow-hidden">
                                        <ContainerLogo>
                                            <Image
                                                img="background-pattern-02"
                                                className="absolute z-0 -top-10 -left-5"
                                                style={{ width: "180px" }}
                                            />
                                            <div
                                                className={cn(
                                                    "rounded-full h-10 w-10 items-center content-center ",
                                                    "bg-soft-blue"
                                                )}
                                                style={{ textAlign: "-webkit-center" }}
                                            >
                                                <Icon icon="blue-circle-check" />
                                            </div>
                                        </ContainerLogo>
                                        <h2 className="text-lg text-text-primary-900 font-semibold z-20  relative mt-4">
                                            Send Question
                                        </h2>
                                        <p className="text-sm font-normal text-text-tertiary-600 mt-1">
                                            You're about to submit your question. Please review your details carefully to ensure all your information is accurate and complete. Once confirmed, we will begin processing your request immediately.
                                        </p>
                                    </div>
                                </ModalHeader>
                                <ModalCloseButton onClose={onClose} />
                                <ModalBody>
                                    <div className='flex justify-start items-center'>
                                        <div className='w-30'>
                                            <Checkbox checked={isChecked} onChange={() => { setIsChecked(!isChecked) }} label={<p>I have read and acknowledged all <a href="/">terms & conditions</a></p>} />
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Flex gap={3} className="!w-full">
                                        <Button
                                            btntype={"base"}
                                            style={{ width: "100%" }}
                                            onClick={onClose}
                                            type="button"
                                        >
                                            Cancel
                                        </Button>
                                        <Button style={{ width: "100%" }} onClick={handleOutsideSubmit} disabled={!isChecked}>Yes, Send</Button>
                                    </Flex>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </div>

                    <div className="translate-y-1/2 overflow-hidden">
                        <Modal
                            isOpen={openDialogDelete}
                            onClose={onCLoseDialogDelete}
                            size={"xl"}
                            closeOnEsc
                            isCentered
                        >
                            <ModalOverlay />
                            <ModalContent containerProps={{sx:{ height: '100vh' }}}>
                                <ModalHeader>
                                    <div className="overflow-hidden">
                                        <ContainerLogo>
                                            <Image
                                                img="background-pattern-02"
                                                className="absolute z-0 -top-10 -left-5"
                                                style={{ width: "180px" }}
                                            />
                                            <div
                                                className={cn(
                                                    "rounded-full h-10 w-10 items-center content-center ",
                                                    "bg-soft-red"
                                                )}
                                                style={{ textAlign: "-webkit-center" }}
                                            >
                                                <img src="/img/trash-icon.svg" alt="delete" />
                                            </div>
                                        </ContainerLogo>
                                        <h2 className="text-lg text-text-primary-900 font-semibold z-20  relative mt-4">
                                            Delete Question
                                        </h2>
                                        <p className="text-sm font-normal text-text-tertiary-600 mt-1">
                                            You're about to delete your question. Please review your details carefully to ensure all your information is accurate and complete. Once confirmed, we will begin processing your delete request immediately.
                                        </p>
                                    </div>
                                </ModalHeader>
                                <ModalCloseButton onClose={onClose} />
                                <ModalBody>

                                </ModalBody>
                                <ModalFooter>
                                    <Flex gap={3} className="!w-full">
                                        <Button
                                            btntype={"base"}
                                            style={{ width: "100%" }}
                                            onClick={onCLoseDialogDelete}
                                            type="button"
                                            disabled={isDeleting}
                                        >
                                            Cancel
                                        </Button>
                                        <Button danger style={{ width: "100%" }} onClick={() => deleteQuestions()} disabled={isDeleting}>
                                            {isDeleting ? <Spinner /> : 'Yes, Delete it'}
                                        </Button>
                                    </Flex>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </div>
                </Form>
            )}
        </Formik>
    </>
}

export default AddendumsComp