import React from "react";
import {
  ProjectGroupStyled,
  Project,
  ImageGrid,
  Image,
} from "./styled";
import noImage from "../../icons/no-image.png";
import { TooltipLabel } from "components/MatchesLabel";
import ReactQuill from "react-quill";

export const ProjectGroup = ({
  projects,
  compareSlideoutClick,
  companyId,
  profileType,
  groupId,
  imageGrid,
  compare,
  projectCount,
  ...props
}) => {
  //For the compare project experience there needs to be 5 slots
  if (compare) {
    for (var i = 1; i <= 5 - projects.length; i++) {
      projects.push(null);
    }
  } else if (!projects.length) {
    return "";
  }

  if (!projects.length) {
    return (
      <p>
        <span>No projects found.</span>
      </p>
    );
  }
  let availableIndexes = [];

  return (
    <ProjectGroupStyled>
      {projects.slice(0, 5).map((item, index) => {
        if (item && item !== null && item.hasOwnProperty("id")) {
          return (
            <Project key={index} id={`project-${index}`}>
              <div style={{ display: "flex" }}>
                <h4 style={{ marginRight: "8px" }}>
                  {item.name.length <= 24
                    ? `${item.name}`
                    : `${item.name.substring(0, 24)}...`}
                </h4>
              </div>

              <p>
                {profileType === 3 && (
                  <p>
                    Trade Package/s:
                    {item.trades?.length > 0 ? (
                      <>
                        {item.trades?.slice(0, 3).map((trade, index) => {
                          return (
                            <>
                              <span>
                                {" "}
                                {trade.name}
                                {index === item.trades?.length - 1
                                  ? ""
                                  : ","}{" "}
                              </span>
                            </>
                          );
                        })}
                        {item.trades?.length >= 4 && (
                          <TooltipLabel label={item.trades?.slice(3)}>
                            <span style={{ textDecoration: "underline" }}>
                              {" "}
                              and {item.trades?.length - 3} more
                            </span>
                          </TooltipLabel>
                        )}
                      </>
                    ) : (
                      <> &mdash;</>
                    )}
                  </p>
                )}
                {profileType === 3 ? (
                  <p>
                    Trade Package Value(Total):{" "}
                    <span>
                      {item.trade_value ? (
                        <>${item.trade_value}</>
                      ) : (
                        <> &mdash;</>
                      )}
                    </span>
                  </p>
                ) : (
                  <span>
                    Construction Value (AUD):{" "}
                    <span>${item.value_humanized}</span>
                  </span>
                )}
              </p>
              <p>
                Year Complete: <span>{item.year_completed}</span>
              </p>
              <p>
                Client:{" "}
                <span>
                  {item?.client_name && <>
                    {item?.client_name.length <= 36
                      ? `${item?.client_name}`
                      : `${item?.client_name.substring(0, 36)}...`}
                  </>}
                </span>
              </p>
              {item.procurement_methods && profileType !== 3 && (
                <p>
                  Procurement Model:{" "}
                  <span>
                    {item.procurement_methods[0] ? (
                      item.procurement_methods[0].name
                    ) : (
                      <>&mdash;</>
                    )}
                  </span>
                </p>
              )}

              {item.summary && (
                <ReactQuill readOnly value={item.summary} theme="bubble"
                  className="section-summary"
                  style={{
                    paddingBottom: 0,
                    overflow: "hidden",
                  }}
                />
              )}

              {imageGrid && item?.images?.length > 0 ? (
                <ImageGrid>
                  {item?.images.slice(0, 3).map((image, index) => (
                    <Image key={index} src={image} />
                  ))}
                </ImageGrid>
              ) : (
                <>
                  <ImageGrid>
                    <Image key={index} src={noImage} />
                  </ImageGrid>
                </>
              )}
            </Project>
          );
        } else {
          availableIndexes.push(index + 1);
          return (
            <></>
          );
        }
      })}
    </ProjectGroupStyled>
  );
};

ProjectGroup.defaultProps = {
  projects: [],
};
