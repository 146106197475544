import styled from "styled-components";

export const PageWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
`;

export const ContentContainer = styled.div` 
  width: 100%;
  margin-right: auto;
  position: relative;
  padding-left: ${(props) => (props.noPadding ? "0px" : "48px")};
  padding-right: ${(props) => (props.noPadding ? "0px" : "48px")};
  padding-top: ${(props) => (props.noPadding ? "0px" : "8px")};
  padding-bottom: ${(props) => (props.noPadding ? "0px" : "8px")};
  background-color: ${(props) => (props.bgColor ? props.bgColor : "white")};
  overflow: auto;
  max-height: 100vh;
`;
