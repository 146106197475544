import { Stack } from "@chakra-ui/react";
import tw, { styled, css } from "twin.macro";
import { FieldForm } from "../Input";
import { useField } from "formik";
import { cn } from "utils/helpers";

const CheckBoxStyled = styled.input(({ meta, required }) => [
  tw`w-3 h-3 accent-royal-blue scale-150 bg-none border border-gray-100 opacity-50 cursor-pointer`,
  meta?.error && required && tw`border border-red-500 appearance-none`,
  css`
    &:checked {
      opacity: 1;
    }
  `,
]);

const CheckboxContainer = styled.div(({attribute}) => [tw`flex gap-2 items-center justify-start`,attribute && tw`items-start`]);

export const Checkbox = ({
  className,
  label,
  required,
  children,
  metaValue,
  ...props
}) => {
  const checked = metaValue && metaValue.length > 0 ? (metaValue?.includes(props.value.toString())) : false;

  return (
    <label className={cn("flex gap-3 items-center cursor-pointer text-text-primary-700 font-medium",props?.border && 'py-4 px-6 border-b border-b-quarternary-400',className)}>
      <CheckBoxStyled type="checkbox" checked={checked} {...props} />
      {label}
      {children}
    </label>
  );
};

export const CheckboxField = ({
  className,
  label,
  required,
  option,
  name,
  attribute,
  ...props
}) => {
  const [,{value}] = useField(name);
  return (
    <div className={attribute ? "w-max" : "w-full"}>
      <Stack direction="column" spacing={props?.border ? 0 : 5}>
      {option?.map((item, index) => (
        <CheckboxContainer key={index} attribute={attribute}>
          <FieldForm hideError={index !== option?.length - 1} className={cn('text-sm',attribute ? 'w-max' : 'w-full',className)} type="checkbox" name={`${name}`} value={item.value} label={item.label} metaValue={value} {...props}/>
          {attribute && <p className="text-sm text-gray-300">{`(${item?.[attribute]})`}</p>}
        </CheckboxContainer>
      ))}
      </Stack>
    </div>
  );
};
