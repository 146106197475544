import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { connect } from 'react-redux';

import { ExpertiseRepeaterStyled } from './styled';
import { Select } from '../Select';
import { MultiSelect } from '../MultiSelect';
import { getExpertiseDisciplines, getDisciplinesByProfileType } from '../../../actions/companyActions';

const ExpertiseDisciplinesConnected = ({
    className,
    name,
    values,
    options,
    getExpertiseDisciplines,
    getDisciplinesByProfileType,
    profileType,
    required,
    ...props
}) => {
    const { setFieldValue } = useFormikContext();

    //receive these from server
    const handleExpertiseChange = (option, field, disciplineField) => {
        setFieldValue(disciplineField, '');
        setFieldValue(field.name, option.value);
    };

    useEffect(() => {
        if (profileType) {
            getDisciplinesByProfileType(profileType);
        }
    }, [getExpertiseDisciplines, getDisciplinesByProfileType, profileType]);

    if (!options) {
        return '';
    }

    return (
        <ExpertiseRepeaterStyled className={className}>
            <div className='flex'>
                <div className='flex-1 mr-2'>
                    <Select
                        label={profileType === 3 ? 'Service' : 'Expertise'}
                        name='expertise'
                        className='col sm-6'
                        options={Object.keys(options)}
                        onChange={(option, field) => handleExpertiseChange(option, field, 'discipline')}
                        required={required}
                        freeText
                    />
                </div>
                <div className='flex-1 ml-2'>
                    <MultiSelect
                        label={profileType === 3 ? 'Category' : 'Discipline'}
                        name='discipline'
                        className='col sm-6'
                        options={options[values.expertise]}
                        required={required}
                        value={values.discipline}
                    />
                </div>
            </div>
        </ExpertiseRepeaterStyled>
    );
};

const mapStateToProps = (state) => {
    return {
        options: state.companyProfile.expertiseDisciplinesByProfile
    };
};

export const ExpertiseDisciplines = connect(mapStateToProps, { getExpertiseDisciplines, getDisciplinesByProfileType })(
    ExpertiseDisciplinesConnected
);
