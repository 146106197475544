import React, { useEffect, useState, useRef } from 'react';
import { cn, getFileNameFromUrl } from 'utils/helpers';

import Dropzone from 'react-dropzone';
import { Tooltip } from '@chakra-ui/react';
import { Icon } from 'assets/icons/Icon';
import { FileCard } from '../FileCard';
import { useField } from 'formik';
import { toast } from 'react-toastify';
import LabelForm from '../LabelForm/LabelForm';

const typeChecker = (types) => {
    const fileTypesObj = {}
    for (const type of types) {
        switch (type) {
            case 'jpg':
                fileTypesObj['image/jpeg'] = ['.jpg', '.jpeg']
                break;
            case 'png':
                fileTypesObj['image/png'] = ['.png']
                break;
            case 'gif':
                fileTypesObj['image/gif'] = ['.gif']
                break;
            case 'svg':
                fileTypesObj['image/svg+xml'] = ['.svg']
                break;
            case 'pdf':
                fileTypesObj['application/pdf'] = ['.pdf']
                break;
            case 'doc':
                fileTypesObj['application/msword'] = ['.doc']
                break;
            case 'docx':
                fileTypesObj['application/vnd.openxmlformats-officedocument.wordprocessingml.document'] = ['.docx']
                break;
            case 'xls':
                fileTypesObj['application/vnd.ms-excel'] = ['.xls']
                break;
            case 'xlsx':
                fileTypesObj['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] = ['.xlsx']
                break;
            case 'csv':
                fileTypesObj['text/csv'] = ['.csv']
                break;
            case 'ppt':
                fileTypesObj['application/vnd.ms-powerpoint'] = ['.ppt']
                break;
            case 'pptx':
                fileTypesObj['application/vnd.openxmlformats-officedocument.presentationml.presentation'] = ['.pptx']
                break;
            default:
                break;
        }
    }
    return fileTypesObj
}

const regexType = /\.([a-zA-Z0-9]+)(?=\?|$)/;

export const FileUpload = ({ label, multiple = false, children, className, hint, description, required, types = [], maxFileSize, defaultFileName, disabled = false, ...props }) => {
    const [field, meta, helpers] = useField(props)
    const [files, setFiles] = useState(multiple ? [] : null)
    const inputRef = useRef(null);
    const { value } = field;

    useEffect(() => {
        if ((files?.length === 0 || files === null) && meta?.value) {
            setFiles(meta.value)
        }
        //eslint-disable-next-line
    }, [meta])

    useEffect(() => {
        if (files !== null && !multiple) {
            if(props?.singleFile){
                helpers.setValue(files)
            }else{
                helpers.setValue(files)
            }
        }
    }, [files, helpers, multiple, props?.singleFile])

    const dropHandler = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            rejectedFiles.forEach((file) => {
                file?.errors?.length > 0 && file.errors[0].code === 'file-invalid-type'
                    && toast.error('Invalid file type')
            })
        }
        if (multiple) {
            acceptedFiles.forEach((file) => {
                if (file.size > maxFileSize * 1024 * 1024) {
                    toast.error(`Maximum file size is ${maxFileSize}MB.`)
                } else {
                    helpers.setValue([...files, file])
                    setFiles((prev) => [...prev, file]);
                }
            })
        } else {
            if (rejectedFiles.length > 1) {
                toast.error('Please upload a single file')
            } else if (acceptedFiles[0].size > maxFileSize * 1024 * 1024) {
                toast.error(`Maximum file size is ${maxFileSize}MB.`)
            } else {
                props?.singleFile ? setFiles(acceptedFiles[0]) : setFiles(acceptedFiles);
            }
        }
    }

    const url = (value) => typeof value === 'string' ? getFileNameFromUrl(value) || '' : '';
    const fileExt = (url) => (url && typeof url === 'string') ? url?.match(regexType) : null;

    const extension = fileExt(url(value)) ? fileExt(url(value))[0] : null;

    const deleteHandler = (index) => {
        const newFiles = files.filter((file, i) => i !== index)
        helpers.setValue(newFiles)
        setFiles(newFiles)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (inputRef.current) {
                inputRef?.current?.click();
            }
        }
    };




    return (
        <div className={cn('w-full', className)}>
            {label && <>
                <LabelForm label={label} required={required}></LabelForm>
                {hint && (
                    <Tooltip dataTip={`input-${props.name}`} content={hint}>
                        <Icon icon="info-circle" className="text-gray-500" size="sm" />
                    </Tooltip>
                )}
            </>}
            {description && <p className="mb-2 text-gray-text">{description}</p>}
            <div className='cursor-pointer'>
                <Dropzone onDrop={(acceptedFiles, rejectedFiles) => dropHandler(acceptedFiles, rejectedFiles)} accept={typeChecker(types)} multiple={multiple} disabled={disabled}>
                    {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
                        <section>
                            <div {...getRootProps()} className={cn('w-full border text-center content-center relative mx-auto border-dashed h-max bg-white rounded-2xl py-10', `border-solid ${isDragReject && 'border-utility-error-500'} ${isDragAccept && 'border-utility-brand-700'} ${disabled && 'bg-gray-300 bg-opacity-25 pointer-events-none'}`, (meta.error && meta.touched) && 'border-red-400', props?.inputBoxClassName)}>
                                <div ref={inputRef}>
                                    <input {...getInputProps()} />
                                </div>
                                <Icon icon="upload" className="icon-download" style={{ position: "relative", marginLeft: 'auto', marginRight: 'auto' }} />
                                {children}
                                <p>
                                    <span className="text-royal-blue font-semibold focusable" onKeyDown={handleKeyDown}>Click to upload</span> or drag and drop
                                </p>
                                <p><span className='uppercase'>{types.length > 0 && types.join(', ')}</span>{maxFileSize && ` (Max file size ${maxFileSize}MB)`}</p>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>
            {Array.isArray(files) ?
                <>
                    {
                        files && files.length > 0 &&
                        files.map((file, i) => (
                            <FileCard
                                file={typeof file === 'string' ? { name: getFileNameFromUrl(file) } : file}
                                setFiles={() => deleteHandler(i)}
                            />))
                    }
                </>
                : files ? (
                    (typeof value === 'string' && fileExt(url(value)) && !multiple) ? (
                        <>
                            {
                                <FileCard file={{ name: `${defaultFileName ? defaultFileName + extension : url(value)}` }} setFiles={() => { helpers.setValue(null); setFiles(null) }} />
                            }
                        </>
                    )
                        : <FileCard file={files} setFiles={() => { helpers.setValue(null); setFiles(null) }} />
                )
                    : null
            }
            {meta.error && meta.touched && <p className='!text-utility-error-500 my-2 font-roboto text-sm'>{meta.error}</p>}
        </div>
    );
};
