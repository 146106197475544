import React, { useEffect } from 'react';
import { CompanyInformationForm } from '../../../components/CompanyInformationForm';
import { connect } from 'react-redux';

import { getCompanySizeOptions } from 'actions/companyActions';

export const CompanyInformationConnected = ({ btnCompanyInformation,profileType, getCompanySizeOptions, ...props }) => {
    useEffect(() => {
        getCompanySizeOptions(profileType);
    }, [getCompanySizeOptions, profileType]);

    return (
        <>
            <CompanyInformationForm btnCompanyInformation={btnCompanyInformation} {...props} />
        </>
    );
};

export const CompanyInformation = connect(null, { getCompanySizeOptions })(CompanyInformationConnected);
