import styled, { css } from "styled-components";
import tw from "twin.macro";

const TextAreaStyles = css`
  border-radius: 10px;
  margin-bottom: 1.25rem;
  position: relative;
  border: 1px solid rgba(132, 146, 166, 0.2);
`;
const TextAreaStylesWhite = css`
  border-radius: 10px;
  margin-bottom: 1.25rem;
  position: relative;
  background: white !important;
  border: 1px solid rgba(132, 146, 166, 0.2);
`;
export const TextAreaStyled = styled.textarea`
  background: white;
  width: 100%;
  color: ${(props) => props.theme.colors.gray};
  min-height: 140px;
  transition: ${(props) => props.theme.transitions.default};
  line-height: 1.5;
  resize: vertical;
  padding: 1.25rem;
  border: none;
  outline: none;
  ${tw`ring-transparent border-transparent placeholder:text-text-primary-200`}
  ${"" /* don't want to double up on border rules*/}
    ${(props) =>
    !props.maxLength &&
    `
        ${TextAreaStyles}
    `}

    ::-webkit-input-placeholder {
    ${tw`text-text-primary-200`}
    font-size: ${(props) => props.placeholderSize};
  }
  ${(props) =>
    !props?.isError
      ? tw`focus:ring-[5px] group-focus:!border-utility-brand-300 focus:ring-ring-blue-primary`
      : tw`focus:ring-[5px] group-focus:!border-utility-error-300 focus:ring-ring-red-primary`}
`;

export const TextAreaContainer = styled.div`
  ${TextAreaStyles}
  border-color: ${(props) => (props.isError ? "#E11900" : "")};
  position: relative;
  margin-bottom: 25px;

  .textarea {
    border-radius: 10px !important;
    overflow: hidden !important;
    border: none !important;
  }
  .ql-editor,
  .ql-container {
    ${tw`!ring-transparent !border-transparent`}
    border: none !important;
  }
  .ql-toolbar {
    ${tw`!ring-transparent !border-b-gray-100`}
  }
  .ql-formats {
    ${tw`!ring-transparent`}
    button {
      ${tw`!ring-transparent !border-transparent`}
      border: none !important;
    }
  }
  ${(props) =>
    !props?.isError
      ? tw`group-focus-within:ring-[5px] group-focus-within:!border-utility-brand-300 group-focus-within:ring-ring-blue-primary`
      : tw`group-focus-within:ring-[5px] group-focus-within:!border-utility-error-300 group-focus-within:ring-ring-red-primary`}
`;
export const TextAreaContainerWhite = styled.div`
  ${TextAreaStylesWhite}
  padding-bottom: ${(props) => props.theme.space.sm_2};
  margin-bottom: 25px;
  border-color: ${(props) => (props.isError ? "#E11900" : "")};
  .textarea {
    border-radius: 10px !important;
    overflow: hidden !important;
    border: none !important;
  }
  .ql-editor,
  .ql-container {
    ${tw`!ring-transparent !border-transparent`}
    border: none !important;
  }
  .ql-toolbar {
    ${tw`!ring-transparent !border-b-gray-100`}
  }
  .ql-formats {
    ${tw`!ring-transparent`}
    button {
      ${tw`!ring-transparent !border-transparent`}
      border: none !important;
    }
  }
  ${(props) =>
    !props?.isError
      ? tw`group-focus-within:ring-[5px] group-focus-within:!border-utility-brand-300 group-focus-within:ring-ring-blue-primary`
      : tw`group-focus-within:ring-[5px] group-focus-within:!border-utility-error-300 group-focus-within:ring-ring-red-primary`}
`;

export const WordCountStyled = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  color: #c6cdd5;
  position: absolute;
  bottom: 0.15rem;
  right: 1rem;
  font-size: ${(props) => props.theme.fontSizes.sm};
  pointer-events: none;
`;
