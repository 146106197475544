import React from "react";
import styled from "styled-components";

export const DataRowStyled = styled.div`
  color: ${(props) => props.theme.colors.grayLight};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: ${(props) =>
    !props.height ? `140px` : `auto`};
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: ${(props) =>
    !props.noBorder ? `1px solid ${props.theme.colors.grayBorder}` : "none"};
  z-index: 1;

  ${(props) =>
    props.contentType === "company-name" &&
    ` 
        height: 140px !important;
    `}

  ${(props) =>
    props.contentType === "company-name-location" &&
    `
        height: 140px;
        width: 400px !important;
    `}

    ${(props) =>
    props.sticky &&
    `
        width:300px;
        position:fixed;
        top:0;
        left:${props.sLeft}px;
        z-index:${props.sLeft < 300 ? -1 : 2};
        background-color:${props.theme.colors.snow};
        &:hover{
            background-color:white;
        }
    `}

    ${(props) =>
    props.contentType === "location" &&
    `
        height: 97px;
    `}

${(props) =>
    props.contentType === "suburb-city" &&
    props.noBorder &&
    `
    min-height: 50px !important;
    `}

    ${(props) =>
    props.contentType === "company-size" && props.noBorder
      ? `
    min-height: 40px !important;
    `
      : ""}

      ${(props) =>
    props.contentType === "anual-turnover"
      ? `
    height: 250px !important;
    `
      : ""}

    ${(props) =>
    props.contentType === "sector" &&
    `
          height: ${props.sectorHeight && props.sectorHeight}px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 0.75rem;
           min-height: 250px !important;
          // margin-top: ${props.compareTable ? "0.5em" : 0};
      `}
  

    ${(props) =>
    props.contentType === "lga" &&
    `
        min-height: ${props.noBorder ? "50px" : "74px"};
        height: ${props.noBorder ? "50px" : "150px"};
    `}

    ${(props) =>
    props.contentType === "avatar-group" &&
    `
        height: 85px;
    `}

    ${(props) =>
    props.contentType === "avatar-list" &&
    `
        height: 380px;
    `}

    ${(props) =>
    props.contentType === "project-list" &&
    `
         height: ${props.height && props.height !== "0px"?props.height:'50px'};
    `}
    ${(props) =>
    props.contentType === "project-list-data" &&
    `
        height: ${props.height && props.height !== "0px"?props.height:'50px'};
    `}
    ${(props) =>
    props.contentType === "project-images" &&
    `
        height: 230px;
    `}

    ${(props) =>
    props.contentType === "count" &&
    `
        height: 86px;
    `}
    p {
    margin: 0;
  }

  .document-div {
    width: 100%;
    border-bottom: 0.5px solid rgba(91, 107, 136, 0.5);
    position: relative;
    padding-top:10px;
    padding-bottom:10px;
    .documents {
      height: 80px;
      width: 100%;
      overflow: hidden;
      .extra-docs {
        background-color: ${(props) => props.theme.colors.blue};
        height: 30px;
        width: 30px;
        position: absolute;
        bottom: 5px;
        font-size: 10px;
        color: ${(props) => props.theme.colors.snow};
        border-radius: 50%;
        right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const DataRow = ({ isElipsis, children, ...props }) => {
  return (
    <DataRowStyled className={props.sticky ? "stick" : ""} {...props}>
      <ul style={{ listStyle: 'none', width: '100%' }}>
        <li style={{ listStyle: 'none' }}> <div className={`${isElipsis ? "w-200 overflow-hidden overflow-ellipsis  line-clamp-1" : ""}`}>
          {children}
        </div></li>
      </ul>
    </DataRowStyled>
  );
};
