import React, { useState } from 'react'
import { DropdownItem, DropdownMenu, DropdownMenuDrawer, DropdownMenuTrigger } from '../MenuDropdown'
import { Icon } from 'assets/icons/Icon'
import { deleteAddendumClient } from 'actions/RFQ/addendumAction'
import { CustomModal } from '../Modal/CustomModal'
import { useSetState } from 'react-use'
import { Button } from '../Button'
import { Spinner } from '@chakra-ui/react'

const DropdownAddendumMenu = ({noBg, nested, className, answerId, questionId,setAddendum,...props}) => {
  const [isOpen,setIsOpen] = useState(false);
  const [isLoading,setIsLoading] = useSetState(false);

  const dialogProperty =  {
    icon : "trash-stroke",
    bgColor: "bg-utility-error-50",
    iconColor : 'text-utility-error-500',
    title : `Delete ${nested ? 'Answer' : 'Addendum'}`,
    description : `You're about to delete your ${nested ? 'answer' : 'addendum'}. Please review your details carefully to ensure all your information is accurate and complete. Once confirmed, we will begin processing your delete request immediately.`,
  } 

  const deleteHandler = () => {
      setIsLoading(true);
      deleteAddendumClient(answerId,nested).then(res => {
         if(res){
            props?.deleteAnswerHandler(nested ? questionId : answerId,answerId,nested)
            setIsLoading(false)
            setIsOpen(false)
         }else{
            setIsLoading(false)
            setIsOpen(false)
         }
      })
    }

    const FooterModal = () => {
        return (
            <div className='flex justify-between gap-2 w-full text-utility-error-50'>
                <Button onClick={()=> setIsOpen(false)} className='!w-full' btntype='base' disabled={isLoading}>Cancel</Button>
                <Button danger onClick={deleteHandler} className='!w-full' variant='primary' disabled={isLoading}>{ isLoading ? <Spinner /> : 'Yes, Delete it'}</Button>
            </div>
        )
    }

  return (
    <div className={className} {...props}>
      <DropdownMenu>
        <DropdownMenuTrigger icon noBg={noBg}></DropdownMenuTrigger>
        <DropdownMenuDrawer>
          <DropdownItem className="w-full" onClick={() => setIsOpen(true)}>
            <Icon icon='delete' width={12} height={12} />
            <p className="font-roboto font-medium text-text-secondary-700" style={{fontSize:'14px'}}>{nested ? 'Delete Answer' : 'Delete Addendum'}</p>
          </DropdownItem>
        </DropdownMenuDrawer>
      </DropdownMenu>
      <CustomModal
        header={dialogProperty} 
        isOpen={isOpen} 
        onClose={()=> setIsOpen(false)} 
        footerComponent={<FooterModal />}
    />
    </div>
  )
}

export default DropdownAddendumMenu
