import api from "api";
import * as constants from "./actionTypes";
import  React  from 'react';
import { clearSignupData, isEmptyObj, isPAPLUsers, processFormData } from "../utils/helpers";
import { toast } from "react-toastify";
import { navigate } from "@reach/router";
import { getCompany } from "./companyActions";
import LogRocket from "logrocket";
import axios from "axios";
import { toFormData } from "axios";
// import { successMessage } from "components/Login/Login";

function getApiWithToken() {
  const token = localStorage.getItem("access_token");
  return api.extend({
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("Authorization", `Bearer ${token}`);
        },
      ],
    },
  });
}

export const authenticateUser = (
  values,
  setSubmitting,
  setStatus,
  successMessage,
) => {
  return async (dispatch) => {
    (async () => {
      const formData = processFormData(values);
      
      try {
        const user = await api.post("auth/login", { body: formData }).json();
        if (user.success === true) {
          if(user?.user?.two_factor_enabled){
            await dispatch({
              type: constants.AUTHENTICATE_TWO_FACTOR,
              payload: {
                access_token: user.access_token,
                two_factor_enabled: true
              }
            })
            localStorage.setItem("two_factor_enabled", true);
            localStorage.setItem("two_factor_verified", false);
            localStorage.setItem("access_token", user.access_token);
            localStorage.setItem("user", JSON.stringify(user.user));
            navigate("/two-factor");
          }else{
            localStorage.setItem("two_factor_enabled", false);
            localStorage.setItem("two_factor_verified", false);
            localStorage.setItem("access_token", user.access_token);
            localStorage.setItem("user", JSON.stringify(user.user));
            if(isPAPLUsers(user.user)){
              navigate("/two-factor-setup");
            }else{
              localStorage.setItem("access_token", user.access_token);
              localStorage.setItem("is_admin", user.is_admin);
              localStorage.setItem("company_admin", user.company_admin || null);
    
              const userProfile = await getApiWithToken()
                  .get("auth/user-profile")
                  .json();
        
          const companyProfile = await getApiWithToken()
            .get(`companies/${userProfile.data.company_id}`)
            .json();
          if (
            companyProfile?.data?.profile_type?.name.toLowerCase() !== "client"
          ) {
            localStorage.setItem("role", "supplier");
          } else {
            localStorage.setItem("role", "client");
          }

          localStorage.setItem("company_id", userProfile.data.company_id);
                  localStorage.setItem("user", JSON.stringify(userProfile.data));
                  
                  localStorage.setItem(
            "sidebar",
            JSON.stringify({ sidebarcollapse: false }),
          );
          await dispatch({
                    type: constants.AUTHENTICATE_USER,
                    payload: {
                      access_token: user.access_token,
                      two_factor_enabled: user?.user?.two_factor_enabled,
                    },
                  });
                  await dispatch({
                    type: constants.GET_USER,
                    payload: userProfile.data,
                  });
                  await dispatch(getCurrentUser());
                  toast(successMessage, { containerId: "custom" });
            }
          }
        }
      } catch (e) {
        const error = await e.response?.json();
        if(error.status === false){
          toast.error(error.message);
        }
        setStatus(error?.error || "connection");
      }

      setSubmitting(false);
    })();
  };
};

export const signup = (
  id,
  first_name,
  last_name,
  email,
  password,
  agreed,
  setSubmitting,
) => {
  return async (dispatch) => {
    const formData = processFormData({
      id: id,
      first_name: first_name,
      last_name: last_name,
      email: email,
      password: password,
      isAgree: agreed,
    });
    try {
      const user = await api
        .post("auth/users/create", {
          body: formData,
        })
        .json();

      if (user.success === true) {
        await dispatch({
          type: constants.GET_SIGNUP_DETAILS,
          payload: user.user,
        });
        return user;
      } else {
        setSubmitting(false);
        return user;
      }
    } catch (e) {
      const error = await e.response.json();
      const errorMessages = error.errors?.password;
      const errorMessage = errorMessages.length < 2 ? errorMessages[0] : errorMessages[errorMessages.length - 1];
      toast.error(errorMessage || error.error);
    }
  };
};

export const saveBusinessDetails = (
  id,
  company_id,
  business_name,
  phone,
  abn,
  setSubmitting,
) => {
  return async (dispatch) => {
    const formData = processFormData({
      id: id,
      company_id: company_id,
      business_name: business_name,
      phone: phone,
      abn: abn,
    });
    try {
      const user = await api
        .post("auth/business/create", {
          body: formData,
        })
        .json();

      if (user.success === true) {
        await dispatch({
          type: constants.GET_SIGNUP_DETAILS,
          payload: user.user,
        });
        return user;
      } else {
        setSubmitting(false);
        return user;
      }
    } catch (e) {
      const error = await e;
      if (error.response.status === 404) {
        let errorMessage =
          "Please refresh the page and start the process again. If you continue to have issues, feel free to email us at support@procuracon.com.au";
        toast.error(errorMessage);
        clearSignupData();
        return error.response.status;
      } else toast.error(error.response);
      toast.error(error.error);
    }
  };
};

export const setSignupData = (data) => {
  return { type: constants.GET_SIGNUP_DETAILS, payload: data };
};

export const completeRegistration = (values, setSubmitting) => {
  return async (dispatch) => {
    const formData = processFormData({
      id: values.id,
      account_type_id: parseInt(values.account_type_id),
      profile_type_id: values.profile_type_id
        ? parseInt(values.profile_type_id)
        : null,
      payment_method: values.payment_method,
      token_id: values.token_id,
      discount_code: values.discount_code,
      automatic_subscription: values.automatic_subscription,
    });
    try {
      const res = await api
        .post("auth/users/complete-registration", {
          body: formData,
          timeout: false,
        })
        .json();
      if (res.success === true) {
        clearSignupData();
        await dispatch({
          type: constants.GET_SIGNUP_DETAILS,
          payload: {},
        });
        toast.success("Registration Completed Successfully");
        return res;
      } else {
        setSubmitting(false);
        return res;
      }
    } catch (e) {
      const error = await e;
      if (error.response.status === 404) {
        let errorMessage =
          "Please refresh the page and start the process again. If you continue to have issues, feel free to email us at support@procuracon.com.au";
        toast.error(errorMessage);
        clearSignupData();
        return error.response.status;
      } else toast.error(error.response);
    }
  };
};

export const forgotPassword = (values, setSubmitting) => {
  return async () => {
    const formData = processFormData(values);

    try {
      const status = await api
        .post("auth/forget-password", { body: formData })
        .json();

      if (status.status) {
        setSubmitting(false);
        return status;
      } else {
        setSubmitting(false);
        return status;
      }
    } catch (e) {
      const error = await e.response.json();
      console.log(error.error);
    }
  };
};

export const resetPassword = (values, setSubmitting) => {
  return async () => {
    const formData = processFormData(values);

    try {
      const status = await api
        .post("auth/reset-password", { body: formData })
        .json();

      if (status.status) {
        toast.success("Reset Password successfully. Redirecting now.", {
          autoClose: 7000,
        });
        navigate("/login");
      } else {
        toast.error(status.message, {
          autoClose: 7000,
        });
      }
    } catch (e) {
      const error = await e.response.json();
      const errorMessages = error.errors?.password;
      const errorMessage = errorMessages.length < 2 ? errorMessages[0] : errorMessages[errorMessages.length - 1];
      toast.error(errorMessage || error.error);
    }

    setSubmitting(false);
  };
};

export const applyDiscount = (id) => {
  return async () => {
    try {
      const result = await api.get(`apply-discount/${encodeURIComponent(id)}`).json();
      return result;
    } catch (e) {
      const error = await e.response.json();
      toast.error(error.error);
    }
  };
};

export const createCompanyUser = (values) => {
  return async () => {
    const formData = processFormData(values);
    try {
      const result = await api
        .post("users/create-company-user", {
          body: formData,
        })
        .json();
      if (result.success === true) {
        toast.success("User Created.");
        return result;
      } else {
        toast.error(result.message);
        return result;
      }
    } catch (e) {
      const error = await e.response.json();
      const errorMessages = error.errors?.password;
      const errorMessage = errorMessages.length < 2 ? errorMessages[0] : errorMessages[errorMessages.length - 1];
      toast.error(errorMessage || error.error);
    }
  };
};

export const verifyEmail = (email, token) => {
  return async (dispatch) => {
    (async () => {
      const formData = processFormData({ email: email, code: token });

      try {
        const res = await getApiWithToken()
          .post("auth/verify-email", { body: formData, timeout: false })
          .json();
        if (res.status === true) {
          const acc_token = localStorage.getItem("access_token");
          if (acc_token) {
            const user = await getApiWithToken()
              .get("auth/user-profile")
              .json();
            localStorage.setItem("company_id", user.data.company_id);
            localStorage.setItem("user", JSON.stringify(user.data));

            await dispatch({
              type: constants.GET_USER,
              payload: user.data,
            });
            toast.success("Email Verified. Redirecting now.");
          } else toast.success("Email has been Verified. Login Now");
          navigate("/login");
        } else {
          toast.dismiss();
          toast.error(res.message);
          const access_token = localStorage.getItem("access_token");
          if (access_token) navigate("/verify-email", { replace: true });
          else navigate("/login", { replace: true });
        }
      } catch (e) {
        const error = await e.response.json();
        toast.error(error.error);
        navigate("/login", { replace: true });
      }
    })();
  };
};

export const setAccessToken = (token,twoFactorPassed) => {
  return async (dispatch) => {
    (async () => {
      if(twoFactorPassed){
        await dispatch({
          type: constants.AUTHENTICATE_TWO_FACTOR,
          payload: {
            access_token: token,
          },
        });
      }else{
        await dispatch({
          type: constants.AUTHENTICATE_USER,
          payload: {
            access_token: token,
          },
        });
      }
      
      await dispatch(getCurrentUser());
    })();
  };
};

export const getCurrentUser = () => {
  return async (dispatch) => {
    (async () => {
      try {
        const user = await getApiWithToken().get("auth/user-profile").json();

        if (user.data && !isEmptyObj(user.data)) {
          localStorage.setItem("company_id", user.data.company_id);
          localStorage.setItem("user", JSON.stringify(user.data));

          if (!LogRocket?._identified) {
            console.info("[LogRocket]", "Identified user: ", user.data);
            LogRocket.identify(user.data.id, {
              name: user.full_name,
              email: user.email,
            });
            LogRocket._identified = true;
          }

          await dispatch({
            type: constants.GET_USER,
            payload: user.data,
          });
          const company = await getApiWithToken()
            .get(
              `companies/${user.data.company_id}?past_projects_published=false`,
            )
            .json();
          if (company?.data?.profile_type?.name.toLowerCase() !== "client") {
            localStorage.setItem("role", "supplier");
          } else {
            localStorage.setItem("role", "client");
          }
          await dispatch({
            type: constants.GET_CURRENT_COMPANY,
            payload: company.data,
          });
          // await createProfileType(company?.data?.profile_type.id)
          await dispatch({
            type: constants.CREATE_PROFILE_TYPE,
            payload: company?.data?.profile_type?.id,
          });
        } else {
          dispatch(logoutUser());
        }
      } catch (e) {
        console.log("error while fetching user", e);
        dispatch(logoutUser());
      }
    })();
  };
};

export const setLoggedInUser = (user) => {
  return async (dispatch) => {
    (async () => {
      await dispatch({
        type: constants.GET_USER,
        payload: user,
      });
      await dispatch(getCompany(user.company_id));
    })();
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    (async () => {
      try {
        const token = localStorage.getItem("access_token");
        const logoutApi = api.extend({
          hooks: {
            beforeRequest: [
              (request) => {
                request.headers.set("Authorization", `Bearer ${token}`);
              },
            ],
          },
        });

        const logout = await logoutApi.post("auth/logout").json();

        if (logout.success === true) {
          localStorage.clear();
          await dispatch({ type: constants.LOGOUT_USER });
          navigate("/login", { replace: true });
        } else {
          toast.error(logout.error);
          localStorage.clear();
        }
      } catch (e) {
        const error = await e.response?.json();
        toast.error(error?.error);
        localStorage.clear();
      }
    })();
  };
};

export const logoutGuestUser = () => {
  return async (dispatch) => {
    (async () => {
      try {
        localStorage.clear();
        navigate("/guest/login", { replace: true });
        await dispatch({ type: constants.LOGOUT_GUEST_USER });
      } catch (e) {
        const error = await e.response?.json();
        toast.error(error?.error);
      }
    })();
  };
};

export const guestSignup = (values, setSubmitting, setStatus) => {
  return async (dispatch) => {
    values.password_confirmation = values.password;
    const formData = processFormData(values);
    try {
      const serverUrl = process.env.REACT_APP_API_URL_V2;
      let signUpUrl = serverUrl + "/guest/register";
      const res = await axios.post(signUpUrl, formData);
      if (res.status === 200) {
        toast.success(res?.data?.message, { autoClose: 10000 });
        navigate("/thank-you");
      } else {
        setSubmitting(false);
        return res;
      }
    } catch (error) {
      setSubmitting(false);
      toast.error(error?.response?.data?.message);
    }
  };
};
export const guestLogin = (
  values,
  setSubmitting,
  setStatus,
  successMessage,
) => {
  return async (dispatch) => {
    let newPayload = {
      email: values.email,
      password: values.password,
      token: null,
    };
    const formData = processFormData(newPayload);
    try {
      const res = await api
        .post("guest/login", {
          body: formData,
        })
        .json();

      if (res.success === true) {
        localStorage.setItem("guest_user", JSON.stringify(res.user));
        localStorage.setItem("role", "guest");
        await dispatch({
          type: constants.AUTHENTICATE_GUEST_USER,
          payload: res.user,
        });
        // toast(successMessage, { containerId: "custom" });
        setSubmitting(false);
        return res;
      } else {
        setSubmitting(false);
        return res;
      }
    } catch (e) {
      setSubmitting(false);
      const error = await e.response.json();
      setStatus(error?.message || "connection");
      toast.error(
        error.message === "suspended" ? (
          <div>
            Your Guest Account is currently suspended.
            <br />
            Please contact support@procuracon.com.au
          </div>
        ) : (
          error.message
        ),
      );
    }
  };
};

export const shareProfile = (token) => {
  return async () => {
    try {
      const result = await getApiWithToken()
        .get(`auth/share-profile?invitetoken=${token}`)
        .json();
      return result;
    } catch (e) {
      const error = await e.response.json();
      return error;
    }
  };
};

export const upgradeAccount = (email) => {
  return async (dispatch) => {
    (async () => {
      try {
        localStorage.clear();
        navigate("/signup", { replace: true, state: { email: email } });
        await dispatch({ type: constants.LOGOUT_GUEST_USER });
      } catch (e) {
        const error = await e.response?.json();
        toast.error(error?.error);
      }
    })();
  };
};

export const enablingTwoFactor = async () => {
    try {
      const result = await getApiWithToken().get("2fa/two-factor-code").json();
      if(result.status){
        return result.data;
      }else{
        toast.error(result.message);
      }
    } catch (e) {
      const error = e?.response?.data?.errors?.code[0] || 'Failed to enable 2FA';
      return error;
    }
}

export const submitTwoFactorCode = async (values) => {
  const data = toFormData({code : values});
  try {
    const result = await getApiWithToken()
      .post("2fa/two-factor-confirm", { body: data })
      .json();
    if(result.success){
      toast.success('2FA enabled successfully');
      localStorage.setItem("access_token", result.access_token);
      localStorage.setItem("two_factor_enabled", true);
      localStorage.setItem("two_factor_verified", true);
      return result;
    }else{
      toast.error('Invalid Code');
    }
  } catch (e) {
    const error = e?.response?.data?.errors?.code[0] || 'Failed to enable 2FA';
    toast.error(error);
  }
}

export const enableTwoFactor = () => {
  return async (dispatch) => {
    dispatch({
      type: constants.ENABLED_TWO_FACTOR,
    })
  }
}

export const authTwoFactorLogin = (values,isBackupCode) => {
  return async (dispatch) => {
    const formData = processFormData({code: values});
    try {
      const result = await getApiWithToken()
      .post(isBackupCode ? "2fa/two-factor-recovery-codes" : "2fa/two-factor-confirm", { body: formData }).json();
      if(result.success){      
      const user = result

      localStorage.setItem("access_token", user.access_token);
      localStorage.setItem("is_admin", user.is_admin);
      localStorage.setItem("company_admin", user.company_admin || null);
      localStorage.setItem("two_factor_verified", true);

      const userProfile = await getApiWithToken()
        .get("auth/user-profile")
        .json();

      localStorage.setItem("company_id", userProfile.data.company_id);
      localStorage.setItem("user", JSON.stringify(userProfile.data));

      await dispatch({
        type: constants.AUTHENTICATE_TWO_FACTOR,
        payload: {
          access_token: user.access_token,
        },
      });
      await dispatch({
        type: constants.GET_USER,
        payload: userProfile.data,
      });
      await dispatch(getCurrentUser());
      // toast(successMessage, { containerId: "custom" });
    }else{
      toast.error(result.message);
    }
    } catch (e) {
      const error = e?.response?.data?.errors?.code[0] || 'Invalid Code';
      toast.error(error);
      return error;
    }
  }
}

export const deactivateTwoFactor = () => {
  return async (dispatch) => {
    try {
      const result = await getApiWithToken()
      .post("2fa/two-factor-disable")
      .json();
      if(result.status){
        await dispatch({
          type: constants.DEACTIVATE_TWO_FACTOR,
        })
        localStorage.setItem("two_factor_enabled", false);
        localStorage.setItem("two_factor_verified", false);
        toast.success(result.message);
        return result.data;
      }else{
        toast.error(result.message);
      }
    } catch (e) {
      const error = e?.response?.data?.errors?.code[0] || 'Failed to disable 2FA';
      return error;
    }
}
}

export const continueTwoFactorSetup = (user) => {
  return async (dispatch) => {
      localStorage.setItem("access_token", user.access_token);
      localStorage.setItem("is_admin", user.is_admin);
      localStorage.setItem("company_admin", user.company_admin || null);
      localStorage.setItem("two_factor_verified", true);

      const userProfile = await getApiWithToken()
        .get("auth/user-profile")
        .json();

      localStorage.setItem("company_id", userProfile.data.company_id);
      localStorage.setItem("user", JSON.stringify(userProfile.data));

      await dispatch({
        type: constants.AUTHENTICATE_TWO_FACTOR,
        payload: {
          access_token: user.access_token,
        },
      });
      await dispatch({
        type: constants.GET_USER,
        payload: userProfile.data,
      });
      await dispatch(getCurrentUser());
      // toast(successMessage, { containerId: "custom" });
  }
}

export const getBackupCodes = async () => {
  try{
    const response = await getApiWithToken()
    .get("2fa/two-factor-recovery-codes")
    .json();
    if(response.status){
      return response.data;
    }else{
      toast.error(response.message);
    }
  }catch(e){
    console.log(e);
  }
}

export const checkAccessToken = async () => {
  const status = await getApiWithToken()
  .post("auth/check-access-token")
  .json();
  return status.status;
}