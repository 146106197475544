import { Button } from "components/RFQ/Button";
import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import tw, { styled, css } from "twin.macro";
import { TabHeader } from "components/TabHeader";
import { CloseButton } from "components/RFQ/CloseButton";
import { FieldArray, Form, Formik } from "formik";
import { Checkbox } from "components/RFQ/Checkbox";
import { Icon } from "assets/icons/Icon";
import { FieldForm } from "components/RFQ/Input";
import { cn } from "utils/helpers";
import { toast } from 'react-toastify';
import apiV2 from "api-v2";
import * as yup from 'yup';
const DrawerBodyStyled = styled(DrawerBody)(() => [tw`p-6 max-h-[calc(100vh - 50px)]`]);
export const RFQSupplierInviteFormStyled = styled.div(() => [
  css`
    .img-card {
      object-fit: cover;
      height: 104px;
      width: 100%;
    }
    .rfq-supplier-form-wrapper {
      margin-bottom: 20px;
    }
    .rfq-form-sub-title {
      font-family: Roboto;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      height: 30px;
    }
    .rfq-form-caption {
      font-family: Roboto;
      font-size: 14px;
      line-height: 20px;
      height: 20px;
      margin-bottom: 30px;
    }
    .input-wrapper {
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  `,
]);
const DrawerHeaderStyled = styled(DrawerHeader)(() => [
  tw`text-black py-6 px-0`,
  css`
    .close-text {
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
    }

    .header-container {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      align-items: center;
    }

    .clear-container {
      display: flex;
      gap: 24px;
      justify-items: center;
      place-items: center;
    }
  `,
]);

const InviteGuestDrawer = ({ rfqName, rfqId, onClose, isOpen, inviteSupplierHandler, ...props }) => {
  const [isChecked, setIsChecked] = useState(false)
  const initialValues = {
    fieldData: [
      { name: '', email: '' }
    ]
  };

  const validationSchema = yup.object().shape({
    fieldData: yup.array().of(
      yup.object().shape({
        name: yup.string().required('Name is required'),
        email: yup.string().email('Invalid email').required('Email is required'),
      })
    ),
  });

  const handleRFQActionSubmit = async (values) => {
    try {
      let payload = {
        guests: values.fieldData
      }
      let res = await apiV2.post(`rfq/${rfqId}/invite-supplier`, payload)
      if (res.status === 200) {
        toast.success(res?.data?.message)
        inviteSupplierHandler(res.data.data?.supplierInvited)
        onClose()
      }
    } catch (err) {
      toast.error(err?.response?.data?.message)
    }
  }


  return (
    <div>
      <Drawer
        placement={"right"}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        {...props}
      >
        <DrawerOverlay onClick={onClose} />
        <DrawerContent>
          <DrawerHeaderStyled borderBottomWidth="1px">
            <div className="mx-6">
              <TabHeader
                noDivider={true}
                heading={"Add Supplier"}
                description={`Add guests to RFQ “${rfqName}” from your Supplier List.`}
              >
                <CloseButton onClose={onClose} />
              </TabHeader>
            </div>
          </DrawerHeaderStyled>
          <DrawerBodyStyled>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleRFQActionSubmit}
            >
              {({ values, isValid, dirty, isSubmitting }) => (
                <Form>
                  <FieldArray name="fieldData">
                    {({ push, remove }) => (
                      <div>
                        {values.fieldData.map((fd, index) => (
                          <RFQSupplierInviteFormStyled>
                            <div className="rfq-supplier-form-wrapper bg-utility-blue-light-100 rounded-md p-6">
                              <h3 className='rfq-form-sub-title'>Guest {index + 1}</h3>
                              <div className="input-wrapper group flex items-start relative pr-8 ">
                                <div style={{ flex: 1 }} className="mr-3 ">
                                  <FieldForm
                                    label="Name"
                                    type="text"
                                    placeholder="Name"
                                    name={`fieldData.${index}.name`}
                                  />
                                </div>
                                <div style={{ flex: 1 }} className="ml-3">
                                  <FieldForm
                                    label="Email"
                                    type="text"
                                    placeholder="you@example.com"
                                    name={`fieldData.${index}.email`}
                                  />
                                </div>
                                {values.fieldData.length !== 1 && <div
                                  className={cn(
                                    "hidden group-hover:flex bg-gra y-300 p-2 rounded-full hover:bg-red-200 w-max h-max absolute top-10 -right-4 cursor-pointer"
                                  )}
                                  onClick={() => remove(index)}
                                >
                                  <Icon icon="trash" style={{ width: "20px", height: "20px" }} />
                                </div>}
                              </div>

                              {index === values.fieldData.length - 1 && <Flex gap={4} align={"center"}>
                                <Button
                                  btntype="secondary"
                                  type={"button"}
                                  className="!w-max"
                                  onClick={() => push({ name: '', email: '' })}
                                >
                                  <Flex gap={2} className="items-center">
                                    <Icon icon="plus-blue" />
                                    Add another
                                  </Flex>
                                </Button>
                              </Flex>}
                            </div>
                          </RFQSupplierInviteFormStyled>
                        ))}
                      </div>
                    )}
                  </FieldArray>
                  <div className='flex justify-start items-center mt-2 mb-5'>
                    <div className='w-30'>
                      <Checkbox checked={isChecked} onChange={() => { setIsChecked(!isChecked) }} />
                    </div>
                    <div className='flex-1 ml-3 font-roboto font-medium text-gray-700' style={{ fontSize: '14px' }}>
                      I’ve verified and all email addresses are correct.
                    </div>
                  </div>
                  <Button type="submit" style={{ width: "100%" }} disabled={!isChecked || !isValid || !dirty || isSubmitting} >
                    {isSubmitting ? <Spinner></Spinner> : "Invite"}
                  </Button>
                </Form>
              )}
            </Formik>
          </DrawerBodyStyled>
        </DrawerContent>
      </Drawer >
    </div >
  );
};

export default InviteGuestDrawer;
