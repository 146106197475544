import { Flex } from "@chakra-ui/react";
import { CardQuestioner } from "components/RFQ/CardQuestioner";
import { FieldForm } from "components/RFQ/Input/Input";
import React from "react";
import {
  QuestionComponent,

} from "./QuestionTypeComponent";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { reorderList } from "utils/reorderList";
import { useField } from "formik";

const SectionContent = ({ OptionSections, index }) => {
  const fieldname = (name) => `sections[${index}]${name}`;
  const [,meta,] = useField(fieldname('questionnaire'));

  return (
    <div className="space-y-3 mb-5">
      <Flex gap={5}>
        <FieldForm placeholder="Add Section Name" name={fieldname("name")} />
      </Flex>
      <Flex>
        <FieldForm
          type="textArea"
          name={fieldname("description")}
          placeholder="Add Section Description"
        />
      </Flex>
      {meta?.error && typeof meta.error === 'string' && meta.touched
      ? <p className="text-md text-red-500 mt-2">
          { meta.error }
        </p> 
      : null
      }
    </div>
  );
};

const ReturnableSchedulesCard = ({
  OptionSections,
  isSmartField,
  dragHandle,
  index,
  deleteSectionsHandler,
  copySectionsHandler,
}) => {

  const fieldname = (name) => `sections[${index}]${name}`;
  const [,meta,helper] = useField(fieldname('questionnaire'))
  const questionsValue = meta.value;
  const sectionIndex = index
  const dragEndHandler = (result) => {
    let value = questionsValue
    let valueSetter = (newValue) => value = newValue
    reorderList(value, result, valueSetter);
    const reordered = value.map((item, index) => ({...item, sort_order: index+1}))
    helper.setValue(reordered)
  }

  const copyQuestionHandler = (index) => {
    const formQuestion = { ...meta.value[index], sort_order: meta.value.length + 1, id: null,uuid: Math.floor(1000 + Math.random() * 9000) };
    helper.setValue([...meta.value, formQuestion]);

    const sectionLastIndex = sectionIndex
    const questionLastIndex = questionsValue?.length - 1 || 0
    setTimeout(() => {
      const cardElement = document.getElementById(`question-${sectionLastIndex}-${questionLastIndex}`)
      cardElement.scrollIntoView({ behavior: "smooth", block: "start" })
    }, 200)
  }

  const deleteQuestionHandler = (index) => {
    const formValue = meta.value[index]
    if(formValue?.id){
      let newFormQuestions = [...meta.value];
      newFormQuestions[index] = {...formValue, isRemove: 1, fields:[]}
      helper.setValue(newFormQuestions);
    }else{
      const newFormQuestions = [...meta.value];
      newFormQuestions.splice(index, 1);
      helper.setValue(newFormQuestions);
    }
  };


  return (
    <div className="w-full h-max" id={`section-container-${index}`}>
      {
        <CardQuestioner
          dragHandle={dragHandle}
          isSection={true}
          isSmartField={isSmartField}
          deleteSectionHandler={() => deleteSectionsHandler(index)}
          copySectionHandler={() => copySectionsHandler(index)}
          id={`section-${index}`}
          label={`Section ${index + 1}`}
        >
          <SectionContent OptionSections={OptionSections} index={index} />
        </CardQuestioner>
      }
      <DragDropContext
        onDragEnd={dragEndHandler}
        className="my-10"
      >
        <Droppable droppableId={`questioner-container-${index}`} direction="vertical">
          {(provided) => (
            <div
              id="questioner-container"
              className="mb-8"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {questionsValue &&
                questionsValue.length > 0 &&
                questionsValue?.map((question, i) => {
                  return question?.isRemove !== 1 ? (
                    <Draggable
                      key={`question-${i}`}
                      draggableId={`question-${i}`}
                      index={i}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <CardQuestioner 
                              dragHandle={provided.dragHandleProps} 
                              sectionIndex={index} 
                              index={i} 
                              copyQuestionHandler={() => copyQuestionHandler(i)}
                              deleteQuestionHandler={() => deleteQuestionHandler(i)}
                              id={`question-${index}-${i}`}
                              label={`Question ${i+1}`}
                              >
                            <QuestionComponent
                              index={i}
                              sectionIndex={index}
                              question={question}
                            />
                          </CardQuestioner>
                        </div>
                      )}
                    </Draggable>
                  ) : null;
                })}
                {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ReturnableSchedulesCard;
