import { Icon } from 'assets/icons/Icon'
import React from 'react'
import tw, { styled } from 'twin.macro'

const ButtonGroupLeftStyled = styled.button(({ active }) => [
  tw`h-[40px] shadow-input bg-white text-black font-semibold px-6 py-4 border border-[#D0D5DD] flex gap-2 items-center rounded-l-[4px] self-center active:bg-gray-200
    hover:bg-gray-50 focus:ring-2 focus:ring-gray-200`,
  active && tw`bg-gray-100`
])
const ButtonGroupRightStyled = styled.button(({ active }) => [
  tw`h-[40px] shadow-input bg-white text-black font-semibold px-6 py-4 border border-[#D0D5DD] border-l-0 flex gap-2 items-center rounded-r-[4px] self-center active:bg-gray-200
    hover:bg-gray-50 focus:ring-2 focus:ring-gray-200`,
  active && tw`bg-gray-100`
])

const ButtonViewGroup = ({ setView, view }) => {
  return (
    <div className='flex'>
      <ButtonGroupLeftStyled type="button" onClick={() => setView('grid')} active={view === 'grid'}>
        <Icon icon='dashboard-square' style={{ color: '#475467' }} />
      </ButtonGroupLeftStyled>
      <ButtonGroupRightStyled type="button" onClick={() => setView('list')} active={view === 'list'}>
        <Icon icon='list-view' style={{ color: '#475467' }} />
      </ButtonGroupRightStyled>
    </div>
  )
}

export default ButtonViewGroup
