import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
  } from "@chakra-ui/react";
  import { Icon } from "assets/icons/Icon";
  import { Image } from "assets/img";
  import React from "react";
  import tw, { styled } from "twin.macro";
  import { cn } from "utils/helpers";
  
  
  const ContainerLogo = styled.div(() => [
    tw`relative w-[480px] h-[48px] text-center items-center flex`,
  ]);
  
 export const CustomModal = ({ isOpen, onClose, header, footerComponent, children,size='xl', ...props }) => {
    return (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          size={size}
          closeOnEsc
          isCentered
          {...props}
        >
          <ModalOverlay />
          <ModalContent  containerProps={{sx:{ height: '100vh' }}}>
                <ModalHeader>
                  <div className="overflow-hidden space-y-3">
                    <ContainerLogo>
                      <Image
                        img="background-pattern-02"
                        className="absolute z-0 -top-10 -left-5"
                        style={{ width: "180px" }}
                      />
                      <div
                        className={cn(
                          "rounded-full h-10 w-10 items-center content-center ",
                          header.bgColor
                        )}
                        style={{ textAlign: "-webkit-center" }}
                      >
                        <Icon icon={header.icon} className={header.iconColor} />
                      </div>
                    </ContainerLogo>
                    <div className="space-y-1">
                      <h2 className="text-lg text-text-primary-900 font-semibold z-20  relative">
                        {header.title}
                      </h2>
                      <p className="text-sm font-normal text-text-tertiary-600">
                        {header.description}
                      </p>
                    </div>
                  </div>
                </ModalHeader>
                <ModalCloseButton onClose={onClose} />
                <ModalBody>
                  {children}
                </ModalBody>
                <ModalFooter>
                  {footerComponent}
                </ModalFooter>
          </ModalContent>
        </Modal>
    );
  };
  
  export default Modal;
  