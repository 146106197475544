  import React from "react";
import { AddendumMessage } from "../AddendumMessage";
import { AddendumConvo } from "../AddendumConvo";

const AddendumClient = ({rfqDetail,addendumData,setAddendumData}) => {


  return (
    <div className="bg-white rounded-2xl border border-utility-gray-200 border-solid shadow-md overflow-hidden pb-10 mb-10">
      <div className="bg-gray-utility-100 p-10 mb-8">
        <h5
          className="font-roboto font-medium text-text-primary-900 mb-5"
          style={{ fontSize: "14px" }}
        >
          Add an addendum
        </h5>

        <AddendumMessage nested={false} setAddendumData={setAddendumData} rfqId={rfqDetail?.id} />
      </div>
      <AddendumConvo addendumData={addendumData} setAddendumData={setAddendumData} rfqDetail={rfqDetail} />
    </div>
  );
};

export default AddendumClient;
