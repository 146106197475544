const {
  PRELIMINARIES,
  TRADEPACKAGE,
  CONTINGENCY,
  PROFESSIONALFEES,
  PROVISIONALSUMS,
  OTHER,
} = require("constants/rfq");

export const defaultOptions = (quoteOption) => {
  switch (quoteOption) {
    case PRELIMINARIES:
      return [
        {
          id: null,
          name: "",
          unit_id: "",
          sort_order: 0,
          placeholder: "Example: Concept Design",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 7,
          sort_order: 0,
          placeholder: "Example: Design Development",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 7,
          sort_order: 0,
          placeholder: "Example: Contract Documentation",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 7,
          sort_order: 0,
          placeholder: "Example: Meeting Attendance",
          isRemove: 0,
        },
      ];
    case TRADEPACKAGE:
      return [
        {
          id: null,
          name: "",
          unit_id: 4,
          sort_order: 0,
          placeholder: "Example: Concrete Supply",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 4,
          sort_order: 0,
          placeholder: "Example: Concrete Install",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 7,
          sort_order: 0,
          placeholder: "Example: Contract Repairs",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 8,
          sort_order: 0,
          placeholder: "Example: Supervisor",
          isRemove: 0,
        },
      ];
    case CONTINGENCY:
      return [
        {
          id: null,
          name: "",
          unit_id: 3,
          sort_order: 0,
          placeholder: "Example: Contingency 1",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 3,
          sort_order: 0,
          placeholder: "Example: Contingency 2",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: "",
          sort_order: 0,
          placeholder: "Example:",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: "",
          sort_order: 0,
          placeholder: "Example:",
          isRemove: 0,
        },
      ];
    case PROFESSIONALFEES:
      return [
        {
          id: null,
          name: "",
          unit_id: "",
          sort_order: 0,
          placeholder: "Example: Concept Design",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 7,
          sort_order: 0,
          placeholder: "Example: Design Development",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 7,
          sort_order: 0,
          placeholder: "Example: Contract Documentation",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 7,
          sort_order: 0,
          placeholder: "Example: Meeting Attendance",
          isRemove: 0,
        },
      ];
    case PROVISIONALSUMS:
      return [
        {
          id: null,
          name: "",
          unit_id: 3,
          sort_order: 0,
          placeholder: "Example: Landscaping (Supply & Install)",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 3,
          sort_order: 0,
          placeholder: "Example: Irrigation (Supply & Install)",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: "",
          sort_order: 0,
          placeholder: "Example: ",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 7,
          sort_order: 0,
          placeholder: "Example: Labourer",
          isRemove: 0,
        },
      ];
    case OTHER:
      return [
        {
          id: null,
          name: "",
          unit_id: 7,
          sort_order: 0,
          placeholder: "Example: Defect Inspections",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 7,
          sort_order: 0,
          placeholder: "Example: Stakeholder Engagement",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 8,
          sort_order: 0,
          placeholder: "Example: Labourer",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: "",
          sort_order: 0,
          placeholder: "Example: ",
          isRemove: 0,
        },
      ];
    default:
      return [
        {
          id: null,
          name: "",
          unit_id: 7,
          sort_order: 0,
          placeholder: "Example: Defect Inspections",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 7,
          sort_order: 0,
          placeholder: "Example: Stakeholder Engagement",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: 8,
          sort_order: 0,
          placeholder: "Example: Labourer",
          isRemove: 0,
        },
        {
          id: null,
          name: "",
          unit_id: "",
          sort_order: 0,
          placeholder: "Example: ",
          isRemove: 0,
        },
      ];
  }
};
