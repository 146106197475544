import { Button } from "components/RFQ/Button";
import React, { useEffect, useMemo, useState } from "react";

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import tw, { styled, css } from "twin.macro";
import { TabHeader } from "components/TabHeader";
import { Input } from "components/RFQ/Input/Input";
import { createRfq } from "actions/RFQ/rfqsAction";
import { navigate, useLocation } from "@reach/router";
import { getProjectSupplier } from "actions/RFQ/projectsAction";
import { CloseButton } from "components/RFQ/CloseButton";
import SelectTemplate from "components/RFQ/SelectTemplate/SelectTemplate";

const DrawerBodyStyled = styled(DrawerBody)(() => [tw`p-6 max-h-[calc(100vh - 100px)]`]);

const DrawerHeaderStyled = styled(DrawerHeader)(() => [
  tw`text-black py-6 px-0`,
  css`
    .close-text {
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
    }

    .header-container {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      align-items: center;
    }

    .clear-container {
      display: flex;
      gap: 24px;
      justify-items: center;
      place-items: center;
    }
  `,
]);

const CreateRFQOverview = ({ onClose, isOpen, ...props }) => {
  const location = useLocation()
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedRFQOptions, setSelectedRFQOptions] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);


  const [projects, setProjects] = useState([]); 
  const [suppliers, setSuppliers] = useState([]);



  useEffect(() => {
    getProjectSupplier().then((res) => {
      setProjects(res);
    });
  }, [])


  const projectsOptions = useMemo(()=> projects.map((project)=> ({value:project?.id,label:project?.name,})) ,[projects])

  const handleChangeProject = (e) => {
    const suppliersOpt = projects.find((project) => project.id === e.value)?.supplier_list.map((list)=> ({value: list.id,label:list.name}))
    setSelectedProject(e);
    setSuppliers(suppliersOpt);
    setSelectedSupplier(null)
  };

  const handleChangeSupplier = (e) => {
    setSelectedSupplier(e);
  };

  const redirectUrl = (id) => location.pathname.includes('request-for-quote/') ? `create-rfq/${id}` : `request-for-quote/create-rfq/${id}`
  const createRFQ = () => {
    const data = {project_id : selectedProject?.value,supplier_list_id: selectedSupplier?.value,template_id: selectedTemplate?.value}
    createRfq(data).then((res) => {
      if(res){
        navigate(redirectUrl(res.id), { state: { rfqData: res }});
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <div>
      <Drawer
        placement={"right"}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        {...props}
      >
        <DrawerOverlay onClick={onClose} />
        <DrawerContent>
          <DrawerHeaderStyled borderBottomWidth="1px">
            <div className="px-6">
            <TabHeader
              noDivider={true}
              heading={"SELECT A PROJECT & SUPPLIER"}
              description={
                "Select your preferred project and supplier to begin creating your request for a quote."
              }
            >
              <CloseButton onClose={onClose} />
            </TabHeader>
            </div>
          </DrawerHeaderStyled>
          <DrawerBodyStyled>
            <div className="mb-8">
              <Input
                onChange={handleChangeProject}
                type="select"
                label="Select Project"
                placeholder="Select Project"
                options={projectsOptions}
                checkIcon={true}
              />
            </div>
            <div
              className="mb-8 "
              style={{ opacity: selectedProject ? 1 : 0.5 }}
            >
              <Input
                onChange={handleChangeSupplier}
                type="select"
                label="Select Supplier"
                placeholder="Select Supplier"
                options={suppliers}
                isDisabled={selectedProject === null}
                checkIcon={true}
                value={selectedSupplier}
                emptyOptionsText="Add supplier list to project"
              />
            </div>

            {selectedProject && selectedSupplier && <SelectTemplate
                setSelectedTemplate={setSelectedTemplate}
                selectedRFQOptions={selectedRFQOptions}
                setSelectedRFQOptions={setSelectedRFQOptions}
            />}

            <div className="mb-8">
              <Button
                width="100%"
                onClick={createRFQ}
                disabled={!selectedSupplier || !selectedProject || !selectedRFQOptions}
              >
                Create RFQ
              </Button>
            </div>
            <div className="text-center">
              <p>
                Haven’t created the project yet?
                <a href="./projects/new-project"> Start here</a>
              </p>
            </div>
          </DrawerBodyStyled>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default CreateRFQOverview;
