import * as Yup from "yup";

const isArray = (type) => type === "checkbox";
const isSmartField = (type) =>
  type === "project_experience" ||
  type === "key_personnel" ||
  type === "corporate_documents";
const isFile = (type) => type === "file";

export const initialQuoteValue = (quoteData) => {
  let quoteValues = {};
  quoteData.forEach((quote) => {
    quote.items.forEach((item) => {
      const { rqfQuoteAnswer } = item;
      quoteValues[item.id] = {
        quantity: rqfQuoteAnswer?.answer?.value?.quantity || 0,
        rate: rqfQuoteAnswer?.answer?.value?.rate || 0,
        unit: item.unit?.id || null,
      };
    });
  });
  return { quote_answer: { ...quoteValues } };
};

export const initialReturnableValue = (
  returnableList,
  isGuest,
  hasDocuments,
  hasPasProjects,
  hasResources
) => {
  let questionValues = {};
  returnableList?.forEach((section) => {
    section.question?.forEach((question) => {
      if (isSmartField(question?.fields?.type)) {
        if (isGuest) {
          if (question?.fields?.type === "corporate_documents") {
            questionValues[`${question.id}`] = question?.rfqAnswer?.answer
              ?.value
              ? question?.rfqAnswer?.answer?.value?.map((file) => ({
                  file_name: file?.fileName || " - ",
                  file_size: file?.fileSize || null,
                  file_path: file?.filePath || null,
                }))
              : [];
          } else {
            questionValues[`${question.id}`] =
              question?.rfqAnswer?.answer?.value[0] || [];
          }
        } else {
          /**
           * if supplier but no documents show upload field
           */
          if (
            question?.fields?.type === "corporate_documents" &&
            !hasDocuments
          ) {
            let additional = question?.rfqAnswer?.answer?.value
              ? question?.rfqAnswer?.answer?.value?.map((file) => ({
                  file_name: file?.fileName || " - ",
                  file_size: file?.fileSize || null,
                  file_path: file?.filePath || null,
                }))
              : [];
            questionValues[`${question.id}`] = {
              chosen_type: "other",
              additional: additional,
            };
          } else if (
            (question?.fields?.type === "project_experience" &&
              !hasPasProjects) ||
            (question?.fields?.type === "key_personnel" && !hasResources)
          ) {
            /**
             * if supplier but no projects or resources show textarea
             */
            let additional = question?.rfqAnswer?.answer?.value || [];
            questionValues[`${question.id}`] = {
              chosen_type: "other",
              additional: additional,
            };
          } else {
            questionValues[`${question.id}`] = question?.rfqAnswer?.answer
              ?.value
              ? typeof question?.rfqAnswer?.answer?.value === "string"
                ? []
                : question?.rfqAnswer?.answer?.value.map((ans) => ans?.id).filter(ans =>  ans !== undefined)
              : [];
          }
        }
      } else if (isFile(question?.fields?.type)) {
        questionValues[`${question.id}`] = question?.rfqAnswer
          ? question?.rfqAnswer?.answer?.value?.map((file) => ({
              file_name: file?.fileName || " - ",
              file_size: file?.fileSize || null,
              file_path: file?.filePath || null,
            }))
          : [];
      } else if (isArray(question?.fields?.type)) {
        questionValues[`${question.id}`] = question?.rfqAnswer?.answer?.value
          ? question?.rfqAnswer?.answer?.value.map((ans) => ans.toString())
          : [];
      } else {
        questionValues[`${question.id}`] =
          question?.rfqAnswer?.answer?.value?.toString()
            ? question?.rfqAnswer?.answer?.value.toString()
            : null;
      }
    });
  });
  return {
    question_answer: { ...questionValues },
  };
};

export const initialAllValue = (returnableList, quoteList) => {
  return {
    ...initialReturnableValue(returnableList),
    quote_answer: quoteList,
  };
};

export const valuesPayload = (values, returnableList, isGuest) => {
  let returnablePayload = { ...values?.question_answer };
  let quotePayload = { ...values?.quote_answer };
  returnableList?.forEach((section) => {
    section.question?.forEach((question) => {
      const value = returnablePayload[question.id];
      if (
        value === "" &&
        (question?.fields?.type === "text" ||
          question?.fields?.type === "textarea")
      ) {
        returnablePayload[question.id] = "<p></p>";
      }
      if (Array.isArray(value)) {
        value.forEach((val, i) => {
          if (
            typeof val === "object" &&
            !Array.isArray(val) &&
            val !== null &&
            !(val instanceof File)
          ) {
            returnablePayload[question.id][i] = val?.file_name;
          }
        });
      }
      if (Array.isArray(value) && value?.length === 0) {
        returnablePayload[question.id] = { isRemove: 1 };
      }
      if (
        isGuest &&
        isSmartField(question?.fields?.type) &&
        question?.fields?.type !== "corporate_documents"
      ) {
        returnablePayload[question.id] = [value];
      }
    });
  });

  for (const quote in quotePayload) {
    const quoteValue = quotePayload[quote];
    quotePayload[quote].rate = quoteValue.rate || 0;
    quotePayload[quote].quantity = quoteValue.quantity || 0;
    quotePayload[quote].unit = quoteValue.unit || 1;
  }

  return {
    question_answer: returnablePayload,
    quote_answer: quotePayload,
  };
};

export const returnableSchema = (
  returnableList,
  isGuest,
  hasDocuments,
  hasPasProjects,
  hasResources
) => {
  let returnableSchema = {};

  const schemaCheck = (
    type,
    required,
    hasDocuments,
    hasPasProjects,
    hasResources
  ) => {
    if (isArray(type)) {
      return required
        ? Yup.array()
            .of(Yup.string("Required").required("Required"))
            .min(1, "Required")
        : Yup.array().of(Yup.string());
    } else if (isSmartField(type)) {
      if (isGuest) {
        if (type === "corporate_documents") {
          return required
            ? Yup.array()
                .of(Yup.mixed("Required").required("Required"))
                .test("empty", "Required", (value) => value?.length > 0)
            : Yup.array().of(Yup.mixed());
        } else {
          return required ? Yup.mixed().required("Required") : Yup.mixed();
        }
      } else {
        /**
         * if supplier but no documents show upload field
         */
        if (type === "corporate_documents" && !hasDocuments) {
          return required
            ? Yup.mixed().required("Required")
            : Yup.array().of(Yup.mixed());
        } else if (
          (type === "project_experience" && !hasPasProjects) ||
          (type === "key_personnel" && !hasResources)
        ) {
          /**
           * if supplier but no projects or resources show textarea
           */
          return required ? Yup.mixed().required("Required") : Yup.mixed();
        } else {
          return required
            ? Yup.array()
                .of(Yup.number().required("Required"))
                .min(1, "Required")
            : Yup.array().of(Yup.number());
        }
      }
    } else if (isFile(type)) {
      return required
        ? Yup.array()
            .of(Yup.mixed("Required").required("Required"))
            .test("empty", "Required", (value) => value?.length > 0)
        : Yup.array().of(Yup.mixed());
    } else {
      return required ? Yup.mixed().required("Required") : Yup.mixed();
    }
  };

  returnableList?.forEach((section) => {
    section.question?.forEach((question) => {
      const questionType = question?.fields?.type;
      if (
        isSmartField(questionType) &&
        ((questionType === 'corporate_documents' && !hasDocuments) 
        ||(questionType === 'project_experience' && !hasPasProjects) 
        ||(questionType === 'key_personnel' && !hasResources) ) &&
        !isGuest
      ) {
        returnableSchema[`${question.id}`] = Yup.object().shape({
          additional: schemaCheck(
            questionType,
            question.required,
            hasDocuments,
            hasPasProjects,
            hasResources
          ),
        });
      } else {
        returnableSchema[`${question.id}`] = schemaCheck(
          questionType,
          question.required,
          hasDocuments,
          hasPasProjects,
          hasResources
        );
      }

      // returnableSchema[`${question.id}`] = schemaCheck(
      //   question?.fields?.type,
      //   question.required
      // );
    });
  });

  return returnableSchema;
};
export const quotesSchema = (quoteList) => {
  let quotesSchema = {};

  quoteList.forEach((quote) => {
    quote.items.forEach((item) => {
      quotesSchema[item.id] = Yup.object().shape({
        quantity: quote.required
          ? Yup.number("Required")
              .required("Required")
              .test("is-positive", "Required", (value) => value > 0)
          : Yup.number("Required"),
        rate: quote.required
          ? Yup.number("Required")
              .required("Required")
              .test("is-positive", "Required", (value) => value > 0)
          : Yup.number("Required"),
        unit: quote.required
          ? Yup.number("Required")
              .required("Required")
              .test("is-positive", "Required", (value) => value > 0)
          : Yup.number("Required"),
      });
    });
  });
  return quotesSchema;
};

export const allValidationSchema = (
  returnable,
  quote,
  isGuest,
  hasDocuments,
  hasPasProjects,
  hasResources,
  isQuote,
  isReturnable,
) => {

  if (isQuote) {
    return Yup.object().shape({
      quote_answer: Yup.object().shape(quotesSchema(quote)),
    });
  }

  if(isReturnable) {
    return Yup.object().shape({
      question_answer: Yup.object().shape(
        returnableSchema(
          returnable,
          isGuest,
          hasDocuments,
          hasPasProjects,
          hasResources
        )
      ),
    });
  }

  return Yup.object().shape({
    question_answer: Yup.object().shape(
      returnableSchema(
        returnable,
        isGuest,
        hasDocuments,
        hasPasProjects,
        hasResources
      )
    ),
    quote_answer: Yup.object().shape(quotesSchema(quote)),
  });
};

export const touchFieldReturnable = (returnableList) => {
  const returnableSchedules = {};
  returnableList?.forEach((section) => {
    section.question?.forEach((question) => {
      returnableSchedules[`${question.id}`] = true;
    });
  });
  return returnableSchedules;
};
