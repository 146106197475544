import * as constants from '../actions/RFQ/actionTypes';

const INITIAL_RFQS_STATE = {
    rfqs:{},
    rfqCount: {},
    loading:false,
    error:'',
   
}

export const rfqsReducer = (state = INITIAL_RFQS_STATE, action) => {
    switch (action.type) {
        case constants.GET_RFQS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case constants.GET_RFQS_SUCCESS:
            return {
                ...state,
                loading: false,
                rfqs: action.payload,
                error:'',
            };

        case constants.GET_RFQ_FILTER_OPTIONS:
            return {
                ...state,
                filterOptions: action.payload,
            };

        case constants.GET_RFQS_FAILED:
            return {
                ...state,
                loading: false,
                rfqs: {},
                error: action.payload,
            };
        
        case constants.RESET_RFQ:
            return {
                ...state,
                rfqs: {},
            }

        case constants.SET_RFQ_FAVORITE: 
            return {
                ...state,
            }
        case constants.SET_RFQ_FAVORITE_SUCCESS: 
            return {
                ...state,
            }
        case constants.SET_RFQ_FAVORITE_FAILED: 
            return {
                ...state,
                error: action.payload,
            }
        case constants.SET_RFQ_ARCHIVED: 
            return {
                ...state,
            }
        case constants.SET_RFQ_ARCHIVED_SUCCESS: 
            return {
                ...state,

            }
        case constants.SET_RFQ_ARCHIVED_FAILED: 
            return {
                ...state,

                error: action.payload,
            }
        case constants.POST_NEW_RFQ_REQUEST: 
            return {
                ...state,
                loading: true
            }
        case constants.POST_NEW_RFQ_SUCCESS: 
            return {
                ...state,
                loading: false
            }
        case constants.POST_NEW_RFQ_FAILED: 
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case constants.IS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        case constants.SET_RFQ_BADGES_COUNT: 
            return {
                ...state,
                rfqCount: action.payload,
            }
        
        default:
            return state;
    }
};