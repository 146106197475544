import React, { useEffect, useState } from "react";
import styled from "styled-components";
import api from "api";
import { DataTable } from "../../../components/DataTable";
import { ResourceLink } from "../../../components/ResourceLink";
import { Toolbar } from "../../../components/Toolbar";
import { ButtonLink } from "../../../components/ButtonLink";
import { NoResults } from "../../../components/NoResults";
import { SearchBox } from "../../../components/SearchBox";
import { SkeletonBase } from "components/Skeleton";
import { getResources } from "actions/companyActions";
import { useDispatch } from "react-redux";
export const ProjectsRow = styled.div`
  display: flex;
`;

export const Resources = ({
  resources,
  companyId,
  isRequesting,
  search,
  adminControls,
  ...props
}) => {
  const [searchResults, setSearchResults] = useState([...resources]);
  const [isSearching, setIsSearching] = useState(true);
  const currentCompanyId = localStorage.getItem("company_id")  
  const dispatch = useDispatch();
  const tableHeaders = [
    "",
    { label: "Name", key: "first_name" },
    { label: "Position", key: "position" },
    { label: "Industry Experience", key: "experience" },
    { label: "Top 3 Sectors", key: "sectors[0].name" },
    "",
  ];

  const onFilterSubmit = async (values) => {
    const { search, sector } = values;
    setIsSearching(true);

    try {
      const filteredProjects = await api
        .get(
          `companies/${currentCompanyId}/key-personnel?filter[company_id]=${companyId}&search=${search}&filter[sector_ids]=${sector}`
        )
        .json();
      setSearchResults([...filteredProjects.data]);
    } catch (e) {
      console.log(e);
    }

    setIsSearching(false);
  };

  useEffect(() => {
    const dispatchResources = async () => {
      await dispatch(getResources(companyId));
      setIsSearching(false);
  }
    dispatchResources();

  }, [companyId, dispatch]);

  return (
    <>
        <Toolbar>
          {adminControls && <ButtonLink style={{maxHeight:"48px"}} to={`/account/${companyId}/company-profile/new-resource`}>Add Key Personnel</ButtonLink>}

          {search && (
            <SearchBox
              placeholder="Search resources"
              handleFormSubmit={onFilterSubmit}
              searchInput
              sectorFilter
              multiSector
              whiteInput
            />
          )}
        </Toolbar>

        {/* todo: clean this logic up - need to separate out component into container - use initial value with useState properly */}
        {!isSearching ? (
          searchResults.length > 0 ? (
            <DataTable
              headers={tableHeaders}
              data={searchResults}
              component={ResourceLink}
              componentProps={{
                className: "col sm-12",
                edit: adminControls ? true : false,
              }}
            />
          ) : (
            <NoResults />
          )
        ) : (
          [...Array(5)].map((e,i) => (
            <div className="flex gap-3 items-center px-4 justify-between bg-gray-100 w-full h-[72px] my-4 py-1.5 ">
                <SkeletonBase key={i} width="60px" height="55px" />
                <SkeletonBase key={i} width="80%" height="36px" />
                <SkeletonBase key={i} width="135px" height="48px" />
            </div>
            
         ))
        )}
    </>
  );
};

Resources.defaultProps = {
  search: true,
  adminControls: true,
};
