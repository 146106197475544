import React from "react";
import styled from "styled-components";

import { IconBoxStyled } from "../IconBox/styled";

export const ContextMenuStyled = styled.div`
  display: flex;
  flex-direction: column;
  z-index:9;
  overflow: 'none';
  background-color: ${(props) =>  (props.collapse?'#003CE9':'rgba(249, 250, 251, 1)' )};
  padding: 0.25rem 0.85rem;
  padding-right: 0px;
  padding-left: 0px;
  left: 0px;
  border-left: 12px #003CE9 solid;
  width: ${(props) => props.collapse? '68px': '380px'};
  position: relative;
  height: 100vh;
  flex-shrink: 0;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  transition: all 0.4s ease-in-out;
  filter: drop-shadow(4px 1px 8px rgba(16, 24, 40, 0.09));
  

  hr {
    border-top: 1px solid #5b6b88;
    opacity: 0.2;
    margin-left: 0;
    margin-right: 0;
  }

  ${IconBoxStyled} {
    border-top-right-radius: ${(props) => props.collapse ? '4px' :'0px'};
    border-bottom-right-radius: ${(props) => props.collapse ? '4px' :'0px'};
  }

  .active-link {
    svg {
      color: ${(props) => props.collapse ? 'white' :props.theme.colors.royalBlue};
      rect {
        stroke: ${(props) => props.theme.colors.royalBlue};
      }
    }
  }
  .profile-share {
    margin-top: auto;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;

    ${IconBoxStyled} {
      margin-right: ${(props) => props.theme.space.xs};
      margin-left: ${(props) => props.theme.space.xs};
      padding: 0;
    }
    span {
      font-size: ${(props) => props.theme.fontSizes.md};
      color: ${(props) => props.theme.colors.navy};
      cursor: pointer;
    }
    &::hover {
      svg {
        color: ${(props) => props.collapse ? 'white' :props.theme.colors.royalBlue};
      }
      span {
        color: ${(props) => props.collapse ? 'white' :props.theme.colors.royalBlue};
      }
    }
  }

  .scroll-container{
    overflow: auto;
    -webkit-box-shadow: 5px 26px 25px -17px rgba(0,0,0,0.2);
    -moz-box-shadow: 5px 26px 25px -17px rgba(0,0,0,0.2);
    box-shadow: 5px 26px 25px -17px rgba(0,0,0,0.2);
    ::-webkit-scrollbar {
      
    }
    ::-webkit-scrollbar-track {
      background : ${(props) => props.collapse ?props.theme.colors.royalBlue: 'white' };
    }
    ::-webkit-scrollbar-thumb {
      background : ${(props) => props.collapse ? 'white' : props.theme.colors.royalBlue };
      border-radius: 10px;
      border: 3px solid #ffffff;
    }
  }
  .scroll-container::-webkit-scrollbar{
    width: ${(props) => props.collapse ? "6px ": '6px' };
  }
  .scroll-container::-webkit-scrollbar-thumb {
      background : ${(props) => props.collapse ? 'white' : props.theme.colors.royalBlue };
      border-radius: 10px;
      border: ${(props) => props.collapse && `solid 2px ${props.theme.colors.royalBlue}` };
  }
  .menu-bar{
    cursor:pointer;
    padding:10px 0;
    display:flex;
    padding-left:10px;
    align-items:center; 
    gap:10px;
    :hover{
      background-color: rgba(0, 69, 245, 0.2);
    }
  }
`;

export const ChevronIconStyled = styled.div`
    place-self: center;
    /* position: absolute; */
    right: -10px;
    top:50px;
    z-index: 9999;
    margin-bottom: 20px;
    padding: 10px;
    position: fixed;
    background-color: ${(props) => props.collapse ? props.theme.colors.royalBlue :'#F9FAFB'};
    border-radius: 7px;
    box-shadow: ${(props) => props.collapse ? 'none':'16px 8px 4px -4px rgba(16, 24, 40, 0.03)'};
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    &:hover {
    ${(props) => props.collapse ? `
      background-color: ${props.theme.colors.royalBlue};
      padding-left: 20px;
      right: -30px;
    ` : `
      padding-right: 20px;
      background-color: rgba(222, 235, 255, 0.5); /* Converted #DEEBFF to rgba with 50% opacity */
    `};
  }
    
`;

export const AccountBadge = styled((props) => {
  return (
    <div className={props.className}>
      <img
        src={
          props.logo ||
          `https://ui-avatars.com/api/?name=${props.name}&color=fff&background=000`
        }
        alt={props.name}
      />
      {props.collapse?"":props.name}
    </div>
  );
})`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: ${(props) => props.theme.colors.navy};
  font-size: ${(props) => props.theme.fontSizes.xl};
  margin-bottom: ${(props) => props.theme.space.lg};
  padding-right: ${(props) => props.theme.space.sm};
  margin-left: ${(props) => props.theme.space.xs};
  margin-top: 32px;
  img {
    width: 40px;
    height: 40px;
    border-radius: ${(props) => props.theme.radii.default};
    margin-right: ${(props) => props.theme.space.xs};
    object-fit: contain;
  }
`;


