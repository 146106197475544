import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";
import { useSelector, useDispatch } from "react-redux";
import _isEmpty from "lodash.isempty";
import { SearchTemplate } from "../../layout/SearchTemplate";
import { SearchResults } from "./SearchResults";
import { FullDraw } from "../../components/FullDraw";
import { SearchShortlist } from "./SearchShortlist";
import { connect } from "react-redux";
import { useLocation } from "@reach/router";
import {
  getGroup,
  searchCompanies,
  removeFromList,
  addProjectToCompareCard,
  removeProjectFromCompareCard,
  getCompareList,
  getShortlist,
  setAddProjectIndex,
  addALLToListCustom,
} from "../../actions/searchActions";
import { getPastProjects } from "../../actions/companyActions";
import { toast } from "react-toastify";
import apiV2 from "api-v2";
import { SkeletonBase } from "components/Skeleton";
import { sidebarCollapse as sidebarCollapseAction } from "actions/sidebarActions";

const SearchContainerConnected = ({
  results,
  compareList,
  compareListCompanies,
  shortlist,
  groupId,
  getGroup,
  searchCriteria,
  searchCompanies,
  isSearching,
  disciplines,
  group,
  sectors,
  types,
  hasSearched,
  lockedSearchItems,
  lockedCompareItems,
  removeFromList,
  getPastProjects,
  pastProjects,
  searchMeta,
  isLoadingNext,
  getCompareList,
  getShortlist,
  trades,
  specialities,
  supplierlist,
  ...props
}) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  let pagesSplit = location.pathname.split("/");
  const lastPage = pagesSplit[pagesSplit.length - 1];
  const [resultFiltered, setResultFiltered] = useState([]);
  const [shortlistFiltered, setShortListFiltered] = useState(shortlist);
  const [isOpen, setIsOpen] = useState(false);
  const [companyProjectData, setCompanyProjectData] = useState({});
  const [showCompanyProjects, setCompanyProjectsView] = useState(false);
  const [companyPersonnel, setCompanyPersonnel] = useState({});
  const [showCompanyPersonnel, setCompanyPersonnelView] = useState(false);
  const { sidebarcollapse } = useSelector((state) => state.sidebar);
  const [compareProjectHeight, setCompareProjectHeight] = useState(50);
  const addProjectIndexes = useSelector(
    (state) => state.search.addProjectIndex
  );
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.auth.user.company_id);

  useEffect(() => {
    if (!sidebarcollapse) {
      setIsOpen(false);
    }
  }, [sidebarcollapse]);

  useEffect(() => {
    if (!supplierlist?.directcompare) {
      setShortListFiltered(shortlist);
    }
    //eslint-disable-next-line
  }, [shortlist]);

  const handleAddProjectClick = async (project, index) => {
    if (addProjectIndexes.length === 0) {
      toast.error("No more room for projects");
      return false;
    }
    const res = await dispatch(
      addProjectToCompareCard(
        groupId,
        project.company_id
          ? project.company_id
          : project.company && project.company.id,
        project,
        addProjectIndexes[0]
      )
    );

    if (res) {
      addProjectIndexes.shift();
      dispatch(setAddProjectIndex(addProjectIndexes));
      toast.success("Project successfully added to compare view");
    } else toast.error("Unable to add project");
  };

  // TODO: Not being used at the moment.
  const handleRemoveProjectClick = async (project, index) => {
    const res = await dispatch(
      removeProjectFromCompareCard(
        groupId,
        project.company_id
          ? project.company_id
          : project.company && project.company.id,
        project,
        index
      )
    );

    if (res) {
      toast.success("Project successfully removed from compare view");
    } else toast.error("Unable to remove project");
  };

  useEffect(() => {
    if (groupId !== "direct") {
      if (groupId && !group.search_criteria) {
        getGroup(groupId);
        getCompareList(parseInt(groupId));
        getShortlist(parseInt(groupId));
        setLoading(false);
      }

      if (!_isEmpty(searchCriteria) && !hasSearched) {
        let search = searchCriteria;
        let newSearchCriteria = { ...search, group_id: parseInt(groupId) };

        (async () => {
          await searchCompanies(newSearchCriteria, true);
        })();
      }
    }

    // eslint-disable-next-line
  }, [getGroup, group, groupId]);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        if (supplierlist?.directcompare === true) {
          let payload = {
            companyId: companyId,
            supplierIds: supplierlist?.suppliers,
          };
          let resCompare = await apiV2.post(
            `/supplier/${supplierlist?.id}/compare`,
            payload,
            { signal: abortController.signal }
          );
          resCompare = resCompare?.data?.data;
          let formatedCompare = [];
          for (let a = 0; a < resCompare.length; a++) {
            resCompare[a].company.projects = resCompare[a].projects;
            formatedCompare.push(resCompare[a].company);
          }
          dispatch(addALLToListCustom("compare", formatedCompare));
          setLoading(false);
        } else {
          setResultFiltered(results);
          setLoading(false);
        }
      } catch (error) {}
    };
    fetchData();
    return () => {
      abortController.abort();
    };
    //eslint-disable-next-line
  }, [supplierlist]);

  const compareSlideoutClick = (items, type, companyId) => {
    if (!sidebarcollapse) {
      dispatch(sidebarCollapseAction(true));
    }
    if (type === "projects") {
      //fetch company projects
      getPastProjects(companyId);

      //grab all the project id's we've got in our compare card for the company, so we can test if the add button should be disabled
      const compareProjects =
        compareList && compareList.length
          ? compareList.flatMap((company) =>
              companyId === company.id ? company.compare_projects : []
            )
          : [];

      setCompanyProjectData({ companyId, compareProjects });
      setCompanyProjectsView(true);
      setCompanyPersonnelView(false);
      setIsOpen(true);
    } else if (type === "personnel") {
      setCompanyPersonnel({ companyId, data: items });
      setCompanyProjectsView(false);
      setCompanyPersonnelView(true);
      setIsOpen(true);
    }
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const hasReachedHorizontalEnd = (element) => {
    let scrollLeft = element.scrollLeft;
    let scrollWidth = element.scrollWidth;

    //trigger 150px before reaching end of scroll div
    if (
      Math.ceil(scrollWidth - scrollLeft - 150) <=
      Math.ceil(element.getBoundingClientRect().width)
    ) {
      return true;
    }

    return false;
  };

  const handleResultsScroll = (element) => {
    if (hasReachedHorizontalEnd(element)) {
      if (searchMeta.current_page < searchMeta.last_page && !isLoadingNext) {
        searchCompanies(searchCriteria, true, searchMeta.current_page + 1);
      }
    }
  };

  const removeFromCompareListFunc = (companyId) => {
    let filteredResult = [...resultFiltered];
    filteredResult = filteredResult.filter((f) => {
      return f.id !== companyId;
    });
    setResultFiltered(filteredResult);
  };

  useEffect(() => {
    setResultFiltered(results);
  }, [results]);

  useEffect(() => {
    if (isSearching) {
      setLoading(true);
    } else {
      if (!supplierlist?.directcompare) {
        setLoading(false);
      }
    }
  }, [isSearching, supplierlist]);

  useEffect(() => {
    /**
     * set height for No. of projects relevant to sector column
     * if have data => 1560px
     * if no data => 50px
     */
    let currentHeight = 50;
    if (compareList.length > 0) {
      for (let a = 0; a < compareList.length; a++) {
        if (!supplierlist?.directcompare) {
          if (compareList[a]?.compare_projects.length > 0) {
            for (let b = 0; b < compareList[a]?.compare_projects?.length; b++) {
              if (compareList[a]?.compare_projects[b] !== null) {
                currentHeight = 1560;
              }
            }
          }
        } else {
          if (compareList[a]?.projects.length > 0) {
            for (let b = 0; b < compareList[a]?.projects?.length; b++) {
              currentHeight = 1560;
            }
          }
        }
      }
      setCompareProjectHeight(currentHeight);
    }
  }, [compareList, supplierlist]);

  return (
    <>
      <SearchTemplate
        resultsCount={
          supplierlist?.directcompare ? resultFiltered.length : searchMeta.total
        }
        compareCount={
          supplierlist?.directcompare
            ? resultFiltered.length
            : compareList
              ? compareList.length
              : 0
        }
        shortlistCount={shortlistFiltered ? shortlistFiltered.length : 0}
        shortlist={shortlistFiltered}
        noDataTable={["shortlist"].includes(lastPage)}
        searchCriteria={searchCriteria}
        disciplines={disciplines}
        sectors={sectors}
        types={types}
        groupId={groupId}
        trades={trades}
        specialities={specialities}
        supplierlist={supplierlist}
        loading={loading}
      >
        {loading ? (
          <div className="mt-5 pt-5">
            <div className="flex ml-5 pl-5">
              <div className="ml-2 mr-2">
                <SkeletonBase width="300px" height="236px" />
                <div className="mt-3 mb-2">
                  <SkeletonBase width="300px" height="36px" />
                </div>
                <div className="mt-3 mb-2">
                  <SkeletonBase width="300px" height="36px" />
                </div>
              </div>
              <div className="ml-2 mr-2">
                <SkeletonBase width="300px" height="236px" />
                <div className="mt-3 mb-2">
                  <SkeletonBase width="300px" height="36px" />
                </div>
                <div className="mt-3 mb-2">
                  <SkeletonBase width="300px" height="36px" />
                </div>
              </div>
              <div className="ml-2 mr-2">
                <SkeletonBase width="300px" height="236px" />
                <div className="mt-3 mb-2">
                  <SkeletonBase width="300px" height="36px" />
                </div>
                <div className="mt-3 mb-2">
                  <SkeletonBase width="300px" height="36px" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <Router
              primary={false}
              style={{
                width: "100%",
                backgroundColor: "#F4F5F7",
                flexGrow: 1,
                paddingTop: "50px",
              }}
            >
              {!loading && (
                <>
                  <SearchResults
                    path="results/*"
                    results={resultFiltered}
                    shortlistFiltered={shortlist}
                    lockedItems={lockedSearchItems}
                    compareSlideoutClick={compareSlideoutClick}
                    isResults
                    cardType="search"
                    handleScrollEnd={handleResultsScroll}
                    isLoadingNext={isLoadingNext}
                    directcompare={
                      supplierlist?.directcompare
                        ? supplierlist?.directcompare
                        : "false"
                    }
                    removeFromCompareListFunc={removeFromCompareListFunc}
                  />
                  <SearchResults
                    path="compare/*"
                    results={compareList}
                    shortlistFiltered={shortlist}
                    compareList={compareList}
                    compareSlideoutClick={compareSlideoutClick}
                    lockedItems={lockedCompareItems}
                    isCompare
                    cardType="compare"
                    isFullDrawOpen={isOpen}
                    directcompare={
                      supplierlist?.directcompare
                        ? supplierlist?.directcompare
                        : "false"
                    }
                    removeFromCompareListFunc={removeFromCompareListFunc}
                    compareProjectHeight={compareProjectHeight}
                    loading={loading}
                  />
                  <SearchShortlist
                    path="shortlist/*"
                    items={shortlist}
                    removeFromList={removeFromList}
                    groupId={groupId}
                    directcompare={
                      supplierlist?.directcompare
                        ? supplierlist?.directcompare
                        : "false"
                    }
                  />
                </>
              )}
            </Router>
          </>
        )}

        <FullDraw
          companyProjectData={companyProjectData}
          showCompanyProjects={showCompanyProjects}
          companyPersonnel={companyPersonnel}
          showCompanyPersonnel={showCompanyPersonnel}
          handleAddProjectClick={handleAddProjectClick}
          handleRemoveProjectClick={handleRemoveProjectClick}
          isOpen={isOpen}
          groupId={groupId}
          toggleOpen={toggleOpen}
          sectors={sectors}
          pastProjects={pastProjects}
        />
      </SearchTemplate>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isSearching: state.search.isSearching,
    results: state.search.searchResult,
    searchMeta: state.search.searchMeta,
    isLoadingNext: state.search.isLoadingNext,
    group: state.search.group,
    searchCriteria: state.search.searchCriteria,
    hasSearched: state.search.hasSearched,
    disciplines: state.search.disciplines,
    types: state.search.types,
    sectors: state.sectors,
    pastProjects: state.companyProfile.pastProjects,
    compareList: state.search.compareList,
    trades: state.companyProfile.trades,
    specialities: state.companyProfile.specialities,
  };
};

export const SearchContainer = connect(mapStateToProps, {
  getGroup,
  searchCompanies,
  removeFromList,
  getPastProjects,
  getCompareList,
  getShortlist,
})(SearchContainerConnected);
