import * as constants from '../actions/RFQ/actionTypes';

const INITIAL_PROJECTS_STATE = {
    projects:{},
    favorites:{
        project_ids:[],
        loading: false,
    },
    projectCount:{},
    loading:false,
    error:'',
}

export const projectReducer = (state = INITIAL_PROJECTS_STATE, action) => {
    switch (action.type) {
        case constants.GET_PROJECTS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case constants.GET_PROJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                projects: action.payload,
                error:'',
            };
        case constants.GET_PROJECTS_FILTER_OPTIONS:
            return {
                ...state,
                filterOptions : action.payload,
            };
        case constants.GET_PROJECTS_FAILED:
            return {
                ...state,
                loading: false,
                projects: {},
                error: action.payload,
            };

        case constants.RESET_PROJECTS:
             return {
                 ...state,
                 projects: {},
             }
        case constants.GET_PROJECT_FAVORITES_REQUEST:
            return {
                ...state,
                favorites: {
                    ...state.favorites,
                    loading: 'loading',
                },
            };
        case constants.GET_PROJECT_FAVORITES_FAILED:
                return {
                    ...state,
                    favorites: {
                        ...state.favorites,
                        loading: 'idle',
                    },
                };
        case constants.GET_PROJECT_FAVORITES_SUCCESS:
            return {
                ...state,
                favorites: {
                    project_ids: action.payload ?? [],
                    loading: 'idle',
                },
            };
        case constants.SET_PROJECT_FAVORITE: 
            return {
                ...state,
            }
        case constants.SET_PROJECT_FAVORITE_SUCCESS: 
            return {
                ...state,
                favorites: {
                    project_ids: action.payload ?? [],
                    loading: 'idle',
                },
                projects: {
                    ...state.projects,
                    favourite: {
                        ...state.projects.favourite,
                        meta : {...state.projects.favourite.meta, total: state.projects.favourite.meta.total + 1}
                    }
                },
                projectCount: {
                    ...state.projectCount,
                    favourite: state.projectCount.favourite + 1
                }
            }
        case constants.SET_PROJECT_UNFAVORITE_SUCCESS: 
        return {
            ...state,
            favorites: {
                project_ids: action.payload ?? [],
                loading: 'idle',
            },
            projects: {
                ...state.projects,
                favourite: {
                    ...state.projects.favourite,
                    data: state.projects.favourite?.data?.filter((data)=> !action.payload.includes(data.id)),
                    meta : {...state.projects.favourite.meta, total: state.projects.favourite.meta.total - action.payload.length || '0'}
                }
            },
            projectCount: {
                ...state.projectCount,
                favourite: state.projectCount.favourite - action.payload.length || '0'
            }
        }
        case constants.SET_PROJECT_FAVORITE_FAILED: 
            return {
                ...state,
                error: action.payload,
            }
        case constants.SET_PROJECT_ARCHIVED: 
            return {
                ...state,
            }
        case constants.SET_PROJECT_ARCHIVED_SUCCESS: 
            return {
                ...state,

            }
        case constants.SET_PROJECT_ARCHIVED_FAILED: 
            return {
                ...state,

                error: action.payload,
            }
        case constants.POST_NEW_PROJECT_REQUEST: 
            return {
                ...state,
                loading: true
            }
        case constants.POST_NEW_PROJECT_SUCCESS: 
            return {
                ...state,
                loading: false
            }
        case constants.POST_NEW_PROJECT_FAILED: 
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case constants.IS_LOADING:
            return {
                ...state,
                loading: action.payload,
            };

        case constants.SET_PROJECT_BADGES_COUNT:
            return {
                ...state,
                projectCount: action.payload,
            };

        
        
        default:
            return state;
    }
};