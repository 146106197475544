import React from 'react';
import { cn } from 'utils/helpers';

export const ToggleButton = ({ size = 'default', className, label, description, children, ...props }) => {
    const sizes = {
        default: 'w-9 h-5 after:h-4 after:w-4',
        lg: 'w-11 h-6 after:h-5 after:w-5',
    };

    return (
        <div>
            <label class={cn('inline-flex cursor-pointer gap-2', !description && 'items-center align-middle')}>
                <div>
                    <label className={cn('text-sm font-semibold flex items-center h-max gap-3 cursor-pointer', description && 'items-start')}>
                    {label}
                        <input type="checkbox" value="" class="sr-only peer" {...props} />
                        <div
                            class={cn(
                                "relative bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-brand-50 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:transition-all after:shadow-sm dark:border-gray-600 peer-checked:bg-royal-blue",
                                sizes[size],
                                className
                            )}
                        ></div>
                        <div>
                            <p className="text-sm font-normal">{description}</p>
                        </div>
                    </label>
                </div>
                {children}
            </label>
        </div>
    );
};