export const fieldTypeQuestion = {
    1: {
      type: 'short',
      description: 'Maximum of 500 characters.'
    },
    2: {
      type: 'long',
      description: 'Maximum of 1500 characters.'
    },
    3: {
      type: 'singleChoice',
      description: null
    },
    4: {
      type: 'multipleChoice',
      description: null
    },
    5: {
      type: 'dropdown',
      description: null
    },
    6: {
      type: 'date',
      description: 'Single-date selection'
    },
    7: {
      type: 'address',
      description: null
    },
    8: {
      type: 'projectexp',
      description: null
    },
    9: {
      type: 'keypersonel',
      description: null
    },
    10: {
      type: 'corporatedoc',
      description: null
    },
    11: {
      type: 'file',
      description: null
    },
  }