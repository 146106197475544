import {
    Accordion,
    AccordionItem,
    AccordionButton,
    Box,
    AccordionIcon,
    AccordionPanel,
  } from "@chakra-ui/react";
  
  import React from "react";
  import tw, { styled } from "twin.macro";
  import { Button } from "../Button";
  import { MultipleCheckbox } from "../MultipleCheckbox";
  
  const AccordionButtonStyled = styled(AccordionButton)(() => [
    tw`px-0 py-4 text-black font-semibold hover:bg-transparent`,
  ]);
  
  const AccordionIconStyled = styled(AccordionIcon)(() => [
    tw`w-5 h-5 text-fq-quinary-400 `,
  ]);
  
  const AccordionItemStyled = styled(AccordionItem)(() => [tw`px-5 border-b`]);
  
  const AccordionPanelStyled = styled(AccordionPanel)(() => [tw`pb-5 p-0`]);
  
  const AccordionFooterStyled = styled.div(() => [
    tw`fixed bottom-0 left-0 w-full bg-white p-6`,
  ]);
  
  const TitleComp = ({ title }) => {
    return (
      <div
        className="font-roboto font-semibold text-text-primary-900"
        style={{ fontSize: "14px", lineHeight: "20px" }}
      >
        {title}
      </div>
    );
  };
  
  const AccordionFilter = ({
    setIsSubmiting,
    filterOptions,
  }) => {
    return (
      <div>
        <Accordion defaultIndex={[0]} allowMultiple>
          {filterOptions.map((filter, index) => (
              <AccordionItemStyled key={`filter-${index}`}>
                <h2>
                  <AccordionButtonStyled>
                    <Box as="span" flex="1" textAlign="left">
                      <TitleComp title={filter.title}></TitleComp>
                    </Box>
                    <div style={{ marginTop: "-30px" }}></div>
                    <AccordionIconStyled />
                  </AccordionButtonStyled>
                </h2>
                <AccordionPanelStyled>
                  <MultipleCheckbox checkboxes={filter.item} name={filter.name} />
                </AccordionPanelStyled>
              </AccordionItemStyled>
            ))
            }
          <AccordionFooterStyled>
            <Button
              size="full"
              type="submit"
              onClick={() => setIsSubmiting(true)}
            >
              Apply
            </Button>
          </AccordionFooterStyled>
        </Accordion>
      </div>
    );
  };
  
  export default AccordionFilter;
  