import api from 'api';
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { STATUS_ACTIVE, STATUS_DRAFT } from './constants';
import moment from 'moment';


export function cn(...inputs) {
    return twMerge(clsx(inputs));
}

export const filenameSpliter = (file) => {
    const filename = file;
    const ext = filename?.split('.').pop();
    return {
        filename: filename,
        ext: ext,
    };
};

export const processFormData = (values) => {
    const formData = new FormData();

    for (var key in values) {
        let value = values[key];
        if (Array.isArray(value)) {
            const valueKey = key;

            if (value.length > 0) {
                value.forEach((item, index) => {
                    let itemData =
                        typeof item === 'object' && !(item instanceof File) && item !== null
                            ? JSON.stringify(item)
                            : item;

                    //setting the array index is the major 🔑 here
                    formData.append(`${valueKey}[${index}]`, itemData);
                });
            } else {
                formData.append(valueKey, '[]');
            }
        } else if (typeof value === 'object' && !(value instanceof File) && value !== null) {
            let valueJson = JSON.stringify(value);
            formData.append(key, valueJson);
        } else {
            formData.append(key, value);
        }
    }
    return formData;
};
export const getApiWithToken = () => {
    const token = localStorage.getItem('access_token');
    return api.extend({
        hooks: {
            beforeRequest: [
                (request) => {
                    request.headers.set('Authorization', `Bearer ${token}`);
                }
            ]
        }
    });
}
export const equalizeHeights = (elements) => {
    const elementHeights = Array.prototype.map.call(elements, (el) => {
        return el.clientHeight;
    });

    var maxHeight = Math.max(...elementHeights);

    Array.prototype.forEach.call(elements, (el) => (el.style.height = `${maxHeight}px`));
};

export const capitalise = (text = '') => {
    if (typeof text === 'string') {
        return `${text.charAt(0).toUpperCase()}${text.replace(/(_)/g, ' ').slice(1)}`
    } else {
        return ""
    }
}

export const formatNumber = (number) => number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const shortenDigits = (labelValue) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

        ? parseFloat(Math.abs(Number(labelValue)) / 1.0e+9).toFixed(0) + "B"
        // Six Zeroes for Millions 
        : Math.abs(Number(labelValue)) >= 1.0e+6

            ? parseFloat(Math.abs(Number(labelValue)) / 1.0e+6).toFixed(0) + "M"
            // Three Zeroes for Thousands
            : Math.abs(Number(labelValue)) >= 1.0e+3

                ? parseFloat(Math.abs(Number(labelValue)) / 1.0e+3).toFixed(0) + "K"

                : Math.abs(Number(labelValue));
}

export const getSlicedDocuments = (documents, category, isCompare) => {
    if (!isCompare)
        return documents.slice(0, 2);
    switch (category) {
        case 1:
        case 2:
            return documents.slice(0, 2);
        case 3:
            return documents.slice(0, 3);
        default:
            return documents;
    }
}

export const isValidAbnOrAcn = (number) => {
    number = number.toString().replaceAll(' ', '');
    if (number.length === 9) {
        return isValidAcn(number);
    }
    if (number.length === 11) {
        return isValidAbn(number);
    }
    return false;
}

const isValidAbn = (abn) => {
    const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];
    abn = abn.toString();
    if (abn.length === 11) {
        let sum = 0;
        for (let i = 0; i < weights.length; i++) {
            let digit = abn[i] - (i ? 0 : 1);
            sum += (digit * weights[i]);

        }
        return (sum % 89) === 0;
    }
    return false;
}

const isValidAcn = (acn) => {
    const weights = [8, 7, 6, 5, 4, 3, 2, 1, 0];
    acn = acn.toString();
    if (acn.length === 9) {
        let sum = 0;
        for (let i = 0; i < weights.length; i++) {
            sum += (acn[i] * weights[i]);
        }
        let check = (10 - (sum % 10)) % 10;
        return parseInt(acn[8]) === parseInt(check);
    }
    return false;
}

export const formatABN = (val) => {
    let copy = val;
    val = val.replace(/[^\dA-Z]/g, '');
    let reg = new RegExp(".{3}", "g");
    if (val.length <= 9) {
        val = val.replace(reg, function (a) {
            return a + ' ';
        });
    } else if (val.length > 9 && val.length <= 11) {
        let firstTwo = val?.substr(0, 2);
        val = val.slice(2);
        val = val.replace(reg, function (a) {
            return a + ' ';
        });
        val = firstTwo + ' ' + val;
    } else {
        val = copy;
    }

    return val.trim();
}

// mark properties of rules true if password is strong
// At least one character, capital character, one number, at least 8 digit
export const getPasswordStrength = (password, rule, isPAPLUser) => {
    if (!password)
        return rule;
    rule.number = password.match(/\d+/g) ? true : false;
    rule.letter = password.match(/[a-zA-Z]/g) ? true : false;
    rule.capital = password.match(/[A-Z]/g) ? true : false;
    rule.count = password.length >= (isPAPLUser ? 15 : 8) ? true : false;
    rule.specialCharacter = password.match(
        /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).*$/
    )
        ? true
        : false;
    return rule;
}

export const profileTypeOptions = [

    {
        value: 1,
        label: 'Client'
    },
    {
        value: 4,
        label: 'Consultant'
    },
    {
        value: 2,
        label: 'Head Contractor'
    },
    {
        value: 3,
        label: 'Subcontractor'
    }
];

export const userRoles = [
    {
        value: 'administrator',
        label: 'Admin User'
    },
    {
        value: 'user',
        label: 'User'
    }
];

export const findUserRole = (value) => userRoles.find(item => item.value === value);

export const isAdmin = (user) => {
    return (JSON.parse(localStorage.getItem('is_admin'))) === true ||
        (user && (user.company_admin || user.position === 'administrator'));
}

export const isSuperAdmin = () => {
    return (JSON.parse(localStorage.getItem('is_admin'))) === true;
}

export const isProcurer = (company) => {
    return company && company.grouping === 'procurer';
}

export const isBidder = (company) => {
    return company && company.grouping === 'bidder';
}

export const isProcurerNBidder = (company) => {
    return company && company.grouping === 'bidder_procurer';
}

export const isClient = (profileTypeId) => {
    return profileTypeId && profileTypeId === 1;
}


/*
    @param old data and new data
    
    This function store sign up data in local storage to avoid loss 
    of data during page refresh in multistep form.

    First it gets previously saved data then merge it with old and new data 
    stringfy object and store back in storage
*/
export const preserveSignUpData = (data, newData) => {
    localStorage.setItem('signup_data', JSON.stringify({ ...JSON.parse(localStorage.getItem('signup_data')), ...data, ...newData }));
}

export const isEmptyObj = (obj) => {
    if (obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype)
        return true;
    return false;
}

export const clearSignupData = () => {
    localStorage.removeItem("signup_data");
    localStorage.removeItem("signup_step");
}

export const arrowLeftIcon = "../src/icons/chevron-left.svg";

export const htmlToText = (html) => {
    return html.replace(/<[^>]*>/g, ' ');
}

export const removeCarriage = (string) => {
    if (string) {
        return string.replace(/\r/g, '');
    } else {
        return '';
    }
}

export const downloadFileObject = async (file) => {
    const url = URL.createObjectURL(file)
    const a = document.createElement('a');
    a.href = url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

function implementImageSRC(url, callback) {
    const img = new Image();
    img.onload = function () {
        callback(false);
    };
    img.onerror = function () {
        callback(true);
    };
    img.src = url;
}

function checkingIsBroken(imageUrl) {
    return new Promise((resolve) => {
        implementImageSRC(imageUrl, (isBroken) => {
            resolve(isBroken);
        });
    });
}

export const isImageBroken = async (imageUrl) => {
    // ignore default image from backend
    if (imageUrl) {
        if (imageUrl.includes("JjItXLpsDvuZCz8mrXvcgchzZL4qgMGaVamxLcQO.png" || imageUrl.includes("usUqYOVKMloCsoUYaqWKHtzE3xeicxsJ9Y6UEiF4.png"))) {
            return true
        }
        let isBroken = await checkingIsBroken(imageUrl);
        if (isBroken === true) {
            return true
        } else {
            return false
        }
    } else {
        return true
    }
}

export const isValidURL = (url) => {
    return url.startsWith('http://') || url.startsWith('https://');
};

export const getFileNameFromUrl = (url) => {
    if(isValidURL(url)){
        const parts = url.split('/');
        return parts[parts.length - 1];
    }else{
        return url || ' - ';
    }
};
export const getCurrentURL = () => {
    var fullURL = window.location.href;
    var lastChar = fullURL.charAt(fullURL.length - 1);
    if (lastChar === "/") {
        return fullURL
    } else {
        return fullURL+"/"
    }
}

export const timezoneChecker = (timezone) => {
    return timezone === 'AEST'
        ? "Australia/Sydney" 
        : "Australia/Perth"
}

export const grabFirstError = (errors) => {
    if (typeof errors === 'object' && errors !== null) {
        for (let error in errors) {
            if (errors.hasOwnProperty(error)) {
                if (errors[error][0] !== '') {
                    return errors[error][0];
                }
            }
        }
    }
    return '';
};

export const isRFQEditable = (status) => {
    return typeof status === 'string' && [STATUS_DRAFT, STATUS_ACTIVE].includes(status.toUpperCase());
};

export const basePathAuth = (companyId) => `/account/${companyId}/`

export const sortArrayByName = (data) => {
    let result = data.sort((a, b) => {
        let nameA = a.name.toLowerCase();
        let nameB = b.name.toLowerCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });
    return result
}


export const isDateWithin30Days = (dateToCheck) => {
    let afterThirtyDays = moment().add(30, 'days');
    let today = moment();
    return moment(dateToCheck).isSameOrBefore(afterThirtyDays) && moment(dateToCheck).isSameOrAfter(today);
}

export const isDateExpired = (dateToCheck) => {
    if (!(dateToCheck instanceof Date)) {
        dateToCheck = new Date(dateToCheck);
    }
    const today = new Date(); 
    return dateToCheck < today
}

export const isPAPLUsers = (user) => {
    const PAPL_EMAIL = "@perthairport.com.au"
    return user?.email?.includes(PAPL_EMAIL)
}