import { Flex, Image } from '@chakra-ui/core';
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { DEFAULT_IMAGE_AVATAR } from 'utils/constants';

const avatarSizes = {
  sm: tw`h-8 w-8 p-1 min-w-8 min-h-8`,
  md: tw`h-10 w-10 p-1 min-w-10 min-h-10`,
  lg: tw`h-12 w-12 p-0.5 min-w-12 min-h-12`
}

const AvatarStyled = styled.img(({className,size='md'}) => [
    tw`bg-white h-10 w-10 rounded-full border-[3px] border-white object-contain text-xs font-medium text-center content-center uppercase`,
    avatarSizes[size],
    tw`${className}`
])
const AvatarNullStyled = styled.div((className) => [
    tw`h-10 w-10 rounded-full border-[3px] border-white  text-xs font-medium text-center content-center bg-royal-blue2 uppercase`,
    tw`${className}`
])

const ContainerStyled = styled(Flex)(({noHover}) => [
    tw`relative rounded-full`,
    !noHover && tw`hover:shadow-md hover:shadow-gray-300`
])

const ImageStyled = styled(Image)(() => [
    tw`w-4 h-4 absolute border border-white rounded-full bottom-0 right-0`
])

const StatusStyled = styled.div(() => [
    tw`bg-utility-success-700 w-4 h-4 absolute border border-white rounded-full bottom-0 right-0`
])


const computeInitials = (name) => {
  return name.split(' ').slice(0, 2).map(word => word[0]).join('').toUpperCase();
}

const defaultImg = (type) => {
  if(type === 'user'){
    return '/img/user-01.svg'
  }else if(type === 'company'){
    return DEFAULT_IMAGE_AVATAR
  }
}


const Avatar = ({ avatar, className, badge, status, alt="initial",defaultImage='company',noHover,...props }) => {
  const initials = computeInitials(alt);

  const avatarNull = () => {
    return <AvatarNullStyled >{initials}</AvatarNullStyled>
  }

  const renderAvatar = () => {
    return <AvatarStyled 
      className={`${className} card-shadow`} 
      src={(avatar && !avatar?.includes("usUqYOVKMloCsoUYaqWKHtzE3xeicxsJ9Y6UEiF4.png")) ? avatar : defaultImage ? defaultImg(defaultImage) : avatarNull()} 
      alt={initials} 
      onError={(e) => {e.target.src = defaultImg(defaultImage)}}
      {...props} 
    />
  }

  return (
    <ContainerStyled noHover={noHover} >
      {renderAvatar()}
      {badge && <ImageStyled src={badge} alt='badge' />}
      {status && <StatusStyled></StatusStyled> }
    </ContainerStyled>
  );
};

export default Avatar;