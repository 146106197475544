import React, { useEffect, useState } from "react";
import { ContextMenuStyled } from "./styled";
import { connect } from "react-redux";
import { ShareProfileModal } from "../ShareProfile/ShareProfileModal";
import ContextMenuClient from "./ContextMenuClient";
import ContextMenuSupplier from "./ContextMenuSupplier";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import ContextMenuGuest from "./ContextMenuGuest";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { sidebarCollapse } from "actions/sidebarActions";

export const ContextMenuComponent = ({
  user,
  company,
  company_current,
  profile_type,
  isDefaultCollapsed,
  ...props
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { sidebarcollapse } = useSelector((state) => state.sidebar);
  const [isCollapseNav, setIsCollapseNav] = useState(isDefaultCollapsed || sidebarcollapse);
  const dispatch = useDispatch();

  if (!props.name) {
    props.name = company_current.name;
  }
  if (!props.logo) {
    props.logo = company_current.logo_path;
  }

  useEffect(() => {
    setIsCollapseNav(sidebarcollapse);
  }, [sidebarcollapse]);

  return (
    <>
      <div
        style={{ position: "relative", width: "fit-content" }}
        className="group"
      >
        <div
          id="notch"
          className={`flex justify-center items-center notch z-50 rounded-tr-md rounded-br-md ${!isCollapseNav ? `w-[10px] left-[379px]  bg-gray-utility-100 group-hover:bg-utility-blue-light-50 group-hover:w-[20px]` : `w-[26px] left-[60px] bg-royal-blue group-hover:w-[36px]`}`}
          onClick={() => {
            const elements = document.getElementsByClassName("sidemuchild");
            const profile = document.getElementById("profile");
            const notch = document.getElementById("notch");

            if (isCollapseNav) {
              notch.classList.toggle("moved");
              Array.from(elements).forEach((element) => {
                element.classList.add("sidemuchildanimate");
              });
              if (profile) {
                profile.classList.remove("profile-hide");
                profile.classList.add("fadeInProfileAnimation");
              }
            } else {
              notch.classList.toggle("moved");
              Array.from(elements).forEach((element) => {
                element.classList.remove("sidemuchildanimate");
              });
              if (profile) {
                profile.classList.remove("fadeInProfileAnimation");
                profile.classList.add("profile-hide");
              }
            }
            dispatch(sidebarCollapse(!isCollapseNav));
          }}
        >
          <div
            style={{ height: "44px", transition: "all 0.4s ease-in-out" }}
            className={`absolute ${!isCollapseNav ? "w-6 group-hover:w-11 bg-gray-utility-100 group-hover:bg-utility-blue-light-50 right-2" : "w-4 right-5"} flex items-center rounded-tl-md rounded-bl-md z-50 }`}
          >
            <div
              className={
                isCollapseNav
                  ? "ml-2 text-2xl w-40"
                  : `ml-1 text-royal-blue text-2xl`
              }
              style={{ transition: "all 0.4s ease-in-out" }}
            >
              {isCollapseNav ? (
                <ChevronRightIcon
                  alt="arrow-right"
                  style={{
                    verticalAlign: "middle",
                    color: "#fff",
                    marginTop: "-6px",
                  }}
                  className="chevron-right"
                />
              ) : (
                <ChevronLeftIcon
                  alt="arrow-left"
                  style={{ marginTop: "-6px" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="relative"
        style={{
          height: "max-content",
          position: "sticky",
          top: 0,
          zIndex: isCollapseNav ? 0 : 2,
        }}
      >
        <ContextMenuStyled company={company} collapse={isCollapseNav}>
          {/* */}
          {profile_type === 1 ? (
            <>
              {localStorage.getItem("role") === "guest" ? (
                <ContextMenuGuest
                  setIsCollapseNav={setIsCollapseNav}
                  isCollapseNav={isCollapseNav}
                  props={props}
                />
              ) : (
                <ContextMenuClient
                  setIsCollapseNav={setIsCollapseNav}
                  isCollapseNav={isCollapseNav}
                  props={props}
                />
              )}
            </>
          ) : (
            <>
              {/*
          // // Back up Code ------------
          <div id="navbar-v1">
          <div style={{ display: 'flex' }}  >
              <AccountBadge name={props.name} logo={props.logo} collapse={isCollapseNav}></AccountBadge>
              <ChevronIconStyled collapse={isCollapseNav} onClick={() => setIsCollapseNav(!isCollapseNav)}>
                {isCollapseNav ?
                  <ChevronRightIcon alt="arrow-right" style={{ verticalAlign: 'middle' }} className="chevron-right" /> : <ChevronLeftIcon alt="arrow-left" style={{ verticalAlign: 'middle' }} />}
              </ChevronIconStyled>
            </div>
            <ContextMenuLink to="company-profile/edit-user-profile">
              <IconBox icon={<UsersIcon />} color="dark" />
              My Profile
            </ContextMenuLink>


            {(isAdmin(user) || isSuperAdmin()) &&
              (
                <>
                  <ContextMenuLink to="company-profile/account-users">
                    <IconBox icon={<AccountUsersIcon />} color="dark" />
                    Account Users
                  </ContextMenuLink>
                  <ContextMenuLink to="company-profile/account-management">
                    <IconBox icon={<SettingsIcon />} color="dark" />
                    Account Management
                  </ContextMenuLink>
                  <ContextMenuLink to="company-profile/company-information">
                    <IconBox icon={<BuildingIcon />} color="dark" />
                    Company Information
                  </ContextMenuLink>
                  {(isProcurerNBidder(company) ||
                    isProcurer(company) ||
                    isBidder(company) ||
                    isSuperAdmin()) && (
                      <ContextMenuLink to="company-profile/company-locations">
                        <IconBox icon={<MapPinIcon />} color="dark" />
                        Office Locations
                      </ContextMenuLink>
                    )}
                </>
              )}

            {((isAdmin(user) && isProcurerNBidder(company)) ||
              isBidder(company) ||
              isSuperAdmin()) && (
                <>
                  <ContextMenuLink to="company-profile/projects">
                    <IconBox icon={<CheckmarkIcon />} color="dark" />
                    Projects & Experience
                  </ContextMenuLink>
                  <ContextMenuLink to="company-profile/resources">
                    <IconBox icon={<PeopleIcon />} color="dark" />
                    Key Personnel & Expertise
                  </ContextMenuLink>
                </>
              )}

            {((isAdmin(user) && !isProcurer(company)) || isSuperAdmin()) &&
              (
                <ContextMenuLink to="company-profile/documents">
                  <IconBox icon={<DocumentIcon />} color="dark" />
                  Corporate Documents
                </ContextMenuLink>
              )}
            {company.profile_type?.id !== 1 &&
              (
                <ContextMenuLink to={`/company/${company.id}`} newTab={true}>
                  <IconBox icon={<OpenEye />} color="dark" />
                  Preview your Profile
                </ContextMenuLink>
              )}
            <ContextMenuLink to="company-profile/shared-companies">
              <IconBox icon={<SharedProfileIcon />} color="dark" />
              Shared Profiles
            </ContextMenuLink>

            {(isAdmin(user) || isSuperAdmin()) && (
              <ContextMenuLink to="company-profile/logs">
                <IconBox icon={<LogIcon />} color="dark" />
                Logs
              </ContextMenuLink>
            )}
            {(isProcurerNBidder(company) || isBidder(company) || isSuperAdmin()) && (
              <div className="profile-share" onClick={() => setIsOpenModal(true)}>
                <IconBox icon={<ShareIcon />} color="dark" />
                <span>Share Profile</span>
              </div>
            )}
          </div> */}

              <ContextMenuSupplier
                setIsCollapseNav={setIsCollapseNav}
                isCollapseNav={isCollapseNav}
                props={props}
              />
            </>
          )}
        </ContextMenuStyled>
        <ShareProfileModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    company_current: state.auth.current_company,
    user: state.auth.user,
    company: state.search.activeCompany,
    profile_type: state.profile_type,
  };
};

export const ContextMenu = connect(mapStateToProps, {})(ContextMenuComponent);
