import React from 'react'
import { FileCard } from 'components/RFQ/FileCard'

const DocumentationComp = ({ data, documentFilter }) => {
    const documentlist = documentFilter ? data?.filter((item) => item.categoryId === documentFilter) : data
    return <>
    {documentlist.map((singleData, index) => {
        const fileData = {
            name: singleData?.fileName,
            file_size: singleData?.fileSize,
            path: singleData?.documentPath,
        }
        return <FileCard key={index} file={fileData} isPreview categoryTag={singleData?.categoryName || ' - '} />
    })}
    </>
}
export default DocumentationComp