import apiV2 from "api-v2";
import * as constant from "./actionTypes";
import { toast } from "react-toastify";
import { navigate } from "@reach/router";
import { toFormData } from "axios";


export const getProjects = ({ limit = 12, page = 1, search = '', sort = 'last_activity', filters, reset = false }) => {
  return async (dispatch, getState) => {
    dispatch({ type: constant.GET_PROJECTS_REQUEST });
    if (reset) dispatch({ type: constant.RESET_PROJECTS, payload: true });

    try {
      const params = { limit, page, search, sort, filters }
      const { data: { projects, filters: filterOptions } } = await apiV2.get(`project`, { params });
      let oldData = await getState().rfq.projects.projects;
      let newOldData = oldData
      if (page > 1) {
        Object.keys(projects).forEach((project) => {
          if (oldData[project].meta.last_page >= projects[project].meta.current_page) {
            newOldData[project].data = [...oldData[project]?.data, ...projects[project]?.data]
            newOldData[project].meta = projects[project].meta;
          }
        })
      }

      let countData = await getState().rfq.projects.projectCount;
      if (page === 1) {
        Object.keys(projects).forEach((project) => (
          countData[project] = projects[project].meta.total
        ))
      }

      if (reset) {
        dispatch({
          type: constant.GET_PROJECTS_SUCCESS,
          payload: projects,
        });
        dispatch({
          type: constant.GET_PROJECTS_FILTER_OPTIONS,
          payload: filterOptions,
        })
      } else {
        await dispatch({
          type: constant.GET_PROJECTS_SUCCESS,
          payload: page > 1 ? newOldData : projects,
        });
        await dispatch({
          type: constant.SET_PROJECT_BADGES_COUNT,
          payload: countData,
        });
        dispatch({
          type: constant.GET_PROJECTS_FILTER_OPTIONS,
          payload: filterOptions,
        })
      }
    } catch (error) {
      dispatch({
        type: constant.GET_PROJECTS_FAILED,
        payload: error.response?.data || error.message,
      });
    }
  };
}

export const getFavoriteProjects = () => {
  return async (dispatch, getState) => {
    dispatch({ type: constant.GET_PROJECT_FAVORITES_REQUEST });

    try {
      const { data } = await apiV2.get(`project/favorites`);
      
      dispatch({
        type: constant.GET_PROJECT_FAVORITES_SUCCESS,
        payload: data?.data,
      });
    } catch (error) {
      dispatch({
        type: constant.GET_PROJECT_FAVORITES_FAILED,
        payload: error.response?.data || error.message,
      });
    }
  };
}


export const getProjectAll = async () => {
  try {
    const res = await apiV2.get(`project`);
    if (res.status) return res.data.projects;
    else throw new Error(res.data.message);
  } catch (err) {
    return err.response?.data || err.message;
  }
};

export const getProjectById = async (projectId) => {
  try {
    const res = await apiV2.get(`project/${projectId}`);
    if (res.status === 200) return res.data.data;
    else throw new Error(res.data.message);
  } catch (err) {
    throw new Error(err?.data?.message);
  }
};

export const setProjectFavorite = (data) => {
  return async (dispatch) => {
    dispatch({
      type: constant.SET_PROJECT_FAVORITE,
    });
    try {
      const res = await apiV2.post(`project/favorite`, data);
      if (res.status) {
        toast.success(res.data.message, { toastId: "setProjectFavorite" });
        if(data.isRemove){
          dispatch({
            type: constant.SET_PROJECT_UNFAVORITE_SUCCESS,
            payload: res.data?.data,
          });
        }else{
          dispatch({
            type: constant.SET_PROJECT_FAVORITE_SUCCESS,
            payload: res.data?.data,
          });
        }
      } else {
        dispatch({
          type: constant.SET_PROJECT_FAVORITE_FAILED,
        });
      }
    } catch (error) {
      dispatch({
        type: constant.SET_PROJECT_FAVORITE_FAILED,
        payload: error.response?.data || error.message,
      });
    }
  };
};
export const setProjectArchived = (id) => {
  return async (dispatch) => {
    dispatch({
      type: constant.SET_PROJECT_ARCHIVED,
    });
    try {
      const res = await apiV2.post(`project/${id}/archived`);
      if (res.status) {
        dispatch({
          type: constant.SET_PROJECT_ARCHIVED_SUCCESS,
        });
        toast.success(res.data.message)
        return res.data;
      } else {
        dispatch({
          type: constant.SET_PROJECT_FAVORITE_FAILED,
        });
        toast.error(res.data.message || "Failed to archive project.");
        return res.data;
      }
    } catch (error) {
      dispatch({
        type: constant.SET_PROJECT_FAVORITE_FAILED,
        payload: error.response?.data || error.message,
      });
      toast.error(error?.response?.data?.message)
    }
  };
};
export const setProjectUnArchived = (id) => {
  return async (dispatch) => {
    dispatch({
      type: constant.SET_PROJECT_ARCHIVED,
    });
    try {
      const res = await apiV2.post(`project/${id}/unarchived`);
      if (res.status) {
        dispatch({
          type: constant.SET_PROJECT_ARCHIVED_SUCCESS,
        });
        toast.success(res.data.message || "Success to unarchive supplier list.");
        return res.data;
      } else {
        dispatch({
          type: constant.SET_PROJECT_FAVORITE_FAILED,
        });
        toast.error(res.data.message || "Failed to unarchive supplier list.");
        return res.data;
      }
    } catch (error) {
      dispatch({
        type: constant.SET_PROJECT_FAVORITE_FAILED,
        payload: error.response?.data || error.message,
      });
      toast.error("Failed to unarchive supplier list.");
    }
  };
};
export const storeNewProject = (body) => {
  return async (dispatch) => {
    dispatch({
      type: constant.POST_NEW_PROJECT_REQUEST,
    });
    try {
      const res = await apiV2.post(`project/store`, body);
      if (res.status) {
        dispatch({
          type: constant.POST_NEW_PROJECT_SUCCESS,
        });
        toast.success(res.data.message || "Project created successfully");
        navigate("./");
        return res.data;
      } else {
        dispatch({
          type: constant.POST_NEW_PROJECT_FAILED,
          payload: res.data,
        });
        toast.error("Project creation failed");
      }
    } catch (error) {
      dispatch({
        type: constant.POST_NEW_PROJECT_FAILED,
        payload: error.response?.data || error.message,
      });
      toast.error("Project creation failed");
    }
  };
};
export const updateProject = (projectId, body) => {
  return async (dispatch) => {
    dispatch({
      type: constant.POST_NEW_PROJECT_REQUEST,
    });
    try {
      const res = await apiV2.patch(`project/${projectId}/update`, body);
      if (res.status) {
        const slug = res.data?.data?.name?.toLowerCase().replace(/ /g, "-");
        dispatch({
          type: constant.POST_NEW_PROJECT_SUCCESS,
        });
        toast.success(res.data.message || "Project updated successfully");
        navigate(`../${projectId}/${slug}`, {});
        return res.data;
      } else {
        dispatch({
          type: constant.POST_NEW_PROJECT_FAILED,
          payload: res.data,
        });
        toast.error("Project update failed");
      }
    } catch (error) {
      dispatch({
        type: constant.POST_NEW_PROJECT_FAILED,
        payload: error.response?.data || error.message,
      });
      toast.error("Project update failed");
    }
  };
};


export const assignSupplierListToProject = async (projectId, listsId) => {
  const data = toFormData(listsId, undefined, { indexes: true });
  try {
    const res = await apiV2.post(`project/${projectId}/assign-supplier-list`, data);
    if (res.data.status) {
      return res.data.message;

    } else throw new Error(res.data.message);
  } catch (err) {
    throw new Error('Error while adding supplier list');
  }
}

export const getProjectSupplier = async () => {
  try {
    const { data: lists } = await apiV2.get(`project/retrieve-project-lite`);
    if (lists.status) {
      return lists.data
    } else {
      return null
    }
  } catch (err) {
    throw new Error(err?.response?.data?.message)
  }
}

export const getProjectFilteringOptions = async () => {
  try {
    const { data: options } = await apiV2.get(`project/project-filter-attribute`);
    if (options.status) {
      return options.data
    } else {
      return null
    }
  } catch (err) {
    throw new Error(err?.response?.data?.message)
  }
} 