import { Icon } from 'assets/icons/Icon'
import tw , { styled, css } from 'twin.macro'
import { cn } from 'utils/helpers'

const sizes= {
    sm: tw`text-sm gap-1.5`,
    md: tw`text-sm gap-1.5`,
    lg: tw`text-base gap-2`,
    xl: tw`text-base gap-2`,
    xxl: tw`text-lg gap-3`,
}

const variants = {
    gray: tw`text-text-tertiary-700 border-text-tertiary-700`,
    color: tw`text-utility-brand-700 border-utility-brand-700`,
}

const ButtonStyled = styled.button(({size='md',variant='default'}) => [
    sizes[size],
    variants[variant],
    tw`flex justify-center items-center gap-x-1.5 font-semibold transition-all disabled:pointer-events-none disabled:opacity-50`,
    css`
        svg{
            ${size !== 'xxl' ? tw`w-5 h-5` : tw`w-6 h-6`}
        }
    `
])

const LineStyled = styled.span(({variant='default'}) => [
    tw`left-0 absolute w-0 mt-6 group-hover:w-full transition-all h-[1px]`,
    variant === 'default' ? tw`bg-text-tertiary-400` : tw`bg-utility-brand-400`,
])

const TextButton = ({icon,children,type='button',className,...props}) => {
  return (
    <ButtonStyled className={cn('group relative',className)} type={type}  {...props} >
        {icon && <Icon name={icon} />}
        {children}
        <LineStyled variant={props?.variant}/>
    </ButtonStyled>
  )
}

export default TextButton
