import React from 'react';
import { AddressCardWrap } from "./styled";

export const AddressCard = ({location , primary})=>{
    const {address,city,postcode, state, local_govt_association,} = location?location:{};

    return (
        <AddressCardWrap>
            {primary && <div className="grid grid-cols-12 address-header">
                <div className="col-span-3"><span>Address</span></div>
                <div className="col-span-2"><span>Postcode</span></div>
                <div className="col-span-2"><span>City/Suburb</span></div>
                <div className="col-span-2"><span>State</span></div>
                <div className="col-span-3"><span>Local Government</span></div>
            </div>}
            <div className="grid grid-cols-12 address-wrap">
                <div className="col-span-3 address-data"><span>{address?address:<>&mdash;</>}</span></div>
                <div className="col-span-2 address-data"><span>{postcode?postcode:<>&mdash;</>}</span></div>
                <div className="col-span-2 address-data"><span>{city?city:<>&mdash;</>}</span></div>
                <div className="col-span-2 address-data"><span>{state?state:<>&mdash;</>}</span></div>
                <div className="col-span-3 address-data last"><span>{local_govt_association?local_govt_association.name:<>&mdash;</>}</span></div>
            </div>
        </AddressCardWrap>
    )
}