import React, { useEffect, useState } from "react";
import { Redirect, Router } from "@reach/router";
import { connect, useDispatch } from "react-redux";
import { SearchForm } from "./SearchForm";
import { SearchContainer } from "./SearchContainer";
import { removeAllToListDirect, removeSupplierList, searchCompanies } from "../../actions/searchActions";
import { getSectors } from "../../actions/sectorActions";
import { getAllDisciplines } from "../../actions/searchActions";
import { getAllTypes } from "../../actions/searchActions";
import styled from "styled-components";
import { ContentContainer } from "components/Layout";
import { ContextMenu } from "components/ContextMenu";
import { isProcurer, isProcurerNBidder } from "utils/helpers";
const NotFound = () => {
  return <h1>ERROR: PATH NOT FOUND</h1>;
};


const AccountPageWrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 100vh;
  ${ContentContainer} {
    //future development
  }
`;

const SearchConnected = ({
  searchCompanies,
  compareList,
  shortlist,
  projectId,
  getSectors,
  searchCriteria,
  getAllDisciplines,
  getAllTypes,
  lockedCompareItems,
  lockedSearchItems,
  company,
  company_current,
  ...props
}) => {
  const [allowAccess, setAllowAccess] = useState("init")
  const [loading, setLoading] = useState("true")
  const [supplierlist, setSupplierList] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    if (Object.keys(company_current).length > 0) {
      if (localStorage.getItem("is_admin") === "true" || isProcurer(company_current) || isProcurerNBidder(company_current)) {
        setAllowAccess("allow")
        setTimeout(() => {
          setLoading(false)
        }, 100)
      } else {
        setAllowAccess("dissalow")
        setTimeout(() => {
          setLoading(false)
        }, 100)
      }
    }

  }, [company_current])

  useEffect(() => {
    getSectors();
    getAllDisciplines();
    getAllTypes();
  }, [getSectors, getAllDisciplines, getAllTypes]);


  const getPathUrl = () => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let supplier = params.get('supplier')
    let supplierlistName = params.get('supplierlist')
    let directcompare = false
    if(params.get('directcompare')==="true"){
      directcompare=true
    }
    let directshortlist = false 
    if(params.get('directshortlist')==="true"){
      directshortlist=true
    }
    
    if (supplier) {
      let supplierlistTemp = {
        id: supplier,
        name: supplierlistName,
        directcompare: directcompare,
        directshortlist:directshortlist,
        suppliers: params.get('suppliers')?.split(',')?.map(id => Number(id)) || []
      }
      setSupplierList(supplierlistTemp)
      return supplierlistTemp
    } else {
      return null
    }

  }

  const doSearchCompanies = (value, resume, page) => {
    let supplierlistTemp = getPathUrl()
    if (supplierlistTemp) {
      setTimeout(() => {
        searchCompanies(value, resume, page, supplierlistTemp)
      }, 100)
    } else {
      setSupplierList({})
      dispatch(removeAllToListDirect())
      dispatch(removeSupplierList())
      setTimeout(() => {
        searchCompanies(value)
      }, 100)
    }
  }

  useEffect(() => {
    getPathUrl()
  }, [])
  return (
    <>
      {loading === false && <>
        {allowAccess === "allow" ? <AccountPageWrap>
          <ContextMenu name={company_current.name} logo={company_current.logo_path} />
          <ContentContainer noPadding={true}>
            <Router primary={false} style={{ width: "100%" }}>
              <SearchForm
                path="/"
                projectId={projectId}
                searchCompanies={doSearchCompanies}
              />
              <SearchContainer
                path="/:groupId/*"
                supplierlist={supplierlist}
                compareList={compareList}
                shortlist={shortlist}
                lockedCompareItems={lockedCompareItems}
                lockedSearchItems={lockedSearchItems}
              />
              <NotFound default />
            </Router>
          </ContentContainer>
        </AccountPageWrap> : <Redirect to="/" />}
      </>}
      {loading === true && <>loading</>}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    company_current: state.auth.current_company,
    company: state.search.activeCompany,
    locations: [],
    projectId: state.search.projectId,
    compareList: state.search.compareList,
    shortlist: state.search.shortlist.map((shortlistItem) => {
      let companyInCompare = state.search.compareList.find(
        (compareItem) => compareItem.id === shortlistItem.id
      );
      return companyInCompare ? companyInCompare : shortlistItem;
    }),
    lockedSearchItems:
      state.search.searchResult &&
      state.search.lockedSearchItems.map((lockedCompanyId) =>
        state.search.searchResult.find(
          (company) => company.id === lockedCompanyId
        )
      ),
    lockedCompareItems:
      state.search.searchResult &&
      state.search.lockedCompareItems.map((lockedCompanyId) =>
        state.search.compareList.find(
          (company) => company.id === lockedCompanyId
        )
      ),
  };
};

export const Search = connect(mapStateToProps, {
  searchCompanies,
  getSectors,
  getAllDisciplines,
  getAllTypes,
})(SearchConnected);
