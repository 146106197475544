
import { Flex } from '@chakra-ui/react';
import { Link } from '@reach/router';
import { Icon } from 'assets/icons/Icon';
import { EditDropdown } from 'components/Pages/Projects/EditDropdown';
import { Avatar } from 'components/RFQ/Avatar';
import { Button } from 'components/RFQ/Button';
import NotFoundDatas from 'components/RFQ/NotFoundDatas/NotFoundDatas';
import StatusBadge from 'components/RFQ/StatusBadge/StatusBadge';
import {TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/RFQ/Table';
import React from 'react'
import tw, { styled, css } from 'twin.macro';

const ListViewStyled = styled.div(() => [
    tw`border rounded-lg mb-12`,
    css`
    .title {
        font-weight: 500;
        font-family: Roboto;
        font-size: 14px;
    }
    table {
        border-collapse: collapse;
        border: solid 1px #EAECF0;
    }
    table tr {
        border: solid 1px #EAECF0;  
    }
    table td {
        border-bottom: solid 1px #EAECF0;  
    }
    .text {
        font-weight: 400;
        font-family: Roboto;
        font-size: 14px;
    }
    .th {
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
    }
    td:nth-child(1) {
    position: relative;
    } 

    td:nth-child(1):before {
        box-shadow: -15px 0 15px -26px inset;
        content: " ";
        height: 100%;
        top: 0;
        left: -15px;
        position: absolute;
        width: 15px;
    }

    td:nth-child(1):after {
        box-shadow: 15px 0 15px -26px inset;
        content: " ";
        height: 100%;
        position: absolute;
        top: 0;
        right: -15px;
        width: 15px;
    }


    `
])



const SkeletonCustom = styled.div((() => [
    tw`animate-pulse bg-gray-300 w-full h-6`
]))



const SkeleteonRow = () => (
    <TableRow>
        <TableCell >
            <SkeletonCustom />
        </TableCell>
        <TableCell >
            <SkeletonCustom />
        </TableCell>
        <TableCell >
            <SkeletonCustom />
        </TableCell>
        <TableCell >
            <SkeletonCustom />
        </TableCell>
        <TableCell >
            <SkeletonCustom />
        </TableCell>
        <TableCell >
            <SkeletonCustom />
        </TableCell>
    </TableRow>
)

const ThComp = ({ title }) => {
    return <div className=' font-roboto text-text-tertiary-600 text-xs th' style={{ gap: '8px', alignItems: 'center' }}>
        {title}
    </div>
}

const ListViewProjects = ({ data, isLoading, currPage, currentTab }) => {

    return (
        <ListViewStyled>
            <table className="w-full border shadow-lg rounded-lg overflow-hidden bg-white" >
                <TableHeader className="rounded-lg">
                    <TableRow>
                        <TableHead firstCol={true}>
                            <div className='flex font-roboto text-text-tertiary-600 th' style={{ gap: '8px', alignItems: 'center' }}>
                                Project
                            </div>
                        </TableHead>
                        <TableHead className={tw`max-w-[90px] min-w-[90px]`}>
                            <ThComp title="Project Status"></ThComp>
                        </TableHead>
                        <TableHead className={tw`max-w-[286px] min-w-[286px]`}>
                            <ThComp title="Project Lead"></ThComp>
                        </TableHead>
                        <TableHead className={tw`max-w-[180px] min-w-[180px]`}>
                            <ThComp title="No. of Suppliers list"></ThComp>
                        </TableHead>
                        <TableHead className={tw`max-w-[180px] min-w-[180px]`}>
                            <ThComp title="No. of RFx"></ThComp>
                        </TableHead>
                        <TableHead className={tw`max-w-[68px]`}>
                            <div className='hidden'>
                                Icon
                            </div>
                        </TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        isLoading && currPage === 1 && !data ?
                            [...Array(6)].map(() => <SkeleteonRow />)
                            :
                            data?.length > 0 ?
                                <>
                                    {data.map((project, index) => {
                                        const slug = project.name?.toLowerCase().replace(/ /g, "-");
                                        return (
                                            <TableRow key={index} className="border-b border-gray-200 group">

                                                <TableCell firstCol={true} className={tw`min-w-[380px] max-w-[400px] group-hover:bg-utility-blue-light-50/30`} >
                                                    <Link to={`${project.id}/${slug}`} className='font-roboto text-text-primary-900 font-semibold' >
                                                        <div className='flex gap-3' style={{
                                                            alignItems: 'center',
                                                        }}>
                                                            <Avatar alt={project?.name} avatar={project.logoPath ? project.logoPath : '/img/placeholder-sm.jpg'} className="shadow-md shadow-gray-300" />
                                                            <p className='max-w-[360px] title line-clamp-1 text-ellipsis capitalize font-roboto text-text-primary-900 text-nowrap'>{project.name ? project.name : " - "}</p>
                                                        </div>
                                                    </Link>
                                                </TableCell>

                                                {/* <TableCell className={tw`max-w-[360px]`}>
                                                    <p className='text overflow-hidden line-clamp-1 text-ellipsis'>
                                                        {project?.description ? htmlToText(project.description) : " - "}
                                                    </p>
                                                </TableCell> */}
                                                <TableCell className={tw`max-w-[300px] group-hover:bg-utility-blue-light-50/30`}>
                                                    <StatusBadge status={project.status} />
                                                </TableCell>
                                                <TableCell className={tw`max-w-[300%] group-hover:bg-utility-blue-light-50/30`}>
                                                    <p>{project.projectLead}</p>
                                                </TableCell>
                                                <TableCell className={tw`max-w-[180px] group-hover:bg-utility-blue-light-50/30`}>
                                                    <p className='text'>
                                                        {project.numberSupplierList ? project.numberSupplierList : '0'}
                                                    </p>
                                                </TableCell>
                                                <TableCell className={tw`max-w-[180px] group-hover:bg-utility-blue-light-50/30`}>
                                                    <p className='text'>
                                                        {project.numberRfq ? project.numberRfq : '0'}
                                                    </p>
                                                </TableCell>
                                                
                                                
                                                <TableCell className={tw`max-w-[68px] group-hover:bg-utility-blue-light-50/30`}>
                                                    <EditDropdown id={project.id} currentTab={currentTab} />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    {isLoading && data && [...Array(4)].map(() => <SkeleteonRow />)}
                                </>

                                :
                                <TableRow className='col-span-5 h-[500px]'>
                                    <div className="absolute -translate-x-2/4 left-2/4">
                                    <NotFoundDatas
                                        text={`No Project/s Found`}
                                        description={"To get started add a New Project."}
                                    >
                                    <Link to={`new-project`}>
                                        <Button style={{maxHeight:"48px"}}>
                                            <Flex  className='items-center gap-[6px]'>
                                                <div className='w-5 h-5 items-center justify-center flex'>
                                                    <Icon icon='plus' style={{width:'12px', height:'12px', color: '#fff', fontWeight: 'bold'}} />                      
                                                </div>
                                                <div className='font-roboto capitalize font-semibold text-base'>
                                                    Add New Project
                                                </div>
                                            </Flex>
                                        </Button>
                                    </Link>
                                    </NotFoundDatas>
                                    </div>
                                </TableRow>
                    }
                </TableBody>
            </table>
        </ListViewStyled>
    )
}

export default ListViewProjects
