import { Flex, Spinner } from "@chakra-ui/react";
import { FormatedInput } from "components/FormInputs/FormatedInput";
import { Heading } from "components/Heading";
import { FileUploadSelect } from "components/RFQ/FileUpload/FileUploadSelect";
import { FieldForm } from "components/RFQ/Input/Input";
import React from "react";
import tw, { styled } from "twin.macro";

const ProjectDetailContainer = styled.div(() => [tw`flex flex-col w-full`]);

const Documentation = ({ titlePage, subTitlePage,documentCategories }) => {
  const isLoading = false;

  return (
    <ProjectDetailContainer>
      <div id="subheader-page" className="py-8">
        <div
          id="subheader-page-title"
          className="text-xl font-semibold text-text-primary-900"
        >
          <Heading fontSize={'36px'} color="#003CE9"> {titlePage}</Heading>
        </div>
        <div
          id="subheader-page-subtitle"
          className="font-roboto text-sm text-text-tertiary-600 mt-1"
        >
          {subTitlePage}
        </div>
      </div>
      <div id="project-detail-content" className="h-full relative min-h-[60vh]">
        {!isLoading ? (
          <>
              <Flex>
                <FormatedInput
                  label="Documentation Description"
                  name="documentation_description"
                  characterLimit={1500}
                  rfq
                  className="w-full"
                  placeholder={'E.g. \nInvitation \nDevelopment Approval DA 987654 \nTerms and Conditions'}
                  subTitle={
                    "Provide a list of all the project  documents you will be providing as part of this Rfx."
                  }
                />
              </Flex>
              <Flex gap={8} marginBottom={20}>
                <FileUploadSelect
                  label={"Document upload"}
                  multiple={true}
                  name="documents"
                  maxFileSize={10}
                  types={["pdf", "docx", "xlsx", "jpeg", "jpg"]}
                  defaultFileName="Document"
                  selectName="category_id"
                  selectOptions={documentCategories}
                  required={true}
                  subtitle='Select a category and upload the relevant  document/s.  Once uploaded, you can re-order the documents.  '
                >
                  <FieldForm
                    name="category_id"
                    type="select"
                    placeholder="Select category"
                    className="w-60 my-3"
                    options={documentCategories}
                    onClick={(e) => e.stopPropagation()}
                    size='sm'
                    checkIcon
                  />
                </FileUploadSelect>
              </Flex>
        </>
        ) : (
          <Spinner
            className="absolute left-1/2 -translate-x-1/2 top-[30vh] "
            size="xl"
          />
        )}
      </div>
    </ProjectDetailContainer>
  );
};

export default Documentation;
