import { AccountManagement } from "components/AccountManagement/AccountManagement";
import { AccountUsers } from "components/AccountUsers";
import { LogsComponent } from "components/Logs";
import TabsComp from "components/RFQ/TabsComp/TabsComp";
import { TabHeader } from "components/TabHeader";
import React, { useState } from "react";

const AccountSettings = ({ company }) => {
  const isLoading = false;
  const ACCOUNTUSER = "Account Users";
  const ACCOUNTMANAGEMENT = "Account Management";
  const LOGS = "Logs";

  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);
  let tab = params.get('tab')

  const [currentTab, setCurrentTab] = useState(tab || ACCOUNTUSER);
  const tabMenu = [
    {
      name: ACCOUNTUSER,
      showBadge: false,
      total: 0,
    },
    {
      name: ACCOUNTMANAGEMENT,
      showBadge: false,
      total: 0,
    },
    {
      name: LOGS,
      showBadge: false,
      total: 0,
    },
  ];

  const changeActiveTab = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <div>
      <TabHeader heading="Account Settings" description=""></TabHeader>
      <div className="mt-5 mb-5">
        <TabsComp
          menus={tabMenu}
          activeTab={currentTab}
          changeActiveTab={changeActiveTab}
          isLoading={isLoading}
        ></TabsComp>
      </div>
      <div className="border-b border-gray-200"></div>
      <div>
        {currentTab === ACCOUNTUSER && (
          <AccountUsers company={company}></AccountUsers>
        )}
        {currentTab === ACCOUNTMANAGEMENT && (
          <AccountManagement company={company}></AccountManagement>
        )}
        {currentTab === LOGS && <LogsComponent company={company} />}
      </div>
    </div>
  );
};
export default AccountSettings;
