import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getApiWithToken } from '../../utils/helpers';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/RFQ/Table';
import { cn } from 'utils/helpers';
import { Button } from 'components/RFQ/Button';
import { Flex } from '@chakra-ui/react';
import tw, { styled } from 'twin.macro';
export const LogsComponent = ({ company, ...props }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [logs, setLogs] = useState({});

    const fetchLogs = async (pageNumber = 1) => {
        const abortController = new AbortController();
        const fetchData = async () => {
            try {
                const result = await getApiWithToken().get(`logs?company_id=${company.id}&page=${pageNumber}`).json();
                setIsLoading(false)
                setLogs(result);
            } catch (error) {
            }
        };
        fetchData();
        return () => {
            abortController.abort();
        };
    };

    useEffect(() => {
        if (company)
            fetchLogs();
    }, [company]) // eslint-disable-line

    const TableHeadComponent = ({ title, className }) => {
        return <div className={cn('font-roboto text-text-tertiary-600 th ml-3', className)} style={{ gap: '8px', alignItems: 'center' }}>
            {title}
        </div>
    }

    const SkeletonCustom = styled.div((() => [
        tw`animate-pulse bg-gray-300 w-full h-6`
    ]))


    const SkeleteonRow = () => (
        <TableRow>
            <TableCell >
                <SkeletonCustom />
            </TableCell>
            <TableCell >
                <SkeletonCustom />
            </TableCell>
            <TableCell >
                <SkeletonCustom />
            </TableCell>
            <TableCell >
                <SkeletonCustom />
            </TableCell>
        </TableRow>
    )

    const enableNext = () => {
        if (parseInt(logs?.last_page) > parseInt(logs?.current_page)) {
            return true
        } else {
            return false
        }
    }

    const enablePrevious = () => {
        if (parseInt(logs?.current_page) === 1) {
            return false
        } else {
            return true
        }
    }
    return (
        <>
            <div className="shadow-card w-full rounded-xl border border-utility-gray-200 mt-5 mb-5">
                <Table className="rounded-xl mt-1 mb-1">
                    <TableHeader>
                        <TableRow>
                            <TableHead>
                                <TableHeadComponent title="Log Number"></TableHeadComponent>
                            </TableHead>
                            <TableHead>
                                <TableHeadComponent title="Date"></TableHeadComponent>
                            </TableHead>
                            <TableHead >
                                <TableHeadComponent title="Type"></TableHeadComponent>
                            </TableHead>
                            <TableHead >
                                <TableHeadComponent title="Action"></TableHeadComponent>
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {
                            !isLoading && logs && logs?.data?.map((log, index) => {
                                return (
                                    <TableRow key={log.id} className="pl-5 pr-5">
                                        <TableCell className='font-roboto text-sm  text-text-primary-900 font-medium' >
                                            #{log.id}
                                        </TableCell>
                                        <TableCell className='font-roboto text-sm text-text-tertiary-600 f font-normal'>
                                            {new Date(log.created_at).toDateString()} {new Date(log.created_at).toLocaleTimeString()}
                                        </TableCell>
                                        <TableCell className='log-type font-roboto text-sm text-text-tertiary-600 f font-normal'>
                                            {log.type}
                                        </TableCell>
                                        <TableCell className="font-roboto text-sm text-text-tertiary-600 font-normal">
                                            {log.action}
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                        {isLoading && <>
                            <SkeleteonRow></SkeleteonRow>
                            <SkeleteonRow></SkeleteonRow>
                            <SkeleteonRow></SkeleteonRow>
                            <SkeleteonRow></SkeleteonRow>
                            <SkeleteonRow></SkeleteonRow>
                        </>}
                    </TableBody>
                </Table>
                {!isLoading && logs && <div className='flex justify-between items-center h-[64px] p-5 border-t transition-colors'>
                    <Button disabled={!enablePrevious()} btntype='base' className="border border-fg-disabled_subtle shadow-input" onClick={() => {
                        if (enablePrevious()) {
                            fetchLogs(parseInt(logs?.current_page) - 1)
                        }
                    }}>
                        <Flex align={'center'} >
                            <p className='font-roboto font-semibold text-secondary-700'>
                                Previous
                            </p>
                        </Flex>
                    </Button>
                    <div className='font-roboto font-medium text-text-tertiary-600 text-sm'>
                        Page {logs?.current_page} of {logs?.last_page}
                    </div>
                    <Button disabled={!enableNext()} btntype='base' className="border border-fg-disabled_subtle shadow-input" onClick={() => {
                        if (enableNext()) {
                            fetchLogs(parseInt(logs?.current_page) + 1)
                        }
                    }}>
                        <Flex align={'center'} >
                            <p className='font-roboto font-semibold text-secondary-700'>
                                Next
                            </p>
                        </Flex>
                    </Button>
                </div>}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const company = state.search.activeCompany;
    const current_user = state.auth.user;

    return {
        isRequesting: state.companyProfile.isRequesting,
        company: company,
        current_user: current_user,
    };
};

export const Logs = connect(mapStateToProps, {})(
    LogsComponent
);
