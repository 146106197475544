import {
    SearchBoxInputRFQContainer,
    SearchIconContainer,
  } from "components/SearchBox/styled";
  import { Form, Formik } from "formik";
  import React from "react";
  import { useDispatch } from "react-redux";
  import styled, { css } from "styled-components";
  import tw from "twin.macro";
  import debounce from "lodash.debounce";
import { getSupplierLists } from "actions/RFQ/supplierListsActions";
import { Icon } from "assets/icons/Icon";
  const SearchBoxStyled = styled.input(() => [
    tw`h-10 flex rounded-md items-center placeholder:text-text-primary-500 placeholder:font-roboto placeholder:font-normal placeholder:text-16px border border-[#D0D5DD]`,
      css`
          background: white;
          padding-left:2.5rem;
          padding-right:2.5rem;
          max-height: 40px;
          width:275px;
          margin: 0;
          &:focus {
            outline: solid #B9E6FE;
          }
      `
  ])

  const getDispatch = (dispatchFunction,search) => {
    switch (dispatchFunction) {
        case 'supplier-lists':
            return getSupplierLists({search});
        default:
            return null;
    }
}

  const SearchBar = ({ dispatchFunction, setState, placeholder='Search',...props }) => {
    const dispatch = useDispatch();
    const initialValues = {
      search: "",
    };
    const debouncedHandleSearch = debounce((search) => {
      dispatchFunction && dispatch(getDispatch(dispatchFunction,search));
      setState && setState(search)
    }, 300); 

    return (
      <div style={{alignSelf:'center'}} className="shadow-input">
        <Formik
          initialValues={initialValues}
        >
          {({ values }) => (
            <Form>
                <SearchBoxInputRFQContainer style={{alignItems:'center'}} {...props}>
                  <SearchBoxStyled
                    type="text"
                    onChange={(e) => debouncedHandleSearch(e.target.value)}
                    name="search"
                    placeholder={placeholder}
                    className="shadow-sm rounded placeholder-text-primary-500 !w-full"
                  ></SearchBoxStyled>
                  <SearchIconContainer>
                    <div className="text-text-primary-500">
                      <Icon icon="search-lg" style={{width:'20px', height:'20px',color:'#98A2B3'}}/> 
                    </div>
                  </SearchIconContainer>
                </SearchBoxInputRFQContainer>
            </Form>
          )}
        </Formik>
      </div>
    );
  };
  export default SearchBar;