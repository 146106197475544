import React, { useEffect, useState } from "react";
import { SearchTemplateStyled, SearchHeader, HeadingInner } from "./styled";
import { Heading } from "../../components/Heading";
import { SideDraw } from "../../components/SideDraw";
import { TabNav } from "../../components/TabNav/TabNav";
import { SearchTagRow } from "../../components/SearchTagRow";
import { RefineResults } from "../../components/RefineResults";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { sidebarCollapse } from "actions/sidebarActions";

export const SearchTemplate = ({
  resultsCount,
  compareCount,
  shortlistCount,
  searchCriteria,
  noDataTable,
  disciplines,
  types,
  sectors,
  shortlist,
  groupId,
  trades,
  specialities,
  supplierlist,
  loading,
  ...props
}) => {
  const [refineOpenState, setRefineOpenState] = useState(false);
  const searchResultTotal = useSelector((state) => state?.search?.searchResult);
  const compareListTotal = useSelector((state) => state?.search?.compareList);
  const shortlistTotal = useSelector((state) => state?.search?.shortlist);

  const { sidebarcollapse } = useSelector((state) => state.sidebar);
  const dispatch = useDispatch();
  const handleRefineOpenClick = () => {
    if (!sidebarcollapse) {
      dispatch(sidebarCollapse(!sidebarcollapse));
    }
    setRefineOpenState(!refineOpenState);
  };

  useEffect(() => {
    if (!sidebarcollapse && refineOpenState) {
      setRefineOpenState(false);
    }
  }, [sidebarcollapse, refineOpenState]);

  const [tabs, setTabs] = useState([
    {
      link: "results",
      title: "Search Results",
      count: resultsCount,
    },
    {
      link: "compare",
      title: "Compare",
      count: compareCount,
    },
    {
      link: "shortlist",
      title: "Shortlist",
      count: shortlistCount,
    },
  ]);

  const changeTotalTabs = (index, data) => {
    let currentTab = [...tabs];
    currentTab[index].count = data?.length;
    setTabs(currentTab);
  };

  useEffect(() => {
    changeTotalTabs(0, searchResultTotal);
    //eslint-disable-next-line
  }, [searchResultTotal]);

  useEffect(() => {
    changeTotalTabs(1, compareListTotal);
    //eslint-disable-next-line
  }, [compareListTotal]);

  useEffect(() => {
    changeTotalTabs(2, shortlistTotal);
    //eslint-disable-next-line
  }, [shortlistTotal]);

  return (
    <SearchTemplateStyled>
      <SearchHeader>
        <RefineResults
          handleClick={handleRefineOpenClick}
          open={refineOpenState}
          searchCriteria={searchCriteria}
          disciplines={disciplines}
          sectors={sectors}
          types={types}
          groupId={groupId}
          trades={trades}
          specialities={specialities}
        />

        <Heading size="xl">
          <HeadingInner className="mb-5">SEARCH SUPPLIERS</HeadingInner>
        </Heading>

        <SearchTagRow
          searchCriteria={searchCriteria}
          disciplines={disciplines}
          sectors={sectors}
          types={types}
          open={refineOpenState}
          handleRefineButtonClick={handleRefineOpenClick}
          trades={trades}
          specialities={specialities}
          supplierlist={supplierlist}
        />

        <TabNav tabs={tabs} supplierlist={supplierlist} loading={loading} />

        <SideDraw shortlist={shortlist} />
      </SearchHeader>

      {props.children}
    </SearchTemplateStyled>
  );
};
