import apiV2 from 'api-v2';

const getAddendumServices = async (rfqId, email) => {
    return await apiV2.post(`/guest/retrieve-addendum/${rfqId}`, {
        email: email
    })
}

const createAddendumServices = async (data) => {
    return await apiV2.post(`/guest/${data?.rfqId}/store-addendum`, {
        email: data?.email,
        addendum: data?.addendum
    })
}

const deleteAddendumServices = async (rfqId,questionId,email) => {
    return await apiV2.post(`/guest/${rfqId}/delete-addendum/${questionId}`, {
        email: email
    })
}

export { getAddendumServices, createAddendumServices,deleteAddendumServices }