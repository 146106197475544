import styled from "styled-components";
import { ContentContainer, PageWrap } from "../Layout";
import React from "react";

export const ContentStyled = styled.div`
  > div {
    padding-top: 30px;
  }
  h1 {
    font-size: 4.9rem;
    margin: 0px 0px 2.5rem;
    line-height: 1;
  }
  h2 {
    display: block;
    font-size: 1.4rem;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  p {
    color: rgb(91, 107, 136);
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    line-height: 1.55;
    font-weight: 400;
    font-size: 0.938rem;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  ol[type="a"] {
    list-style: lower-alpha;
  }
  ol[type="i"] {
    list-style-type: lower-roman;
  }
  li {
    display: list-item;
    line-height: 1.5;
    font-weight: 400;
    font-size: 0.938rem;
  }
  strong {
    color: rgb(91, 107, 136);
  }
`;

export const ContentPage = ({ children }) => {
  return (
    <PageWrap>
      <ContentStyled>
        <ContentContainer bgColor="rgba(0,0,0,0)">{children}</ContentContainer>
      </ContentStyled>
    </PageWrap>
  );
};
