import { Spinner } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { submitSubmissionGuestRFQ } from "actions/guestflow/guestRfqActions";
import { submitSubmissionRFQ } from "actions/RFQ/Supplier/rfqActions";
import { Checkbox } from "components/RFQ/Checkbox";
import { useState } from "react";

const { Button } = require("components/RFQ/Button");
const { CustomModal } = require("components/RFQ/Modal/CustomModal");

export const SubmitModal = ({
  showDialog,
  onClose,
  rfqId,
  companyId,
  saveSubmissionHandler,
  isGuest,
  guestEmail,
}) => {
  const [checked, setChecked] = useState(false);
  const [saving, setSaving] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const dialogProperty = {
    icon: "blue-circle-check",
    bgColor: "bg-brand-secondary",
    iconColor: "text-utility-brand-700",
    title: "Submit your application",
    description:
      "You're about to submit your request for a quote. Please review your details carefully to ensure all your information is accurate and complete. Once confirmed, we will begin processing your request immediately.",
  };

  const submitHandler = () => {
    setSaving(true);
    saveSubmissionHandler(true).then((res) => {
      if (res) {
        setSaving(false);
        setSubmitting(true);
        (isGuest
          ? submitSubmissionGuestRFQ(rfqId, guestEmail, checked ? 1 : 0)
          : submitSubmissionRFQ(rfqId, companyId, checked ? 1 : 0)
        ).then((res) => {
          if (res) {
            setSubmitting(false);
            onClose();
            navigate(".");
          } else {
            setSubmitting(false);
            onClose();
          }
        });
      } else {
        setSaving(false);
        setSubmitting(false);
        onClose();
        return false;
      }
    });
  };

  const FooterComponent = () => {
    return (
      <div className="flex justify-between gap-2 w-full text-utility-error-50">
        <Button
          className="flex !w-full gap-3"
          onClick={submitHandler}
          disabled={!checked || submitting || saving}
        >
          {(submitting || saving) && <Spinner />}{" "}
          {saving ? "Saving ..." : submitting ? "Submitting ..." : "Submit Now"}
        </Button>
        <Button
          onClick={onClose}
          btntype="base"
          className="!w-full"
          disabled={submitting || saving}
        >
          Cancel
        </Button>
      </div>
    );
  };

  return (
    <CustomModal
      onClose={onClose}
      isOpen={showDialog}
      header={dialogProperty}
      footerComponent={<FooterComponent />}
      size="xl"
    >
      <div className="px-1 py-2">
        <Checkbox
          label={
            <p>
              I have read and acknowledged all{" "}
              <a target="_blank" href="/">
                terms & conditions
              </a>
            </p>
          }
          className="text-sm"
          onChange={(e) => setChecked(e.target.checked)}
          checked={checked}
        />
      </div>
    </CustomModal>
  );
};
