import React from 'react'
import { Flex } from '@chakra-ui/react'
import { Button } from "components/RFQ/Button";
const MenuBarComp = ({setShowDialog, ...props}) => {
    return <>
        <Flex gap={4} {...props}>
            <Button btntype='secondary' onClick={() => setShowDialog('decline')} >
                <div className='font-roboto font-semibold text-utility-blue-dark-100'  >
                    Decline
                </div>
            </Button>
            <Button btntype='primary' onClick={() => setShowDialog('accept')}>
                <div className='font-roboto font-semibold text-white' >
                    Accept
                </div>
            </Button>
        </Flex>


    </>

}

export default MenuBarComp