import { SortItemButton, DropdownMenuItem } from 'components/SortItemButton';
import React from 'react'


const SortComp = ({
    sorted,
    onClickCB,
    openPopUpCB,
    setOpenPopUpCB,
    data
}) => {
    return <>
        <SortItemButton value={sorted.sort} openPopUp={openPopUpCB} setOpenPopUp={setOpenPopUpCB}>
            {data.map((item, index) => (
                <DropdownMenuItem key={index} onClick={() => { onClickCB(item) }} className="p-0">
                    <div class="flex items-center h-10">
                        <div className='text-sm font-roboto capitalize text-secondary-700 font-medium my-auto'>{item.name}</div>
                    </div>
                </DropdownMenuItem>
            ))}
        </SortItemButton>
    </>
}
export default SortComp