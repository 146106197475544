import * as constants from "../actions/actionTypes";

const INITIAL_PROFILE_STATE = {
  resources: [],
  pastProjects: [],
  documentCategories: [],
  documentSubCategories: [],
  documents: [],
  document_access_requests: [],
  postcodeRegions: [],
  procurementMethods: [],
  isRequesting: false,
  requestingResources: true,
  savingDraft: false,
  documentDeleting: false,
  loadingPastProjects: false,
  expertiseDisciplines: null,
  searchResults: {},
  companySizeOptions: [],
  trades: [],
  tradeValues:[],
  specialities: [],
  localGovtAssociations: [],
};

export const companyProfileReducer = (
  state = INITIAL_PROFILE_STATE,
  action
) => {
  switch (action.type) {
    case constants.IS_REQUESTING:
      return {
        ...state,
        isRequesting: action.payload,
      };

    case constants.ADD_PAST_PROJECT:
      return {
        ...state,
        pastProjects: [...state.pastProjects, action.payload],
      };

    case constants.SAVING_DRAFT_PAST_PROJECT:
      return {
        ...state,
        savingDraft: action.payload,
      };

    case constants.FETCHING_PAST_PROJECTS:
      return {
        ...state,
        loadingPastProjects: action.payload,
      };

    case constants.GET_PAST_PROJECTS:
      return {
        ...state,
        loadingPastProjects: false,
        pastProjects: action.payload,
      };

    case constants.EDIT_PAST_PROJECT:
      return {
        ...state,
        pastProjects: state.pastProjects.map((project) =>
          project.id === action.payload.id ? { ...action.payload } : project
        ),
      };

    case constants.SEARCH:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          [action.payload.searchEntity]: action.payload.response.data,
        },
      };

    case constants.IS_SEARCHING_PROJECTS:
      return {
        ...state,
        isSearchingProjects: action.payload,
      };

    case constants.DELETE_PAST_PROJECT:
      return {
        ...state,
        pastProjects: state.pastProjects.filter(
          (project) => project.id !== action.payload.data.id
        ),
      };

    case constants.REQUESTING_RESOURCES:
      return {
        ...state,
        requestingResources: action.payload,
      };

    case constants.GET_RESOURCES:
      return {
        ...state,
        resources: action.payload,
      };

    case constants.ADD_RESOURCE:
      return {
        ...state,
        resources: [...state.resources, action.payload],
      };

    case constants.EDIT_RESOURCE:
      return {
        ...state,
        resources: state.resources.map((resource) =>
          resource.id === action.payload.data.id
            ? { ...action.payload.data }
            : resource
        ),
      };

    case constants.DELETE_RESOURCE:
      return {
        ...state,
        resources: state.resources.filter(
          (resource) => resource.id !== action.id
        ),
      };

    case constants.GET_DOCUMENT_CATEGORIES:
      return {
        ...state,
        documentCategories: action.payload,
      };

    case constants.GET_DOCUMENT_SUBCATEGORIES:
      return {
        ...state,
        documentSubCategories: action.payload,
      };

    case constants.GET_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      };

    case constants.ADD_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents, action.payload.data],
      };
    case constants.DOCUMENT_ACCESS_REQUEST:
      return {
        ...state,
        document_access_requests: action.payload.data,
      };
    case constants.SEND_DOCUMENT_ACCESS_REQUEST:
      return {
        ...state,
        document_access_requests: state.document_access_requests.concat(
          action.payload.data
        ),
      };
    case constants.UPDATE_DOCUMENT_ACCESS_REQUEST:
      return {
        ...state,
        document_access_requests: state.document_access_requests.map(
          (request) =>
            request.id !== action.payload.id ? request : action.payload
        ),
      };
    case constants.EDIT_DOCUMENT:
      return {
        ...state,
        documents: state.documents.map((document) =>
          document.id === action.payload.data.id
            ? { ...action.payload.data }
            : document
        ),
      };

    case constants.DELETE_DOCUMENT:
      return {
        ...state,
        documents: state.documents.filter(
          (document) => document.id !== action.payload.data.id
        ),
      };

    case constants.DOCUMENT_IS_DELETING:
      return {
        ...state,
        documentDeleting: action.payload,
      };

    case constants.GET_PROCUREMENT_METHODS:
      return {
        ...state,
        procurementMethods: action.payload,
      };

    case constants.GET_EXPERTISE_DISCIPLINES:
      return {
        ...state,
        expertiseDisciplines: action.payload,
      };

    case constants.GET_DISCIPLINES_BY_PROFILE_TYPE:
      return {
        ...state,
        expertiseDisciplinesByProfile: action.payload,
      };

    case constants.GET_COMPANY_SIZE_OPTIONS:
      return {
        ...state,
        companySizeOptions: action.payload,
      };

    case constants.GET_TRADES:
      return {
        ...state,
        trades: action.payload,
      };
    case constants.GET_TRADES_VALUE:
      return {
       ...state,
        tradeValues: action.payload,
      };
    case constants.GET_SPECIALITIES:
      return {
        ...state,
        specialities: action.payload,
      };
    case constants.GET_POSTCODE_REGIONS:
      return {
        ...state,
        postcodeRegions: action.payload,
      };
    case constants.GET_LOCAL_GOVT_ASSOCIATION:
      return {
        ...state,
        localGovtAssociations: action.payload,
      };
    default:
      return state;
  }
};
