import React, { useState, useRef } from "react";
import TabsComp from "components/RFQ/TabsComp/TabsComp";
import { TabHeader } from "components/TabHeader";
import { CompaniesListing } from "components/CompaniesListing";
import { Documents } from "./Documents";
import {
  isAdmin,
  isBidder,
  isProcurer,
  isProcurerNBidder,
  isSuperAdmin,
} from "utils/helpers";
import { useSelector } from "react-redux";
import { Resources } from "./Resources";
import { ProjectListing } from "./ProjectListing";
import { CompanyLocationForm } from "components/CompanyLocationForm";
import { CompanyInformation } from "./CompanyInformation";
// import { ShareProfileModal } from "../../../components/ShareProfile/ShareProfileModal";
// import { Button } from "../../../components/Button";

const CompanyProfileV2 = ({ company }) => {
  const { user } = useSelector((state) => state?.auth);

  const allowedOfficeLocations =
    isProcurerNBidder(company) ||
    isBidder(company) ||
    isProcurer(company) ||
    isSuperAdmin();
  const allowedDocument =
    (isAdmin(user) && !isProcurer(company)) || isSuperAdmin();

  const { documents, resources, requestingResources, pastProjects } =
    useSelector((state) => state?.companyProfile);
  const COMPANYINFORMATION = "Company Information";
  const OFFICELOCATIONS = "Office Locations";
  const PROJECTEXPERIENCES = "Projects and Experiences";
  const KEYPERSONNELEXPERTISE = "Key Personnel and Expertise";
  const CORPORATEDOCUMENTS = "Corporate Documents";
  const SHAREDPROFILES = "Shared Profiles";
  const formikRef = useRef(null);
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);
  let activeTab = params.get("tab");
  if (activeTab) {
    activeTab = activeTab.replace(/-/g, " ");
  } else {
    activeTab = COMPANYINFORMATION;
  }
  const [currentTab, setCurrentTab] = useState(activeTab);
  const btnOfficeLocation = useRef(null);
  // const [isOpenModal, setIsOpenModal] = useState(false);

  const tabMenu = [
    {
      name: COMPANYINFORMATION,
      showBadge: false,
      total: 0,
      disabled: false,
    },
    {
      name: OFFICELOCATIONS,
      showBadge: false,
      total: 0,
      disabled: !allowedOfficeLocations,
    },
    {
      name: PROJECTEXPERIENCES,
      showBadge: false,
      total: 0,
      disabled: false,
    },
    {
      name: KEYPERSONNELEXPERTISE,
      showBadge: false,
      total: 0,
      disabled: false,
    },
    {
      name: CORPORATEDOCUMENTS,
      showBadge: false,
      total: 0,
      disabled: !allowedDocument,
    },
    {
      name: SHAREDPROFILES,
      showBadge: false,
      total: 0,
      disabled: false,
    },
  ];

  const changeActiveTab = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <div>
      <TabHeader
        heading="COMPANY PROFILE"
        description=""
      />
      <div className="mt-5 mb-5">
        <div className={"flex gap-3"}>
          <TabsComp
            menus={tabMenu}
            activeTab={currentTab}
            changeActiveTab={changeActiveTab}
            isLoading={false}
          ></TabsComp>
          {/* <Button
            btntype="tab"
            onClick={() => setIsOpenModal(true)}
          >
            Share Profile
          </Button> */}
        </div>
      </div>
      <div className="border-b border-gray-200"></div>
      <div className="mt-12">
        {currentTab === COMPANYINFORMATION && (
          <CompanyInformation formikRef={formikRef} />
        )}
        {currentTab === OFFICELOCATIONS && (
          <>
            {allowedOfficeLocations && (
              <CompanyLocationForm
                company={company}
                btnOfficeLocation={btnOfficeLocation}
              />
            )}
          </>
        )}
        {currentTab === PROJECTEXPERIENCES && (
          <ProjectListing
            path="projects"
            projects={pastProjects}
            companyId={company?.id}
          />
        )}
        {currentTab === KEYPERSONNELEXPERTISE && (
          <Resources
            path="resources/*"
            resources={resources}
            isRequesting={requestingResources}
            companyId={company.id}
          />
        )}
        {currentTab === CORPORATEDOCUMENTS && (
          <>
            {allowedDocument && (
              <Documents
                path="documents"
                documents={documents}
                editable
              />
            )}
            <Documents
              path="documents"
              documents={documents}
              editable
            />
          </>
        )}
        {currentTab === SHAREDPROFILES && (
          <CompaniesListing company={company}></CompaniesListing>
        )}
      </div>
      {/* <ShareProfileModal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
      /> */}
    </div>
  );
};
export default CompanyProfileV2;
