import { Flex } from "@chakra-ui/react";
import { useParams } from "@reach/router";
import {
  createSupplierList,
  removeAllToListDirect,
} from "actions/searchActions";
import apiV2 from "api-v2";
import { Icon } from "assets/icons/Icon";
import { Avatar } from "components/RFQ/Avatar";
import { Badge } from "components/RFQ/Badge";
import { Button } from "components/RFQ/Button";
import { Input } from "components/RFQ/Input";
import NotFoundDatas from "components/RFQ/NotFoundDatas/NotFoundDatas";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/RFQ/Table";
import { SkeletonBase } from "components/Skeleton";
import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import tw, { styled, css } from "twin.macro";
import Contract from "../Contract/Contract";
import moment from "moment";

const ListViewStyled = styled.div(() => [
  tw`my-8 rounded-2xl`,
  css`
    box-shadow: 0px 0px 10px -4px rgba(0, 0, 0, 0.25);
    .title {
      font-weight: 500;
      font-family: Roboto;
      font-size: 14px;
    }

    .text {
      font-weight: 400;
      font-family: Roboto;
      font-size: 14px;
    }
  `,
]);

const SkeletonCustom = styled.div(() => [
  tw`animate-pulse bg-gray-300 w-full h-6`,
]);

const SkeleteonRow = () => (
  <TableRow>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
    <TableCell>
      <SkeletonCustom />
    </TableCell>
  </TableRow>
);

const CompanyList = ({
  suppliers,
  loadList,
  suppliersStatus,
  MenuDropdown,
  checkedRow,
  setCheckedRow,
  onContractAddOrRemove
}) => {
  let params = useParams();
  const supplierListId = params.id;
  const supplierListName = params.slug;
  const dispatch = useDispatch();
  const isLoading = loadList || suppliersStatus === "loading";
  const rowSelected = (rowId) => checkedRow.some((row) => row.id === rowId);
  const companyId = useSelector((state) => state.auth.user.company_id);
  // const checkNoNull = (text, secondText) => {
  //   if (text) {
  //     text = text.charAt(0).toUpperCase() + text.slice(1);
  //     return secondText ? text + ", " : text;
  //   } else {
  //     return "";
  //   }
  // };
  const checkStatusCancel = (company) => company?.account_status === "cancelled";
  const checkedIds = checkedRow.map((row) => row.id);

  const TableHeadComponent = ({ title }) => {
    return (
      <div
        className=" font-roboto text-text-tertiary-600 text-xs th"
        style={{ gap: "8px", alignItems: "center" }}
      >
        {title}
      </div>
    );
  };

  const navigateCompareSelected = async (page) => {
    if (page === "shortlist") {
      let payload = {
        companyId: companyId,
        supplierIds: checkedIds,
      };
      let response = await apiV2.post(
        `/supplier/${supplierListId}/shortlist`,
        payload,
        {
          responseType: "blob",
        }
      );
      if (response) {
        const file = new Blob([response.data], {
          type: "application/pdf",
        });
    
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    } else {
      let payload = {
        id: supplierListId,
        name: supplierListName,
        active: suppliers,
      };
      await dispatch(createSupplierList(payload));
      await dispatch(removeAllToListDirect());
      setTimeout(() => {
        window.open(
          `/search/direct/compare?supplier=${supplierListId}&supplierlist=${supplierListName}&directcompare=true&suppliers=${checkedIds.join(",")}`,
        );
      }, 100);
    }
  };

  const checkHandler = (e, company) => {
    if (e.target.checked) {
      setCheckedRow((prevChecked) => [...prevChecked, company]);
    } else {
      setCheckedRow((prevChecked) =>
        prevChecked.filter((row) => row.id !== company?.id)
      );
    }
  };


  return (
    <ListViewStyled>
      <div className="w-full rounded-t-2xl border-gray-200 p-6">
        {loadList ? (
          <SkeletonBase />
        ) : (
          <div className="flex gap-4 items-center justify-between h-9">
            <div className="flex gap-4 items-center">
              {checkedRow?.length ? (
                <p>{checkedRow?.length} Selected out of {suppliers?.length}</p>
              ) : (
                <p>{suppliers?.length} {`Supplier${suppliers?.length > 1 ? "s" : ""}`}</p>
              )}
            </div>
            {checkedRow.length !== 0 && (
              <div className="flex gap-4 items-center">
                <Button
                  btntype="secondary"
                  size="xs"
                  onClick={() => navigateCompareSelected("compare")}
                >
                  <Flex align={"center"} gap={2}>
                    <Icon icon="switch-horizontal" color="#0031DD" />
                    <p className="font-roboto font-semibold text-utility-blue-dark-100">
                      Compare Selected
                    </p>
                  </Flex>
                </Button>

                <Button
                  btntype="secondary"
                  size="xs"
                  onClick={() => navigateCompareSelected("shortlist")}
                >
                  <Flex align={"center"} gap={2}>
                    <Icon icon="download" />
                    <p className="font-roboto font-semibold text-utility-blue-dark-100">
                      Download Shortlist PDF
                    </p>
                  </Flex>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <Table
        containerClass={tw`w-full rounded-2xl rounded-t-none border-t border-gray-200`}
      >
        <TableHeader>
          <TableRow>
            <TableHead firstCol={true} className="!pl-6">
              <div
                className="flex"
                style={{ gap: "8px", alignItems: "center" }}
              >
                <TableHeadComponent title="Company"></TableHeadComponent>
              </div>
            </TableHead>
            <TableHead className={tw`max-w-[208px]`}>
              <TableHeadComponent title="Contact"></TableHeadComponent>
            </TableHead>
            <TableHead className={tw`max-w-[208px]`}>
              <TableHeadComponent title="Contract"></TableHeadComponent>
            </TableHead>
            <TableHead className={tw`max-w-[208px]`}>
              <TableHeadComponent title="Expiry Date"></TableHeadComponent>
            </TableHead>
            <TableHead className={tw`max-w-[208px]`}>
              <TableHeadComponent title="Account Status"></TableHeadComponent>
            </TableHead>
            {/* <TableHead className={tw`max-w-[208px]`}>
              <TableHeadComponent title="Performance Management"></TableHeadComponent>
            </TableHead> */}
            <TableHead className={tw`max-w-[48px]`}>
              <TableHeadComponent />
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isLoading ? (
            [...Array(4)].map(() => <SkeleteonRow />)
          ) : suppliers?.length > 0 ? (
            suppliers?.map((company, index) => {
              return (
                <TableRow
                  key={index}
                  className={
                    `${rowSelected(company.id) &&
                      "[&>td]:bg-utility-blue-light-50/30"} group`
                  }
                >
                  <TableCell
                    firstCol={true}
                    className=" overflow-hidden !pl-6 group-hover:bg-utility-blue-light-50/30"
                  >
                    <div className="flex gap-4">
                      <div className="relative ml-2 text-black flex gap-2 items-center">
                        <Input
                          type="checkbox"
                          className="mr-2"
                          checked={rowSelected(company.id)}
                          onClick={(e) => checkHandler(e, company)}
                        />
                        <Avatar
                          avatar={company.logo_path}
                          size="lg"
                          className="bg-white shadow-md"
                          defaultImage="company"
                        />
                        <a href={`/company/${company?.id}`} target="_blank" rel="noreferrer" className="title text-text-tertiary-700">
                          {company.name ? company.name : " - "}
                        </a>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className={tw` group-hover:bg-utility-blue-light-50/30`}>
                    <p className="text !font-medium !text-text-primary-900">{company?.contact_name || " - "}</p>
                    <p className="text">{company?.email || " - "}</p>
                  </TableCell>
                  <TableCell className={tw` group-hover:bg-utility-blue-light-50/30`}>
                    <Contract company={company} supplierListId={supplierListId} onContractAddOrRemove={onContractAddOrRemove}/>
                  </TableCell>
                  <TableCell className={tw` group-hover:bg-utility-blue-light-50/30`}>
                    <p className="text !font-medium !text-text-primary-900">{company?.contract?.contract_expiry?moment(company?.contract?.contract_expiry).format("D MMM YYYY"):'-'}</p> 
                  </TableCell>
                  <TableCell className={tw`max-w-[218px] group-hover:bg-utility-blue-light-50/30`}>
                    <p className="text">
                      {
                        <Badge 
                          className='!border-0 !font-medium' 
                          color={checkStatusCancel(company) ? 'error' : 'default'} >{checkStatusCancel(company) ? 'Suspended' : 'Active' }
                        </Badge>
                      }
                    </p>
                  </TableCell>
                  {/* <TableCell className={tw`max-w-[2px] group-hover:bg-utility-blue-light-50/30`}>
                      <Badge className='!border-0 !font-medium' >Coming soon</Badge>
                  </TableCell> */}
                  <TableCell className={tw`max-w-20 min-w-20 w-20 group-hover:bg-utility-blue-light-50/30`}>
                    <MenuDropdown companyId={company?.id} />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow className="col-span-5 h-[500px]">
              <div className="absolute -translate-x-2/4 left-2/4">
                <NotFoundDatas
                  text={`No Supplier Found`}
                  description={"To get started, Add Suppliers to your List"}
                />
              </div>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </ListViewStyled>
  );
};

export default CompanyList;
