import { Image } from '@chakra-ui/react';
import { Icon } from 'assets/icons/Icon';
import { Checkbox } from 'components/FormInputs/Checkbox';
import React from 'react';
import { styled } from 'twin.macro';
import tw, { css } from 'twin.macro';


const sizes = {
    sm: tw`px-2 py-1 text-xs max-h-6`,
    md: tw`px-2 py-0.5 text-sm max-h-6`,
    lg: tw`px-2.5 py-1.5 text-sm h-7`,
};

const TagsStyled = styled.div(({ size }) => [
    tw`flex flex-wrap gap-1.5 font-medium border border-gray-300 rounded-lg items-center content-center w-max bg-gray-50`,
    sizes[size],
    tw`transition-colors`,
    css`
        &:hover .hovered-icon-class {
            background-color: #edfeff;
            color: #667085;
        }
    `,
]);

const Tags = ({ size = 'md', count, img, checkbox, checked, children, deletable, countable,handleDelete, ...props }) => {
    return (
        <>
            <TagsStyled size={size} {...props}>
                {checkbox ? (
                    <>
                        <Checkbox size="sm" classNameLabel="ml-0 gap-[2px]">
                            {img && <Image width={16} height={16} src={img} className="rounded-full" />}
                            {children}
                            {countable && <div className="bg-gray-100 rounded-sm w-5 h-5 text-center items-center place-content-center">{count}</div>}
                        </Checkbox>
                        {deletable && <Icon icon={'close'} width={8} height={8} color="#D0D5DD" style={{cursor:'pointer'}} onClick={handleDelete} />}
                    </>
                ) : (
                    <>
                        {img && <Image width={16} height={16} src={img} className="rounded-full" />}
                        {children}
                        {deletable && <Icon icon={'close'} color="#D0D5DD" style={{width:'10px', height:'10px', cursor:'pointer'}}  onClick={handleDelete} />}
                        {countable && <div className="bg-gray-100 rounded-sm w-5 h-5 text-center items-center place-content-center">{count}</div>}
                    </>
                )}
            </TagsStyled>
        </>
    );
};

export default Tags;
