import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { thunk as thunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';

// import monitorReducersEnhancer from './enhancers/monitorReducers';
import loggerMiddleware from './middleware/logger';
import rootReducer from './reducers';
import { SUPPLIERRFQ } from 'constants/supplier';
import { GUESTRFQ_STORAGE } from 'constants/guest';

export default function configureStore(preloadedState) {
    let initialState = {
        ...preloadedState,
        search:localStorage.getItem("search")?JSON.parse(localStorage.getItem("search")):preloadedState?.search,
        supplier_rfq:localStorage.getItem(SUPPLIERRFQ)?JSON.parse(localStorage.getItem(SUPPLIERRFQ)):preloadedState?.supplier_rfq,
        guest_rfq:localStorage.getItem(GUESTRFQ_STORAGE)?JSON.parse(localStorage.getItem(GUESTRFQ_STORAGE)):preloadedState?.guest_rfq,
        sidebar:localStorage.getItem("sidebar")?JSON.parse(localStorage.getItem("sidebar")):preloadedState?.sidebar,
    }
    const middlewares = [thunkMiddleware, loggerMiddleware];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);

    const store = createStore(rootReducer, initialState, composedEnhancers);

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
    }

    return store;
}
