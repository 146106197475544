import React from "react";

import { TableBody } from "./styled";
import { DataRow } from "../../components/CompanyResultCardTable/DataRow";
import { DataHeadings } from "../SearchTemplate/styled";

export const SearchResultsTable = ({
  noDataTable,
  compareTable,
  searchCriteria,
  directcompare,
  compareProjectHeight,
  ...props
}) => {
  return (
    <TableBody paddingTop="0px">
      {!noDataTable && (
        <DataHeadings compareTable={compareTable}>
          <DataRow  contentType="suburb-city">
            <h3>Suburb / City</h3>
          </DataRow>
          <DataRow contentType="lga">
            <h3>Local Government Area</h3>
          </DataRow>
          <DataRow  contentType="company-size">
            <h3>Company Size</h3>
          </DataRow>
          <DataRow  contentType="anual-turnover">
            {searchCriteria.profile_type !== 1 &&
              searchCriteria.profile_type !== 4 && searchCriteria.profile_type !== 3 ? (
              <h3>Annual Turnover</h3>
            ) : <h3>Specialty</h3>}
          </DataRow>
          {/* disable this when direct compare */}
          {!directcompare && <DataRow
            contentType="sector"
            sectorHeight={props?.sectorHeight}
            compareTable={compareTable}
          >
            {searchCriteria.profile_type !== 1 && (
              <>
                {searchCriteria.profile_type === 4 && (
                  <h3>Expertise & Disciplines</h3>
                )}
                {searchCriteria.profile_type === 3 && <h3>Trade/s</h3>}
                {searchCriteria.profile_type === 2 && <h3>Specialty</h3>}
              </>
            )}
          </DataRow>}
          {/* end disable this when direct compare */}
          {searchCriteria.profile_type !== 3 ?
            (compareTable ? (
              <DataRow contentType="avatar-list" height="380px">
                <h3>No. of key personnel with relevant sector experience</h3>
              </DataRow>
            ) : (
              <DataRow contentType="avatar-group" height="380px">
                <h3>No. of key personnel with relevant sector experience</h3>
              </DataRow>
            )) : <DataRow contentType="avatar-group" height="380px">
              <h3>No. of key personnel with relevant sector experience</h3>
            </DataRow>}
          {compareTable ? (
            <DataRow contentType="project-list" height={`${compareProjectHeight}px`}>
              <h3>{directcompare === true ? 'No. of latest completed projects from the current year' : 'No. of projects relevant to sector'}</h3>
            </DataRow>
          ) : (
            <DataRow contentType="project-images" height="380px">
              <h3>No. of projects relevant to sector</h3>
            </DataRow>
          )}

          <DataRow contentType="documents" height="380px">
            <h3>Corporate Info</h3>
          </DataRow>
        </DataHeadings>
      )}
      {props.children}
    </TableBody>
  );
};
