import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import { Heading } from "../Heading";
import { TextInput } from "../FormInputs/TextInput";
import { Submit } from "../FormInputs/Submit";
import { connect } from "react-redux";
import { forgotPassword } from "../../actions/authActions";
import { guestForgotPassword } from "../../actions/guestActions";
import { OnSubmitValidationError } from "../../utils/OnSubmitValidationError";

import rightImg from "./../../icons/login-right-img.svg";
import { BackButton } from "../BackButton/BackButton";
import { Link, navigate } from "@reach/router";
import {
  LeftSection,
  LoginSection,
  LoginWrapper,
  RightSection,
} from "../Login/styled";
import { ResetPasswordWrapper } from "../ResetPassword/styled";
import { FlashError } from "../FormInputs/FlashError";
import { LogoWrapper } from "../LogoWrapper/LogoWrapper";

export const ResetPasswordComponent = ({ ...props }) => {
  const [visible, setVisible] = useState(0);
  let guest = false;
  if (window.location.href.includes("guest")) {
    guest = true;
  }
  const handleSubmit = async (values, setStatus, setSubmitting) => {
    setStatus(null);
    setSubmitting(true);
    if (guest) {
      try {
        const result = await props.guestForgotPassword(values, setSubmitting);
        if (result.success) {
          setVisible(1);
        } else {
          setStatus("not_found");
        }
      } catch (e) {
        setStatus("backend");
      }
    } else {
      try {
        const result = await props.forgotPassword(values, setSubmitting);
        if (result.status) {
          setVisible(1);
        } else {
          setStatus("not_found");
        }
      } catch (e) {
        setStatus("backend");
      }
    }
  };

  return (
    <LoginWrapper>
      <ResetPasswordWrapper>
        <LeftSection>

          <LogoWrapper />

          <LoginSection>
            {visible === 0 ? (
              <>
                <div className="reset-arrow">
                  <BackButton
                    label={"Back"}
                    handleOnClick={() => navigate(-1)}
                  />
                </div>
                <Heading marginBottom="16px">
                  <span>FORGOT MY PASSWORD</span>
                </Heading>
                <p className="instructions">
                  A link to reset your password can be sent to your email. Enter
                  your email below.
                </p>

                <Formik
                  enableReinitialize
                  initialValues={{ email: "" }}
                  validationSchema={Yup.object({
                    email: Yup.string().email().required(),
                  })}
                  onSubmit={(values, { setStatus, setSubmitting }) => {
                    handleSubmit(values, setStatus, setSubmitting);
                  }}
                >
                  {(props) => {
                    const { setFieldValue, isSubmitting, status, setStatus } =
                      props;
                    const onSubmitValidationError = () => {
                      setStatus("required");
                    };

                    return (
                      <Form>
                        <div className="row">
                          <TextInput
                            label="Enter your email"
                            placeholder="Email"
                            name="email"
                            required={true}
                            type="email"
                            className="col xl-12 text-mr"
                            setFieldValue={setFieldValue}
                          />
                          <div className="col xl-12">
                            {status && (
                              <FlashError
                                heading={errorMessages[status].heading}
                                text={errorMessages[status].description}
                              />
                            )}
                          </div>
                          <div className="col xl-12">
                            <Submit
                              type="submit"
                              widthExpand
                              marginTop="32px"
                              isSubmitting={isSubmitting}
                              text="SEND RECOVERY EMAIL"
                              submittingText="Sending..."
                            />
                          </div>
                        </div>
                        <OnSubmitValidationError
                          callback={onSubmitValidationError}
                        />
                      </Form>
                    );
                  }}
                </Formik>
              </>
            ) : (
              <div className="message">
                <div className="reset-arrow">
                  <BackButton
                    label={"Back"}
                    handleOnClick={() => setVisible(0)}
                  />
                </div>
                <Heading marginBottom="16px">
                  <span>Reset link has been sent</span>
                </Heading>
                <p className="instructions">
                  Password reset link has been sent to your email. Please check
                  your inbox to reset password.
                </p>
              </div>
            )}
          </LoginSection>
          <div className="float-bottom">
            <p>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </p>
            <p>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </p>
            <p>
              <Link to="#">
                © {new Date().getFullYear()} Procuracon. All right reserved.
              </Link>
            </p>
          </div>
        </LeftSection>
        <RightSection
          style={{ backgroundImage: `url(${rightImg})` }}
        ></RightSection>
      </ResetPasswordWrapper>
    </LoginWrapper>
  );
};

const errorMessages = {
  required: {
    heading: "* Required Field",
    description: "There are incomplete required fields, Please complete them.",
  },
  not_found: {
    heading: "Email Not Found",
    description: "An account with this email can not be found.",
  },
  backend: {
    heading: "Internal Error",
    description: "Unable to process your request.",
  },
};

export const ResetPassword = connect(null, {
  guestForgotPassword,
  forgotPassword,
})(ResetPasswordComponent);
