import * as constants from "../actions/actionTypes";

const INITIAL_STATE = 1

export const profileTypeReducer = (
    state = INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case constants.CREATE_PROFILE_TYPE:
            return action.payload
        default:
            return state;
    }
};
