import { Icon } from 'assets/icons/Icon'
import React, { useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'
import useOnClickOutside from 'utils/useClickOutside'

const SortButtonStyled = styled.button(() => [
    tw`h-10 bg-white text-text-secondary-700 text-sm font-semibold gap-2 px-4 py-2 border border-[#D0D5DD] flex items-center shadow-input rounded-[4px] self-center overflow-hidden
    hover:bg-gray-50 focus:ring-2 focus:ring-gray-200
    `
])

const DropdownMenuDrawerStyled = styled.div(() => [
    tw`grid bg-white  min-w-52 z-50 border-[#D0D5DD] mt-2 rounded-lg absolute shadow-card p-1`
])
const DropdownMenuItemStyled = styled.div(() => [
    tw`relative gap-2 items-center hover:bg-utility-blue-light-100  hover:text-utility-dark-50 focus:text-text-primary-700 cursor-pointer pl-4 pr-4 transition-all duration-200`
])

export const DropdownMenuDrawer = ({ children, ref, ...props }) => {
    return (
        <DropdownMenuDrawerStyled {...props} >
            {children}
        </DropdownMenuDrawerStyled>
    );
};
export const DropdownMenuItem = ({ children, ref, ...props }) => {
    return (
        <DropdownMenuItemStyled {...props} >
            {children}
        </DropdownMenuItemStyled>
    );
};



export const SortItemButton = ({ children, value, openPopUp, setOpenPopUp, classNameDrawer }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);



    useOnClickOutside(dropdownRef, () => {
        setIsOpen(false);
        setOpenPopUp(false);
    });
    return (
        <div ref={dropdownRef} style={{ alignContent: 'center' }}>
            <SortButtonStyled onClick={() => { setIsOpen(true); setOpenPopUp(true) }}>
                <span className='2xl:hidden font-roboto font-semibold  text-text-secondary-700 text-sm'>
                    {value}
                </span>
                <span className='hidden 2xl:block font-roboto font-semibold  text-text-secondary-700 text-sm'>
                    Sort
                </span>
                <Icon icon='chevron-down' style={{ color: '#344054',fontWeight:'bold' }} />
            </SortButtonStyled>
            {(isOpen && openPopUp) && <DropdownMenuDrawer
                className={classNameDrawer}
                ref={dropdownRef}
            >
                {children}
            </DropdownMenuDrawer>}
        </div>
    )
}

