import { Spinner } from '@chakra-ui/react'
import { Icon } from 'assets/icons/Icon'
import React from 'react'
import tw, { styled } from 'twin.macro'

const UtilityButtonStyled = styled.button(()=>[
    tw`h-[44px] font-roboto bg-white text-text-secondary-700 font-semibold px-6 py-4 border border-[#D0D5DD] flex gap-2 items-center rounded-[4px] self-center hover:bg-text-primary-50`
])

const UtilityButton = ({text,icon,loading,className,...props}) => {
  return (
    <UtilityButtonStyled {...props} className={className?className:'disabled:opacity-50'} >
      {!loading ?
      <>
        <Icon icon={icon}/>
        <text className='capitalize text-base font-semibold'>{text}</text>
      </>
      :
      <Spinner />
      }
    </UtilityButtonStyled>
  )
}

export default UtilityButton