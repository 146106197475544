import { combineReducers } from "redux";
import { searchReducer } from "./searchReducer";
import { companyProfileReducer } from "./companyProfileReducer";
import { sectorsReducer } from "./sectorsReducer";
import { globalPresenceReducer } from "./globalPresenceReducer";
import { authReducer } from "./authReducer";
import { accountTypeReducer } from "./accountTypeReducer";
import { accountManagementReducer } from "./accountManagementReducer";
import { rfqReducers } from "./rfqReducer";
import { profileTypeReducer } from "./profileTypeReducer";
import { supplierRFQReducer } from "./supplierflow/supplierRFQReducer";
import { guestReducer } from "./guestflow/guestReducer";
import { guestRFQReducer } from "./guestflow/guestRFQReducer";
import { sidebarReducer } from "./sidebarReducer";

const appReducer = combineReducers({
  accountTypes: accountTypeReducer,
  search: searchReducer,
  companyProfile: companyProfileReducer,
  sectors: sectorsReducer,
  globalPresence: globalPresenceReducer,
  auth: authReducer,
  accountManagement: accountManagementReducer,
  rfq: rfqReducers,
  profile_type:profileTypeReducer,
  supplier_rfq:supplierRFQReducer,
  guest_rfq:guestRFQReducer,
  guest:guestReducer,
  sidebar:sidebarReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
